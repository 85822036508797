/* Homepage > Organisations > Login */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useStateValue } from '../../services/state';
import { useGetScreenData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createSubmitData, createOrgSubmitData } from '../../utils/helper';
import { validatePhone } from '../../utils/validation';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { H3, P, PSmall } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import Loader from  '../../components/Loader';
import LoadingScreen from '../LoadingScreen';

const TwoFactorScreen = () => {

  const [{ context, loading }] = useStateValue();

  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);

  const [ processing, setProcessing ] = useState(false);
  const [ resendProcessing, setResendProcessing ] = useState(false);
  const [ error, setError ] = useState();

  const [ phoneNumber, setPhoneNumber ] = useState(null);
  const [ phoneNumberError, setPhoneNumberError ] = useState(null);
  const [ phoneNumberFormatted, setPhoneNumberFormatted ] = useState(null);
    
  const [ codeSent, setCodeSent ] = useState(false);

  const [ uniqueCode, setUniqueCode ] = useState(null);
  const [ uniqueCodeError, setUniqueCodeError ] = useState(null);

  const [ newRegistration, setNewRegistration ] = useState(false);

  useGetScreenData('organisations/two-factor', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (context) {
      if (context.code_sent) {
        setCodeSent(true);
      }
      if (context.verified_phone_number) {
        setPhoneNumberFormatted(context.verified_phone_number);
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const onBlurPhoneNumber = e => {
    if (!validatePhone || !validatePhone(e.target.value)) {
      setPhoneNumberError("You must enter a valid UK phone number");
    } else {
      setPhoneNumberError(null);
    }
  };

  const validatePhoneNumberForm = () => {
    if (phoneNumber && validatePhone(phoneNumber)) {
      return true;
    } else {
      return false;
    }
  };

  const onPhoneNumberKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      submitPhoneNumberForm();
    }
  };

  const submitPhoneNumberForm = async () => {
    if (!validatePhoneNumberForm() || processing) return null;
    setProcessing(true);

    const submitData = createSubmitData({
      phone_number: phoneNumber,
    });
    axios.post('/api/v1/2fa_register', submitData).then((res) => {
      setProcessing(false);
      if (res.data && res.data.success === true) {
        setPhoneNumberFormatted(res.data.phone_number_formatted);
        setCodeSent(true);
        setError(null);
      } else {
        setError(res.data.error);
      }
    });
  }

  const handleSkip = async () => {
    if (context && context.can_skip) {
      if (processing) return null;
      setIsLoading(true);
  
      // Set two_factor_state on the session as "skipped" and redirect to the dashboard
  
      const submitData = createOrgSubmitData({});
      axios.post('/api/v1/2fa_skip', submitData).then((res) => {
        setIsLoading(false);
        history.push(`/organisations/dashboard`);
      });
    }
  }

  const handleUniqueCodeChange = (value) => {
    setUniqueCode(value);
  };

  const onBlurUniqueCode = e => {
    if (!uniqueCode || uniqueCode.length !== 6) {
      setUniqueCodeError("Please enter the 6-digit number you should receive via SMS");
    } else {
      setUniqueCodeError(null);
    }
  };

  const validateUniqueCodeForm = () => {
    if (uniqueCode && uniqueCode.length === 6) {
      return true;
    } else {
      return false;
    }
  };

  const onUniqueCodeKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      submitUniqueCodeForm();
    }
  };

  const submitUniqueCodeForm = async () => {
    if (!validateUniqueCodeForm() || processing || resendProcessing) return null;
    setProcessing(true);

    const submitData = createOrgSubmitData({
      phone_number : phoneNumberFormatted,
      code: uniqueCode,
    });
    axios.post('/api/v1/2fa_verify', submitData).then((res) => {
      setProcessing(false);
      if (res.data && res.data.success === true) {
        setError(null);
        if (res.data.new_registration) {
          setNewRegistration(true);
        } else {
          history.push(`/organisations/dashboard`);
        }
      } else {
        setError(res.data.error);
      }
    });
  }

  const handleResendCode = async () => {
    if (processing || resendProcessing) return null;
    setResendProcessing(true);

    // Resend the 2FA code to the user's phone number

    const submitData = createOrgSubmitData({
      phone_number : phoneNumberFormatted,
      code: uniqueCode,
    });
    axios.post('/api/v1/2fa_resend', submitData).then((res) => {
      setResendProcessing(false);
      if (res.data && res.data.success === true) {
        setError(null);
      } else {
        setError(res.data.error);
      }
    });
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  let pageTitle = "Organisations - Login";
  if (context && !codeSent) {
      pageTitle = "Organisations - Setup two-factor authentication";
  }

  return(
    <>
      <MetaTags>
        <title>CareersPro - Organisation Login</title>
        <meta id="meta-description" name="description" content="CareersPro - Organisation login" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={pageTitle} />
        <CircleOne />
        <CircleTwo />
        {context && !codeSent && !newRegistration && (
          <>
            <SubTitle>Setup two-factor authentication</SubTitle>
            <Text>To make your account more secure, set up two-factor authentication by adding your mobile phone number. Once registered, in order to log in you'll need to enter your password as well as a unique code that we'll send you via SMS to your mobile phone.</Text>
            <Grid>
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <Form>
                    <Input
                      type="tel"
                      name="phone"
                      label="Phone number"
                      onBlur={onBlurPhoneNumber}
                      handleChange={(value) => handlePhoneNumberChange(value)}
                      handleKeyDown={onPhoneNumberKeyDownHandler}
                      error={phoneNumberError}
                    />
                  </Form>
                  <ButtonContainer>
                    {processing ? (
                      <Loader />
                    ) : validatePhoneNumberForm() ? (
                      <PrimaryButton onClick={() => submitPhoneNumberForm()}>Register for two-factor authentication</PrimaryButton>
                    ) : (
                      <Tooltip text="Please enter your UK phone number above">
                        <GrayButton disabled>Register for two-factor authentication</GrayButton>
                      </Tooltip>
                    )}
                  </ButtonContainer>
                  {error && (
                    <Error>
                      <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                    </Error>
                  )}
                </Col>
              </Row>
            </Grid>
            {context.can_skip && (
              <BackButtonContainer>
                <SecondaryButton onClick={handleSkip}>Skip</SecondaryButton>
              </BackButtonContainer>
            )}
          </>
        )}
        {context && codeSent && !newRegistration && (
          <>
            <SubTitle>Enter unique code</SubTitle>
            <Text>You should receive an SMS shortly with a unique code. {context.two_factor_state === "unauthenticated" ? "Enter the code below to login." : "Enter the code below to register your mobile phone for two-factor authentication."} This code will expire in ten minutes.</Text>
            <Grid>
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <Form>
                    <Input
                      type="text"
                      name="code"
                      label="Unique code"
                      onBlur={onBlurUniqueCode}
                      handleChange={(value) => handleUniqueCodeChange(value)}
                      handleKeyDown={onUniqueCodeKeyDownHandler}
                      error={uniqueCodeError}
                    />
                  </Form>
                  <ButtonContainer>
                    {processing ? (
                      <Loader />
                    ) : validateUniqueCodeForm() ? (
                      <PrimaryButton onClick={() => submitUniqueCodeForm()}>Submit</PrimaryButton>
                    ) : (
                      <Tooltip text="Please enter the 6-digit code you should receive via SMS">
                        <GrayButton disabled>Submit</GrayButton>
                      </Tooltip>
                    )}
                  </ButtonContainer>
                  {error && (
                    <Error>
                      <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                    </Error>
                  )}
                </Col>
              </Row>
            </Grid>
            <BackButtonContainer>
              {resendProcessing ? (
                <Loader />
              ) : (
                <SecondaryButton onClick={handleResendCode}>Resend code</SecondaryButton>
              )}
            </BackButtonContainer>
            {context.two_factor_state === "unauthenticated" && (
              <Text>If you’re having trouble logging in, contact us on support@careerspro.co.uk</Text>
            )}
          </>
        )}
        {newRegistration && (
          <>
            <SubTitle>Mobile phone registered</SubTitle>
            <Text>You have successfully registered your mobile phone for two-factor authentication.  Each time you log in, you'll need to enter your password and the unique code that we'll send you via SMS.</Text>
            <BackButtonContainer>
              <PrimaryButton onClick={() => {history.push(`/organisations/dashboard`);}}>Proceed to dashboard</PrimaryButton>
            </BackButtonContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default TwoFactorScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const Form = styled.div`
  margin: auto;
  margin-top: 32px;
  max-width: 356px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 32px;
  margin-bottom: 20px;
  text-align: center;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const Error = styled.div`
  margin-top: 4px;
  text-align: center;
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  padding-top: 20px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-wrap: anywhere;
  max-width: 800px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-top: 12px;
    padding-bottom: 8px;
    max-width: 600px;
  }
`;