import { useEffect} from "react";
import { useLocation } from "react-router-dom";


/**
 * Hook to handle tracking pageviews on location change trigged from ReactRouter route
 */
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // If we have access to GA script, trigger pageview event
    if (window.ga) {
      // Timeout before sending event so we send the correct page title
      setTimeout(() => {
        if (window.ga && window.ga.getAll) {
          const analytics = window.ga.getAll()[0];
          analytics.set('page', location.pathname + location.search);
          analytics.set('title', document.title);
          analytics.send('pageview');
        }
      }, 1500);
    } 
  }, [location]);
};

export default usePageViews;