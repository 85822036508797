/* Final results, then wait 3 minutes for ratings modal to appear */

import React, { useState, useEffect } from 'react';
import { Amplitude } from "@amplitude/react-amplitude";

import { useStateValue } from "../../services/state";

import Modal from '../../components/Modal';
import Feedback from './Feedback';


let timeout = null;

const FeedbackModal = () => {
  const [{ user_id, context }] = useStateValue();
  const [ open, setOpen ] = useState(false);
  const [ numTimesShownRatingDialog, setNumTimesShownRatingDialog ] = useState(0);

  useEffect(() => {
    if (context && (context.final_results || context.career_id) && !timeout && !context.rating && user_id && !context.are_some_careers_locked) {
      if (numTimesShownRatingDialog < 3) {
        timeout = setTimeout(() => {
          setNumTimesShownRatingDialog(numTimesShownRatingDialog+1);
          setOpen(true);
          timeout = null;
        }, 180000);
      }
    } else if (timeout && (!context || (!context.final_results && !context.career_id))) {
      clearTimeout(timeout);
      timeout = null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const closeModal = (logEvent) => {
    setOpen(false);
    logEvent('Close Feedback Modal');
  };

  const checkPathways = () => {
    if (context) {
      if (context.final_results) {
        const pw = context.final_results.find(item => item.num_pathways > 0);
        return pw;
      }
      if (context.career_id >= 0) {
        const pw = context.pathways.length > 0;
        return pw;
      }
    }
  };

  return (
    <Amplitude userProperties={{rating: (context && context.rating)}}>
      {({logEvent}) => (
        <Modal
          open={open}
          handleClose={() => {closeModal(logEvent); logEvent('Close Feedback Modal')}}
          large
          hasCloseButton
          largeContent
          topZ
        >
          <Feedback
            hasPathways={checkPathways()}
            closeModal={() => {setOpen(false); logEvent('Close Feedback Modal')}}
          />
        </Modal>
      )}
    </Amplitude>
  );
};

export default FeedbackModal;
