import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from '../config/colors';
import layout from '../config/layout';
import { H3 } from '../ui/typography/Typography';
import { PrimaryButton, SecondaryButton } from '../ui/buttons/Buttons';
import IconClose from '../assets/icon-close.svg';

const Modal = ({
  children,
  open,
  buttonPrimaryLabel,
  handlePrimaryButtonClick,
  buttonSecondaryLabel,
  handleSecondaryButtonClick,
  handleClose,
  title,
  large,
  hasCloseButton,
  largeContent,
  topZ,
  hideBottomPaddingMobile,
}) => {
  const [close, setClose] = useState(false);

  const closeModal = () => {
    if (close) {
      setClose(false);
      handleClose();
    }
  };

  const closeModalOnEsc = e => {
    if (e.keyCode === 27) setClose(true);
  };

  useEffect(() => {
    window.addEventListener('keyup', closeModalOnEsc);

    return () => {
      window.removeEventListener('keyup', closeModalOnEsc)
    }
  }, [])

  useEffect(() => {
    if (open) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }
  }, [open])

  if (!open) return null;

  const onPrimaryButtonClick = () => {
    handlePrimaryButtonClick && handlePrimaryButtonClick();
    document.body.classList.remove('fixed');
    setClose(true);
  };

  const onSecondaryButtonClick = () => {
    handleSecondaryButtonClick && handleSecondaryButtonClick();
    document.body.classList.remove('fixed');
    setClose(true);
  };

  const handleBgClick = e => {
    if (e.target.classList.contains('bg')) {
      setClose(true);
    }
  };

  return (
    <>
      <ModalBg close={close} topZ={topZ} />
      <Container close={close} topZ={topZ} onAnimationEnd={closeModal} hasCloseButton={hasCloseButton} largeContent={largeContent} onClick={handleBgClick} className="bg">
        {hasCloseButton && (
          <CloseButton onClick={() => setClose(true)}></CloseButton>
        )}
        <Content large={large} hasCloseButton={hasCloseButton} hideBottomPaddingMobile={hideBottomPaddingMobile}>
          {title && (
            <H3Styled>{title}</H3Styled>
          )}
          {children}
          {(buttonPrimaryLabel || buttonSecondaryLabel) && (
            <ButtonContainer>
              {buttonSecondaryLabel && (
                <Button onClick={onSecondaryButtonClick}>
                  <SecondaryButton>{buttonSecondaryLabel}</SecondaryButton>
                </Button>
              )}
              {buttonPrimaryLabel && (
                <Button onClick={onPrimaryButtonClick}>
                  <PrimaryButton>{buttonPrimaryLabel}</PrimaryButton>
                </Button>
              )}
            </ButtonContainer>
          )}
        </Content>
      </Container>
    </>
  );
};

export default Modal;

Modal.propTypes = {
  buttonPrimaryLabel: PropTypes.string,
  buttonSecondaryLabel: PropTypes.string,
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  handlePrimaryButtonClick: PropTypes.func,
  handleSecondaryButtonClick: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  open: false,
  hasCloseButton: false,
  largeContent: false,
};

const fadeIn = keyframes`
  0% {
    opacity: 0.0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.0;
  }
`;

const moveUp = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
`;

const moveDown = keyframes`
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
`;

const Container = styled.div`
  align-items: ${props => props.hasCloseButton || props.largeContent ? 'baseline' : 'center'};
  animation: ${props => props.close ? fadeOut : fadeIn} 0.62s forwards;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding-top: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${props=> props.topZ ? 102 : 100};

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 0;
  }
`;

const ModalBg = styled.div`
  background-color: ${colors.WHITE};
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: ${props=> props.topZ ? 101 : 99};
  top: 0;
  left: 0;
  opacity: 0;
  animation: ${props => props.close ? fadeOut : fadeIn} 0.62s forwards;
`;

const Content = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  max-width: ${props => props.hasCloseButton ? '100%' : 'calc(100% - 40px)'};
  padding: 32px;
  position: relative;
  width: ${props => props.large ? '900px' : '544px'};
  margin: 100px 0;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 16px;
    margin-bottom: ${props => props.hideBottomPaddingMobile ? 0 : '100px'};
    animation: ${props => props.close ? moveDown : moveUp} 0.62s forwards;
    ${props => !props.hasCloseButton && `
      animation: none;
    `};
  }
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
  text-align: right;
`;

const H3Styled = styled(H3)`
  margin-bottom: 16px;
`;

const Button = styled.a`
  margin-left: 16px;
`;

const CloseButton = styled.a`
  background-color: ${colors.LCG_DARK_PURPLE};
  background-image: url(${IconClose});
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 100%;
  height: 32px;
  position: fixed;
  right: 10px;
  top: 10px;
  width: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 20px;
    z-index: 1;
  }
`;
