/* Education screen > Degree tab
   Ref. EducationScreen.js
   May need to select "Yes" for "Do you have a degree?" to see the Degree tab */

import React, { useState } from 'react';
import styled from 'styled-components';

import { copyArray } from '../../utils/copyArray';
import { cleanStringForComparison } from '../../utils/helper';

import AutocompleteField from '../../ui/form/AutocompleteField';
import { Label, HintText } from '../../ui/typography/Typography';
import IncrementInput from '../../components/IncrementInput';
import Checkbox from '../../ui/form/Checkbox';

const Degree = ({ handleChange, options, university_autocomplete_options }) => {
  let grades = [
    { "id": 0, "name": "3rd" },
    { "id": 1, "name": "2:2" },
    { "id": 2, "name": "2:1" },
    { "id": 3, "name": "1st" },
  ];

  let subjectValues = [
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. French", id: 1, error: null },
    { value: "", placeholder: "e.g. Business", id: 2, error: null }
  ];

  const [subjects, setSubjects] = useState(subjectValues);
  const [grade, setGrade] = useState(grades[1]);

  const [university, setUniversity] = useState({value: "", placeholder: "Your university name", error: null, university_outside_uk: false});

  const onFieldBlur = (item, id) => {
    const val = options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item != null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(subjects);
      if (item !== "" || id === 0) {
        copy[id].error = "This is not a valid subject";
      }
      setSubjects(copy);
    }
  };

  const onUniversityFieldBlur = (item) => {
    const val = university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(university);
      copy.error = "This is not a valid university";
      setUniversity(copy);
    }
  };

  const handleInputFieldChange = (value, id) => {
    let copy = copyArray(subjects);
    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }

    setSubjects(copy);
    prepData(copy, grade, university);
  };

  const handleGradeChange = (value) => {
    setGrade(value);
    prepData(subjects, value, university);
  };

  const handleUniversityInputFieldChange = (value) => {
    let copy = copyArray(university);

    copy.error = null;
    copy.value = value.name || value;

    setUniversity(copy);
    prepData(subjects, grade, copy);
  };

  const handleUniversityOutsideUKChange = (value) => {
    let copy = copyArray(university);

    copy.university_outside_uk = value;

    setUniversity(copy);
    prepData(subjects, grade, copy);
  };

  const prepData = (subjs, grade, userUniversity) => {
    let qualifications = [];

    // eslint-disable-next-line no-unused-vars
    for (const subject of subjs) {
      if (options) {
        const val = options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));

        if (val) {
          qualifications.push({
            id: val.id,
            grade: grade.name,
          })
        }
      }
    }

    let degreeUniversity = null;
    if (university_autocomplete_options) {
      const matchedUniversity =
        university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((userUniversity.value !== null) ? cleanStringForComparison(userUniversity.value.toLowerCase()) : userUniversity.value));
      if (userUniversity.university_outside_uk) {
        degreeUniversity = -1;
      } else if (matchedUniversity) {
        degreeUniversity = matchedUniversity.id;
      }
    }

    handleChange(qualifications, degreeUniversity);
  };


  return (
    <>
      <GradeWrap>
        <Label>What grade did you achieve in your degree?</Label>
        <IncrementInput values={grades} startValue={grades[1]} handleChange={it => handleGradeChange(it)} />
      </GradeWrap>
      <Label>What subject is your degree in?</Label>
      <HintText>Type a subject and select from available options.  You can enter multiple subjects if you have a joint honours, or a degree with a major and a minor.</HintText>
      {subjects.map(item => (
        <InputWrap key={item.id}>
          <AutocompleteField
            placeholder={item.placeholder}
            suggestions={options}
            handleChange={it => handleInputFieldChange(it, item.id)}
            error={item.error}
            onBlur={e => onFieldBlur(e.target.value, item.id)}
          />
        </InputWrap>
      ))}
      <LabelSpaced>Where did you study your degree?</LabelSpaced>
      <HintText>Type, then select from available options.</HintText>
      <InputWrap>
        <AutocompleteField
          placeholder={university.placeholder}
          suggestions={university_autocomplete_options}
          handleChange={it => handleUniversityInputFieldChange(it)}
          error={university.error}
          onBlur={e => onUniversityFieldBlur(e.target.value)}
          disabled={university.university_outside_uk}
        />
      </InputWrap>
      <InputWrap>
        <Checkbox
          handleChange={it => handleUniversityOutsideUKChange(it)}
          label="I studied at a university outside the UK"
          name="university_outside_uk"
        />
      </InputWrap>
    </>
  );
};

export default Degree;

const InputWrap = styled.div`
  margin-bottom: 12px;
`;

const GradeWrap = styled.div`
  margin-bottom: 32px;
`;

const LabelSpaced = styled(Label)`
  margin-top: 32px;
`;
