import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { PSmall } from '../typography/Typography';
import IconTick from '../../assets/icon-tick.svg';

const Checkbox = ({
  error,
  defaultChecked,
  handleChange,
  label,
  name,
  disabled,
  testid
}) => {

  const onChange = e => {
    handleChange(e.target.checked);
  };

  return (
    <Container>
      <CheckboxContainer>
        <InputCheckBox type="checkbox" name={name} defaultChecked={defaultChecked} onChange={onChange} disabled={disabled} data-testid={testid} />
        <Label error={error} htmlFor={name}>{label}</Label>
      </CheckboxContainer>
      {error && (
        <Error>
          <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
        </Error>
      )}
    </Container>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  position: relative;
`;

const Label = styled.label`
  color: ${colors.GRAY};
  font-size: 16px;
  line-height: 24px;
  padding-left: 36px;
  line-height: 32px;
  position: relative;

  &::before {
    background-color: ${props => props.error ? colors.LCG_DARK_PURPLE_FADE : 'none'};
    border-radius: 8px;
    border: 2px solid  ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_LIGHT_GREY};
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 24px;
  }
`;

const InputCheckBox = styled.input`
  &[type=checkbox] {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &:disabled {
      &:hover + label,
      & + label {
        &::before {
          background-color: ${colors.LCG_LIGHTER_GREY};
        }
      }
    }

    &:checked {

      &:disabled {
        &:hover + label,
        & + label {
          &::before {
            background-color: ${colors.GRAY};
          }
        }
      }

      &:hover + label,
      & + label {
        &::before {
          background-color: ${colors.LCG_LIGHT_PURPLE};
          background-image: url(${IconTick});
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      &:active + label {
        &::before {
          background-image: none;
        }
      }
    }

    &:hover {
      & + label {
        &::before {
          background: ${colors.LCG_LIGHT_PURPLE_FADE};
          border-color: ${colors.LCG_LIGHT_PURPLE_FADE};
        }
      }
    }

    &:active + label {
      &::before {
        background-color: ${colors.LCG_LIGHT_PURPLE};
      }
    }
  }
`;

const Error = styled.div`
  margin-top: 4px;
`;
