/* Final results > Browse careers (at bottom) */

import React, { useState, useEffect } from 'react';
import styled from  'styled-components';
import { Amplitude } from '@amplitude/react-amplitude';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

import { copyArray } from '../../utils/copyArray';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';
import { sortResults } from './sort';
import colors from '../../config/colors';

import { Col, Row } from 'react-styled-flexboxgrid';
import { PrimaryButton, SecondaryButton } from  '../../ui/buttons/Buttons';
import CheckboxCard from '../../components/CheckboxCard';
import LoadingScreen from '../LoadingScreen';
import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import SelectBar from '../../components/SelectBar';
import { H5, P } from '../../ui/typography/Typography';
import layout from  '../../config/layout';
import PathwaysInfo from '../../components/PathwaysInfo';
import { Tag } from '../../ui/tag/Tag';

const BrowseCareers = () => {

  const [ careerButtonsActive, setCareerButtonsActive ] = useState([]);
  const [{ context, user_id, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const defaultLoadingMessage = "Loading all careers from our database..."
  const [ loadingMessage, setLoadingMessage ] = useState(defaultLoadingMessage);
  const history = useHistory();
  const match = useRouteMatch("/:userId/browse");

  const [ filterType, setFilterType ] = useState('none');
  const [ industry, setIndustry ] = useState({id: -1, label: 'all'});
  const [ quality, setQuality ] = useState({id: -1, label: 'all'});
  const [ sortType, setSortType ] = useState('time');
  const defaultNumCareersShowing = 20;
  const [ numCareersShowing, setNumCareersShowing ] = useState(defaultNumCareersShowing);

  const filterValues = [
    {label: "None", id: "none"},
    {label: "Industry", id: "industry"},
    {label: "Quality", id: "quality"},
  ];

  const sortValues = [];
  sortValues.push({label: "Time", id: "time"});
  sortValues.push({label: "Cost", id: "cost"});
  sortValues.push({label: "Salary", id: "salary"});
  if (context && context.include_skills_match) {
    sortValues.push({label: "Skills", id: "skills"});
  }
  sortValues.push({label: "Jobs", id: "jobs"});
  

  useEffect(() => {
    if (context && context.careers) {
      window.scrollTo(0, 0);
    }

    const handleScroll = (e) => {
      // If we are within two screens of the bottom, render more careers
      if ( (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop) < (e.target.scrollingElement.clientHeight * 3) ) {
        setNumCareersShowing(n => n + defaultNumCareersShowing);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [context]);

  useGetScreenData('browse', setIsLoading, match.params.userId);

  const handleFilterChange = (item) => {
    setFilterType(item.id);
    setNumCareersShowing(defaultNumCareersShowing);
  }

  const handleIndustryChange = (item) => {
    setIndustry(item);
    setNumCareersShowing(defaultNumCareersShowing);
  }
  
  const handleQualityChange = (item) => {
    setQuality(item);
    setNumCareersShowing(defaultNumCareersShowing);
  }

  const handleSortChange = (item) => {
    setSortType(item.id);
  }

  const sortCareers = (careers) => {
    let results = careers;

    if (sortType) {
      results = sortResults(careers, sortType);
    }

    return results;
  };

  const handleChange = (selected_item) => {
    let copy = copyArray(careerButtonsActive);
    const existingItem = copy.findIndex(item => item.career_id === selected_item.career_id);

    if (existingItem > -1) {
      copy.splice(existingItem, 1)
    } else {
      copy.push(selected_item);
    }
    setCareerButtonsActive(copy);
  };

  const save = (logEvent) => {

    // Get the career ids of those selected and post back to the server
    const careersSelected = careerButtonsActive.map(item => {
      return item.career_id;
    });

    if (careersSelected.length > 0) {

      let careerNames = [];
      for (let i=0; i<context.careers.length; i++) {
        if (careersSelected.includes(context.careers[i].career_id)) {
          careerNames.push(context.careers[i].career_name);
        }
      }
      let careerNamesString = "";
      for (let i=0; i<careerNames.length; i++) {
        if (careerNamesString !== "") {
          if (i === (careerNames.length -1)) {
            careerNamesString += " and ";
          } else {
            careerNamesString += ", ";
          }
        }
        careerNamesString += careerNames[i];
      }

      setLoadingMessage("Adding these careers to your results: " + careerNamesString);
      setIsLoading(true);

      logEvent('Added Careers (Browse)', {
        careers: careerNames,
        career_ids: careersSelected,
        num_careers_selected: careersSelected.length,
      });

      const formData = createSubmitData({
        user_id: user_id,
        selected_careers: careersSelected,
      });
  
      axios.post('/api/v1/addCareers', formData).then((resp) => {
        let url = `/${resp.data.user_id}/${resp.data.screen.name}/${resp.data.screen.context.source_url ? resp.data.screen.context.source_url : ''}`;
        setTimeout(() => {
          dispatch({type: 'update', values: {window_top: 0}});
          setIsLoading(false);
          dispatch({type: 'updateResponse', data: resp.data});
          setLoadingMessage(defaultLoadingMessage);
          history.push(url);
        }, 3000);
      });
      setCareerButtonsActive([]);

    }

  };

  if (loading || isLoading) return <LoadingScreen text={loadingMessage} />;

  const backSaveButton =
    <Amplitude>
      {({ logEvent }) => (
        <>
          {careerButtonsActive.length > 0 ? (
            <PrimaryButton onClick={() => save(logEvent)}>Save and return to results</PrimaryButton>
          ) : (
            <Link to={`/${user_id}/results`}>
              <SecondaryButton>Back to results</SecondaryButton>
            </Link>
          )}
        </>
      )}
    </Amplitude>;

  const industryOptions = context && context.industries ? [{id: -1, label: "All"}].concat(context.industries) : null;
  const qualityOptions = context && context.qualities ? [{id: -1, label: "All"}].concat(context.qualities) : null;

  let numCareersAdded = 0;

  return (
    <>
      <MetaTags>
        <title>Browse careers</title>
        <meta id="meta-description" name="description" content="Browse careers" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Browse careers"
        subtitle="Browse all careers and add your favourites to your list."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <ButtonContainer mt="20px" mb="43px">
              {backSaveButton}
            </ButtonContainer>
          </Col>
          <Col xs={12} md={8} mdOffset={2}>
            <ToggleButtonsContainer>
              <ToggleButtonsLabel>Filter by</ToggleButtonsLabel>
              <SelectBar
                values={filterValues}
                handleChange={(item) => handleFilterChange(item)}
                selectedValue={filterValues[0]}
              />
            </ToggleButtonsContainer>
            {industryOptions && filterType === "industry" && (
              <SubToggleButtonsContainer>
                <SelectBar
                  values={industryOptions}
                  handleChange={(item) => handleIndustryChange(item)}
                  selectedValue={industry}
                />
              </SubToggleButtonsContainer>
            )}
            {qualityOptions && filterType === "quality" && (
              <SubToggleButtonsContainer>
                <SelectBar
                  values={qualityOptions}
                  handleChange={(item) => handleQualityChange(item)}
                  selectedValue={quality}
                />
              </SubToggleButtonsContainer>
            )}
          </Col>
          <Col xs={12} md={8} mdOffset={2}>
            <ToggleButtonsContainer margin="32px 0 32px 0">
              <ToggleButtonsLabel>Sort by</ToggleButtonsLabel>
              <SelectBar
                values={sortValues}
                handleChange={(item) => handleSortChange(item)}
                selectedValue={sortValues[0]}
              />
            </ToggleButtonsContainer>
          </Col>
          <Col xs={12} md={8} mdOffset={2}>
            {context && context.careers && sortCareers(context.careers).map(item => {
              if ( filterType === "none" || 
                   ( filterType === "industry" && ( industry.id === -1 || item.industry_ids.includes(industry.id) ) ) || 
                   ( filterType === "quality" && ( quality.id === -1 || item.quality_ids.includes(quality.id) ) ) ) {
                if (numCareersAdded < numCareersShowing) {
                  numCareersAdded++;
                  return (
                    <CardContainer key={item.career_id}>
                      <CheckboxCard
                        handleClick={(it) => handleChange(it)}
                        tags={
                          filterType === "industry" && industry.id >= 0 ? [industry.label] :
                          filterType === "quality" && quality.id >= 0 ? [quality.label] :
                          [] }
                        labels={sortType === "jobs" && item.labels ? item.labels : [] }
                        title={item.career_name}
                        is_selected={item.selected || (careerButtonsActive.findIndex(careerButton => careerButton.career_id === item.career_id) >= 0)}
                        is_disabled={item.selected}
                        note={item.selected ? "This career is already in your list" : null}
                        value={item}
                      >
                        {item.is_skills_match && sortType === "skills" && (
                          <TagContainer>
                            <Tag key="skills_match" color={colors.LCG_DARK_PURPLE} bgColor={colors.LCG_DARK_PURPLE_FADE}>STRONG SKILLS MATCH</Tag>
                          </TagContainer>
                        )}
                        <P align="center" margin="16px 0 0 0">{item.career_description}</P>
                        {item.num_pathways > 0 && sortType !== "skills" && sortType !== "jobs" && (
                          <PathwaysInfoContainer>
                            <PathwaysInfo user_id={user_id} pathwaysSummary={item.pathways_summary} sortType={sortType} light />
                          </PathwaysInfoContainer>
                        )}
                      </CheckboxCard>
                    </CardContainer>
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </Col>
          <Col xs={12} md={8} mdOffset={2}>
            <ButtonContainer mt="43px" mb="52px">
              {backSaveButton}
            </ButtonContainer>
          </Col>
        </Row>
      </ThreeDotsBgLayout>
    </>
  );
};

export default BrowseCareers;

const CardContainer = styled.div`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${props => props.mt ? props.mt : '0px'};
  margin-bottom: ${props => props.mb ? props.mb : '0px'};
`;

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${props => props.margin && 'margin: ' + props.margin};

  @media (max-width: ${layout.breakpoints.MD}) {
    justify-content: center;
    flex-direction: column;
  }
`;

const SubToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;

  @media (max-width: ${layout.breakpoints.MD}) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;

const ToggleButtonsLabel = styled(H5)`
  padding: 0px 12px 0px 12px;
  min-width: 110px;
  max-width: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
    margin: 0px 12px 6px 12px;
  }
`;

const PathwaysInfoContainer = styled.div`
  margin-top: 16px;
`;

const TagContainer = styled.div`
  margin-top: 8px;
  text-align: center;
`;
