import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { PSmall } from '../typography/Typography';

const TextArea = ({
  error,
  defaultValue,
  handleChange,
  placeholder,
  label,
  hint,
  disabled,
  tall
}) => {
  const [value, setValue] = useState();

  const handleTextarea = (e) => {
    setValue(e.target.value);
    handleChange(e.target.value);
  };

  return (
    <Container>
      {label && (
        <Label htmlFor={value}>{label}</Label>
      )}
      {hint && (
        <Hint>{hint}</Hint>
      )}
      <Textarea error={error && error} defaultValue={defaultValue} onChange={handleTextarea} placeholder={placeholder} id={value} disabled={disabled} tall={tall} />
      {error && (
        <Error>
          <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
        </Error>
      )}
    </Container>
  );
};

export default TextArea;

TextArea.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  tall: PropTypes.bool,
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Textarea = styled.textarea`
  background-color: ${props => props.error ? colors.LCG_DARK_PURPLE_FADE : 'transparent'};
  border-radius: 8px;
  border: 0;
  border: 2px solid ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_LIGHT_GREY};
  font-size: 20px;
  line-height: 30px;
  padding: 8px 12px;
  width: 100%;
  height: ${props => props.tall ? "356px;" : "144px;"};

  &:focus {
    border-color: ${colors.LCG_LIGHT_PURPLE};
    outline: none;
  }

  &:disabled {
    background-color: ${colors.LCG_LIGHTER_GREY};
    color: ${colors.TEXT_GRAY};
  }
`;

const Error = styled.div`
 margin-top: 4px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: ${colors.TEXT_DARK};
  font-weight: 700;
`;

const Hint = styled.p`
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  color: ${colors.GRAY};
`;
