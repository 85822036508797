import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';
// import layout from '../config/layout';
// import { PSmall } from '../ui/typography/Typography';

const ProgressBar = ({ max, current }) => {
  let width_percentage = max === 0 ? 0 : Math.round((current / max) * 100);

  return (
    <Container>
      <Bar>
        <ProgressLine width={width_percentage} />
      </Bar>
      {/* <ProgressText color={colors.LCG_LIGHT_PURPLE}>{current} of {max}</ProgressText> */}
    </Container>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

const Container = styled.div``;

const Bar = styled.div`
  background-color: ${colors.LCG_LIGHT_PURPLE_FADE};
  border-radius: 4px;
  height: 8px;
  margin-bottom: 4px;
  position: relative;
  width: 100%;
`;

const ProgressLine = styled.div`
  background-color: ${colors.LCG_LIGHT_PURPLE};
  border-radius: 4px;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.3s;
  width: ${props => props.width}%;
`;

// const ProgressText = styled(PSmall)`
//   margin-left: 60px;

//   @media(max-width: ${layout.breakpoints.MD}) {
//     margin-left: 45px;
//   }
// `;
