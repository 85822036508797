/* Homepage > FAQs section */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Amplitude } from "@amplitude/react-amplitude";
import ReactHtmlParser from 'react-html-parser';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { BgPurpleLine3 } from '../../ui/bg/BgColoredLines';
import Title from './Title';
import { Tag } from '../../ui/tag/Tag';
import { P } from '../../ui/typography/Typography';
import { copyArray } from '../../utils/copyArray';

const FAQSection = ({price, premium_price}) => {

  const [ faqsSelected, setFaqsSelected ] = useState([]);

  const faqs = [
    { key: 0, question: "When do I pay? Is it a one-off?", answer: [
      "You can take our career quiz and view your overview page for free, with lots of fascinating insights into careers and skills.",
      "You will then have the option to pay a one-off " + price + " to view standard features including your final result careers and pathways.",
      "Or you can pay " + premium_price + " to view premium features.  These include all the standard features plus your transferable skills, videos and all the careers in our database.",
      "This is a single charge, not a subscription and will give you access for a year.",
      "We accept all credit and debit cards.",
    ] },
    { key: 1, question: "Do you offer a money back guarantee?", answer: [
      "Yes. We offer a 14-day money back guarantee.",
      "If you aren’t satisfied with CareersPro for any reason, just send us an email to support@careerspro.co.uk within 14 days of payment and we’ll happily refund you. Please note refunds may take 5-10 days to appear in a bank statement.",
      "Our customer support team is available 9am - 6pm, Monday to Friday, so please bear with us if you send a refund request over the weekend.",
    ] },
    { key: 2, question: "Can I buy access as a gift for someone?", answer: [
      "You absolutely can. Here’s how:",
      "1) Follow <a href=\"https://buy.stripe.com/eVa9BG9glfNd9Ne7sw\" target=\"_blank\" rel=\"noopener noreferrer\">this link</a> to gift 1 year’s access to all standard and premium features for " + premium_price,
      "2) Email us at careers-pro@learningcurvegroup.co.uk to let us know you’ve paid",
      "3) We’ll email you a unique URL and a gift certificate pdf for you to send on",
    ] },
    { key: 3, question: "Can I look at multiple careers?", answer: [
      "Yes, you can both search for a specific career to add to your final results, and you can browse across all the careers in our database.",
    ] },
    { key: 4, question: "Can I come back to finish the quiz later?", answer: [
      "Yes, if you use the same device. When you accept our cookies, it allows us to auto-save your answers, so if you want to finish them off later you don’t need to start the quiz all over again, just come back to www.careerspro.co.uk.",
      "If you complete the quiz but aren’t ready to pay yet, you can come back to your final results any time",
    ] },
    { key: 5, question: "Is this different from old school career quizzes?", answer: [
      "Almost all other career quizzes use a single piece of research from the 1950s, called Holland codes, to determine their career recommendations. This methodology places you into one of six personality types, and then shows you careers which match those types - there has been virtually no innovation in this research area over the past 70 years. This is why many quizzes feel the same, providing career recommendations which are very generalised and distinctly unhelpful.",
      "We built CareersPro from our own research and first principles, focusing on interests, qualifications and skills, rather than broad personality types. We have then added in significant information about each career and the path into it - personalised to you - so you can make more informed decisions.",
    ] },
    { key: 6, question: "Will you just sell my data?", answer: [
      "No. We fully comply with GDPR and you can read more about our privacy policy <a href=\"https://careerspro.co.uk/privacy\" target=\"_blank\" rel=\"noopener noreferrer\">here</a>. We only ever ask for information that is essential to providing you with the best service possible. Our mission is to help you find career happiness - this is centred on social impact, not quick profit.",
    ] },
    { key: 7, question: "Why isn’t this free? I can find career info myself", answer: [
      "On the tech side, we spent 1.5 years developing and building our AI and software that helps you to make more informed career decisions.",
      "On the career information side, the data in our app is compiled from hundreds of different sources by our research team; there’s far more to it than what you can read up on the National Careers Service website.",
      "Whilst much of this info is available freely online, many of our users don’t have the weeks needed to research several careers in depth and learn how to make a career change from where they are now, along with figuring out the time and cost, whether there’s any funding available and if it is truly for them.",
      "There’s often lots of conflicting and contradictory information out there, which is why we aim to validate all our research to save you time and energy in what can often be a frustrating, drawn-out journey.",
      "We’ve also calculated which careers you have a strong skills match with, highlighting which specific skills you have from your current job and previous work experience that are directly transferable to a new career. This can be used to help you tailor CVs and cover letters to new careers and jobs.",
    ] },
    { key: 8, question: "Who are you funded by?", answer: [
      "We are a tech startup based in London, and are an independent limited company.",
      "As runners up in Nesta’s CareerTech competition, funded by the Department for Education, we were awarded a total of £130,000 from March 2020-2021.",
      "As is common for tech startups, to get us off the ground we raised our first investment round of £460,000 with Forward Partners, a top London VC-firm in March 2020. We also received £40,000 from 3 angel investors.",
    ] },
    { key: 9, question: "How does the AI work?", answer: [
      "Our CEO & resident software engineer, Phil, built the Artificial Intelligence algorithm which powers our interests-based career matching.",
      "Stage one of our website is a series of ‘CareersPro’ questions, asking you to choose between two careers. This uses ‘comparative judgement’ as <a href=\"https://link.springer.com/article/10.1186/s41239-016-0018-x\" target=\"_blank\" rel=\"noopener noreferrer\">research</a> shows that people are very good at picking between one of two options, which is an effective way of helping you make complex decisions.",
      "But with a database of 504 careers, there are 126,756 possible pairs we could show. To handle this, Phil’s algorithm makes 32 million computations during the quiz, and uses the anonymised results of previous users to learn which of the career pairs are best to show you.",
      "If you’d like to find out more about the tech that underpins our software, drop Phil a line: phil@careerspro.co.uk ",
    ] },
    { key: 10, question: "Do you accept speculative job and internship applications?", answer: [
      "Yes, we’d love to hear from anyone who is committed to our mission of career happiness. We hope to expand our team significantly over the coming year -  we will be looking for software engineers, researchers, sales whizzes and marketing professionals soon.",
      "We accept interns on a rolling basis for a minimum of 2 months full-time, and are happy to accept university students, graduates and career changers. If you are willing to jump into the deep end and learn what it’s like to work in an early stage tech company, then please do get in touch. Our interns are always paid.",
      "Please send CVs and cover letters to emma@careerspro.co.uk. Please include why you feel career happiness is an important issue.",
    ] },
    { key: 11, question: "Your website really helped me! Can I share my story with you?", answer: [
      "We’re so pleased to hear that! Please send over your story to emma@careerspro.co.uk",
    ] },
    { key: 12, question: "How can I contact you?", answer: [
      "If your question isn’t answered here or you have some feedback you’d like to share, we’d love to hear from you. Just send us an email to careers-pro@learningcurvegroup.co.uk and we’ll get back to you asap.",
    ] },
  ];

  const selectFAQ = (faqKey, logEvent)  => {
    let copy = copyArray(faqsSelected);
    const index = copy.indexOf(faqKey);
    if (index >= 0) {
      // Deselect
      copy.splice(index, 1);
    } else {
      // Select
      copy.push(faqKey);

      // Get faq name and id
      let faqQuestion = "";
      for (let i=0; i<faqs.length; i++) {
        if (faqs[i].key === faqKey) {
          faqQuestion = faqs[i].question;
        }
      }

      // Log Amplitude event
      logEvent('FAQ Selected', {question: faqQuestion, key: faqKey});
    }
    setFaqsSelected(copy);
  };

  return (
    <Container>
      <BgPurpleLine3 />
      <Grid id="faqs">
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Title is_subtitle>
              <Span>FAQs</Span>
            </Title>
            <Amplitude>
              {({ logEvent }) => (
                <FAQsContainer>
                  {faqs.map(faq =>
                    <FAQContainer key={faq.key} selected>
                      {faqsSelected.includes(faq.key) && (
                        <FAQDetailContainer>
                          <FAQDetailTopLabel onClick={() => {selectFAQ(faq.key, logEvent)}}>{faq.question}</FAQDetailTopLabel>
                          {faq.answer.map((answer_paragraph, key) =>
                            <FAQDetailBody key={key}>{ReactHtmlParser(answer_paragraph)}</FAQDetailBody>
                          )}
                        </FAQDetailContainer>
                      )}
                      {!faqsSelected.includes(faq.key) && (
                        <FAQsTagContainer onClick={() => {selectFAQ(faq.key, logEvent)}}>
                          <FAQsTag dynamic_font>{faq.question}</FAQsTag>
                        </FAQsTagContainer>
                      )}
                    </FAQContainer>
                  )}
                </FAQsContainer>
              )}
            </Amplitude>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};

export default FAQSection;

const Container = styled.div`
  padding-top: 226px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 146px;
  }
`;

const Span = styled.span`
  color: ${colors.TEXT_DARK};
`;

const FAQsContainer = styled.div`
  padding: 5px 1rem 20px 1rem;
  max-width: 768px;
  margin: auto;
`;

const FAQContainer = styled.div`
  ${props => props.selected  && `padding-top: 5px;`};
  cursor: pointer;
`;

const FAQsTagContainer = styled.div`
  text-align: center;
  display: block;
`;

const FAQsTag = styled(Tag)`
  margin: 4px 0 10px 0;
  padding: 5px 8px;
  color: ${colors.TEXT_DARK};
  background-color: ${colors.LCG_DARK_PURPLE_FADE};
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: 6px 0 10px 0;
  }

  &:hover {
    color: ${colors.LCG_DARK_GREY};
    background-color: ${colors.LCG_DARK_PURPLE_FADE};
  }
`;

const FAQDetailContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  margin: 18px 0 10px 0;
  background-color: ${colors.WHITE};
  padding: 44px 32px 32px 32px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 36px 16px 16px 16px;
  }
`;

const FAQDetailTopLabel = styled.div`
  line-height: 18px;
  background-color: ${colors.LCG_DARK_PURPLE_FADE};
  color: ${colors.LCG_DARK_GREY};
  position: absolute;
  padding: 5px 8px;
  font-weight: 700;
  border-radius: 4px;
  left: 50%;
  top: -14px;
  transform: translateX(-50%);
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY};

  @media(max-width: ${layout.breakpoints.MD}) {
    top: -12px;
  }
`;

const FAQText = styled(P)`
  display: flex;
  text-align: center;
  display: inline-block;
  flex: 1;
  padding: 4px 0px 5px 0px;
  margin-bottom: 8px;
  width: 100%;
`;

const FAQDetailBody = styled(FAQText)`
  color: ${colors.TEXT_DARK};
`;
