import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../Modal';
import { H4 } from '../../ui/typography/Typography';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import ResultsModalCard from './ResultsModalCard';

const ResultsModal = ({ open, setOpen, title, children, handleShowMore, results, showMoreButton, icon }) => {
  return (
    <Modal
      open={open}
      handleClose={() => setOpen(false)}
      large
      hasCloseButton
    >
      <Title align="center" icon={icon}>{title}</Title>
      {children}
      {results.map(result => (
        <ResultsModalCard
          key={result.title}
          title={result.title}
          duration={result.duration}
          description={result.description}
          ctaUrl={result.url}
        />
      ))}
      {showMoreButton && (
        <ButtonContainer>
          <SecondaryButton>Show more</SecondaryButton>
        </ButtonContainer>
      )}
    </Modal>
  );
};

export default ResultsModal;

ResultsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  handleShowMore: PropTypes.func,
  results: PropTypes.array,
  showMoreButton: PropTypes.bool,
  icon: PropTypes.string,
};

const Title = styled(H4)`
  margin-top: 22px;
  margin-bottom: 12px;

  ${props => props.icon && `
    background-image: url(${props.icon});
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 50px;
  `}
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
