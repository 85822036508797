/* Quality selection screen after the quiz */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { copyArray } from '../../utils/copyArray';
import { createSubmitData } from '../../utils/helper';
import { getNamesByIDs } from '../../utils/getNamesByIDs';

import Multiselect from '../../components/multiselect/Multiselect';
import { Col, Row } from 'react-styled-flexboxgrid';
import { PrimaryButton, GrayButton } from  '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import RestartFlow from '../RestartFlow';

const TopQualities = () => {
  const [{ context, user_id, loading, screen_name } , dispatch ] = useStateValue();
  const [qualityButtonsActive, setQualityButtonsActive] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();
  const history = useHistory();
  const match = useRouteMatch("/:userId/quality-selection");

  useGetScreenData('quality-selection', setIsLoading, match.params.userId);

  const handleChange = (id, values) => {
    const existingItem = qualityButtonsActive.findIndex(item => item.quality_id === values[id].quality_id);
    let copy = copyArray(qualityButtonsActive)
    if (existingItem > -1) {
      copy.splice(existingItem, 1)
    } else {
      copy.push(values[id]);
    }
    setQualityButtonsActive(copy);
  };

  const next = (logEvent) => {

    setLoadingText(context.loading_message);
    setIsLoading(true);

    let qualityIDsSelected = qualityButtonsActive.map(item => {
      return item.quality_id;
    });
    dispatch({type: 'update', values: { showCareerSelectionInfoScreen: true }});

    let qualityIDs = qualityButtonsActive ? qualityButtonsActive.map(item => {return item.quality_id;}) : null;

    logEvent('Completed Quality Selection', {
      qualities: qualityIDs ? getNamesByIDs(qualityIDs, context.top_qualities_for_logging, null) : null,
      quality_ids: qualityIDs,
      num_qualities_selected: qualityButtonsActive.length
    });

    const formData = createSubmitData({
      user_id: user_id,
      selected_qualities: qualityIDsSelected
    })

    axios.post('/api/v1/qualitiesSelected', formData).then((resp) => {
      setTimeout(() => {
        setIsLoading(false);
        dispatch({type: 'updateResponse', data: resp.data});
        history.push(`/${user_id}/${resp.data.screen.name}/${resp.data.screen.context.source_url && resp.data.screen.context.source_url}`);
      }, 2500)
    });
  };

  var numRequiredQualitiesSelected = 2;
  var disabledButtonText = "";
  var numQualitiesToSelect = numRequiredQualitiesSelected - qualityButtonsActive.length;
  if (numQualitiesToSelect > 0) {
    if (numQualitiesToSelect === 1) {
      disabledButtonText += "Please select at least one more";
    } else if (numQualitiesToSelect === 2) {
      disabledButtonText += "Please select at least two";
    }
  }
  if (loading || isLoading || screen_name !== 'quality-selection') return <LoadingScreen text={loadingText} />;

  let qualityIDs = qualityButtonsActive ? qualityButtonsActive.map(item => {return item.quality_id;}) : null;

  return (
    <>
      <MetaTags>
        <title>Top Qualities</title>
        <meta id="meta-description" name="description" content="Select your favourite qualities" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Select the top qualities you look for in a career"
        subtitle="Pick the qualities that sound most appealing to you..."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <MultiselectWrap>
              <Multiselect
                values={context.top_qualities}
                handleChange={handleChange}
                testid="qualities"
              />
            </MultiselectWrap>
            <ButtonContainer>
              {qualityButtonsActive.length < numRequiredQualitiesSelected ? (
                <GrayButton disabled>{disabledButtonText}</GrayButton>
              ) : (
                <Amplitude userProperties={{
                  qualities: qualityIDs ? getNamesByIDs(qualityIDs, context.top_qualities_for_logging, null) : null,
                  quality_ids: qualityIDs,
                  num_qualities_selected: qualityButtonsActive.length
                }}>
                  {({ logEvent }) => (
                    <PrimaryButton onClick={() => next(logEvent)} data-testid="next">Continue</PrimaryButton>
                  )}

                </Amplitude>
              )}
            </ButtonContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default TopQualities;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 32px;
`;

const MultiselectWrap = styled.div`
  max-width: 352px;
  margin: auto;
`;
