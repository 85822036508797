import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

StateProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  initialState: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

// How to update the state:
// import { useStateValue } from "../../services/state";
// const [_, dispatch] = useStateValue();
// dispatch({
//   type: "stateTypeName",
//   stateObject: newData
// });

// How to access the context:
// import { StateContext } from "../../services/state";
// static contextType = StateContext;
// const [{ stateObject }, dispatch] = this.context;
