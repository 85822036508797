/* Privacy content v.1.1 (latest that is shown by default) - ref. PrivacyScreen.js */

import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { H1, H2, H3, H4, P, PSmall, Ul } from '../../ui/typography/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import colors from '../../config/colors';

const PrivacyContent = () => {

  return (
    <>
      <Row>
        <Col xs={12}>
          <H1 align="center">Privacy Policy</H1>
        </Col>
      </Row>
      <RowStyled>
        <Col xs={12} md={10}>
        <P>Learning Curve Group is committed to ensuring that your personal information is used properly and is kept securely. This Privacy Policy explains how we will collect and use your personal information. This policy explains the privacy practices for our services. We'll refer to our website and our apps as our "Services".</P>
        <H3Styled>1. Who are we?</H3Styled>
        <P>If you have any questions about your personal information, please get in touch with us by emailing dpo@learningcurvegroup.co.uk. </P>
        <P>Learning Curve Group (a trading name of Learning Curve (NE) Group Limited, Learning Curve (JAA) Limited and Learning at Work Limited). Learning Curve Group is registered as a data controller with the Information Commissioner under reference: Z1343917. Learning Curve Group is a company registered in England and Wales with company number 06388790. Our registered office is at Dunelm Rise, Durhamgate, County Durham DL16 6FS.  Below is a list of all Learning Curve Group entities.</P>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor:colors.LCG_DARK_PURPLE, color:colors.WHITE}}>
                <TableCell style={{color:colors.WHITE}}>Company</TableCell>
                <TableCell style={{color:colors.WHITE}}>UKPRN</TableCell>
                <TableCell style={{color:colors.WHITE}}>Registration Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Learning Curve Group Limited</TableCell>
                <TableCell>10008935</TableCell>
                <TableCell>05233487</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Profound Services Limited</TableCell>
                <TableCell>10022358</TableCell>
                <TableCell>05969610</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>Northern Care Training Limited</TableCell>
                <TableCell>10013515</TableCell>
                <TableCell>05256533</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>Acorn Training Consultants Limited</TableCell>
                <TableCell>10000108</TableCell>
                <TableCell>03182459</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>London Hairdressing Apprenticeship Academy Limited</TableCell>
                <TableCell>10035171</TableCell>
                <TableCell>07710651</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>Antrec Limited  </TableCell>
                <TableCell>10043333</TableCell>
                <TableCell>07873541</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>APCYMRU LIMITED (MPCT)  </TableCell>
                <TableCell>10000311</TableCell>
                <TableCell>07873541</TableCell>
              </TableRow>              
              <TableRow>
                <TableCell>The White Rose School of Beauty and Complementary Therapies Limited</TableCell>
                <TableCell>10007484</TableCell>
                <TableCell>03172520</TableCell>
              </TableRow>
            </TableBody>          
          </Table>
        </TableContainer>
        <H3Styled>2. What information we collect?</H3Styled>
        <P>We collect various types of personally Identifiable Information (PII) from you during your use of our websites and applications. These include the following:</P>
        <Ul>
          <li>Personal Identity Information (including name, age, gender).</li>
          <li>Contact information (including email addresses, phone numbers, home address).</li>
          <li>Survey information (including information collected to match to suitable careers and jobs like your location, employment status, details of your qualifications and past work experiences).</li>
          <li>Website, device, and technical information (including browsers and IP addresses).</li>
          <li>Information you give us through our web chat or in-application chat.</li>
          <li>Information you give us on coaching calls we have with you.</li>
          <li>Information about actions you've taken to move into a new career.</li>
        </Ul>
        <H3Styled>3. How is it being collected?</H3Styled>
        <P>Most of the personally Identifiable Information we process is provided to us directly by yourself for the purpose of you finding the right career.</P>
        <P>Under the UK General Data Protection Regulation 2018 (UK GDPR), the lawful bases we rely on for processing this information are by your consent. You can remove your consent at any time. You can do this by contacting dpo@learningcurvegroup.co.uk.</P>
        <P>To make visiting our website as user-friendly as possible, and to provide you with all the available features, we collect specific data from the device you used to access our website. This data includes your:</P>
        <Ul>
          <li>IP Address</li>
          <li>Operating system</li>
          <li>Browser type and version</li>
          <li>Date and time of access</li>
        </Ul>
        <H3Styled>4. How will it be used?</H3Styled>
          <P>We need to use your data for a contract we have with you, or to enter a contract with you. We use details about you to:</P>
          <Ul>
            <li>Consider your application.</li>
            <li>Give you the services we agreed to in line with our terms and conditions.</li>
            <li>Send you messages about your account and other services you use if you get in touch, or we need to send out information to you.</li>
            <li>Investigate and resolve complaints and other issues.</li>
            <li>We need to use your data to comply with the law, we keep records of information we hold about you in line with legal requirements.</li>
          </Ul>
          <P>When it's in our "legitimate interest" We need to use your data for our legitimate interests, or those of a third party. This means using data in a way that you might expect us to, for a reason which is in your and/or our (or a third party's) interest and which doesn't involve overriding your privacy rights.</P>
          <H3Styled>How Long We Keep Your Information</H3Styled>
          <P>We will only retain your personal information for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal information for a longer period in the event of a complaint or if we reasonably believe that there is a prospect of litigation in respect to our relationship with you.</P>
          <P>To determine the appropriate retention period for personal information, we consider the amount, nature and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</P>
          <H3Styled>5. Who will we share it with?</H3Styled>
          <P>We share your data with companies that give services to us. Here we mean companies that help us to provide services we use and need to process details about you for this reason. We share as little information as we can and encrypt and/or make it impossible for you to be identified by the recipient where possible (for instance by using a User ID rather than your name).</P>
          <P>Cloud computing power and storage providers like Heroku, Microsoft Azure and MongoDB Atlas.</P>
          <P>Companies that help us with marketing (but we won't share identifiable personal data with third parties for their own direct marketing unless you give us permission, and you can opt out any time).</P>
          <P>Software companies we use for emailing like Front, or for web chat or in-app chat like Intercom.</P>
          <P>Companies that provide single sign-on services for creating an account and logging into our Service, like Google</P>
          <P>Anyone you give us permission to share it with. We tell you in our “Service” when we need your consent to share your data with peers, training providers, employers, recruiters, career professionals and other third parties or users.</P>
          
          <H3Styled>6. How do we protect and store your information?</H3Styled>
          <P>We endeavour to store all information within the UK but we may transfer and store the data we collect from you somewhere outside the European Economic Area ('EEA'). People who work for us or our partners might also process your data. We may share data with organisations and countries that:</P>
          <P>The European Commission says the country or organisation has adequate data protection, or We've agreed to standard data protection clauses approved by the European Commission with the organisation.</P>
          <P>We will only retain your personal information for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal information for a longer period in the event of a complaint or if we reasonably believe that there is a prospect of litigation in respect to our relationship with you.</P>
          <P>To determine the appropriate retention period for personal information, we consider the amount, nature and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information. </P>
          
          <H3Styled>7. What rights do you have regarding your personal data?</H3Styled>
          <P>Under data protection law, you have rights including:</P>
          <Ul>
            <li>Your right of access - You have the right to ask us for copies of your personal information.</li>
            <li>Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
            <li>Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.</li>
            <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</li>
            <li>Your right to object to processing - You have the right to object to the processing of your personal information in certain circumstances.</li>
            <li>Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
          </Ul>
          <P>If you make a request, we have one month to respond to you.</P>
          <P>Please contact us at dpo@learningcurvegroup.co.uk or 01388777129 if you wish to make a request.</P>

          <H3Styled>8. How To Complain?</H3Styled>
          <P>If you have any concerns about our use of your personal information, you can make a complaint to us in writing to our registered office is at Dunelm Rise, Durhamgate, County Durham DL16 6FS or you can email dpo@learningcurvegroup.co.uk.</P>
          <P>You can also complain to the ICO if you are unhappy with how we have used your data.</P>
          <P>The ICO’s address:</P>
          <P>Information Commissioner’s Office</P>
          <P>Wycliffe House</P>
          <P>Water Lane</P>
          <P>Wilmslow</P>
          <P>Cheshire</P>
          <P>SK9 5AF</P>
          <P>Helpline number: 0303 123 1113 </P>
          <P>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></P>
          <P>If you're still not happy, you can refer your complaint with a data protection supervisory authority in the EU country you live or work, or where you think a breach has happened. The UK's supervisory authority is the Information Commissioner's Office (ICO). For more details, you can visit their website at ico.org.uk.</P>
        </Col>
      </RowStyled>
      <Row style={{marginTop:'5em'}}>
        <Col xs={12}>
          <H1 align="center">Cookie Policy</H1>
        </Col>
      </Row>
      <RowStyled>
        <Col xs={12} md={10}>
        <H3Styled>1. What are cookies?</H3Styled>
        <P>A cookie, also known as a browser cookie, is a small file which is downloaded to your computer, mobile or other device when you visit our website or use our application. This page describes what information they gather, how we use it and why we sometimes need to store these cookies.</P>
        <P>Cookies help make your online experience more efficient and relevant to your interests. For instance, they are used to remember your preferences on sites that you visit often, to remember your user ID and to help you to navigate between pages more efficiently. Different types of cookies are used to do different things. They also help us to identify ways to improve your overall site experience, or to measure the number and the most popular pages you visit.</P>
        <P>This policy sets out the types of cookies we use and what we use them for.</P>

        <H3Styled>2. Are there different types of cookies?</H3Styled>
        <P>Yes, there are different types of cookies. Cookies are divided into “first party” and “third party” cookies, and “session” and “persistent” cookies.</P>
        <H4Styled>2.1 “First party” and “third party” cookies</H4Styled>
        <P>Cookies can be set and controlled by the website (known as a “first party cookie”) or by a third party such as Google, Amplitude Third-party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you. (known as a “third party cookie”).</P>
        <H4Styled>2.2 “Session cookies” and “persistent cookies”</H4Styled>
        <P>Cookies can also be divided into “session cookies” and “persistent cookies”:</P>
        <P>(a) Session cookies</P>
        <P>Session cookies are stored in your computer or device’s memory during your browsing session and are automatically deleted from your computer when you leave a website. These cookies usually store a session ID, allowing you to move from page to page without having to log-in repeatedly.</P>
        <P>They are widely used by commercial websites. Session cookies do not collect any information from your computer or device, and they expire at the end of your browser session.</P>
        <P>(b) Persistent cookies</P>
        <P>Persistent cookies usually have an expiration date and so stay in your browser until they expire, or until you manually delete them. For example, we use persistent cookies for functionality. We also use persistent cookies to better understand usage patterns so that we can improve the website. We’ve included more details below to help you to understand what kinds of cookies we use.</P>
        <H4Styled>Email newsletter related cookies</H4Styled>
        <P>This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</P>
        <H4Styled>Survey related cookies </H4Styled>
        <P>Our website offers users surveys such as feedback surveys to understand our user base more accurately. These surveys may use cookies to remember who has already taken part in a survey or to provide you with accurate results after you change pages.</P>
        <H4Styled>Other technologies linked to cookies.</H4Styled>
        <P>There are other technologies which can seem like cookies, but are not the same, including pixels and site tags. These pixels or tags can be used to retrieve information such as your device type or operating system, IP address, time of visit, etc. Please see our privacy policy for more detail on the types of information that may be collected. They are sometimes used to create or access cookies on your browser but are not cookies themselves.</P>
        
        <H3Styled>3. How do we use cookies?</H3Styled>
        <P>We use cookies to help us to track our website visitor information and to learn about the behaviour of visitors to our website for research and statistical purposes.</P>
        <P>As detailed in the privacy policy, we may disclose data collected from cookies, to third parties, in an anonymous form, for research and statistical purposes, and to help us to optimise our websites and for the testing of the site.</P>
        <P>From time to time, we may also analyse IP addresses, user agent strings or other anonymous data sources.</P>
        <P>Third party suppliers</P>
        <P>We work with third party suppliers who place cookies on your device and report on “web analytics” information.</P>
        <P>We also use social media buttons and/or plugins on our site that allow you to connect with your social network in various ways. We do not disclose information about identifiable individuals, but we may provide them with aggregate information about our users to assist them in performing this service. </P>
        <P>If you click on a hyperlink from our website to any third-party websites (for example, if you 'share' content with friends or colleagues through social networks), you may be sent cookies from these third-party websites.</P>
        <P>The use of cookies is covered in this policy. How third parties use cookies is covered in their cookie policies. We have included a list of some of the third parties we work with below with a link to their cookie policy:</P>
        <P>Twitter Cookie Policy</P>
        <P>Bing Cookie Policy</P>
        <P>LinkedIn Cookie Policy</P>
        <P>Google Cookie Policy</P>

        <H3Styled>4. How can you control the use of cookies?</H3Styled>
        <P>Most web browsers automatically accept cookies. You can always choose to disable your web browser’s ability to accept cookies. Please note that if you do elect to disable your web browser’s ability to accept cookies, you may not be able to access or take advantage of many features of the service and some parts of the website may not work properly. If this is the case a message is usually displayed allowing you to change your settings back.</P>
        <P>You can control how cookies are set within your browser settings. Find out more about:</P>
        <P>"Private Browsing" in Firefox.</P>
        <P>"Incognito" Browsing and cookie settings in Chrome.</P>
        <P>"InPrivate" Browsing and cookie settings in Edge.</P>
        <P>"Private Browsing" and cookie settings in Safari.</P>
        <P>You can also control your cookie preferences using our Cookies Settings.</P>
        
        <H3Styled>5. Cookies we use</H3Styled>
        <P>Below we set out in detail the types of cookies that we use on this website, and their function.</P>
        <P>All cookies will expire after a specific period to ensure that they only last for as long as they are required.</P>
        <H4Styled>Strictly necessary cookies</H4Styled>
        <P>These cookies let you move around our website, and use essential features – such as accessing secure areas of our website and identifying you as being logged in. They are required for the proper operation of our website and if they are disabled our website may not be able to function properly.</P>
        <P>These cookies don’t gather any information about you as an individual that could be used for marketing or remembering where you’ve been on the internet.</P>
        <H4Styled>Analytics cookies</H4Styled>
        <P>We use these cookies, sometimes referred to as ‘web analytics’, to collect information about how visitors use our website. This includes details of the site where the visitor has come from, pages viewed, which content visitors are clicking on, which products visitors are interested in and at what point a visitor leaves our website.</P>
        <P>You can control how these cookies are set on your computer or device using our Cookies Settings</P>
        <P>We use this information to improve the way our website works – for example, by ensuring that users are finding what they are looking for easily.</P>
        <H4Styled>Functionality cookies</H4Styled>
        <P>We use these cookies to recognise users when they return to our website. They enable the personalisation of content, the recognition of users and, and remember user preferences.</P>
        <P>We use the information to improve our website and enhance our visitors’ experience.</P>
        <P>You can control how these cookies are set on your computer or device using our Cookies Settings.</P>

        <H3Styled>More Information</H3Styled>
        <P>Hopefully, this document has clarified things for you and - as was previously mentioned - if there is something that you aren't sure whether you need or not, it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</P>
        </Col>
      </RowStyled>
    </>
  );
};

export default PrivacyContent;

const RowStyled = styled(Row)`
  justify-content: center;
`;

const H3Styled = styled(H3)`
  margin-top: 48px;
  margin-bottom: 16px;
`;

const H4Styled = styled(H4)`
  margin-top: 36px;
  margin-bottom: 12px;
`;
