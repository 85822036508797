/* Final results > Career screen > View nearby jobs (or similar) */

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Amplitude } from "@amplitude/react-amplitude";

import { getJobsButtonLabel, viewAdzunaJob, viewJobs } from './getJobs';
import { useStateValue } from "../../services/state";
import { createSubmitData, shortString } from '../../utils/helper';

import { H3, P, PSmall } from '../../ui/typography/Typography';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import ResultsModal from '../../components/resultsmodal/ResultsModal';
import ResultsModalCard from '../../components/resultsmodal/ResultsModalCard';

import IconExternalLink from '../../assets/icon-external-link.svg';
import IconJob from '../../assets/icon-job.svg';


const JobSection = ({ item }) => {
  const [{ user_id, adzuna_jobs, context }] = useStateValue();
  const [open, setOpen] = useState(false);

  const seeMoreJobs = (logEvent) => {
    logEvent('Clicked View Jobs', {career_id: item.career_id});
    if (item.jobs_link !== null && item.jobs_link !== "" && item.jobs_link_text !== null && item.jobs_link_text !== "") {
      viewJobs(user_id, item.jobs_link, item.career_name, item.career_id, null)
    } else if (item.adzuna_uk_job_count < 20) {
      viewJobs(user_id, "google", item.career_name, item.career_id, null)
    } else if (adzuna_jobs && adzuna_jobs[item.career_id] && adzuna_jobs[item.career_id].adzuna_local_job_count === 0) {
      viewJobs(user_id, "adzuna", item.career_name, item.career_id, item.adzuna_rephrase)
    } else if (adzuna_jobs && adzuna_jobs[item.career_id] && adzuna_jobs[item.career_id].adzuna_local_job_count > 0) {
      const submitData = createSubmitData({
        user_id: user_id,
        career_id: item.career_id,
      });
      axios.post('/api/v1/viewNearbyJobsClicked', submitData);
      setOpen(true);
    } else { // Temp fix as there's an issue with Adzuna
      viewJobs(user_id, "google", item.career_name, item.career_id, null)
    }
  };

  const jobsButtonLabel = getJobsButtonLabel(item, context.commuting_distance_in_miles, adzuna_jobs);

  return (
    <>
      <Container>
        <Title align="center">Find a job</Title>
        <P align="center">See the types of jobs that are available.</P>
        <ButtonWrap>
          <Amplitude>
            {({ logEvent }) => (
              (!adzuna_jobs || !adzuna_jobs[item.career_id]) && !jobsButtonLabel.isExternal ? (
                <PSmall align="center">Fetching jobs...</PSmall>
              ) : (
                <SecondaryButton onClick={() => seeMoreJobs(logEvent)}>
                  {jobsButtonLabel.label}
                  {jobsButtonLabel.isExternal && <Image src={IconExternalLink} alt="" />}
                </SecondaryButton>
              )
            )}
          </Amplitude>
        </ButtonWrap>
      </Container>

      <ResultsModal
        open={open}
        setOpen={setOpen}
        title="Find a job"
        results={[]}
        handleShowMore={() => {}}
        icon={IconJob}
      >
        <>
          <P align="center">See the types of jobs that are available nearby</P>
          {adzuna_jobs && adzuna_jobs[item.career_id] && adzuna_jobs[item.career_id].adzuna_local_jobs && (adzuna_jobs[item.career_id].adzuna_local_jobs.map(job => (
            <ResultsModalCard
              key={job.adzuna_id}
              title={ReactHtmlParser(job.title)}
              subtitle={ReactHtmlParser(`${job.salary_text} <br/> ${ReactHtmlParser(job.company)} in ${ReactHtmlParser(job.location)}`)}
              description={shortString(job.description, 200)}
              ctaLabel="Apply"
              handleCtaClick={() => viewAdzunaJob(job.redirect_url, item.career_name, item.career_id, user_id)}
              isPurple
            >
            </ResultsModalCard>
          )))}
        </>
      </ResultsModal>
    </>
  );
};

export default JobSection;

const Container = styled.div`
  padding: 20px 0 20px 0;
`;

const Title = styled(H3)`
  padding-top: 50px;
  background-image: url(${IconJob});
  background-repeat: no-repeat;
  background-position: top center;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const Image = styled.img`
  margin-left: 4px;
  padding-bottom: 2px;
  margin-top: 4px;
`;
