/* Homepage > Steps 1-4 showing the key features of the platform */

import React from 'react';
import styled from 'styled-components';
// import { Amplitude } from '@amplitude/react-amplitude';
import ReactPlayer from "react-player";

import colors from '../../config/colors';
import layout from '../../config/layout';
// import { getNullifiedAmplitudeUserProperties } from '../../utils/getNullifiedAmplitudeUserProperties';
// import { getParameterByName } from '../../utils/helper';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Title from './Title';
import { BgBlueLine, BgRedLine, BgGreenLine, BgGreenLine2 } from '../../ui/bg/BgColoredLines';
import { ShapeLCGPurple } from '../../ui/bg/BgTwoDotsLCGPurpleLine';
import { P } from '../../ui/typography/Typography';
// import { PrimaryButton } from '../../ui/buttons/Buttons';
import screenshotStep0_1 from '../../assets/screenshot_step0_1_purple.png';
import screenshotStep1_4 from '../../assets/screenshot_step1_4_purple.svg';
import screenshotStep1_4b from '../../assets/screenshot_step1_4b_purple.png';
import screenshotStep2_3 from '../../assets/screenshot_step2_3_purple.png';
import screenshotStep2_3b from '../../assets/screenshot_step2_3b_purple.png';
import screenshotStep3_2 from '../../assets/screenshot_step3_2_purple.svg';

const KeyFeaturesSection = ({ handleQuizStart, price_headline, price_disclaimer, userHasAccess, freeAccessText, educationInstitution }) => {

  // const adId = getParameterByName('utm_content');
  // const source = getParameterByName('utm_source');
  // const medium = getParameterByName('utm_medium');

  let userID = localStorage.getItem("user_id");
  if (userID === "null") {
    userID = null;
  }

  // const shouldShowDisclaimerText = false;

  // const fullAccessText = "You have been gifted with full access 🎁";

  // let quizText = "";
  // if (!userID) {
  //   quizText = "Tap ‘Get Started’ to begin the 10 minute quiz. We’ll match your ";
  // } else {
  //   quizText = "Our quiz matches your ";
  // }
  // quizText += "interests, skills and qualifications to 500+ careers. View salary info, day-in-the-life videos, and see which careers are future-proof and have lots of jobs available ✨";

  return (
    <Container>
      <BlueContainer>
        <BgBlueLine />
        <Grid id="features">
          <Row>
            <Col xs={12} md={6}>
              {!educationInstitution && (
                <>
                  <Title label="Step 1" is_subtitle>
                  Use comparative judgement to discover <SpanRed>what's most important to you in a career <span role="img" aria-label="Thinking face emoji">🤔</span></SpanRed>
                  </Title>
                  <P color={colors.TEXT_DARK}>Far more than a career quiz, our AI-powered platform identifies your top career qualities and empowers you to choose careers that align with them. Or if you already know what career you want to pursue, you can skip the quiz and jump straight to step 2...</P>
                </>
              )}
              {educationInstitution && (
                <>
                  <Title label="Step 1" is_subtitle>
                  Use our next generation career quiz to <SpanRed>discover your dream career <span role="img" aria-label="Thinking face emoji">🤔</span></SpanRed>
                  </Title>
                  <P color={colors.TEXT_DARK}>Far more than a career quiz, our AI-powered platform identifies your top career qualities and empowers you to choose careers that align with them. Or if you already know what career you want to pursue, you can skip the quiz and jump straight to step 2...</P>
                </>
              )}
            </Col>
            <Col xs={12} md={6}>
              <ScreenshotImage src={screenshotStep0_1} alt="Screenshot - AI-powered platform" />
            </Col>
          </Row>
        </Grid>
      </BlueContainer>
      <LCGPurpleContainer>
        <ShapeLCGPurple />
        <Grid id="features">
          <Row>
            <Col xs={12} md={6}>
              {!educationInstitution && (
                  <>
                    <Title label="Step 2" is_subtitle>
                    Refine your <SpanBlue>career ideas <span role="img" aria-label="Nerd face emoji">🤓</span></SpanBlue>
                    </Title>
                    <P color={colors.TEXT_DARK}>Explore careers you have a strong skills match with, those that use your qualifications, careers which are future-proof and what their salaries are. We'll give you all the facts, so you can make well informed decisions.</P>
                  </>
              )}
              {educationInstitution && (
                  <>
                    <Title label="Step 2" is_subtitle>
                    Refine your <SpanBlue>career ideas <span role="img" aria-label="Nerd face emoji">🤓</span></SpanBlue>
                    </Title>
                    <P color={colors.TEXT_DARK}>Get insights into the time and cost to get into each of your careers of interest, as well as what starting salary you can expect.  We'll even tell you which careers are future proof.  We'll give you all the facts, so you can make well informed decisions.</P>
                  </>
              )}
            </Col>
            <Col xs={12} md={6}>
              {!educationInstitution && (
                <ScreenshotImage src={screenshotStep1_4} alt="Screenshot - Results" />
              )}
              {educationInstitution && (
                <ScreenshotImage src={screenshotStep1_4b} alt="Screenshot - Results" />
              )}
            </Col>
          </Row>
        </Grid>
      </LCGPurpleContainer>
      <RedContainer>
        <BgRedLine />
        <Grid>
          <Row>
            <Col xs={12} md={6}>
              {!educationInstitution && (
                <>
                  <Title label="Step 3" is_subtitle>
                    Discover <SpanRed>your pathway <span role="img" aria-label="Grinning face with star eyes emoji">🤩</span></SpanRed>
                  </Title>
                  <P color={colors.TEXT_DARK}>Our AI-powered <SpanItalic>Pathways</SpanItalic> tool calculates every step you'd need to take to change into each career - from where you personally are now. It lets you know the time and cost for each too, so you can plan for the future.</P>
                </>
              )}
              {educationInstitution && (
                <>
                  <Title label="Step 3" is_subtitle>
                    Discover <SpanRed>your pathway <span role="img" aria-label="Grinning face with star eyes emoji">🤩</span></SpanRed>
                  </Title>
                  <P color={colors.TEXT_DARK}>Our AI-powered <SpanItalic>Pathways</SpanItalic> tool calculates every step you'd need to take to get into each career - from where you personally are now. It lets you know the time and cost for each too, so you can plan for the future, whether that's an apprenticeship, university or another route.</P>
                  <BgGreenLine2Container>
                    <BgGreenLine2 />
                  </BgGreenLine2Container>
                </>
              )}
            </Col>
            <Col xs={12} md={6}>
              {!educationInstitution && (
                <ScreenshotImage src={screenshotStep2_3} alt="Screenshot - Pathways overview" />
              )}
              {educationInstitution && (
                <ScreenshotImage src={screenshotStep2_3b} alt="Screenshot - Pathways overview" />
              )}
            </Col>
          </Row>
        </Grid>
      </RedContainer>
      {!educationInstitution && (
        <GreenContainer>
          <BgGreenLine />
          <Grid>
            <Row>
              <Col xs={12} md={6}>
                <Title label="Step 4" is_subtitle>
                  Uncover your top <SpanBlue>transferable skills</SpanBlue> to craft successful job applications <span role="img" aria-label="Victory hand emoji">✌️</span>
                </Title>
                <P color={colors.TEXT_DARK}>Understand which of your specific skills are transferable to every single career, so you can write a meaningful CV and cover letter. When you’re ready, search and apply for jobs nearby or across the UK.</P>
              </Col>
              <Col xs={12} md={6}>
                <ScreenshotImage src={screenshotStep3_2} alt="Screenshot - Skills" />
              </Col>
            </Row>
          </Grid>
          {/* WYRB VIDEO SECTION HIDDEN */}
          {/* <VideoContainer>
            <Video>
              <ReactPlayerResponsive
                url="https://youtu.be/jCSQG1QCawI"
                width="100%"
                height="100%"
                controls={true}
                config={{
                  youtube: {
                    playerVars: { cc_load_policy: 1 }
                  }
                }}
              />
            </Video>
          </VideoContainer> */}
          {/* {!userID && (
            <ButtonWrap>
              <Amplitude userProperties={getNullifiedAmplitudeUserProperties(adId, source, medium)}>
                {({ logEvent }) =>
                  <ButtonContainer>
                    <PrimaryButton id="start_button" onClick={() => {handleQuizStart(adId, source, medium); logEvent('Clicked Get Started');}}>Get started</PrimaryButton>
                    {price_headline && !userHasAccess && !freeAccessText && shouldShowDisclaimerText && (
                      <DisclaimerText align="center" mt="12px">{price_headline}</DisclaimerText>
                    )}
                    {(userHasAccess || freeAccessText) && shouldShowDisclaimerText && (
                      <DisclaimerText align="center" mt="12px">{fullAccessText}</DisclaimerText>
                    )}
                    {price_disclaimer && !userHasAccess !freeAccessText && && shouldShowDisclaimerText && (
                      <DisclaimerText align="center">{price_disclaimer}</DisclaimerText>
                    )}
                  </ButtonContainer>
                }
              </Amplitude>
            </ButtonWrap>
          )} */}
        </GreenContainer>
      )}
    </Container>
  );
};

export default KeyFeaturesSection;

const Container = styled.div`
  padding-right: 20px;
  padding-left: 20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: inherit;
  }
`;

const BgGreenLine2Container = styled.div`
  @media(max-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`

const BlueContainer = styled.div`
  padding-top: 220px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 80px;
    padding-bottom: 20px;
  }
`;

const LCGPurpleContainer = styled.div`
  padding-top: 240px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 176px;
    padding-bottom: 8px;
  }
`

const RedContainer = styled.div`
  padding-top: 210px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 156px;
    margin-bottom: 8px;
  }
`;

const GreenContainer = styled.div`
  padding-top: 336px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 176px;
  }
`;

const SpanBlue = styled.span`
  color: ${colors.LCG_LIGHT_PURPLE};
`;

const SpanRed = styled.span`
  color: ${colors.LCG_DARK_PURPLE};
`;

const SpanItalic = styled.span`
  font-style: italic;
`

const ScreenshotImage = styled.img`
  width: 85%;
  max-width: 600px;
  display: block;
  margin: 0 auto 0 auto;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 500px;
    width: 100%;
    margin: 20px auto 20px auto;
  }
`;

const VideoContainer = styled.div`
  padding: 32px 32px 32px 32px;
  max-width: 1024px;
  margin: auto;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 20px 20px 20px 20px;
  }
`;

const Video = styled.div`
  position: relative;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)
`;

const ReactPlayerResponsive = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

// const ButtonWrap = styled.div`
//   margin-top: 20px;
//   margin-bottom: 52px;
//   text-align: center;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     margin-top: 32px;
//     margin-bottom: 12px;
//   }
// `;

// const ButtonContainer = styled.div`
//   margin: 10px auto 20px auto;
//   display: block
// `

// const DisclaimerText = styled(PVSmall)`
//   color: ${colors.TEXT_DARK};
//   margin-top: ${props => props.mt ? props.mt : '0px'};
//   margin-bottom: 4px;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     max-width: 420px;
//     margin-left: auto;
//     margin-right: auto;
//     position: relative;
//   }
// `;
