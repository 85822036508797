/* Card collection part of the form within CheckoutForm.js */

import React from 'react';
import styled from 'styled-components';
import { CardElement } from 'react-stripe-elements';
import { Label } from '../../ui/typography/Typography';
import colors from '../../config/colors';

const CardSection = ({ onBlur, handleChange, error, required }) => {
  return (
    <Container>
      <Label>Card details{required && (<Required> *</Required>)}</Label>
      <InputWrap>
        <CardElement style={{base: {fontSize: '18px'}}} onChange={(e) => handleChange(e)} onBlur={onBlur} />
      </InputWrap>
      {/* {error && <Error>{error}</Error>} */}
    </Container>
  );
};

export default CardSection;

const Container = styled.div`
  margin-bottom: 32px;
`;

const InputWrap = styled.div`
  border: 2px solid ${colors.LCG_LIGHT_GREY};
  padding: 12px 10px;
  height: 50px;
  border-radius: 8px;
`;

// const Error = styled(P)`
//   margin-top: 5px;
//   color: #FA054F;
//   font-size: 16px;
// `;

const Required = styled.span`
  color: ${colors.LCG_DARK_PURPLE};
`;
