/* Dashboard (for pay-as-you-go prepay customer) > Purchase more (towards the top) */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import layout from '../../config/layout';

import { createOrgSubmitData } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import Loader from  '../../components/Loader';
import StripeForm from '../SignupScreen/StripeForm';
import { P } from '../../ui/typography/Typography';

const PurchaseLicencesScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);

  const [ paymentIntent, setPaymentIntent ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();
  const [ thanks, setThanks ] = useState(false);

  const [stripeLoaded, setStripeLoaded] = useState({});
  
  const defaultFormValues = {
    num_licences: 50, // Gets overridden once context changes
    are_card_details_valid: false,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    card_details: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  const nullPrice = {
    price_pence: null,
    price_text: null,
    vat_pence: null,
    vat_text: null,
    total_price_pence: null,
    total_price_text: null,
  };
  const [ price, setPrice ] = useState(nullPrice);

  const nullB2bPackage = {
    name: null,
    min_num_users: null,
    price_pence: null,
    price_text: null,
    vat_pence: null,
    vat_text: null,
    discount: null
  };
  const [ b2bPackage, setB2bPackage ] = useState(nullB2bPackage);

  // Set the stripePublicAPIKey
  var stripePublicAPIKey = "";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Dev environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY_DEV;
  } else {
    // Production environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY;
  }

  useGetScreenData('organisations/purchase', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/purchase', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/purchase") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (context && context.num_licences_default > 0) {
      setFormValues({
        ...formValues,
        "num_licences": context.num_licences_default,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const loadScript = src => new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src;
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const result = await loadScript('https://js.stripe.com/v3/')
      setStripeLoaded(result)
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {
    if (e.elementType === "card") {
      const errorMsg = formValues.are_card_details_valid !== true ? "You must provide valid card details" : null;
      setFormErrors({
        ...formErrors,
        "card_details": errorMsg
      });
    }
  };

  const validateForm = (setErrors) => {
    let isValid = true;
    let newErrors = initialErrorState;

    if (formValues.num_licences === null) {
      isValid = false;
    }

    if (formValues.are_card_details_valid !== true) {
      isValid = false;
    }

    setErrors && setFormErrors(newErrors);
    return isValid;
  };

  const getStripeResponse = async (elements, stripe) => {
    const cardElement = elements.getElement('card');
    try {
      const stripeResponse = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${context.organisation_user_first_name} ${context.organisation_user_last_name}`,
              email: context.organisation_user_email,
            }
          }
        }
      );
      return stripeResponse;
    } catch(error) {
      return error.response;
    }
  };

  const submitForm = async (elements, stripe) => {
    if (!validateForm(true) || processing) return null;
    setProcessing(true);

    const stripeResponse = await getStripeResponse(elements, stripe);
    if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {

      const orgSubmitData = createOrgSubmitData({
        stripe_payment_intent: paymentIntent,
        signup: false,
        details: formValues,
        price: price,
        b2b_package: b2bPackage,
      });

      axios.post('/api/v1/process_customer_payment', orgSubmitData).then((res) => {
        setProcessing(false);
        if (res.data && res.data.succeeded === true) {
          setThanks(true);
        } else if (res.data && res.data.error) {
          setError(res.data.error);
          console.log(res.data.error);
        } else {
          // Else it has not succeeded or failed (probably in progress somehow), so don't make any updates
        }
      });

    } else {
      setProcessing(false);
      let errorMessage = "Payment failed";
      if (stripeResponse && stripeResponse.error && stripeResponse.error.message) {
        errorMessage += ": " + stripeResponse.error.message;
      }
      setError(errorMessage);
    }
  }

  if (loading || isLoading || !context || (!thanks && !stripeLoaded.successful)) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Purchase licences</title>
        <meta id="meta-description" name="description" content="CareersPro - Purchase licences" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name + " - Purchase licences"} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        {thanks && (
          <TopSectionSuccess>
            <TopTextSuccess align="center">Thanks for your payment of {price.total_price_text} for {formValues.num_licences.toLocaleString()} user licence{formValues.num_licences !== 1 ? `s` : ``}.  We have emailed you a VAT invoice.</TopTextSuccess>
            <BackButtonWrap>
              <Link to={`/organisations/dashboard`}>
                <PrimaryButton>Return to dashboard</PrimaryButton>
              </Link>
            </BackButtonWrap>
          </TopSectionSuccess>
        )}
        {!thanks && (
          <>
            {stripeLoaded.successful && context && (
              <StripeProvider apiKey={stripePublicAPIKey}>
                <Elements>
                  <StripeForm processing={processing} formValues={formValues} formErrors={formErrors} price={price} setPrice={setPrice} error={error} paymentIntent={paymentIntent} setPaymentIntent={setPaymentIntent} validateField={validateField} validateForm={validateForm} handleInputChange={handleInputChange} submitForm={submitForm} defaultNumUserLicences={context.num_licences_default} b2bPackage={b2bPackage} setB2bPackage={setB2bPackage} hasSidePadding={true} />
                </Elements>
              </StripeProvider>
            )}
            {!stripeLoaded.successful && (
              <Loader />
            )}
            <BackButtonContainer>
              <Link to={`/organisations/dashboard`}>
                <SecondaryButton>Back to dashboard</SecondaryButton>
              </Link>
            </BackButtonContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default PurchaseLicencesScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 309px;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const TopSectionSuccess = styled.div`
  margin-top: 20px;
`;

const TopTextSuccess = styled(P)`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 356px;
  }
`;

const BackButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;
