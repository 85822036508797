/* Dashboard > Manage Team > Add Team Member */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

import { useStateValue } from '../../services/state';
import { useGetScreenData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createSubmitData, createOrgSubmitData } from '../../utils/helper';
import { validateEmail, doesEmailDomainMatch } from '../../utils/validation';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P, PSmall, H3 } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import Input from '../../ui/form/Input';
import Loader from  '../../components/Loader';
import Tooltip from '../../components/Tooltip';
import Checkbox from '../../ui/form/Checkbox';

const AddTeamMemberScreen = () => {

  const history = useHistory();

  const [{ context, loading }] = useStateValue();

  const [ checkingEmailUniqueness, setCheckingEmailUniqueness ] = useState(false);
  const [ isEmailUnique, setIsEmailUnique ] = useState(null);

  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();

  const [ isLoading, setIsLoading ] = useState(true);

  const defaultFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    enable_2fa: false,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    first_name: null,
    last_name: null,
    email: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  useGetScreenData('organisations/dashboard', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    if (context) {
      const enable2FAVarName = "enable_2fa";
      setFormValues({
        ...formValues,
        [enable2FAVarName]: context.activated_2fa,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleInputChange = (value, name) => {

    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (name === "email" && validateEmail(value) && (!context.email_domains || context.email_domains.length === 0 || doesEmailDomainMatch(value, context.email_domains))) {
      checkEmailUniqueness(name, value);
    }

  };

  const validateField = e => {

    const value = e.target.value;
    const name = e.target.name;

    if (name === "first_name" || name === "last_name") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    } else if (name === "email") {
      let errorMsg = null;
      if (!validateEmail(value)) {
        errorMsg = "This is not a valid email";
      } else if (context.email_domains && context.email_domains.length > 0 && !doesEmailDomainMatch(value, context.email_domains)) {
        errorMsg = "This email must match one of the email domains associated with this organisation";
      }
      if (errorMsg) {
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
    }

  };

  const checkEmailUniqueness = async (name, value) => {
    // Check the user's email address isn't already in use
    setCheckingEmailUniqueness(true);
    const submitData = createSubmitData({
      email: value,
    });
    axios.post('/api/v1/check_organisation_user_email_is_unique', submitData).then(res => {
      let errorMsg = null;
      if (!res.data.unique) {
        errorMsg = "This email is already in use";
        setIsEmailUnique(false);
      } else {
        setIsEmailUnique(true);
      }
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
      setCheckingEmailUniqueness(false);
    });
  };

  const validateForm = (setErrors) => {
    let isValid = true;
    let newErrors = initialErrorState;

    if (formValues.first_name === "") {
      newErrors = {...newErrors, first_name: "This field is required"};
      isValid = false;
    }

    if (formValues.last_name === "") {
      newErrors = {...newErrors, last_name: "This field is required"};
      isValid = false;
    }

    if (!validateEmail(formValues.email) || (context.email_domains && context.email_domains.length > 0 && !doesEmailDomainMatch(formValues.email, context.email_domains)) || isEmailUnique === false) {
      isValid = false;
    }

    setErrors && setFormErrors(newErrors);
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm(true) || processing) return null;
    setProcessing(true);

    const orgSubmitData = createOrgSubmitData({
      details: formValues,
    });
    axios.post('/api/v1/invite_team_member', orgSubmitData).then((res) => {
      setProcessing(false);
      if (res.data && res.data.succeeded === true) {
        history.push(`/organisations/team`);
      } else if (res.data && res.data.error) {
        setError(res.data.error);
        console.log(res.data.error);
      }
    });
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Add team member</title>
        <meta id="meta-description" name="description" content="CareersPro - Invoices" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name + " - Add team member"} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        <SubTitle>Add team member</SubTitle>
        <Text pt pb>Enter the name and email address of a new team member.  They will be able to add and invite users, and view their results.  They won't be able to buy new licences or manage team members - only you can do this.</Text>
        <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <Form>
                <InputWrap>
                  <Input type="text" name="first_name" label="First name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} error={formErrors['first_name']} />
                </InputWrap>
                <InputWrap>
                  <Input type="text" name="last_name" label="Last name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} error={formErrors['last_name']} />
                </InputWrap>
                <InputWrap>
                  <Input type="email" name="email" label="Email"  onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} error={formErrors['email']} />
                </InputWrap>
                <CheckboxWrap>
                  <Checkbox
                    label="Enable two-factor authentication"
                    name="enable_2fa"
                    handleChange={(it) => handleInputChange(it, 'enable_2fa')}
                    defaultChecked={context.activated_2fa}
                  />
                </CheckboxWrap>
                <SendInviteButtonWrap>
                  {processing ? (
                    <PSmall align="center">Sending invite...</PSmall>
                  ) : validateForm(false) ? (
                    checkingEmailUniqueness !== true ? (
                      <PrimaryButton onClick={() => submitForm()}>Send invitation email</PrimaryButton>
                    ) : (
                      <Loader />
                    )
                  ) : (
                    <Tooltip text="Please enter your team member's details above">
                      <GrayButton disabled>Send invitation email</GrayButton>
                    </Tooltip>
                  )}
                </SendInviteButtonWrap>
                {error && <Error align="center">{error}</Error>}
              </Form>
            </Col>
          </Row>
        </Grid>
        <BackButtonContainer>
          <Link to={`/organisations/team`}>
            <SecondaryButton>Back to team</SecondaryButton>
          </Link>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default AddTeamMemberScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 250px;
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  max-width: 450px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const SendInviteButtonWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
  padding-top: 8px;
`;

const Error = styled(P)`
  color: #FA054F;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 52px 0 32px;
`;

const CheckboxWrap = styled.div`
  text-align: center;
  margin-top: 32px;
  margin-bottom: 12px;

  @media (max-width: ${layout.breakpoints.SM}) {
    margin-top: 20px;
    margin-bottom: 8px;
  }
`
