/* Final results > Overview (towards the top) */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useStateValue } from "../../services/state";
import layout from  '../../config/layout';

import { SecondaryButton } from '../../ui/buttons/Buttons';
import Insights from '../Insights';
import Modal from '../../components/Modal';
import { H3 } from '../../ui/typography/Typography';

const OverviewModal = () => {
  const [{ user_id, context }] = useStateValue();
  const [ open, setOpen ] = useState(false);

  let numDownloadClicks = 0;
  let timestampFirstDownloadClick = 0;
  let downloading = false;

  const handleDownloadReportClick = async () => {
    if (!downloading) {
      let currentTimestamp = new Date().getTime();
      if (currentTimestamp < (timestampFirstDownloadClick + 5000)) {
        // If this click is within 5 seconds of the first one, then increment
        numDownloadClicks++;
      } else {
        // Otherwise, start again
        timestampFirstDownloadClick = currentTimestamp;
        numDownloadClicks = 1;
      }
      if (numDownloadClicks === 5) {
        downloading = true;
        const resp = await axios({
          url: `/api/v1/getCareerReport?user_id=${user_id}`,
          method: 'GET',
          responseType: 'blob',
        });
        if (resp && resp.status === 200) {
          let filename = "Career Report.pdf";
          let contentDispositionHeaders = resp.headers["content-disposition"].split(";");
          for (let i=0; i<contentDispositionHeaders.length; i++) {
            if (contentDispositionHeaders[i].includes("filename")) {
              filename = contentDispositionHeaders[i].split("=")[1];
            }
          }
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      }
    }
  };

  return (
    <>
      <ButtonWrap>
        <SecondaryButton small onClick={() => setOpen(true)}>Overview</SecondaryButton>
      </ButtonWrap>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        large
        hasCloseButton
      >
        <Title align="center"><span onClick={() => handleDownloadReportClick()}>Overview</span> of {context && context.first_name ? context.first_name + "'s" : "your"} results</Title>
        <Insights />
      </Modal>
    </>
  );
};

export default OverviewModal;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 12px 12px 0px;

  @media (max-width: ${layout.breakpoints.MD}) {
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
  }
`;

const Title = styled(H3)`
  margin: 32px;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin: 20px;
  }
`
