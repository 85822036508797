import styled from 'styled-components';
import colors from '../../config/colors';
import ShapeLCGPurpleImage from '../../assets/shapes/lcg-shape-purple.svg';
import ShapeLCGPurpleDownImage from '../../assets/shapes/lcg-shape-purple-down.svg';
import ShapeGreenUpImage from '../../assets/shapes/lcg-shape-purple-up.svg';
import ShapeGreenDownImage from '../../assets/shapes/lcg-shape-purple-down-long.svg';
import ShapeLineBlueImage from '../../assets/shapes/lcg-shape-pink.svg';
import ShapePurpleUpImage from '../../assets/shapes/lcg-shape-dark-blue-up.svg';
import ShapePurpleDownImage from '../../assets/shapes/lcg-shape-dark-blue-down.svg';
import ShapePurpleImage from '../../assets/shapes/lcg-shape-dark-blue.svg';
import ShapeRedImage from '../../assets/shapes/lcg-shape-pink.svg';
import ShapePurpleFlatImage from '../../assets/shapes/lcg-shape-dark-blue-flat.svg';
import ShapeLineDarkBlueImage from '../../assets/shapes/lcg-shape-dark-blue.svg';

export const CircleLCGPurple = styled.div`
  width: 66px;
  height: 66px;
  position: absolute;
  border-radius: 100%;
  background-color: ${colors.LCG_DARK_PURPLE};
`;

export const SemiCircleLCGPurple = styled.div`
  width: 33px;
  height: 66px;
  position: absolute;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background-color: ${colors.LCG_DARK_PURPLE};
`;


export const CircleRed = styled.div`
  width: 115px;
  height: 115px;
  position: absolute;
  border-radius: 100%;
  background-color: ${colors.LCG_DARK_PURPLE};
`;

export const CirclePurple = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 100%;
  background-color: ${colors.LCG_DARK_PURPLE};
`;

export const ShapeLCGPurpleUp = styled.div`
  position: absolute;
  width: 460px;
  height: 175px;
  background-image: url(${ShapeLCGPurpleImage});
  background-size: 100% 100%;
`;

export const ShapeLCGPurpleDown = styled.div`
  position: absolute;
  width: 460px;
  height: 175px;
  background-image: url(${ShapeLCGPurpleDownImage});
  background-size: 100% 100%;
`;

export const ShapeGreenUp = styled.div`
  position: absolute;
  width: 628px;
  height: 336px;
  background-image: url(${ShapeGreenUpImage});
  background-size: 100% 100%;
`;

export const ShapeGreenDown = styled.div`
  position: absolute;
  width: 737px;
  height: 431px;
  background-image: url(${ShapeGreenDownImage});
  background-size: 100% 100%;
`;

export const ShapePurpleUp = styled.div`
  position: absolute;
  width: 628px;
  height: 336px;
  background-image: url(${ShapePurpleUpImage});
  background-size: 100% 100%;
`;

export const ShapePurpleDown = styled.div`
  position: absolute;
  width: 628px;
  height: 336px;
  background-image: url(${ShapePurpleDownImage});
  background-size: 100% 100%;
`;

export const ShapePurpleFlat = styled.div`
  position: absolute;
  width: 628px;
  height: 336px;
  background-image: url(${ShapePurpleFlatImage});
  background-size: 100% 100%;
`;

export const ShapePurple = styled.div`
  position: absolute;
  width: 628px;
  height: 336px;
  background-image: url(${ShapePurpleImage});
  background-size: 100% 100%;
`;

export const LineBlue = styled.div`
  position: absolute;
  width: 970px;
  height: 122px;
  background-image: url(${ShapeLineBlueImage});
  background-size: 100% 100%;
`;

export const LineDarkBlue = styled.div`
  position: absolute;
  width: 970px;
  height: 122px;
  background-image: url(${ShapeLineDarkBlueImage});
  background-size: 100% 100%;
`;

export const LineRed = styled.div`
  position: absolute;
  width: 460px;
  height: 175px;
  background-image: url(${ShapeRedImage});
  background-size: 100% 100%;
`;
