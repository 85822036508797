import fingerprint from 'fingerprintjs';

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const getPriceText = (pricePence) => {
  var pricePounds = pricePence/100;
  if (pricePounds % 1 !== 0) {
    // Has decimals, so ensure it is just two decimals
    pricePounds = pricePounds.toFixed(2);
  }
  return "£" + pricePounds.toLocaleString();
}

export const boldCharactersInString = (targetString, inputString) => {
  return boldCharactersInStringAfterDelimeter(targetString, inputString, [" ", "/", "(", ")"]);
}

export const boldCharactersInStringAfterDelimeter = (targetString, inputString, delimeters) => {

  var newString = targetString;

  if (delimeters.length === 0) {
    // Base case after applying all the delimeters to the target string
    // Before returning, replace the bold markup with proper html markup
      // We do this because we can't have "/" added to the string as it's also a delimeter
    newString = newString.replace(/<START_BOLD>/g, "<b>");
    newString = newString.replace(/<END_BOLD>/g, "</b>");
    return newString;
  } else {

    var targetStringArray = targetString.split(delimeters[0]);
    var inputStringArray = inputString.split(" ");

    // Remove any strings with no letters / numbers in the inputStringArray
    for (let i=0; i<inputStringArray.length; i++) {
      var regExp = /[a-zA-Z0-9]/g;
      if(regExp.test(inputStringArray[i]) === false) {
        inputStringArray.splice(i, 1);
        i--;
      }
    }

    // Cycle through the inputStringArray to make sure you find an occurence of every item
    var match = true;
    for (let i=0; i<inputStringArray.length && match; i++) {
      var foundTargetStringWord = false;
      for (let j=0; j<targetStringArray.length && !foundTargetStringWord; j++) {
        if (targetStringArray[j].toLowerCase().startsWith(inputStringArray[i].toLowerCase())) {
          // Found an occurence of the input item in one of the target items, so ...
          foundTargetStringWord = true;
          if (targetStringArray[j].includes("<START_BOLD>") === false && targetStringArray[j].includes("<END_BOLD>") === false) {
            // The target item has not yet been marked up, so mark it up
            targetStringArray[j] =
              "<START_BOLD>" + targetStringArray[j].substring(0, inputStringArray[i].length) + "<END_BOLD>" +
              targetStringArray[j].substring(inputStringArray[i].length, targetStringArray[j].length);
          }
        }
      }
      if (!foundTargetStringWord) {
        match = false;
      }
    }

    if (match === true) {
      // We found occurences of each item in the input string, so set the new target string
      newString = "";
      for (let i=0; i<targetStringArray.length; i++) {
        newString += targetStringArray[i];
        if (i < (targetStringArray.length-1)) {
          newString += delimeters[0]
        }
      }
    }

    // Recurse on the rest of the delimeters in the target string
    delimeters.splice(0, 1);
    return boldCharactersInStringAfterDelimeter(newString, inputString, delimeters);

  }

}

export const cleanString = (string) => {
  string = string.trim();
  string = string.replace(/\n/g, ' ');
  while (string.includes("  ")) {
    // eslint-disable-next-line
    string = string.replace(/  /g, ' ');
  }
  return string;
}

export const cleanStringForComparison = (string) => {
  string = string.trim();
  string = string.replace(/\n/g, ' ');
  string = string.replace(/[^a-z ]/g, "")
  while (string.includes("  ")) {
    // eslint-disable-next-line
    string = string.replace(/  /g, ' ');
  }
  return string;
}

export const getParameterByName = (paramName, url = window.location.href) => {
    // eslint-disable-next-line
    paramName = paramName.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const createSubmitData = (data) => {
  const userFingerprint = (new fingerprint()).get();
  const userAgent = navigator.userAgent;
  const fullData = {
    ...data,
    fingerprint: userFingerprint,
    user_agent: userAgent,
  };

  return fullData;
};

export const createOrgSubmitData = (data) => {
  const userFingerprint = (new fingerprint()).get();
  const userAgent = navigator.userAgent;
  const organisationUserId = localStorage.getItem("organisation_user_id");
  const sessionId = localStorage.getItem("session_id");

  const fullData = {
    ...data,
    fingerprint: userFingerprint,
    user_agent: userAgent,
    organisation_user_id: organisationUserId,
    session_id: sessionId,
  };

  return fullData;
}

export const shortString = (description, charCount) => {
  var shortDescription = description.substring(0, charCount);
  if (shortDescription.length > 0) {
    shortDescription += "...";
  }
  return shortDescription;
};

export const getJobSalary = (startingSalary, experiencedSalary) => {
  let output = "";
  if (startingSalary > 0) {
    output = `£${startingSalary.toLocaleString('en-gb')}`;
    if (experiencedSalary > 0) {
      output += ` - £${experiencedSalary.toLocaleString('en-gb')}`;
    }
  } else if (startingSalary === 0) {
    output = "Varied salary";
  }
  return output;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
