export const initialState = {
  access_token: null,
  transaction_list: null,
  loading: false,
  final_results: null,
  marketplace_partners: null,
  rating: null,
  commuting_distance_in_miles: null,
  adzuna_jobs: null,
  window_top: null,
  showPreQuizInfoScreen: false,
  showCareerSelectionInfoScreen: false,
  context: null,
  careersPrevSelected: [],
  stripe_payment_intent: null,
  screen_name: 'home',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "endLoading": {
      return {
        ...state,
        loading: false
      };
    }
    case "startLoading": {
      return {
        ...state,
        loading: true
      };
    }
    case "update": {
      return {
        ...state,
        ...action.values
      };
    }
    case "restart": {
      return {
        ...state,
        ...initialState
      };
    }
    case "updateResponse": {
      if (action.data.user_id) {
        localStorage.setItem("user_id", action.data.user_id);
        if (action.data.screen.name === "results") {
          localStorage.setItem("results", true);
          localStorage.setItem("locked", action.data.screen.context.are_some_careers_locked);
          if (action.data.screen.context.amount_paid_pence > 0) {
            localStorage.setItem("paid", true);
          }
          if (action.data.screen.context.emails_sent === true) {
            localStorage.setItem("emails_sent", true);
          }
        }
      }
      return {
        ...state,
        user_id: action.data.user_id,
        screen_name: action.data.screen.name,
        context: action.data.screen.context,
        loading: false,
      };
    }
    case "setToken": {
      return {
        ...state,
        access_token: action.access_token,
      };
    }
    default:
      return state;
  }
};
