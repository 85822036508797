/* Legacy screen at the start of the flow - no longer used */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import { useStateValue } from "../../services/state";
import { createSubmitData } from '../../utils/helper';
import { useGetScreenData } from '../../hooks/getDataHooks';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import SelectButton from '../../components/singleselect/SelectButton';
import { Label } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';
import RestartFlow from '../RestartFlow';



const NoDegreeScreen = () => {
  const [{ user_id, loading, context }, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();
  const match = useRouteMatch("/:userId/aspire-degree");
  const history = useHistory();

  useGetScreenData('aspire-degree', setIsLoading, match.params.userId);

  const handleClick = (value, logEvent) => {

    setLoadingText(context.loading_message);

    dispatch({type: 'update', values: { loading: true, aspire_degree: value, showPreQuizInfoScreen: true,}});

    logEvent('Completed Aspire Degree', {aspire_degree: value});

    const formData = createSubmitData({
      aspire_degree: value,
      user_id: user_id,
    });

    axios.post('/api/v1/handleAspireDegreeInput', formData).then((res) => {
      setIsLoading(true);
      dispatch({ type: 'updateResponse', data: res.data});
      setTimeout(() => {
        setIsLoading(false);
        history.push(`/${res.data.user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
      }, 2500);
    })
  };

  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  return (
    <>
      <MetaTags>
        <title>Aspire to get a degree?</title>
        <meta id="meta-description" name="description" content="Do you aspire to get a degree?" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Let’s kick things off"
        subtitle="This will help us suggest more relevant jobs"
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Amplitude userProperties={{have_degree: false}}>
              {({ logEvent }) => (
                <QuestionContainer>
                  <Label>Do you aspire to get a degree?</Label>
                  <ButtonWrap onClick={() => handleClick('yes', logEvent)} data-testid="aspire_yes">
                    <SelectButton label="Yes" />
                  </ButtonWrap>
                  <ButtonWrap onClick={() => handleClick('maybe', logEvent)} data-testid="aspire_maybe">
                    <SelectButton label="Maybe" />
                  </ButtonWrap>
                  <ButtonWrap onClick={() => handleClick('no', logEvent)} data-testid="aspire_no">
                    <SelectButton label="No" />
                  </ButtonWrap>
                </QuestionContainer>
              )}
            </Amplitude>
            <RestartFlow largeMarginTop endpoint={context.endpoint} />
          </Col>
        </Row>
      </ThreeDotsBgLayout>
    </>
  );
};

export default NoDegreeScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 8px;
`;
