import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircleRed, CircleLCGPurple, CirclePurple } from './Shapes';
import layout from '../../config/layout';

const BgThreeDots = ({ PurpleCircleUp, addMobile }) => {
  return (
    <>
      <ShapeRed addMobile={addMobile} />
      <ShapePurple top={PurpleCircleUp} addMobile={addMobile} />
      <ShapeLCGPurple addMobile={addMobile} />
    </>
  );
};

export default BgThreeDots;

BgThreeDots.propTypes = {
  PurpleCircleUp: PropTypes.bool,
};

const ShapeRed = styled(CircleRed)`
  top: 154px;
  right: -45px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 240px;
    right: -100px;

    ${props => !props.addMobile && `
      display: none;
    `}

  }
`;

const ShapePurple = styled(CirclePurple)`
  top: ${props => props.top ? '-26px' : '24px'};
  left: -20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: -17px;
    left: -41px;

    ${props => !props.addMobile && `
      display: none;
    `}
  }
`;

const ShapeLCGPurple = styled(CircleLCGPurple)`
  top: calc(100vh - 150px);
  left: -50px;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => !props.addMobile && `
      display: none;
    `}
  }
`;
