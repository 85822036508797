/* Homepage > Get started > In employment (or other options) > Location screen > Education screen
   Note: On the first input screen, most other "employment status" inputs leads to the Education
   screen, except I think for Student > College and Student > Univerisity */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';
import { useRouteMatch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { createSubmitData } from '../../utils/helper';
import { getNameByID, getNamesByIDs } from '../../utils/getNamesByIDs';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Label, HintText, Error } from '../../ui/typography/Typography';
import SelectBar from '../../components/SelectBar';
import None from './None';
import Gcses from './Gcses';
import Alevels from './Alevels';
import Degree from './Degree';
import MastersPhd from './MastersPhd';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
import LoadingScreen from '../LoadingScreen';
import RestartFlow from '../RestartFlow';
import Multiselect from '../../components/multiselect/Multiselect';

const EducationScreen = ({ updateAppState }) => {
  const [{ user_id, context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingText, setLoadingText ] = useState();
  const [ generalError, setGeneralError ] = useState(null);
  const [ haveDegree, setHaveDegree ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false }
  ]);
  const [ aspireDegree, setAspireDegree ] = useState([
    { name: 'Yes', selected: false, id: 0, value: "yes" },
    { name: 'Maybe', selected: false, id: 1, value: "maybe" },
    { name: 'No', selected: false, id: 2, value: "no" }
  ]);
  const [ edLevel, setEdLevel ] = useState(null);
  const [ otherQualifications, setOtherQualifications ] = useState([]);
  const [ gcses, setGcses ] = useState(null);
  const [ alevels, setAlevels ] = useState(null);
  const [ alevelSchool, setAlevelSchool ] = useState(null);
  const [ degree, setDegree ] = useState(null);
  const [ degreeUniversity, setDegreeUniversity ] = useState(null);
  const [ masters, setMasters ] = useState(null);
  const [ mastersUniversity, setMastersUniversity ] = useState(null);
  const [ phd, setPhd ] = useState(null);
  const [ phdUniversity, setPhdUniversity ] = useState(null);

  const history = useHistory();
  const match = useRouteMatch("/:userId/education");

  const noDegreeEdOptions = [{ id:'none', label: 'None' }, { id: 'gcses', label: 'GCSEs'}, { id:'alevels', label: 'A-levels'}];
  const degreeEdOptions = [{ id:'degree', label: 'Degree'}, {id:'masters', label: 'Masters' }, {id:'phd', label: 'PhD'}];

  useGetScreenData('education', setIsLoading, match.params.userId);

  const setHaveDegreeToValue = (value) => {
    const haveDegreeCopy = haveDegree.slice();
    // eslint-disable-next-line no-unused-vars
    for (const haveDegreeOption of haveDegreeCopy) {
      if (haveDegreeOption.value === value) {
        haveDegreeOption.selected = true;
      } else {
        haveDegreeOption.selected = false;
      }
    }
    setHaveDegree(haveDegreeCopy);
  }

  const setAspireDegreeToValue = (value) => {
    const aspireDegreeCopy = aspireDegree.slice();
    // eslint-disable-next-line no-unused-vars
    for (const aspireDegreeOption of aspireDegreeCopy) {
      if (aspireDegreeOption.value === value) {
        aspireDegreeOption.selected = true;
      } else {
        aspireDegreeOption.selected = false;
      }
    }
    setAspireDegree(aspireDegreeCopy);
  }

  useEffect(() => {
    if (context && context.have_degree !== null) {
      setHaveDegreeToValue(context.have_degree);
    }
    if (context && context.aspire_degree !== null) {
      setAspireDegreeToValue(context.aspire_degree);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const validateForm = () => {
    const userHaveDegree = haveDegree.find(item => item.selected === true);
    const userAspireDegree = aspireDegree.find(item => item.selected === true);

    if (context.age >= 21 && !userHaveDegree) return false;
    if ((context.age < 21 || (userHaveDegree && userHaveDegree.value === false)) && !userAspireDegree) return false;

    if (context && context.live_in_the_uk === true && context.country !== "scotland") {
      if (!edLevel) return false;
      if ( edLevel.id === 'gcses' && (!gcses || gcses.num_gcses === 0) )  return false;
      if ( edLevel.id === 'alevels' && (
          ( (!alevels || alevels.length === 0) && (!otherQualifications || otherQualifications.length === 0) ) ||
          !alevelSchool
         ) ) return false;
      if ( edLevel.id === 'degree' && (!degree || degree.length === 0 || !degreeUniversity) ) return false;
      if ( edLevel.id === 'masters' && (!masters || masters.length === 0 || !mastersUniversity || !degree || degree.length === 0 || !degreeUniversity) ) return false;
      if ( edLevel.id === 'phd' && (!phd || phd.length === 0 || !phdUniversity || (masters && masters.length > 0 && (!mastersUniversity || mastersUniversity.length === 0)) || !degree || degree.length === 0 || !degreeUniversity) ) return false;
    }

    return true;
  };

  const submitForm = (logEvent) => {
    if (!validateForm) return;

    if (context.should_capture_experience !== true) {
      setLoadingText(context.loading_message);
    }
    setIsLoading(true);

    const userHaveDegree = haveDegree.find(item => item.selected === true);
    const userAspireDegree = aspireDegree.find(item => item.selected === true);

    let data = createSubmitData({
      user_id: user_id,
      education_details: {
        highest_education_level: (edLevel !== null) ? edLevel.id : null,
      }
    });

    if (userHaveDegree) {
      data["education_details"]["have_degree"] = userHaveDegree.value;
    }
    if (userAspireDegree) {
      data["education_details"]["aspire_degree"] = userAspireDegree.value;
    }

    if (edLevel !== null) {
      if (edLevel.id === 'none') {
        data.education_details = {...data.education_details, other_qualifications: otherQualifications};
      }
      if (edLevel.id === 'gcses') {
        data.education_details = {...data.education_details, ...gcses};
      }
      if (edLevel.id === 'alevels') {
        data.education_details = {...data.education_details, qualifications: alevels, other_qualifications: otherQualifications, alevel_school: alevelSchool};
      }
      if (edLevel.id === 'degree') {
        data.education_details = {...data.education_details, qualifications: degree, degree_university: degreeUniversity};
      }
      if (edLevel.id === 'masters') {
        data.education_details = {...data.education_details, qualifications: [...masters, ...degree], masters_university: mastersUniversity, degree_university: degreeUniversity};
      }
      if (edLevel.id === 'phd') {
        data.education_details = {...data.education_details, qualifications: [...phd, ...masters, ...degree], phd_university: phdUniversity, masters_university: mastersUniversity, degree_university: degreeUniversity};
      }
    }

    axios.post('/api/v1/handleEducationInput', data).then((res) => {

      dispatch({type: 'updateResponse', data: res.data})

      if (!res.data.errors) {
        logEvent('Completed Education Input', {
          have_degree: data.education_details.have_degree,
          aspire_degree: data.education_details.aspire_degree,
          highest_education_level: data.education_details.highest_education_level,
          num_gcses: data.education_details.num_gcses,
          gcses: getGCSEs(data.education_details),
          alevels: getNamesByIDs(alevels, context.alevel_autocomplete_options, "id"),
          alevel_school: getNameByID(alevelSchool, context.pre_university_autocomplete_options),
          alevel_school_from_home: (alevelSchool === -1) ? true : null,
          alevel_school_outside_uk: (alevelSchool === -2) ? true : null,
          degree: getNamesByIDs(degree, context.degree_autocomplete_options, "id"),
          degree_university: getNameByID(degreeUniversity, context.university_autocomplete_options),
          degree_university_outside_uk: (degreeUniversity === -1) ? true : null,
          masters: getNamesByIDs(masters, context.masters_autocomplete_options, "id"),
          masters_university: getNameByID(mastersUniversity, context.university_autocomplete_options),
          masters_university_outside_uk: (mastersUniversity === -1) ? true : null,
          phd: getNamesByIDs(phd, context.phd_autocomplete_options, "id"),
          phd_university: getNameByID(phdUniversity, context.university_autocomplete_options),
          phd_university_outside_uk: (phdUniversity === -1) ? true : null,
          other_qualifications: data.education_details.other_qualifications,
        });
      }

      if (res.data.errors) {
        setIsLoading(false);
        // eslint-disable-next-line no-unused-vars
        for (const error of res.data.errors) {
          if (error.hasOwnProperty('non-field-errors')) {
            setGeneralError(error['non-field-errors']);
          }
        }
      } else {
        if (res.data.screen.context.question_number) {
          dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
          setTimeout(() => {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
          }, 2500);
        } else {
          setIsLoading(false);
          history.push(`/${user_id}/${res.data.screen.name}`);
        }
      }
    });
  };

  const getGCSEs = (educationDetails) => {
    let gcses = [];
    if (educationDetails.have_gcse_maths) {
      gcses.push("Maths");
    }
    if (educationDetails.have_gcse_english) {
      gcses.push("English");
    }
    if (educationDetails.have_gcse_science) {
      gcses.push("Science");
    }
    if (educationDetails.have_gcse_foreign_language) {
      gcses.push("A foreign language");
    }
    if (gcses.length > 0) {
      return gcses;
    } else {
      return null;
    }
  }

  const handleHaveDegreeChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setHaveDegree(values);

    // Any change to the have degree multi-select should nullify all other values below it
    nullifyAspireDegree();
    setEdLevel(null);
    nullifyEducationDetails();
  };

  const nullifyAspireDegree = () => {
    const aspireDegreeCopy = aspireDegree.slice();
    // eslint-disable-next-line no-unused-vars
    for (const aspireDegreeOption of aspireDegreeCopy) {
      aspireDegreeOption.selected = false;
    }
    setAspireDegree(aspireDegreeCopy);
  }

  const nullifyEducationDetails = () => {
    setOtherQualifications([]);
    setGcses(null);
    setAlevels(null);
    setAlevelSchool(null);
    setDegree(null);
    setDegreeUniversity(null);
    setMasters(null);
    setMastersUniversity(null);
    setPhd(null);
    setPhdUniversity(null);
  }

  const handleAspireDegreeChange = (id, data) => {
    const values = [...data];
    var isNowSelected = false;
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
        if (value.selected) {
          isNowSelected = true;
        }
      } else {
        value.selected = false;
      }
    }
    setAspireDegree(values);

    // If the user deselected an option (so that none are now selected), nullify all other values below it
    if (!isNowSelected) {
      setEdLevel(null);
      nullifyEducationDetails();
    }
  };

  const handleEducationLevelChange = (item) => {
    setEdLevel(item);
    nullifyEducationDetails();
  };

  const handleMastersPhdChange = (phdValues, phdUniversity, mastersValues, mastersUniversity, degreeValues, degreeUniversity) => {
    phdValues && setPhd(phdValues);
    phdUniversity && setPhdUniversity(phdUniversity);

    setMasters(mastersValues);
    setMastersUniversity(mastersUniversity);

    setDegree(degreeValues);
    setDegreeUniversity(degreeUniversity);
  };

  const userHaveDegree = haveDegree.find(item => item.selected === true);
  const userAspireDegree = aspireDegree.find(item => item.selected === true);

  var subtitle = "This will help us build a personalised pathway for you into your careers of interest.";
  if (context && (context.live_in_the_uk === false || context.country === "scotland")) {
    subtitle = "This will help us select relevant careers to show you.";
  }

  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  var title = "Your education";
  var highestEducationLevelLabel = "What's your highest level of education?";
  if (context.past) {
    title = "Your past education";
    highestEducationLevelLabel = "What’s your highest level of education prior to this course?";
  }

  return (
    <>
      <MetaTags>
        <title>Education</title>
        <meta id="meta-description" name="description" content="Input your education" />
      </MetaTags>
      <ThreeDotsBgLayout
        title={title}
        subtitle={subtitle}
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              {context.age >= 21 && (
                <InputWrap>
                  <Label>Do you have a degree?</Label>
                  <HintText>For example a BA, BSc, MA etc.</HintText>
                  <Multiselect
                    values={haveDegree}
                    handleChange={handleHaveDegreeChange}
                    name=""
                    disabled={context && context.have_degree != null}
                    testid="have_degree"
                  />
                </InputWrap>
              )}
              {(context.age < 21 || (userHaveDegree && userHaveDegree.value === false)) && (
                <InputWrap>
                  <Label>For the right career, would you consider studying for a degree?</Label>
                  <Multiselect
                    values={aspireDegree}
                    handleChange={handleAspireDegreeChange}
                    name=""
                    disabled={context && context.aspire_degree != null}
                    testid="study_abroad"
                  />
                </InputWrap>
              )}
              {context && (context.live_in_the_uk === true && context.country !== "scotland") &&
               ( (context.age < 21 && userAspireDegree) || (userHaveDegree && (userHaveDegree.value === true || userAspireDegree) ) ) && (
                <InputWrap>
                  <Label>{highestEducationLevelLabel}</Label>
                  {(context.age < 21 || userHaveDegree.value === false) &&
                    <HintTextNarrow>If you studied for BTECs, NVQs, T Levels, Highers or equivalent, choose A-Levels.</HintTextNarrow>
                  }
                  {context.age >= 21 && userHaveDegree.value === true &&
                    <HintTextNarrow>Add any professional level qualifications in Masters.</HintTextNarrow>
                  }
                  <SelectBar values={(context.age >= 21 && userHaveDegree.value) ? degreeEdOptions : noDegreeEdOptions} handleChange={(item) => handleEducationLevelChange(item)} selectedValue={edLevel} testid="education_level" />
                </InputWrap>
              )}
              {(context.age < 21 || (userHaveDegree && !userHaveDegree.value)) && userAspireDegree && edLevel && edLevel.id === 'none' && (<None handleChange={item => setOtherQualifications(item)} />)}
              {(context.age < 21 || (userHaveDegree && !userHaveDegree.value)) && userAspireDegree && edLevel && edLevel.id === 'gcses' && (<Gcses handleChange={item => setGcses(item)} />)}
              {(context.age < 21 || (userHaveDegree && !userHaveDegree.value)) && userAspireDegree && edLevel && edLevel.id === 'alevels' && (
                <Alevels
                  handleChange={(qualifications, alternativeQualifications, school) => {
                    setAlevels(qualifications);
                    setOtherQualifications(alternativeQualifications);
                    setAlevelSchool(school)
                  }}
                  options={context.alevel_autocomplete_options}
                  pre_university_autocomplete_options={context.pre_university_autocomplete_options}
                />
              )}
              {context.age >= 21 && userHaveDegree && userHaveDegree.value && edLevel && edLevel.id === 'degree' && (
                <Degree handleChange={(item, university) => {
                  setDegree(item);
                  setDegreeUniversity(university);
                }}
                options={context.degree_autocomplete_options}
                university_autocomplete_options={context.university_autocomplete_options} />
              )}
              {context.age >= 21 && userHaveDegree && userHaveDegree.value && edLevel && edLevel.id === 'masters' && (
                <MastersPhd
                  handleChange={handleMastersPhdChange}
                  optionsOne={context.masters_autocomplete_options}
                  optionsTwo={context.degree_autocomplete_options}
                  university_autocomplete_options={context.university_autocomplete_options}
                  subjectValuesOne={[
                    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
                    { value: "", placeholder: "e.g. French", id: 1, error: null },
                    { value: "", placeholder: "e.g. Business", id: 2, error: null }
                  ]}
                  subjectValuesTwo={[
                    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
                    { value: "", placeholder: "e.g. French", id: 1, error: null },
                    { value: "", placeholder: "e.g. Business", id: 2, error: null }
                  ]}
                  labelOne="What subject is your masters in?"
                  hintOne="Type a subject and select from available options. You can enter multiple subjects if you have more than one masters."
                  labelTwo="What subject was your undergrad degree in?"
                  hintTwo=" Type a subject and select from available options. You can enter multiple subjects if you have a joint honours, or a degree with a major and a minor."
                  whereLabelOne="Where did you study your masters?"
                  whereLabelTwo="Where did you study your degree?"
              />)}
              {context.age >= 21 && userHaveDegree && userHaveDegree.value && edLevel && edLevel.id === 'phd' && (
                <MastersPhd
                  handleChange={handleMastersPhdChange}
                  optionsOne={context.phd_autocomplete_options}
                  optionsTwo={context.masters_autocomplete_options}
                  optionsThree={context.degree_autocomplete_options}
                  university_autocomplete_options={context.university_autocomplete_options}
                  subjectValuesOne={[{ value: "", placeholder: "e.g. Mathematics", id: 0, error: null }]}
                  subjectValuesTwo={[
                    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
                    { value: "", placeholder: "e.g. French", id: 1, error: null },
                    { value: "", placeholder: "e.g. Business", id: 2, error: null }
                  ]}
                  subjectValuesThree={[
                    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
                    { value: "", placeholder: "e.g. French", id: 1, error: null },
                    { value: "", placeholder: "e.g. Business", id: 2, error: null }
                  ]}
                  labelOne="What subject is your PhD in?"
                  hintOne="Type a subject and select from available options."
                  labelTwo="If you have a masters, enter the subject you studied here."
                  hintTwo="Type a subject and select from available options. You can enter multiple subjects if you have more than one masters."
                  labelThree="What subject was your undergrad degree in?"
                  hintThree="Type a subject and select from available options. You can enter multiple subjects if you have a joint honours, or a degree with a major and a minor."
                  whereLabelOne="Where did you study your PhD?"
                  whereLabelTwo="Where did you study your masters?"
                  whereLabelThree="Where did you study your degree?"
                  noAdd
                />
              )}
            </QuestionContainer>
            <ButtonWrap>
              {validateForm() ? (
                <Amplitude userProperties={{
                  have_degree: (userHaveDegree ? userHaveDegree.value : null),
                  aspire_degree: (userAspireDegree ? userAspireDegree.value : null),
                  highest_education_level: (edLevel !== null) ? edLevel.id :  null,
                  num_gcses: (gcses ? gcses.num_gcses : null),
                  gcses: (gcses ? getGCSEs(gcses) : null),
                  alevels: getNamesByIDs(alevels, context.alevel_autocomplete_options, "id"),
                  alevel_school: getNameByID(alevelSchool, context.pre_university_autocomplete_options),
                  alevel_school_from_home: (alevelSchool === -1) ? true : null,
                  alevel_school_outside_uk: (alevelSchool === -2) ? true : null,
                  degree: getNamesByIDs(degree, context.degree_autocomplete_options, "id"),
                  degree_university: getNameByID(degreeUniversity, context.university_autocomplete_options),
                  degree_university_outside_uk: (degreeUniversity === -1) ? true : null,
                  masters: getNamesByIDs(masters, context.masters_autocomplete_options, "id"),
                  masters_university: getNameByID(mastersUniversity, context.university_autocomplete_options),
                  masters_university_outside_uk: (mastersUniversity === -1) ? true : null,
                  phd: getNamesByIDs(phd, context.phd_autocomplete_options, "id"),
                  phd_university: getNameByID(phdUniversity, context.university_autocomplete_options),
                  phd_university_outside_uk: (phdUniversity === -1) ? true : null,
                  other_qualifications: (otherQualifications.length > 0 ? otherQualifications : null),
                }}>
                  {({ logEvent }) => (
                    <PrimaryButton onClick={() => submitForm(logEvent)} data-testid="next">Next</PrimaryButton>
                  )}
                </Amplitude>
              ) : (
                <Tooltip text="Please enter your education details above">
                  <GrayButton>Next</GrayButton>
                </Tooltip>
              )}
              {generalError && <Error>{generalError.map(item => item)}</Error>}
            </ButtonWrap>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default EducationScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 382px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 8px;
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const HintTextNarrow = styled(HintText)`
  width: 300px;
`;
