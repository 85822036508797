/* Restart button on any of the input screens or "Log out" on final results */

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from '../components/Modal';
import { P } from '../ui/typography/Typography';
import layout from '../config/layout';
import { SecondaryButton } from '../ui/buttons/Buttons';
import { useHistory } from 'react-router-dom';
import { useStateValue } from "../services/state";
import { Amplitude} from "@amplitude/react-amplitude";

const RestartFlow = ({ largeMarginTop, logOut, areSomeCareersLocked, paid, canEmailResults, emailsSent, endpoint }) => {
  // eslint-disable-next-line
  const [ _, dispatch ] = useStateValue();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleRestart = () => {
    localStorage.setItem("user_id", null);
    localStorage.setItem("results", null);
    localStorage.setItem("locked", null);
    localStorage.setItem("paid", null);
    localStorage.setItem("emails_sent", null);
    dispatch({type: 'restart'});
    history.push('/' + (endpoint ? endpoint : ''));
  };

  return (
      <Amplitude>
        {({ logEvent }) => (
          <>
            <ButtonContainer largeMarginTop={largeMarginTop}>
              <SecondaryButton onClick={() => {setOpen(true); if (logOut) {logEvent('Clicked Log Out')} else {logEvent('Clicked Restart')}}}>{logOut && `Log out`}{!logOut && `Restart`}</SecondaryButton>
            </ButtonContainer>
            <Modal
              open={open}
              handleClose={() => setOpen(false)}
              title="Are you sure?"
              buttonPrimaryLabel="Yes"
              buttonSecondaryLabel="No"
              handlePrimaryButtonClick={() => {handleRestart(); if (logOut) {logEvent('Logged Out')} else {logEvent('Restarted')}}}
            >
              {!logOut && (
                <P>Are you sure you want to restart? You'll lose all of your progress so far.</P>
              )}
              {logOut && emailsSent && (
                <P>Are you sure you want to log out? You'll only be able to access your results from the link we have emailed you.</P>
              )}
              {logOut && !emailsSent && canEmailResults && (
                <P>Are you sure you want to log out? You'll lose all of your progress so far unless you email a link to these results (at the top of the page)</P>
              )}
              {logOut && !emailsSent && !canEmailResults && areSomeCareersLocked && (
                <P>Are you sure you want to log out? You'll lose all of your progress so far. If you unlock your careers, we'll email you a link so you can return any time.</P>
              )}
              {logOut && !emailsSent && !canEmailResults && !areSomeCareersLocked && (
                <P>Are you sure you want to log out? You'll lose all of your progress so far.</P>
              )}
            </Modal>
          </>
        )}
      </Amplitude>
  );
};

export default RestartFlow;

RestartFlow.propTypes = {
  largeMarginTop: PropTypes.bool,
};

const ButtonContainer = styled.div`
  text-align: center;
  margin: ${props => props.largeMarginTop ? '52px' : '20px'} 0 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: ${props => props.largeMarginTop ? '52px' : '20px'} 0 32px;
  }
`;
