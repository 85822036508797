/* Screen to show information to users, used throughout the quiz experience (e.g. quiz hints and tips,
   just before career selection etc.)) */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BgTwoDotsTwoShapes from '../ui/bg/BgTwoDotsTwoShapes';
import Header from '../components/Header';
import { H3, PSmall, P } from '../ui/typography/Typography';
import { PrimaryButton } from '../ui/buttons/Buttons';
import ReactHtmlParser from 'react-html-parser';

const InfoScreen = ({ title, text, buttonLabel, handleButtonClick, extraText }) => {
  return (
    <Container>
      <BgTwoDotsTwoShapes />
      <Header logo_alignment="center" />
      <ContentContainer>
        <Content>
          <H3 align="center">{title}</H3>
          {text && text.map(p => (
            <P key={p} align="center">{ReactHtmlParser(p)}</P>
          ))}
          {
            buttonLabel && (
              <ButtonWrap>
                <PrimaryButton id="info_screen_button" onClick={handleButtonClick} data-testid="info_button">{buttonLabel}</PrimaryButton>
              </ButtonWrap>
            )
          }
          {extraText && (<PSmall align="center">{extraText}</PSmall>)}
        </Content>
      </ContentContainer>
    </Container>
  );
};


export default InfoScreen;

InfoScreen.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  buttonLabel: PropTypes.string,
  handleButtonClick: PropTypes.func,
  extraText: PropTypes.string,
};

const Container = styled.div`
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Content = styled.div`
  max-width: 544px;
  padding: 0 20px 100px;
`;

const ButtonWrap = styled.div`
  margin-top: 32px;
  text-align: center;
  margin-bottom: 12px;
`;
