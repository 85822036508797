import styled, { css } from 'styled-components';
import colors from '../../config/colors';
import layout from '../../config/layout';

export const H1 = styled.h1`
  color: ${props => props.color ? props.color : colors.TEXT_DARK };
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 32px;
  ${props => props.align && 'text-align: ' + props.align};


  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: 24px;
      line-height: 30px;
    `};
  }
`;

export const H2 = styled.h2`
  color: ${props => props.color ? props.color : colors.TEXT_DARK };
  font-size: ${props => props.h3 ? "28px" : props.h5 ? "20px" : "36px"};
  line-height: ${props => props.h3 ? "30px" : props.h5 ? "30px" : "50px"};
  font-weight: 700;
  margin-bottom: 32px;
  ${props => props.align && 'text-align: ' + props.align};


  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: ${props => props.h3 ? "20px" : props.h5 ? "16px" : "22px"};
      line-height: ${props => props.h3 ? "32px" : props.h5 ? "24px" : "30px"};
    `};
  }
`;

export const H3 = styled.h3`
  color: ${props => props.color ? props.color : colors.TEXT_DARK };
  font-size: ${props => props.h1 ? "48px" : props.h2 ? "36px" : "28px"};
  line-height: ${props => props.h1 ? "60px" : props.h2 ? "50px" : "30px"};
  font-weight: 700;
  margin-bottom: 32px;
  ${props => props.align && 'text-align: ' + props.align};


  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: ${props => props.h1 ? "24px" : props.h2 ? "22px" : "20px"};
      line-height: ${props => props.h1 ? "30px" : props.h2 ? "30px" : "32px"};
    `};
  }
`;

export const H4 = styled.h4`
  color: ${props => props.color ? props.color : colors.TEXT_DARK };
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 4px;
  ${props => props.align && 'text-align: ' + props.align};

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: 18px;
      line-height: 28px;
    `};
  }
`;

export const H5 = styled.h5`
  color: ${props => props.color ? props.color : colors.TEXT_DARK };
  font-size: ${props => props.h3 ? "28px" : "20px"};
  font-weight: 700;
  line-height: 30px;
  ${props => props.align && 'text-align: ' + props.align};

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: ${props => props.h3 ? "20px" : "16px"};
      line-height: 24px;
    `};
  }
`;

export const P = styled.p`
  color: ${props => props.color ? props.color : colors.TEXT_BODY };
  font-size: 20px;
  font-weight: ${props => props.bold ? 700 : 400 };
  ${props => props.italic && 'font-style: italic;'};
  line-height: 32px;
  margin-bottom: ${props => props.nomargin ? 0 : '16px'};
  ${props => props.margin && 'margin: ' + props.margin};
  ${props => props.align && 'text-align: ' + props.align};
  ${props => props.underline && 'text-decoration: underline'};

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: 16px;
      line-height: 24px;
    `};
  }
`;

export const PSmall = styled.p`
  color: ${props => props.color ? props.color : colors.TEXT_BODY };
  font-size: 16px;
  font-weight: ${props => props.bold ? 700 : 400 };
  line-height: 24px;
  margin-bottom: ${props => props.nomargin ? 0 : '16px'};
  ${props => props.align && 'text-align: ' + props.align};
`;

export const PVSmall = styled.p`
  color: ${props => props.color ? props.color : colors.TEXT_BODY };
  font-size: 14px;
  font-weight: ${props => props.bold ? 700 : 400 };
  line-height: 24px;
  margin-bottom: 16px;
  ${props => props.align && 'text-align: ' + props.align};
`;

export const ListStyle = css`
  color: ${props => props.color ? props.color : colors.TEXT_BODY };
  font-size: 20px;
  font-weight: ${props => props.bold ? 700 : 400 };
  line-height: 32px;
  margin-bottom: 8px;
  ${props => props.align && 'text-align: ' + props.align};
  margin-left: 32px;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => !props.desktop_styles_only && `
      font-size: 16px;
      line-height: 24px;
    `};
  }
`;

export const Ul = styled.ul`
  ${ListStyle};

  li {
    list-style: disc;
    color: inherit;
    margin-bottom: 8px;
    marign-left: 16px;
  }
`;

export const Ol = styled.ol`
  ${ListStyle};

  li {
    list-style: decimal;
    color: inherit;
    margin-bottom: 8px;
    marign-left: 16px;
  }
`;


export const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: ${colors.TEXT_DARK};
  font-weight: 700;
  display: block;
`;

export const HintText = styled.p`
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${colors.GRAY_DARK};
`;

export const Error = styled(PSmall)`
  color: ${colors.LCG_DARK_PURPLE};
  margin-top: 12px;
`;
