import Cookies from "js-cookie";

const runScripts = () => {
 const cookieValue = Cookies.get("cookies");
 const cookieLocalStorage = localStorage.getItem("cookies");

  if (cookieValue === 'on' || cookieLocalStorage === true || cookieLocalStorage === "true" || true) {
    // Hotjar Tracking Code for www.careerspro.co.uk
    (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:2205361,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

    //pagead2 google
    let pagead = document.createElement('script');
    pagead.setAttribute("data-ad-client", "ca-pub-2018257882226559");
    pagead.async = true;
    pagead.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.getElementsByTagName('head')[0].appendChild(pagead);

    // Global site tag (gtag.js) - Google Analytics
    let gtagEl = document.createElement('script');
    gtagEl.async = true;
    gtagEl.src = "https://www.googletagmanager.com/gtag/js?id=UA-175471203-1";
    document.getElementsByTagName('head')[0].appendChild(gtagEl);

    let gtagElScript = document.createElement('script');
    gtagElScript.text =`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-175471203-1');
    gtag('config', 'AW-592771736');`;
    document.getElementsByTagName('head')[0].appendChild(gtagElScript);

    //Facebook

    let fb = document.createElement('script');

    fb.text =`window.fbAsyncInit = function() {
      FB.init({
        appId      : '797489534026146',
        cookie      : true,
        xfbml      : true,
        version    : 'v5.0'
      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));`;

    document.getElementsByTagName('body')[0].appendChild(fb);

    // Snap
    let snapScript = document.createElement('script');
    snapScript.text = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
      {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
      a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
      r.src=n;var u=t.getElementsByTagName(s)[0];
      u.parentNode.insertBefore(r,u);})(window,document,
      'https://sc-static.net/scevent.min.js');
      snaptr('init', 'cc4fd3d6-0690-4a4f-9360-df36f6f16855', {
      'user_email': 'emma@careerspro.co.uk'
      });
      snaptr('track', 'PAGE_VIEW');`;
    document.getElementsByTagName('head')[0].appendChild(snapScript);

    // Tiktok
    let tiktokScript = document.createElement('script');
    tiktokScript.text = `!function (w, d, t) {
		  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
		  ttq.load('C3QQ2C04C3SCJI4RIA30');
		  ttq.page();
		}(window, document, 'ttq');`;
    document.getElementsByTagName('head')[0].appendChild(tiktokScript);

    // LinkedIn
    let linkedinScript1 = document.createElement('script');
    linkedinScript1.text = `_linkedin_partner_id = "3743857"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`;
    document.getElementsByTagName('head')[0].appendChild(linkedinScript1);
    let linkedinScript2 = document.createElement('script');
    linkedinScript2.text = `(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);`;
    document.getElementsByTagName('head')[0].appendChild(linkedinScript2);
    let linkedinNoScript = document.createElement('noscript');
    linkedinNoScript.text = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3743857&fmt=gif" />`;
    document.getElementsByTagName('head')[0].appendChild(linkedinNoScript);

  }
};

export default runScripts;
