import styled, { css } from 'styled-components';
import colors from '../../config/colors';

export const ButtonStyle = css`
  border-radius: 24px;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;

  font-size: ${props => props.small ? '16px' : '20px'};
  padding: ${props => props.small ? '4px 20px' : '8px 32px'};
  border-radius: ${props => props.small ? '20px' : '24px'};

  &:disabled {
    cursor: default;
    background-color: ${colors.LCG_LIGHTER_GREY};
    color: ${colors.LCG_LIGHT_GREY};

    &:hover {
      background-color: ${colors.LCG_LIGHTER_GREY};
      color: ${colors.LCG_DARK_GREY};
    }
  }
`;

export const PrimaryButton = styled.button`
  ${ButtonStyle};
  background-color: ${colors.LCG_LIGHT_PURPLE};
  color: ${colors.TEXT_LIGHT};

  &:hover {
    background-color: ${colors.LCG_LIGHT_PURPLE_DARK};
  }
`;

export const SecondaryButton = styled.button`
  ${ButtonStyle};
  box-shadow: inset 0 0 0 2px ${colors.LCG_LIGHT_PURPLE_FADE};
  color: ${colors.LCG_LIGHT_PURPLE};
  background-color: ${colors.WHITE};

  &:hover {
    background-color: ${colors.BTN_BORDER_LIGHT};
  }
`;

export const GrayButton = styled.button`
  ${ButtonStyle};
  color: ${colors.TEXT_DARK};
  background-color: ${colors.GRAY_FADE};

  &:disabled {
    cursor: default;
    background-color: ${colors.LCG_LIGHTER_GREY};
    color: ${colors.LCG_LIGHT_GREY};

    &:hover {
      background-color: ${colors.LCG_LIGHTER_GREY};
      color: ${colors.LCG_DARK_GREY}
    }
  }
`;

export const LCGPurpleButton = styled.button`
  ${ButtonStyle};
  color: ${colors.WHITE};
  background-color: ${colors.LCG_DARK_PURPLE};

  &:hover {
    background-color: ${colors.LCG_DARK_PURPLE};
  }
`;

export const PurpleButton = styled.button`
  ${ButtonStyle};
  color: ${colors.WHITE};
  background-color: ${colors.LCG_LIGHT_PURPLE};

  &:hover {
    background-color: ${colors.LCG_DARK_PURPLE};
  }
`;
