/* Homepage > Privacy link at in the footer */

import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';

import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid } from 'react-styled-flexboxgrid';
import PrivacyContent from './PrivacyContent';
import PrivacyContent10 from './PrivacyContent10';

const privacyContent = {
  "v1.1": <PrivacyContent />,
  "v1.0": <PrivacyContent10 />,
};

const PrivacyScreen = () => {
  let match = useRouteMatch("/privacy/:version");
  const history = useHistory();

  if (match && !privacyContent[match.params.version]) {
    history.push('/404');
  }

  return (
    <>
      <MetaTags>
        <title>Privacy Policy</title>
        <meta id="meta-description" name="description" content="Would You Rather Be's privacy policy" />
      </MetaTags>
      <Container>
        <Grid>
          {privacyContent[match ? match.params.version : 'v1.1']}
        </Grid>
      </Container>
    </>
  );
};

export default PrivacyScreen;

const Container = styled.div`
  padding: 80px 0;
`;
