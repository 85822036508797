/* Homepage > Testimonials section */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { P, H3 } from '../../ui/typography/Typography';
import Title from './Title';
import { ShapeLCGPurple } from '../../ui/bg/BgTwoDotsLCGPurpleLine';
import { getRandomInt } from '../../utils/helper';
import Quote from '../../components/Quote';

// import TestimonialImage5 from '../../assets/testimonial_profile_5.png';
// import TestimonialImage6 from '../../assets/testimonial_profile_6.png';
import supportedByLogos from '../../assets/supported_by_logos.png';
// import testimonial1Image from '../../assets/testimonial1.gif';
// import testimonial2Image from '../../assets/testimonial2.gif';

let quoteInterval;

const TestimonialsV2Section = ({completions}) => {

  const quotes = [
    {quote: 'This should be in every secondary school so pupils can see a life plan of a career and what it takes to get there. Very insightful', author: 'Ann, London'},
    {quote: 'Really good options and made me really think about what I would rather be doing than working from home all the time!', author: 'Adam, Exeter'},
    {quote: 'Interesting choices and I felt accurate reflecting on personality. The test gave lots of valid and reasonable options to match traits.', author: 'Eva, Glasgow'},
    {quote: 'Really helpful, thanks. Something like this 12 years ago would have changed my life.', author: 'Mia, London'},
    {quote: 'It’s so helpful to be able to follow the pathways and actually get some insight into how long it takes! Perfect for someone like me who has ADHD', author: 'Joe, Guildford'},
    {quote: 'Very clear cut and easy to understand advice on how to pursue different careers!', author: 'Yasmine, London'},
    {quote: 'I\'m amazed that you were able to pick out a large set of careers that actually sound interesting - even some I hadn\'t even thought of myself!', author: 'Thomas, Oxford'},
    {quote: 'Really great options that open the mind.', author: 'Naomi, London'},
    {quote: 'Love how quick and easy this was to use! I also love the different pathways you can use to get to where you want. It makes looking for a new career so much easier', author: 'Shrina, Kent'}
  ];

  const [quoteNumber, setQuoteNumber] = useState(getRandomInt(quotes.length));

  useEffect(() => {
    quoteInterval = setInterval(() => {
      setQuoteNumber(preQuoteNumber => preQuoteNumber !== (quotes.length - 1) ? preQuoteNumber + 1 : 0);
    }, 8000);
    return () => {
      clearInterval(quoteInterval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container>
        <ShapeLCGPurple />
        <Grid id="customers">
          <Row>
            <ColCentered xs={12} md={8}>
              <Title is_subtitle>Careers advice fit for the 21st century</Title>
            </ColCentered>
          </Row>
          {/* <Row>
            <Col xs={12} md={5}>
              <TestimonialText><Quote>“</Quote>Something like this 12 years ago would have changed my life.</TestimonialText>
              <P color={colors.TEXT_GRAY} align="center">Mia, London</P>
              <SmallScreenProfileImages>
                <TestimonialImage src={TestimonialImage5} alt="Mia, Londo" />
              </SmallScreenProfileImages>
            </Col>
            <Col xs={12} md={5} mdOffset={1}>
              <TestimonialText><Quote>“</Quote>Got my dream careers all in one list! I have no idea how you did it but it’s so helpful to be able to follow the pathways and actually get some insight into how long it takes to accomplish certain career paths! Perfect for someone like me.</TestimonialText>
              <P color={colors.TEXT_GRAY} align="center">Joe, Guildford</P>
              <SmallScreenProfileImages>
                <TestimonialImage src={TestimonialImage6} alt="Joe, Guildford" />
              </SmallScreenProfileImages>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              <LargeScreenProfileImages>
                <TestimonialImage src={TestimonialImage5} alt="Mia, London" />
              </LargeScreenProfileImages>
            </Col>
            <Col xs={12} md={5} mdOffset={1}>
              <LargeScreenProfileImages>
                <TestimonialImage src={TestimonialImage6} alt="Joe, Guildford" />
              </LargeScreenProfileImages>
            </Col>
          </Row> */}
          <RowQuote>
            <ColQuote>
              <Quote
                text={quotes[quoteNumber].quote}
                author={quotes[quoteNumber].author}
                stars={5}
              />
            </ColQuote>
          </RowQuote>
          {completions && (
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <TitleSmaller align="center">Join {completions.toLocaleString('gb-en')} other people discovering their dream career</TitleSmaller>
              </Col>
            </Row>
          )}
          <Row>
            <SupportedBySection>
              <SupportedByText>Supported by</SupportedByText>
            </SupportedBySection>
          </Row>
          <Row>
            <SupportedByLogos src={supportedByLogos} alt="Supported by the Department for Education and Nesta" />
          </Row>
          {/* <Row>
            <TestimonialImage src={testimonial1Image} alt="Testimonial" hidden={showTestimonialImage1} />
            <TestimonialImage src={testimonial2Image} alt="Testimonial" hidden={!showTestimonialImage1} />
          </Row> */}
        </Grid>
      </Container>
    </div>
  );
};

export default TestimonialsV2Section;

TestimonialsV2Section.propTypes = {
  completions: PropTypes.number,
};

const Container = styled.div`
  padding-top: 240px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 176px;
    margin-bottom: 8px;
  }
`;

// const LargeScreenProfileImages = styled.div`
//   display: inherit;
//   @media(max-width: ${layout.breakpoints.MD}) {
//     display: none;
//   }
// `;

// const SmallScreenProfileImages = styled.div`
//   display: none;
//   @media(max-width: ${layout.breakpoints.MD}) {
//     display: inherit;
//   }
// `;

// const TestimonialText = styled(H3)`
//   font-weight: 400;
//   margin-bottom: 12px;
//   text-align: center;
  
//   @media(max-width: ${layout.breakpoints.MD}) {
//     padding: 0 12px 0 12px;
//   }
// `;

// const TestimonialImage = styled.img`
//   max-width: 50%;
//   width: 150px;
//   padding: 12px 0px 12px 0px;
//   display: block;
//   margin: auto;

//   @media(max-width: ${layout.breakpoints.MD}) {
//     padding: 8px 0px 32px 0px;
//     max-width: 30%;
//     width: 100px;
//   }
// `;

// const Quote = styled.span`
//   color: ${colors.LCG_DARK_PURPLE};
//   font-size: 52px;

//   @media(max-width: ${layout.breakpoints.MD}) {
//     font-size: 40px;
//   }
// `;

const TitleSmaller = styled(H3)`
  margin-top: 32px;
`;

const SupportedBySection = styled.div`
  margin: 8px auto 0px auto;
  display: block;
`

const SupportedByText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin-bottom: 12px;
`;

const SupportedByLogos = styled.img`
  width: 90%;
  max-width: 600px;
  display: block;
  margin: auto;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 450px;
  }
`;

// const TestimonialImage = styled.img`
//   width: 90%;
//   max-width: 400px;
//   padding: 32px 12px 12px 12px;
//   display: block;
//   margin: auto;

//   @media(max-width: ${layout.breakpoints.MD}) {
//     max-width: 300px;
//     padding: 32px 8px 8px 8px;
//     display: ${props => props.hidden ? 'none' : 'inherit'};
//   }
// `;

const RowQuote = styled(Row)`
  justify-content: center;
  margin-bottom: 40px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-bottom: 10px;
  }
`;

const ColQuote = styled(Col)`
  width: 100%;
`;

const ColCentered = styled(Col)`
  text-align: center;
  margin: auto;
`
