/* Final results > Career screen > Top part */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import colors from '../../../config/colors';
import layout from '../../../config/layout';
import { getJobSalary } from  '../../../utils/helper';

import { H5, H3, PSmall, P } from '../../../ui/typography/Typography';
import { Tag } from '../../../ui/tag/Tag';
import RevealContent from './RevealContent';
import { MoreLinkA } from '../../../ui/buttons/Link';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const AboutCard = ({ user_id, item, context }) => {
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (context.live_in_the_uk === false || (context.pathways && context.pathways.length === 0)) {
      setOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <>
      <Grid>
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Label align="center" color={colors.TEXT_BODY}>{context && context.first_name ? context.first_name + ", find" : "Find"} career happiness as {item.career_name_prefix}</Label>
            <Title align="center">{item.career_name}</Title>
            <Salary align="center" nomargin={item.extra_salary_notes !== ""}>{getJobSalary(item.starting_salary, item.experienced_salary)}</Salary>
            {item.extra_salary_notes !== "" && (<PSmall align="center">{item.extra_salary_notes}</PSmall>)}
            {/* {item.skills_match && item.skills_match !== "" && (<SkillsMatchLabel>{item.skills_match} SKILLS MATCH</SkillsMatchLabel>)} */}
            {item.tasks && item.tasks.length > 0 && (
              <TagContainer>
                {item.top_qualities && item.top_qualities.map(tag => (
                  <Tag key={tag}>#{tag}</Tag>
                ))}
              </TagContainer>
            )}
            <TagContainer>
              {item.labels && item.labels.map(label => (
                <Tag key={label.key} color={label.positive ? colors.LCG_GREEN : colors.LCG_DARK_PURPLE} bgColor={label.positive ? colors.LCG_GREEN_FADE : colors.LCG_DARK_PURPLE_FADE}>{label.name}</Tag>
              ))}
              {item.is_skills_match && (
                <Tag key="skills_match" color={colors.LCG_DARK_PURPLE} bgColor={colors.LCG_DARK_PURPLE_FADE}>STRONG SKILLS MATCH</Tag>
              )}
            </TagContainer>
            <Description align="center">{ReactHtmlParser(item.career_description)}</Description>
          </Col>
        </Row>
      </Grid>

      <HiddenContent open={open}>
        <RevealContent item={item} />
      </HiddenContent>
      <LinkWrap>
        <MoreLinkA onClick={() => setOpen(!open)} arrowUp={open ? false : true}>{open ? 'Show less' : 'Read more'}</MoreLinkA>
      </LinkWrap>
    </>
  );
};

export default AboutCard;

const Title = styled(H3)`
  margin-bottom: 4px;
  margin-top: 0;
`;

const TagContainer = styled.div`
  text-align: center;
`;

const LinkWrap = styled.div`
  text-align: center;
`;

const Description = styled(P)`
  margin: 20px 0 8px;
`;

const HiddenContent = styled.div`
  max-height: ${props => props.open ? '1000px' : 0};
  overflow: hidden;
  transition: max-height 0.65s;
  width: 100%;
`;

const Label = styled(H5)`
  margin-bottom: 0;
  margin-top: 40px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 20px;
  }
`;

const Salary = styled(PSmall)`
  margin-bottom: ${props => props.nomargin ? 0 : '16px'};
`;

// const SkillsMatchLabel = styled.p`
//   font-size: 12px;
//   line-height: 24px;
//   font-weight: 700;
//   color: ${colors.TEXT_DARK};
//   text-align: center;
//   margin-bottom: 4px;
// `;
