/* Pairwise career comparison within Pair.js */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Amplitude } from '@amplitude/react-amplitude';

import layout from '../../config/layout';
import colors from '../../config/colors';

import QuestionnaireCard from '../../components/QuestionnaireCard';
import BgThreeDots from '../../ui/bg/BgThreeDots';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { H3, P } from '../../ui/typography/Typography';
import ProgressBar from '../../components/ProgressBar';
import Loader from '../../components/Loader';

import Bg from '../../assets/bg-purple.svg';

const CareerChoice = ({
  pair,
  onClick,
  pairs_judged,
  num_pairs_to_judge,
  loading,
  loading_text,
}) => {
  const handlePairClick = (value, logEvent) => {
    logEvent('Completed Question', {question_id: pair.id});
    if (pairs_judged === (num_pairs_to_judge-1)) {
      logEvent('Completed Quiz', {num_questions: num_pairs_to_judge});
    }
    onClick(value);
  };


  return (
    <Container>
      <BgThreeDots />
      <Grid>
        <Row>
          <Col xs={12} md={6} mdOffset={3}>
            <Title align="center">Choose Between...</Title>
          </Col>
        </Row>
      </Grid>
      <QuestionContainer>
        {(pairs_judged !== (num_pairs_to_judge-1) || !loading) && <Image src={Bg} alt="" />}
        {loading ? (
            <Loader isLight={pairs_judged !== (num_pairs_to_judge-1)}>
              {loading_text && (<P align="Center">{loading_text}</P>)}
            </Loader>
          ) : (
            <Grid>
              <Amplitude>
                {({ logEvent }) => (
                  <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                      <QuestionnaireCard
                        title={pair.a.name}
                        description={pair.a.description}
                        handleClick={() => handlePairClick(true, logEvent)}
                        firstLoad={true}
                        testid="pair_a"
                      />
                    <PStyled align="center" color={colors.WHITE}>OR</PStyled>
                      <QuestionnaireCard
                        title={pair.b.name}
                        description={pair.b.description}
                        handleClick={() => handlePairClick(false, logEvent)}
                        firstLoad={true}
                        testid="pair_b"
                      />
                    </Col>
                  </Row>
                )}
              </Amplitude>
            </Grid>
          )
        }
      </QuestionContainer>
      <ProgressBarContainer>
        <Grid>
          <Row>
            <Col xs={12} md={6} mdOffset={3}>
              <ProgressBar max={num_pairs_to_judge} current={pairs_judged ? (pairs_judged + 1) : 1} />
            </Col>
          </Row>
        </Grid>
      </ProgressBarContainer>
    </Container>
  );
}

export default CareerChoice;

CareerChoice.propTypes = {
  onClick: PropTypes.func.isRequired,
  pair: PropTypes.object.isRequired,
  pairs_judged: PropTypes.number,
  num_pairs_to_judge: PropTypes.number,
};


const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const Title = styled(H3)`
  margin-top: 66px;
  margin-bottom: 4px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 40px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 640px;
  justify-content: center;
  overflow: hidden;
  padding: 86px 0;
  position: relative;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 20px 0;
    min-height: 450px;
    height: auto;
    background-size: 460px auto;
  }
`;

const PStyled = styled(P)`
  margin: 16px 0;
`;

const ProgressBarContainer = styled.div`
  margin: 32px 0 20px;
`;

const Image = styled.img`
  height: 640px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: auto;
  z-index: -1;

  @media (max-width: ${layout.breakpoints.MD}) {
    height: 450px;
  }
`;
