import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { H5, PSmall } from '../../ui/typography/Typography';
import { LCGPurpleButton, PurpleButton } from '../../ui/buttons/Buttons';

const ResultsModalCard = ({ title, subtitle, description, ctaLabel, children, handleCtaClick, isPurple }) => {
  return (
    <Container isPurple={isPurple}>
      <H5 align="center">{title}</H5>
      <PSmall bold align="center" color={colors.TEXT_DARK}>{subtitle}</PSmall>
      {children}
      <PSmall align="center">{description}</PSmall>
      <ButtonContainer>
        <div target="_blank" onClick={handleCtaClick} rel="noopener noreferrer">
          {isPurple ? (
            <PurpleButton>{ctaLabel}</PurpleButton>
          ) : (
            <LCGPurpleButton>{ctaLabel}</LCGPurpleButton>
          )}
        </div>
      </ButtonContainer>
    </Container>
  );
};

export default ResultsModalCard;

ResultsModalCard.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any,
  description: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  children: PropTypes.node,
  handleCtaClick: PropTypes.func,
};

ResultsModalCard.defaultProps = {
  ctaLabel: 'Enrol for free',
};

const Container = styled.div`
  background-color: ${props => props.isPurple ? colors.LCG_DARK_PURPLE_FADE : colors.LCG_DARK_PURPLE_FADE};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
