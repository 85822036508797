/* Privacy content v.1.0 - ref. PrivacyScreen.js */

import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { H1, H2, H3, H4, P, PSmall, Ul } from '../../ui/typography/Typography';
import { Link } from 'react-router-dom';

const PrivacyContent10 = () => {

  return (
    <>
      <Row>
        <Col xs={12}>
          <H2 align="center">Would You Rather Be</H2>
          <H1 align="center">Privacy</H1>
          <PSmall align="center">Version 1.0 - November 16th 2019</PSmall>
          <PSmall align="center"><Link to="/privacy/v1.1">Version 1.1</Link><br/><Link to="/privacy/v1.0">Version 1.0</Link></PSmall>
        </Col>
      </Row>
      <RowStyled>
        <Col xs={12} md={10}>
          <P>At Would You Rather Be, we care about your privacy.  We believe in transparency and we're committed to being upfront about our privacy practices, including how we treat your personal information.  This policy explains the privacy practices for our services.  We'll refer to our website and our apps as our "Services".</P>
          <H3Styled>Who we are</H3Styled>
          <P>We are Would You Rather Be Limited ("we", "our", "us")</P>
          <P>If you have any questions about your personal information, please get in touch with us by emailing careers-pro@learningcurvegroup.co.uk.</P>
          <P>We are a private limited company, incorporated in the UK.  Our company number is 12418139.  Our website address is: https://www.careerspro.co.uk.</P>
          <H3Styled>The Information We Hold About You</H3Styled>
          <H4 mobile>Information submitted through our Services</H4>
          <P>For example, in our career discovery app, you may enter your name and email address.  We will store this information.</P>
          <P>We also store details about how you use our services, such as the inputs you type into the app.</P>
          <H3Styled>How We Use This Information</H3Styled>
          <P>To provide our services, we use it to:</P>
          <Ul>
            <li>Help us give you the full app experience</li>
            <li>Track, analyse and improve the services we give our users</li>
          </Ul>
          <P>We also use it to exercise what's known as our legitimate interests.  This is when we use data for a reason which is in your and/or our interest, and which doesn't involve overriding your privacy rights.  We use it to:</P>
          <Ul>
            <li>Send an email summarising your career results</li>
            <li>Send an email with updates to our services if you opt-in</li>
            <li>Market products and services generally in the app</li>
          </Ul>
          <H3Styled>Who We Share It With</H3Styled>
          <P>We may share your personal information with:</P>
          <Ul>
            <li>Anyone who works for us when they need it to do their job.</li>
            <li>Any organisation which supports any of our services you use, when they need it to offer those services.</li>
            <li>Anyone who you give us explicit permission to share it with.</li>
          </Ul>
          <P>We'll also share it to comply with the law or to protect the rights, property or safety of us, our customers or others.</P>
          <H3Styled>How Long We Keep It</H3Styled>
          <P>We keep your data indefinitely, unless you ask us to delete it.  This is so that we can continue to provide our services to you.</P>
          <H3Styled>How Long We Keep It</H3Styled>
          <P>Your Rights</P>
          <P>You have a right to:</P>
          <Ul>
            <li>Access the personal data we hold about you, or to get a copy of it.</li>
            <li>Make us correct inaccurate data.</li>
            <li>Ask us to delete your data, though for legal reasons we might not always be able to do it.</li>
            <li>Object to us using your data for direct marketing and in certain circumstances 'legitimate interests', research and statistical reasons.</li>
            <li>Withdraw any consent you've previously given us.</li>
          </Ul>
          <P>To do so, please contact us by emailing careers-pro@learningcurvegroup.co.uk.</P>
          <H3Styled>Where We Store Your Data</H3Styled>
          <P>We might transfer and store the data we collect from you somewhere outside the European Economic Area ('EEA').  People who work for us or our partners might also process your data.  We may share data with organisations and countries that:</P>
          <Ul>
            <li>The European Commission say have adequate data protection.</li>
            <li>We've agreed standard data protection clauses with.</li>
          </Ul>
        </Col>
      </RowStyled>
    </>
  );
};

export default PrivacyContent10;

const RowStyled = styled(Row)`
  justify-content: center;
`;

const H3Styled = styled(H3)`
  margin-top: 48px;
  margin-bottom: 16px;
`;
