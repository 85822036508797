import React from 'react';
import styled from 'styled-components';
import colors from '../config/colors';
import { PSmall } from '../ui/typography/Typography';
import IconWarning from '../assets/icons/icon-warning.svg';

const Warning = ({ text }) => {
  return (
    <Container>
      <PSmall nomargin color={colors.TEXT_DARK}>{text}</PSmall>
    </Container>
  );
};

export default Warning;

const Container = styled.div`
  background-color: ${colors.LCG_DARK_PURPLE};
  padding: 8px 8px 8px 42px;
  background-image: url(${IconWarning});
  background-repeat: no-repeat;
  background-position: 10px center;
`;
