import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import layout from '../config/layout';

const MaskedImage = ({ imgUrl, left }) => {
  return (
    <Container isLeft={left}>
      {left ? (
        <svg width="0" height="0">
          <clipPath id="clip-1" clipPathUnits="objectBoundingBox">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.24365 0.0215883C0.202581 0.0549044 0.189552 0.117171 0.213091 0.167625L0.28247 0.316331L0.198419 0.227384C0.162134 0.188984 0.106479 0.185846 0.0669673 0.219971C0.0274553 0.254096 0.0157277 0.31543 0.0392673 0.364838L0.164517 0.627725L0.146141 0.617649C0.0956814 0.589982 0.0348554 0.613608 0.0102817 0.670419C-0.014292 0.72723 0.00669215 0.795714 0.0571512 0.823381L0.358194 0.988446C0.397827 1.01018 0.445384 1.00066 0.475939 0.964885C0.499444 0.937364 0.508764 0.899133 0.502328 0.863045L0.58431 0.949803C0.620474 0.988074 0.675901 0.991336 0.715412 0.957518C0.754922 0.9237 0.766956 0.862697 0.743876 0.813227L0.726685 0.776381L0.825064 0.891742C0.860088 0.932813 0.916605 0.93864 0.957671 0.905413C0.998738 0.872187 1.01186 0.81002 0.988452 0.759548L0.854309 0.470265C0.891115 0.471413 0.927189 0.449927 0.946195 0.411124C0.973298 0.355791 0.955429 0.286196 0.906282 0.255681L0.666728 0.106942C0.627475 0.0825696 0.578676 0.0900326 0.546619 0.12531C0.52833 0.145438 0.517955 0.171907 0.516271 0.199239L0.376362 0.0351753C0.341304 -0.0059346 0.284719 -0.0117278 0.24365 0.0215883Z" fill="#FA054F"/>
          </clipPath>
        </svg>
      ): (
        <svg width="0" height="0" >
          <clipPath id="clip-2" clipPathUnits="objectBoundingBox">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.755817 0.0226988C0.796804 0.055939 0.809807 0.118063 0.786315 0.168403L0.717074 0.31677L0.800957 0.228026C0.837171 0.189714 0.892713 0.186583 0.932146 0.22063C0.971579 0.254678 0.983284 0.315872 0.959791 0.365166L0.834792 0.627455L0.853131 0.617403C0.90349 0.589799 0.964195 0.613371 0.988719 0.670052C1.01324 0.726734 0.992302 0.795061 0.941944 0.822665L0.641501 0.987355C0.601948 1.00904 0.554485 0.999542 0.523991 0.963848C0.500533 0.936389 0.491232 0.898245 0.497656 0.862239L0.415836 0.9488C0.379745 0.986984 0.324428 0.990238 0.284997 0.956497C0.245565 0.922757 0.233555 0.861892 0.25659 0.812535L0.273746 0.775772L0.175564 0.890872C0.14061 0.931849 0.0842052 0.937662 0.0432207 0.904511C0.00223603 0.87136 -0.0108565 0.809335 0.0125011 0.758978L0.146377 0.470353C0.109644 0.471498 0.0736422 0.450061 0.0546741 0.411348C0.0276249 0.35614 0.045459 0.286704 0.0945076 0.256259L0.333584 0.107858C0.372758 0.0835412 0.42146 0.0909873 0.453452 0.126184C0.471705 0.146267 0.482059 0.172675 0.48374 0.199945L0.623371 0.0362549C0.658358 -0.00476142 0.71483 -0.0105414 0.755817 0.0226988Z" fill="red" />
          </clipPath>
        </svg>
      )}

      <Image src={imgUrl} isLeft={left} alt="" />
    </Container>
  );
};

export default MaskedImage;

MaskedImage.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  left: PropTypes.bool.isRequired,
};

MaskedImage.defaultProps = {
  left: true,
};

const Container = styled.div`
  width: 672px;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 500px;
  }

  @media(max-width: ${layout.breakpoints.SM}) {
    width: 457px;
  }
`;

const Image = styled.img`
  clip-path: url(#${props => props.isLeft ? 'clip-1' : 'clip-2'});
  height: auto;
  max-width: 100%;
  width: 100%;
`;
