/* Interstitial screen that appears just before a user selects their top careers after the quiz */

import React from 'react';
import { useStateValue } from '../../services/state';
import InfoScreen from '../InfoScreen';

const CareerSelectionInfoScreen = () => {
  // eslint-disable-next-line
  const [_, dispatch] = useStateValue();

  return (
    <InfoScreen
      title="Well done!"
      text={[
        'We have learnt which qualities are important to you in a career. ',
        'All you need to do now is choose the jobs you are most interested in from the short lists.',
        'And then it’s results time!',
      ]}
      buttonLabel="Continue"
      handleButtonClick={() => dispatch({type: 'update', values: { showCareerSelectionInfoScreen: false }})}
      extraText="Less than 2 minutes to go"
    />
  );
};

export default CareerSelectionInfoScreen;
