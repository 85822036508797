/* Final results > Career screen > Pathway section */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { H3, H4, P } from '../../ui/typography/Typography';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import ResultsCardSimple from '../../components/ResultsCardSimple';
import PathwaysInfo from '../../components/PathwaysInfo';

import PathwaysBg from '../../assets/pathways-bg.svg';
import PathwaysIcon from '../../assets/icon-pathways.svg';

const Pathways = ({ user_id, first_name, item, handleButtonClick }) => {
  let pathwaysDescriptionIntro = "We";
  if (first_name) {
    pathwaysDescriptionIntro = first_name + ", we";
  }
  let pathwaysDescription =
    pathwaysDescriptionIntro + " have created " + item.pathways.length + " pathway" + (item.pathways.length > 1 ? 's' : '') +
    ", unique to you, that show how you could become " + item.career_name_with_prefix + ".";
  if (item.pathways.length === 0) {
    pathwaysDescription =
      pathwaysDescriptionIntro + " can't identify any viable pathways for you to become " + item.career_name_with_prefix + ".";
  } else if (!user_id) {
    pathwaysDescription =
      pathwaysDescriptionIntro + " have created " + item.pathways.length + " pathway" + (item.pathways.length > 1  ? 's' : '') +
      ", that show how you could become " + item.career_name_with_prefix + ".";
  }

  return (
    <Container>
      <Grid>
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Title align="center">{item.pathways.length === 0 ? 'Pathways unavailable' : (user_id ? 'Your pathways' : 'Pathways')}</Title>
            <P align="center">{pathwaysDescription}</P>
            <ItemContainer>
              {item.pathways.map(pathway => (
                <ResultsCardSimple
                  key={pathway.key}
                  ctaLabel="View pathway"
                  handleCtaClick={() => handleButtonClick(pathway)}
                  showConfetti={pathway.time === 'None' ? true : false}
                >
                  <Label>{pathway.heading}</Label>
                  <ItemTitle align="center">{pathway.title}</ItemTitle>
                  <PathwaysInfo user_id={user_id} pathway={pathway} />
                </ResultsCardSimple>
              ))}
            </ItemContainer>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};

export default Pathways;

Pathways.propTypes = {
  item: PropTypes.object
};

const Container = styled.div`
  background-image: url(${PathwaysBg});
  background-position: center 200px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-top: 32px;
`;

const Title = styled(H3)`
  background-image: url(${PathwaysIcon});
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 50px;
`;

const ItemContainer = styled.div``;

const Label = styled.p`
  color: ${colors.GRAY};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
`;

const ItemTitle = styled(H4)`
  margin-bottom: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
