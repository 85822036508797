import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Amplitude } from '@amplitude/react-amplitude';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useHistory, Link } from 'react-router-dom';

import { fetchData } from '../hooks/getDataHooks';

import LogoLarge from '../assets/logo/careersPro_logo_grey.svg';
import iconMenu from '../assets/icon-menu.svg';
import iconClose from '../assets/icon-close.svg';
import colors from '../config/colors';
import layout from '../config/layout';
import { PrimaryButton } from '../ui/buttons/Buttons';
import { getParameterByName } from '../utils/helper';
import { getNullifiedAmplitudeUserProperties } from '../utils/getNullifiedAmplitudeUserProperties';
import { H2, H5 } from '../ui/typography/Typography';
import iconInstagram from '../assets/icon-instagram.svg';
import iconTiktok from '../assets/icon-tiktok.svg';
import iconFacebook from '../assets/icon-facebook.svg';

const Menu = ({ handleQuizStart, menuItems, showMenu, setShowMenu, shortenedHomepage, educationInstitution }) => {

  const history = useHistory();

  const [ isLoggedIn, setIsLoggedIn ] = useState(false);

  const [ isLargeScreen, setIsLargeScreen ] = useState(false);

  const mobileScrollOffset = '487'; // Size of open menu + 15
  const desktopScrollOffset = '100';  // Size of top menu bar + 15

  const adId = getParameterByName('utm_content');
  const source = getParameterByName('utm_source');
  const medium = getParameterByName('utm_medium');

  let userID = localStorage.getItem("user_id");
  if (userID === "null") {
    userID = null;
  }

  useEffect(() => {
    const getData = async () => {
      const resp = await fetchData('organisations/dashboard', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        if (resp.data.screen.name === "organisations/dashboard") {
          setIsLoggedIn(true);
        }
      }
    };
    if (educationInstitution) {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationInstitution, history]);

  const login = () => {
    if (isLoggedIn) {
      history.push(`/organisations/dashboard`);
    } else {
      history.push(`/organisations/login` + (educationInstitution ? "/" + educationInstitution.endpoint : ""));
    }
  }

  useLayoutEffect(() => {
    function updateWindowSize() {
      if (window.innerWidth > layout.numericalBreakpoints.MD) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Container show_menu={showMenu}>
      <MenuRow>
        <Left xs={6}>
          <img src={LogoLarge} alt="CareersPro Logo" onClick={() => {setShowMenu(false); window.scrollTo({top: 0, behavior: 'smooth'});}} style={{cursor:'pointer'}}/>
        </Left>
        <Right xs={6}>
          {isLargeScreen && (
            <>
              {menuItems.map(menu_item => (
                ((menu_item.shortened || !shortenedHomepage) && (menu_item.education_institution || !educationInstitution)) && (
                menu_item.href ?
                  <ExternalLinkStyled key={menu_item.key} to={menu_item.href}><TopBarLink onClick={() => {setShowMenu(false)}}>{menu_item.menu_name}</TopBarLink></ExternalLinkStyled>
                :
                  <AnchorLinkStyled offset={isLargeScreen ? desktopScrollOffset : mobileScrollOffset} href={`#${menu_item.id}`} key={menu_item.key}><TopBarLink>{menu_item.top_bar_name}</TopBarLink></AnchorLinkStyled>
              )))}
            </>
          )}
          {!userID && !educationInstitution && (
            <Amplitude userProperties={getNullifiedAmplitudeUserProperties(adId, source, medium)}>
              {({ logEvent }) =>
                <GetStartedButton small={!isLargeScreen} onClick={() => {handleQuizStart(adId, source, medium); logEvent('Clicked Get Started');}}>Get started</GetStartedButton>
              }
            </Amplitude>
          )}
          {educationInstitution && (
            <PrimaryButton onClick={() => {login();}}>{isLoggedIn ? (educationInstitution.is_school ? "School dashboard" : "Dashboard") : (educationInstitution.is_school ? "Teacher login" : "Login")}</PrimaryButton>
          )}
          {!isLargeScreen && !showMenu && (<MenuIcon src={iconMenu} alt="Open menu" onClick={() => {setShowMenu(true)}} />)}
          {!isLargeScreen && showMenu && (<MenuIcon src={iconClose} alt="Close menu" onClick={() => {setShowMenu(false)}} />)}
        </Right>
      </MenuRow>
      <MenuContents showMenu={showMenu}>
        {menuItems.map(menu_item => (
          ((menu_item.shortened || !shortenedHomepage) && (menu_item.education_institution || !educationInstitution)) && (
          menu_item.href ?
            <Link key={menu_item.key} to={menu_item.href}><MenuLink onClick={() => {setShowMenu(false)}}>{menu_item.menu_name}</MenuLink></Link>
          :
            <AnchorLink offset={isLargeScreen ? desktopScrollOffset : mobileScrollOffset} href={menu_item.href ? menu_item.href : `#${menu_item.id}`} key={menu_item.key}><MenuLink onClick={() => {setShowMenu(false)}}>{menu_item.menu_name}</MenuLink></AnchorLink>
        )))}
        <SocialMediaIcons>
          <SocialMediaIcon pr><a href="https://www.instagram.com/wouldyouratherb/" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconInstagram} alt="Instagram" /></a></SocialMediaIcon>
          <SocialMediaIcon pr><a href="https://vm.tiktok.com/ZMRF5tQ9P/" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconTiktok} alt="Tiktok" /></a></SocialMediaIcon>
          <SocialMediaIcon><a href="https://www.facebook.com/woulduratherb/" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconFacebook} alt="Facebook" /></a></SocialMediaIcon>
        </SocialMediaIcons>
      </MenuContents>
    </Container>
  )
};

export default Menu;

Menu.propTypes = {
  menuItems: PropTypes.array,
};

const Container = styled.div`
  background-color: ${colors.LCG_GREY};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 20px;
`;

const MenuRow = styled.div`
  padding: 10px 20px 10px 20px;
  display: flex;
  line-height: 0;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 10px 12px 10px 12px;
  }
`

const MenuContents = styled.div`
  margin-top: ${props => props.showMenu ? '20px' : 0};
  padding-bottom: ${props => props.showMenu ? '5px' : 0};
  max-height: ${props => props.showMenu ? '1000px' : 0};
  overflow: hidden;
  transition: max-height 0.65s;
  width: 100%;
`

const SocialMediaIcons = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const SocialMediaIcon = styled.div`
  padding-right: ${props => props.pr ? '12px' : '0px'};
  display: inline;
`;

const SocialMediaImage = styled.img`
  width: 35px;
`

const Left = styled.div`
  margin: auto auto auto 0;
  text-align: left;
`;

const Right = styled.div`
  margin: auto 0 auto auto;
  text-align: right;

  @media (min-width: ${layout.breakpoints.MD}) {
    display: flex;
  }
`;

const GetStartedButton = styled(PrimaryButton)`
  @media (max-width: ${layout.breakpoints.VSM}) {
    display: none;
  }
`

const MenuIcon = styled.img`
  vertical-align: middle;
  margin-left: 12px;
  width: 36px;
`;

const MenuLink = styled(H2)`
  text-align: center;
  color: white;
`

const TopBarLink = styled(H5)`
  margin: auto 32px auto 0px;
  color: white;
`

const AnchorLinkStyled = styled(AnchorLink)`
  margin: auto;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.WHITE};
  }
`

const ExternalLinkStyled = styled(Link)`
  margin: auto;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.WHITE};
  }
`
