import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';

const Tooltip = ({ children, text }) => {
  return (
    <Container>
      <TooltipContainer className="tooltip" >
        {text}
      </TooltipContainer>
      { children }
    </Container>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: inline-block;
  max-width: 100%;
  min-width: 128px;
  position: relative;
  z-index: 1;

  &:hover {
    .tooltip {
      opacity: 1;
      height: auto;
      padding: 4px 8px;
    }
  }
`;

const TooltipContainer = styled.div`
  font-family: 'Overpass', sans-serif;
  background-color: ${colors.TEXT_DARK};
  border-radius: 4px;
  bottom: calc(100% + 4px);
  color: ${colors.WHITE};
  left: 50%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  transition: opacity 0.32s;
  width: fit-content;
  z-index: -9999;
`;
