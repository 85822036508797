/* Utility functions for Jobs section of career screen */

import axios from 'axios';
import { copyArray } from '../../utils/copyArray';

export const getAdzunaJobsCollection = (final_results) => {
  let jobs = [];

  for (var i=0; i<final_results.length; i++) {
    if (final_results[i].adzuna_uk_job_count >= 20 && (final_results[i].jobs_link === "" || final_results[i].jobs_link === null) ) {
      var newJob = {};
      newJob["career_id"] = copyArray(final_results)[i].career_id;
      newJob["career_name"] = copyArray(final_results)[i].career_name;
      newJob["adzuna_uk_job_count"] = copyArray(final_results)[i].adzuna_uk_job_count;
      newJob["jobs_link"] = copyArray(final_results)[i].jobs_link;
      newJob["adzuna_rephrase"] = copyArray(final_results)[i].adzuna_rephrase;
      jobs.push(copyArray(newJob));
    }
  }

  return jobs;
};

export const getJobsButtonLabel = (item, commuting_distance_in_miles, adzunaJobs) => {
  let isExternal = false;
  let label = 'View jobs nearby';
  if (commuting_distance_in_miles === -1) {
    label = "View jobs across the UK";
  }

  if (item.jobs_link !== null && item.jobs_link !== "" && item.jobs_link_text !== null && item.jobs_link_text !== "") {
    label = item.jobs_link_text;
    isExternal = true;
  } else if (adzunaJobs && adzunaJobs[item.career_id] && (adzunaJobs[item.career_id].adzuna_uk_job_count < 20 || adzunaJobs[item.career_id].adzuna_local_job_count === 0)) {
    label = "View jobs"
    isExternal = true;
  } else if (adzunaJobs && adzunaJobs[item.career_id] && adzunaJobs[item.career_id].adzuna_local_job_count > 0) {
    if (commuting_distance_in_miles === -1) {
      label = 'View jobs across the UK';
    } else {
      label = 'View jobs nearby';
    }
  }

  return {label: label, isExternal: isExternal};

};

export const viewAdzunaJob = (link, careerName, careerID, user_id) => {

  axios.post('/api/v1/viewAdzunaJob', {
    user_id: user_id,
    career_id: careerID,
    career_name: careerName,
  }).then((res) => {});

  const url = link.match(/^http[s]?:\/\//) ? link : 'http://' + link;
  window.open(url, '_blank')
};

export const viewJobs = (user_id, link, careerName, careerID, alternativeAdzunaCareerName) => {

  axios.post('/api/v1/viewJobs', {
    user_id: user_id,
    career_id: careerID,
    career_name: careerName,
  }).then((res) => {});

  var url = link;

  if (link === "google") {

    let urlFriendlyJobName = careerName.toLowerCase().replace(/ /g, '+').replace(/\s/g,'').replace(/[^a-z0-9%+]/gi, '');

    url = "https://www.google.com/search?";
    url += "&q=" + urlFriendlyJobName;
    url += "&ibp=htl;jobs";

    link = url;

  } else if (link === "adzuna") {

    if (alternativeAdzunaCareerName !== "") {
      careerName = alternativeAdzunaCareerName;
    }

    let urlFriendlyJobName = careerName.toLowerCase().replace(/ /g, '%20').replace(/\s/g,'').replace(/[^a-z0-9%]/gi, '');

    url = "https://www.adzuna.co.uk/jobs/search?partnerb=1&utm_source=wyrb-dtl&utm_medium=ppc";
    url += "&qtl=" + urlFriendlyJobName;

    link = url;

  }
  const validLink = link.match(/^http[s]?:\/\//) ? link : 'http://' + link;
  window.open(validLink, '_blank')

};
