/* Links shared with new customers for the sign up screen to create a new dashboard account
   Example generic link: https://careerspro.co.uk/organisations/signup */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { useStateValue } from '../../services/state';
import { useGetScreenData } from '../../hooks/getDataHooks';
import layout from '../../config/layout';
import colors from '../../config/colors';

import { createSubmitData } from '../../utils/helper';
import { validateEmail, validateEmailDomain, doesEmailDomainMatch } from '../../utils/validation';
import { copyArray } from '../../utils/copyArray';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import Select from '../../ui/form/Select';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { P, PSmall, H3, Label, HintText } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';
import StripeForm from './StripeForm';
import Loader from  '../../components/Loader';
import Tooltip from '../../components/Tooltip'

const SignupScreen = () => {

  const history = useHistory();

  const [{ context, loading }] = useStateValue();
  const [ isLoading, setIsLoading ]= useState(true);

  const [ paymentIntent, setPaymentIntent ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();
  const [ thanks, setThanks ] = useState(false);

  const [stripeLoaded, setStripeLoaded] = useState({});

  const [ checkingEndpointUniqueness, setCheckingEndpointUniqueness ] = useState(false);
  const [ isEndpointUnique, setEndpointUnique ] = useState(null);

  const [ checkingEmailUniqueness, setCheckingEmailUniqueness ] = useState(false);
  const [ isEmailUnique, setIsEmailUnique ] = useState(null);

  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

  const [passwordStrength, setPasswordStrength] = useState(null);

  let signupMatch = useRouteMatch("/organisations/signup");
  let signupWithOrgMatch = useRouteMatch("/organisations/signup/:org");
  let match = signupWithOrgMatch ? signupWithOrgMatch : (signupMatch ? signupMatch : null);

  let defaultNumUserLicences = 1;
  let organisationToFetchInfoFor = null;
  if (match.params.org === "small") {
    defaultNumUserLicences = 20;
  } else if (match.params.org === "medium") {
    defaultNumUserLicences = 100;
  } else {
    organisationToFetchInfoFor = match.params.org;
  }

  useGetScreenData('organisations/signup', setIsLoading, null, null, {
    organisation: organisationToFetchInfoFor,
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  console.log(context);

  const defaultFormValues = {
    organisation: "",
    organisation_type: "",
    endpoint: "",
    email_domains: [],
    first_name: "",
    last_name: "",
    job_title: "",
    email: "",
    password: "",
    confirm_password: "",
    num_licences: defaultNumUserLicences,
    are_card_details_valid: false,
    licence_number: 0,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    organisation: null,
    organisation_type: null,
    endpoint: null,
    email_domains: null,
    first_name: null,
    last_name: null,
    job_title: null,
    email: null,
    password: null,
    confirm_password: null,
    card_details: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  const nullPrice = {
    price_pence: null,
    price_text: null,
    vat_pence: null,
    vat_text: null,
    total_price_pence: null,
    total_price_text: null,
  };
  const [ price, setPrice ] = useState(nullPrice);

  const nullB2bPackage = {
    name: null,
    min_num_users: null,
    price_pence: null,
    price_text: null,
    vat_pence: null,
    vat_text: null,
    discount: null
  };
  const [ b2bPackage, setB2bPackage ] = useState(nullB2bPackage);

  const organisationTypeOptions = [
    {label: 'Career coach', id: 'career_coach'},
    {label: 'Training provider', id: 'training_provider'},
    {label: 'Outplacement service', id: 'outplacement_service'},
    {label: 'School', id: 'school'},
    {label: 'College', id: 'college'},
    {label: 'University', id: 'university'},
    {label: 'Other', id: 'other'},
  ];

  const emailDomainsPlaceholder = "@Optional extra email domain...";

  let organisationTypeSelectedOption = null;
  if (context && context.type) {
    for (var i=0; i<organisationTypeOptions.length && !organisationTypeSelectedOption; i++) {
      if (organisationTypeOptions[i].id === context.type) {
        organisationTypeSelectedOption = organisationTypeOptions[i];
      }
    }
  }

  // Set the stripePublicAPIKey
  var stripePublicAPIKey = "";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Dev environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY_DEV;
  } else {
    // Production environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY;
  }

  const loadScript = src => new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src;
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context) {
      if (defaultNumUserLicences > 50) {
        // If the default number of licences is >1, then force the customer to pay as they would expect to
        // Only on default endpoints "/small" and "/medium"
        context.should_pay = true;
      }
      if (context.should_pay) {
        const fetchData = async () => {
          const result = await loadScript('https://js.stripe.com/v3/')
          setStripeLoaded(result)
        }
        fetchData();
      }
      var formValuesToUpdate = {};
      if (context.name) {
        formValuesToUpdate["organisation"] = context.name;
      }
      if (organisationTypeSelectedOption) {
        formValuesToUpdate["organisation_type"] = organisationTypeSelectedOption.id;
      }
      if (context.endpoint && context.endpoint.length > 0) {
        formValuesToUpdate["endpoint"] = context.endpoint;
      }
      formValuesToUpdate["email_domains"] = [];
      let currentEmailDomainIndex = 0;
      if (context.email_domains && context.email_domains.length > 0) {
        for (; currentEmailDomainIndex<context.email_domains.length; currentEmailDomainIndex++) {
          formValuesToUpdate["email_domains"].push({
            id: currentEmailDomainIndex,
            value: context.email_domains[currentEmailDomainIndex],
            placeholder: emailDomainsPlaceholder,
            error: null,
          });
        }
      }
      do {
        formValuesToUpdate["email_domains"].push({
          id: currentEmailDomainIndex++,
          value: "",
          placeholder: emailDomainsPlaceholder,
        });
      } while (formValuesToUpdate["email_domains"].length < 3);
      setFormValues({
        ...formValues,
        ...formValuesToUpdate,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleInputChange = (value, name, id) => {

    if (name === "email_domains") {
      let formValuesCopy = copyArray(formValues);
      formValuesCopy["email_domains"][id].value = value;
      if (id === (formValuesCopy["email_domains"].length-1)) {
        formValuesCopy["email_domains"].push({
          id: formValuesCopy["email_domains"].length,
          value: "",
          placeholder: emailDomainsPlaceholder,
          error: null,
        });
      }
      setFormValues(formValuesCopy);
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (name === "endpoint" && validateEndpoint(value, false)) {
      checkEndpointUniqueness(name, value);
    }

    if (name === "email" && validateEmail(value)) {
      checkEmailUniqueness(name, value, null);
    }

    if (name === "password") {
      if (value.length >= 8) {
        if (value.length >= 15) {
          setPasswordStrength("very strong");
        } else if(strongPassword.test(value) || value.length >= 12){
          setPasswordStrength("strong");
        } else if(mediumPassword.test(value) || value.length >= 10){
          setPasswordStrength("medium");
        } else{
          setPasswordStrength("weak");
        }
      } else {
        setPasswordStrength(null);
      }
    }

  };

  const validateEndpoint = (value, shouldSetErrors) => {

    let isValid = true;
    let endpointError = null;

    if (!value || value === "") {
      isValid = false;
      endpointError = "You must specify a link";
    } else if (value.length < 3) {
      isValid = false;
      endpointError = "You must specify a link ending with at least 3 characters";
    } else if (!/[a-zA-Z-0-9]+/.test(value)) {
      isValid = false;
      endpointError = "Your link ending can only contain letters, numbers and '-' characters";
    } else if (value.length > 15 && !value.includes("-")) {
      isValid = false;
      endpointError = "Please use a shorter link or use the '-' character to make it more readable";
    }

    if (shouldSetErrors) {
      setFormErrors({
        ...formErrors,
        "endpoint": endpointError
      });
    }

    return isValid;

  }

  const validateField = (e, id) => {

    if (e.elementType === "card") {

      const errorMsg = formValues.are_card_details_valid !== true ? "You must provide valid card details" : null;
      setFormErrors({
        ...formErrors,
        "card_details": errorMsg
      });

    } else {

      const value = e.target.value;
      const name = e.target.name;

      let emailErrorMessage = null;

      if (name === "organisation" || (context && !context.internal && name === "organisation_type") || name === "first_name" || name === "last_name" ||
          name === "job_title") {
        const errorMsg = value === "" ? "This field is required" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      } else if (name === "endpoint") {
        if (validateEndpoint(value, true)) {
          checkEndpointUniqueness(name, value);
        }
      } else if (name === "email_domains") {
        let formValuesCopy = copyArray(formValues);
        if (id === 0 && value === "") {
          formValuesCopy["email_domains"][id].error = "You must specify at least one valid email domain";
        } else {
          if (value !== "" && !validateEmailDomain(value)) {
            formValuesCopy["email_domains"][id].error = "This is not a valid email domain";
          } else {
            formValuesCopy["email_domains"][id].error = null;
          }
        }
        setFormValues(formValuesCopy);
      } else if (name === "email") {
        if (!validateEmail(value)) {
          emailErrorMessage = "This is not a valid email";
          setFormErrors({
            ...formErrors,
            [name]: emailErrorMessage
          });
        }
      } else if (name === "password") {

        let errorMsg = null;
        if (value === "") {
          errorMsg = "You must provide a password";
        } else if (value.length < 8) {
          errorMsg = "Your password must be at least 8 characters long";
        }

        const errorMsgConfirmPassword =
          value !== "" && formValues.confirm_password !== "" && value !== formValues.confirm_password ? "Passwords don't match" : null;

        const confirmPasswordName = "confirm_password";
        setFormErrors({
          ...formErrors,
          [name]: errorMsg,
          [confirmPasswordName]: errorMsgConfirmPassword
        });

      } else if (name === "confirm_password") {
        const errorMsg = value !== formValues.password ? "Passwords don't match" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }

      if (name === "email_domains" || name === "email") {

        let currentEmail = formValues["email"];
        if (name === "email") {
          currentEmail = value;
        }

        let emailDomains = [];
        let nonBlankEmailDomainSpecified = false;
        for (let i=0; i<formValues["email_domains"].length; i++) {
          let currentEmailDomain = formValues["email_domains"][i].value.toLowerCase();
          if (name === "email_domains" && i === id) {
            currentEmailDomain = value.toLowerCase();
          }
          emailDomains.push(currentEmailDomain);
          if (currentEmailDomain.length > 0) {
            nonBlankEmailDomainSpecified = true;
          }
        }

        checkEmailUniqueness("email", currentEmail, function(emailUniquenessError) {
          let errorMsg = null;
          if (emailErrorMessage) {
            // Email invalid (set above)
            errorMsg = emailErrorMessage;
          } else if (emailUniquenessError) {
            // Email not unique
            errorMsg = emailUniquenessError;
          } else if (currentEmail && currentEmail.length > 0 &&
            nonBlankEmailDomainSpecified && !doesEmailDomainMatch(currentEmail, emailDomains)) {
            // No error so far, so now check that the email domain matches one set, assuming an
            // email and domain has been set
            errorMsg = "Your email must match one of the email domains you specified above";
          }
          // Now the error has been set or not, so update it (or remove it)
          const emailKey = "email";
          setFormErrors({
            ...formErrors,
            [emailKey]: errorMsg
          });
        });
        
      }

    }

  };

  const checkEndpointUniqueness = async (name, value) => {
    // Check the endpoint is unique
    setCheckingEndpointUniqueness(true);
    const submitData = createSubmitData({
      endpoint: value,
      current_endpoint: context.endpoint,
    });
    axios.post('/api/v1/check_endpoint_is_unique', submitData).then(res => {
      let errorMsg = null;
      if (!res.data.unique) {
        errorMsg = "This link is already in use";
        setEndpointUnique(false);
      } else {
        setEndpointUnique(true);
      }
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
      setCheckingEndpointUniqueness(false);
    });
  }

  const checkEmailUniqueness = async (name, value, callback) => {
    // Check the user's email address isn't already in use
    setCheckingEmailUniqueness(true);
    const submitData = createSubmitData({
      email: value,
    });
    axios.post('/api/v1/check_organisation_user_email_is_unique', submitData).then(res => {
      let errorMsg = null;
      if (!res.data.unique) {
        errorMsg = "This email is already in use";
        setIsEmailUnique(false);
      } else {
        setIsEmailUnique(true);
      }
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
      setCheckingEmailUniqueness(false);
      if (callback) {
        callback(errorMsg);
      }
    });
  };

  const validateForm = (setErrors) => {
    let isValid = true;
    let newErrors = initialErrorState;

    if (formValues.organisation === "") {
      newErrors = {...newErrors, organisation: "This field is required"};
      isValid = false;
    }

    if (context && !context.internal && formValues.organisation_type === "") {
      newErrors = {...newErrors, organisation_type: "This field is required"};
      isValid = false;
    }

    if (formValues.first_name === "") {
      newErrors = {...newErrors, first_name: "This field is required"};
      isValid = false;
    }

    if (formValues.last_name === "") {
      newErrors = {...newErrors, last_name: "This field is required"};
      isValid = false;
    }

    if (formValues.job_title === "") {
      newErrors = {...newErrors, job_title: "This field is required"};
      isValid = false;
    }

    if (formValues.licence_number === "") {
      newErrors = {...newErrors, licence_number: "This field is required"};
      isValid = false;
    }

    if (typeof context.education_institution_id === "number" && context.education_institution_id >= 0) {

      if (!validateEndpoint(formValues.endpoint, false) || isEndpointUnique === false) {
        isValid = false;
      }
  
      for (let i=0; i<formValues.email_domains.length; i++) {
        if (i === 0 && formValues.email_domains[i].value === "") {
          isValid = false;
        } else if (formValues.email_domains[i].value !== "" && !validateEmailDomain(formValues.email_domains[i].value)) {
          isValid = false;
        }
      }
  
      let emailDomains = [];
      for (let i=0; i<formValues["email_domains"].length; i++) {
        emailDomains.push(formValues["email_domains"][i].value.toLowerCase());
      }
      if (!validateEmail(formValues.email) || isEmailUnique === false || !doesEmailDomainMatch(formValues.email, emailDomains)) {
        isValid = false;
      }

    }

    if (formValues.password === "") {
      newErrors = {...newErrors, password: "This field is required"};
      isValid = false;
    } else if (formValues.password.length < 8) {
      newErrors = {...newErrors, password: "Your password must be at least 8 characters long"};
      isValid = false;
    }

    if (formValues.confirm_password !== formValues.password) {
      newErrors = {...newErrors, confirm_password: "Passwords don't match"};
      isValid = false;
    }

    if (context && context.should_pay) {

      if (formValues.num_licences === null) {
        isValid = false;
      }
  
      if (formValues.are_card_details_valid !== true) {
        isValid = false;
      }

    }

    setErrors && setFormErrors(newErrors);
    return isValid;
  };

  const getStripeResponse = async (elements, stripe) => {
    const cardElement = elements.getElement('card');
    try {
      const stripeResponse = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${formValues.first_name} ${formValues.last_name}`,
              email: formValues.email,
            }
          }
        }
      );
      return stripeResponse;
    } catch(error) {
      return error.response;
    }
  };

  const submitForm = async (elements, stripe) => {
    if (!validateForm(true) || processing) return null;
    setProcessing(true);

    let shouldPay = false;
    if (context && context.should_pay) {
      shouldPay = true;
    }
    let successfulPayment = false;
    if (shouldPay) {
      const stripeResponse = await getStripeResponse(elements, stripe);
      if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
        successfulPayment = true;
      } else {
        setProcessing(false);
        let errorMessage = "Payment failed";
        if (stripeResponse && stripeResponse.error && stripeResponse.error.message) {
          errorMessage += ": " + stripeResponse.error.message;
        }
        setError(errorMessage);
      }
    }

    if (!shouldPay || successfulPayment) {
      const submitData = createSubmitData({
        stripe_payment_intent: (shouldPay ? paymentIntent : null),
        signup: true,
        details: formValues,
        price: price,
        b2b_package: b2bPackage,
        organisation_signup_id: organisationToFetchInfoFor,
        education_institution_id: context.education_institution_id,
        current_endpoint: context.endpoint,
      });
      axios.post('/api/v1/process_customer_payment', submitData).then((res) => {
        setProcessing(false);
        if (res.data && res.data.succeeded === true) {
          localStorage.setItem("organisation_user_id", res.data.organisation_user_id);
          localStorage.setItem("session_id", res.data.session_id);
          setThanks(true);
        } else if (res.data && res.data.error) {
          setError(res.data.error);
          console.log(res.data.error);
        } else {
          // Else it has not succeeded or failed (probably in progress somehow), so don't make any updates
        }
      });
    }
  }

  if (loading || isLoading || !context || (!context.used && !thanks && context.should_pay && !stripeLoaded.successful)) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Sign up</title>
        <meta id="meta-description" name="description" content="CareersPro - Sign up" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="Organisations - Sign up" />
        <CircleOne />
        <CircleTwo />
        {context && context.used && (
          <>
            <SubTitle>Sign up link used</SubTitle>
            <TopTextSuccess align="center">This sign up link has already been used. Please reach out to the sales team if you need a new signup link: sales@careerspro.co.uk</TopTextSuccess>
          </>
        )}
        {context && !context.used && thanks && (
          <TopSectionSuccess>
            {context && context.should_pay && (
              <>
                <TopTextSuccess align="center">Thanks for your payment of {price.total_price_text} for {formValues.num_licences.toLocaleString()} user licence{formValues.num_licences !== 1 ? `s` : ``}.</TopTextSuccess>
                <TopTextSuccess align="center">We have emailed you a VAT invoice.</TopTextSuccess>
              </>
            )}
            {context && !context.should_pay && !context.internal && (
              <TopTextSuccess align="center">Thanks for creating a new organisation account with CareersPro.</TopTextSuccess>
            )}
            {context && context.num_free_licences > 0 && !context.internal && (
              <TopTextSuccess align="center">We've credited your account with {context.num_free_licences.toLocaleString()} free user licence{context.num_free_licences !== 1 ? `s` : ``} to get you started.</TopTextSuccess>
            )}
            {context && context.internal && (
              <>
                <TopTextSuccess align="center">You've just created a new internal CareersPro account!</TopTextSuccess>
                <TopTextSuccess align="center">Please make sure you keep it secure with a strong password and keep your computer locked when you're away from it.</TopTextSuccess>
              </>
            )}
            <TopTextSuccess align="center">Before you access your dashboard, we encourage you to set up two-factor authentication to make your account more secure.  Click the button below to set this up...</TopTextSuccess>
            <BackButtonWrap>
              <Link to={`/organisations/two-factor`}>
                <PrimaryButton>Set up two-factor authentication</PrimaryButton>
              </Link>
            </BackButtonWrap>
            <BackButtonWrap>
              <Link to={`/organisations/dashboard`}>
                <SecondaryButton>Skip to dashboard</SecondaryButton>
              </Link>
            </BackButtonWrap>
          </TopSectionSuccess>
        )}
        {context && !context.used && !thanks && (
          <>
            <Grid>
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <Form>
                    <SubTitle>Organisation details</SubTitle>
                    <InputWrap>
                      <Input type="text" name="organisation" label="Organisation name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'organisation')} error={formErrors['organisation']} inputValue={context && context.name ? context.name : null}
                      disabled={typeof context.education_institution_id === "number" && context.education_institution_id >= 0} />
                    </InputWrap>
                    {context && !context.internal && (
                      <InputWrap>
                        <Select
                          label="Organisation type"
                          options={organisationTypeOptions}
                          selectedOption={organisationTypeSelectedOption}
                          onBlur={validateField}
                          handleChange={(it) => handleInputChange(it && it.id ? it.id : "", 'organisation_type')}
                          error={formErrors['organisation_type']}
                          is_disabled={typeof context.education_institution_id === "number" && context.education_institution_id >= 0}
                        />
                      </InputWrap>
                    )}
                    <InputWrap>
                      <Label>Number of Licences</Label>
                      <HintText>Enter the number of licences that the organisation has ordered. If it is unlimited licences then enter -1.</HintText>
                      <Input type="text" name="licence_number" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'licence_number')} error={formErrors['licence_number']} />  
                    </InputWrap>
                    {typeof context.education_institution_id === "number" && context.education_institution_id >= 0 && (
                      <>
                        <InputWrap>
                          <Label>Link</Label>
                          <HintText>You can optionally edit your organisation's url here to mirror your own website, e.g. /st-helens-school could be shortened to /sthn. This is the link pupils will go to so they can take the assessment.</HintText>
                          <Input
                            type="text"
                            name="endpoint"
                            onBlur={validateField}
                            handleChange={(it) => handleInputChange(it, 'endpoint')}
                            error={formErrors['endpoint']}
                            left_label={`http://localhost:3000/`}
                            inputValue={context.endpoint}
                          />
                        </InputWrap>
                        <InputWrap>
                          <Label>Email domains</Label>
                          <HintText>Enter the end of your organisation's email address, e.g. @sthn.ac.uk, so that we can verify your pupils and staff. Only people with these email address endings will have access to CareersPro.</HintText>
                          {formValues["email_domains"].map(item => (
                            <MultiInputWrap key={item.id}>
                              <Input
                                type="text"
                                name="email_domains"
                                placeholder={item.placeholder}
                                id={item.id}
                                onBlur={e => validateField(e, item.id)}
                                handleChange={(it) => handleInputChange(it, 'email_domains', item.id)}
                                error={item.error}
                                inputValue={item.value}
                                prefix="@"
                              />
                            </MultiInputWrap>
                          ))}
                        </InputWrap>
                      </>
                    )}
                    <SubTitle>Your details</SubTitle>
                    <InputWrap>
                      <Input type="text" name="first_name" label="First name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} error={formErrors['first_name']} />
                    </InputWrap>
                    <InputWrap>
                      <Input type="text" name="last_name" label="Last name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} error={formErrors['last_name']} />
                    </InputWrap>
                    <InputWrap>
                      <Input type="text" name="job_title" label="Job title" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'job_title')} error={formErrors['job_title']} />
                    </InputWrap>
                    <SubTitle>Account details</SubTitle>
                    <InputWrap>
                      <Input type="email" name="email" label="Email"  onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} error={formErrors['email']} />
                    </InputWrap>
                    <InputWrap>
                      <Input type="password" name="password" label="Password" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'password')} error={!passwordStrength ? formErrors['password'] : null} />
                      {passwordStrength && (
                        <PasswordMessageContainer>
                          <PasswordMessage strength={passwordStrength}>Your password strength is {passwordStrength}</PasswordMessage>
                        </PasswordMessageContainer>
                      )}
                    </InputWrap>
                    <InputWrap>
                      <Input type="password" name="confirm_password" label="Confirm password" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'confirm_password')} error={formErrors['confirm_password']} />
                    </InputWrap>
                    {context && context.should_pay && (
                      <>
                        <SubTitle>Payment details</SubTitle>
                        {stripeLoaded.successful && (
                          <StripeProvider apiKey={stripePublicAPIKey}>
                            <Elements>
                              <StripeForm processing={processing} formValues={formValues} formErrors={formErrors} price={price} setPrice={setPrice} error={error} paymentIntent={paymentIntent} setPaymentIntent={setPaymentIntent} validateField={validateField} validateForm={validateForm} handleInputChange={handleInputChange} submitForm={submitForm} checkingEndpointUniqueness={checkingEndpointUniqueness} checkingEmailUniqueness={checkingEmailUniqueness} defaultNumUserLicences={defaultNumUserLicences} b2bPackage={b2bPackage} setB2bPackage={setB2bPackage} />
                            </Elements>
                          </StripeProvider>
                        )}
                        {!stripeLoaded.successful && (
                          <Loader />
                        )}
                      </>
                    )}
                    {context && !context.should_pay && (
                      <>
                        <SignupButtonWrap>
                          {processing ? (
                            <PSmall align="center">Signing up...</PSmall>
                          ) : validateForm(false) ? (
                            (checkingEmailUniqueness !== true && checkingEndpointUniqueness !== true) ? (
                              <PrimaryButton onClick={() => submitForm()}>Sign up</PrimaryButton>
                            ) : (
                              <Loader />
                            )
                          ) : (
                            <Tooltip text="Please enter your details above">
                              <GrayButton disabled>Sign up</GrayButton>
                            </Tooltip>
                          )}
                        </SignupButtonWrap>
                        {error && <Error align="center">{error}</Error>}
                        {context && context.num_free_licences > 0 && !context.internal && (
                          <SignupButtonSubText align="center">On signing up you'll get {context.num_free_licences.toLocaleString()} free licence{context.num_free_licences !== 1 ? `s` : ``}</SignupButtonSubText>
                        )}
                        {context && !context.internal && (
                          <SignupButtonSubText align="center">By signing up you agree to our <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></SignupButtonSubText>
                        )}
                        {context && context.internal && (
                          <SignupButtonSubText align="center">This will create an internal account. This should only be used for demo purposes or for issuing free trials to individual customers</SignupButtonSubText>
                        )}
                      </>
                    )}
                  </Form>
                </Col>
              </Row>
            </Grid>
            <BackButtonContainer>
              <SecondaryButton onClick={() => {history.goBack()}}>Back</SecondaryButton>
            </BackButtonContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default SignupScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const MultiInputWrap = styled.div`
  margin-bottom: 12px;
`;

const Form = styled.div`
  margin: auto;
  max-width: 450px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const PasswordMessageContainer = styled.div`
  margin-top: 4px;
`;

const PasswordMessage = styled(PSmall)`
  ${props => props.strength === "weak" && `color: ${colors.LCG_DARK_PURPLE_FADE};`};
  ${props => props.strength === "medium" && `color: ${colors.LCG_LIGHT_PURPLE};`};
  ${props => props.strength.includes( "strong") && `color: ${colors.LCG_DARK_PURPLE};`};
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const TopSectionSuccess = styled.div`
  margin-top: 20px;
`;

const TopTextSuccess = styled(P)`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 356px;
  }
`;

const SignupButtonWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
  padding-top: 8px;
`;

const Error = styled(P)`
  color: #FA054F;
`;

const SignupButtonSubText = styled(PSmall)`
  margin-top: 20px;
`;

const BackButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;
