import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { PSmall, Label, HintText, P, PVSmall } from '../typography/Typography';
import ReactHtmlParser from 'react-html-parser';
import { boldCharactersInString, cleanString } from '../../utils/helper';

const AutocompleteField = ({
  error,
  hint,
  label,
  suggestions,
  handleChange,
  placeholder,
  onBlur,
  id,
  disabled,
  testid
}) => {
  const [value, setValue] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [nonAddedfilteredSuggestions, setNonAddedFilteredSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [stopBlur, setStopBlur] = useState(false);

  const onChange = (e) => {

    var subsetOfRelevantSuggestions = [];
    var firstAlphanumericCharacterOfInputString = null;
    const lowerCaseInput = e.target.value.toLowerCase();
    for (var i=0; i<lowerCaseInput.length && firstAlphanumericCharacterOfInputString === null; i++) {
      if (lowerCaseInput.charAt(i).match(/[a-z0-9+]+/gi)) {
        firstAlphanumericCharacterOfInputString = lowerCaseInput.charAt(i);
      }
    }
    if (firstAlphanumericCharacterOfInputString !== null) {
      subsetOfRelevantSuggestions = suggestions.ids[firstAlphanumericCharacterOfInputString];
    }
    if (subsetOfRelevantSuggestions === null) {
      subsetOfRelevantSuggestions = [];
    }

    var suggestionsToUse = [];
    for (let i=0; i<suggestions.strings.length; i++) {
      if (subsetOfRelevantSuggestions.includes(suggestions.strings[i].id)) {
        suggestionsToUse.push(suggestions.strings[i]);
      }
    }

    const suggestionsLimit = 100;
    var newSuggestions = [];
    for (let i=0; i<suggestionsToUse.length && newSuggestions.length <= suggestionsLimit; i++) {
      var shouldAddAsNewSuggestion = false;
      
      var suggestionNameTrimmed = cleanString(suggestionsToUse[i].name);
      suggestionsToUse[i].match = boldCharactersInString(suggestionNameTrimmed, e.target.value);
      var suggestionMatchTrimmed = cleanString(suggestionsToUse[i].match);
      if (suggestionMatchTrimmed !== suggestionNameTrimmed) {
        shouldAddAsNewSuggestion = true;
      }

      if (suggestionsToUse[i].aka) {
        suggestionsToUse[i].aka_full_match_string = "";
        for (let j=0; j<suggestionsToUse[i].aka.length; j++) {
          var currentAKASuggestionNameTrimmed = cleanString(suggestionsToUse[i].aka[j].name);
          suggestionsToUse[i].aka[j].match = boldCharactersInString(currentAKASuggestionNameTrimmed, e.target.value);
          var currentAKASuggestionMatchTrimmed = cleanString(suggestionsToUse[i].aka[j].match);
          if (currentAKASuggestionMatchTrimmed !== currentAKASuggestionNameTrimmed) {
            shouldAddAsNewSuggestion = true;
            if (suggestionsToUse[i].aka_full_match_string !== "") {
              suggestionsToUse[i].aka_full_match_string += ", ";
            }
            suggestionsToUse[i].aka_full_match_string += suggestionsToUse[i].aka[j].match;
          }
        }
      }
      
      if (shouldAddAsNewSuggestion) {
        newSuggestions.push(suggestionsToUse[i]);
      }
    }
    if (newSuggestions.length >= suggestionsLimit) {
      // Remove all suggestions as we've hit the limit, so we only have a subset of them - wait until
      // the user types more characters and the list is small enough to fit within the limit, otherwise
      // the UX is too slow
      newSuggestions = [];
    }

    var nonAddedNewSuggestions = [];
    var nonAddedIndex = 0;
    for (let i=0; i<newSuggestions.length; i++) {
      if (!newSuggestions[i].added) {
        newSuggestions[i].non_added_id = nonAddedIndex++;
        nonAddedNewSuggestions.push(newSuggestions[i]);
      }
    }

    setActiveSuggestion(0);
    setFilteredSuggestions(newSuggestions);
    setNonAddedFilteredSuggestions(nonAddedNewSuggestions);
    setShowSuggestion(true);
    setValue(e.target.value);
    handleChange && handleChange(e.target.value, id);

  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // on enter
      if (showSuggestion) {
        setActiveSuggestion(0);
        if (nonAddedfilteredSuggestions[activeSuggestion]) {
          setShowSuggestion(false);
          setValue(nonAddedfilteredSuggestions[activeSuggestion].name);
          handleChange && handleChange(nonAddedfilteredSuggestions[activeSuggestion].name, id);
          setFilteredSuggestions([]);
        }
      }
    } else if (e.keyCode === 38) {
      // on arrow up
      if (activeSuggestion !== 0) {
        if (document.getElementById(activeSuggestion - 1)) {
          const target = document.getElementById(activeSuggestion - 1);
          target.parentNode.scrollTop = target.offsetTop;
        }
        setActiveSuggestion(activeSuggestion - 1)
      };
    }
    else if (e.keyCode === 40) {
      // on arrow down
      if (activeSuggestion + 1 !== nonAddedfilteredSuggestions.length) {
        if (document.getElementById(activeSuggestion + 1)) {
          const target = document.getElementById(activeSuggestion + 1);
          target.parentNode.scrollTop = target.offsetTop;
        };
        setActiveSuggestion(activeSuggestion + 1)
      }
    }
  };

  const onItemClick = (itemID) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setNonAddedFilteredSuggestions([]);
    setShowSuggestion(false);
    setValue(nonAddedfilteredSuggestions[itemID].name);
    handleChange && handleChange(nonAddedfilteredSuggestions[itemID].name, id);
    setStopBlur(false);
  };

  const handleBlur = e => {
    if (!stopBlur) {
      setActiveSuggestion(0);
      setFilteredSuggestions([]);
      setNonAddedFilteredSuggestions([]);
      setShowSuggestion(false);
      onBlur(e);
    }
  };

  let addedID = -1;

  return (
    <Container>
      {label && (<Label>{label}</Label>)}
      {hint && (<HintText>{hint}</HintText>)}
      <InputContainer>
        <Input onKeyDown={onKeyDown} value={value} onChange={onChange} error={error && error} placeholder={placeholder} onBlur={e => handleBlur(e)} disabled={disabled}  data-testid={testid} />
        { showSuggestion && value && filteredSuggestions.length > 0 && (
          <List>
            {filteredSuggestions.map((suggestion) => (
              <Item
                key={suggestion.key}
                onMouseDown={() => setStopBlur(true)}
                onClick={(e) => {if (!suggestion.added) onItemClick(suggestion.non_added_id)}}
                active={!suggestion.added && activeSuggestion === suggestion.non_added_id}
                onMouseEnter={() => {if (!suggestion.added) setActiveSuggestion(suggestion.non_added_id)}}
                id={suggestion.added ? addedID-- : suggestion.non_added_id}
              >
                <ItemText nomargin added={suggestion.added}>{ReactHtmlParser(suggestion.match)}{(suggestion.added ? " (already added)" : "")}</ItemText>
                {suggestion.aka && suggestion.aka.length > 0 && suggestion.aka_full_match_string !== "" && (
                  <AKAText nomargin added={suggestion.added}>Also known as: {ReactHtmlParser(suggestion.aka_full_match_string)}</AKAText>
                )}
              </Item>
            ))}
          </List>
        )}
      </InputContainer>
      {error && (
        <Error>
          <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
        </Error>
      )}
    </Container>
  )
};

export default AutocompleteField;

AutocompleteField.propTypes = {
  error: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  suggestions: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  testid: PropTypes.string,
};

AutocompleteField.defaultProps = {
  suggestions: {strings:[],ids:[]},
};

const Container = styled.div`
`;

const InputContainer = styled.div`
  position: relative;
`;

const Input = styled.input`
  background-color: ${props => props.error ? colors.LCG_DARK_PURPLE_FADE : 'transparent'};
  border-radius: 8px;
  border: 0;
  border: 2px solid ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_LIGHT_GREY};
  font-size: 20px;
  line-height: 30px;
  padding: 8px 12px;
  width: 100%;
  text-transform: ${props => props.value === "" ? 'none' : 'capitalize'};

  &:focus {
    border-color: ${colors.LCG_LIGHT_PURPLE};
    outline: none;
  }

  &:disabled {
    background-color: ${colors.LCG_LIGHTER_GREY};
  }
`;

const Error = styled.div`
  margin-top: 4px;

  p {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  background-color: ${colors.WHITE};
  border-radius: 8px;
  border: 2px solid rgba(0,0,0,0.3);
  max-height: 192px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
`;

const Item = styled.li`
  padding: 8px;
  width: 100%;
  ${props => props.active && `background-color: ${colors.LCG_LIGHTER_GREY};`}
`;

const ItemText = styled(P)`
  color: ${props => props.added ? colors.TEXT_GRAY : colors.TEXT_DARK };
`;

const AKAText = styled(PVSmall)`
  color: ${props => props.added ? colors.TEXT_GRAY : colors.TEXT_DARK };
`;
