export const getNameByID = (id, idNamePairs) => {
  let val = null;
  if (idNamePairs && idNamePairs.strings !== null && idNamePairs.strings.length > 0) {
    val = idNamePairs.strings.find(it => it.id === id);
  }
  return (val ? val.name : null);
};

export const getNamesByIDs = (arrayOfIDs, idNamePairs, idAttributeName) => {
  let names = [];
  if (arrayOfIDs !== null && idNamePairs != null && idNamePairs.strings !== null) {
    for (let i=0; i<arrayOfIDs.length; i++) {
      let val = idNamePairs.strings.find(it => it.id === (idAttributeName ? arrayOfIDs[i][idAttributeName] : arrayOfIDs[i]));
      if (val !== null) {
        names.push(val.name);
      }
    }
  }
  if (names.length > 0) {
    return names;
  } else {
    return null;
  }
};
