/* Screen for the internal sales team to generate signup links for new education institution customers
   so they can go to the link generated and create a new dashboard account for their insitution
   Example signup link that will be created: https://careerspro.co.uk/organisations/signup/eton-college */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useGetScreenData } from '../../hooks/getDataHooks';

import { useStateValue } from '../../services/state';
import layout from '../../config/layout';

import { createSubmitData, cleanStringForComparison } from '../../utils/helper';
import { validateEmailDomain } from '../../utils/validation';
import { copyArray } from '../../utils/copyArray';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { P, H3, Label, HintText } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';
import Tooltip from '../../components/Tooltip'
import AutocompleteField from '../../ui/form/AutocompleteField';
import Loader from  '../../components/Loader';

const CreateSignupLinkEducationInstitution = () => {

  const [{ context, loading }] = useStateValue();
  const [ isLoading, setIsLoading ]= useState(true);

  const [ error, setError ] = useState();

  const [ educationInstitutionID, setEducationInstitutionID ] = useState(null);
  const [ educationInstitutionEndpoint, setEducationInstitutionEndpoint ] = useState(null);

  const [ checkingSignupEndpointUniqueness, setCheckingSignupEndpointUniqueness ] = useState(false);
  const [ isSignupEndpointUnique, setSignupEndpointUnique ] = useState(null);

  const [ checkingEndpointUniqueness, setCheckingEndpointUniqueness ] = useState(false);
  const [ isEndpointUnique, setEndpointUnique ] = useState(null);

  const [ signupLink, setSignupLink ] = useState(null);
  const [ educationInstitutionName, setEducationInstitutionName ] = useState(null);

  const emailDomainsPlaceholder = "@Optional extra email domain...";

  const defaultFormValues = {
    education_institution_name: "",
    signup_endpoint: "",
    endpoint: "",
    email_domains: [{
      id: 0,
      value: "",
      placeholder: emailDomainsPlaceholder,
    },
    {
      id: 1,
      value: "",
      placeholder: emailDomainsPlaceholder,
    },
    {
      id: 2,
      value: "",
      placeholder: emailDomainsPlaceholder,
    }],
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    education_institution_name: null,
    signup_endpoint: null,
    endpoint: null,
    email_domains: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  useGetScreenData('organisations/create-signup-link', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (value, name, id) => {
    if (name === "education_institution_name") {
      let clientValuesSet = false;
      let newEducationInstitutionName = value.name || value;
      if (context.education_institutions_autocomplete_options) {
        let userEducationInstitutionID = null;
        let userEducationInstiutionEndpoint = null;
        const matchedEducationInstitution =
          context.education_institutions_autocomplete_options.strings.find(it =>
            cleanStringForComparison(it.name.toLowerCase()) === ((newEducationInstitutionName !== null) ? cleanStringForComparison(newEducationInstitutionName.toLowerCase()) : newEducationInstitutionName));
        if (matchedEducationInstitution) {
          userEducationInstitutionID = matchedEducationInstitution.id;
          userEducationInstiutionEndpoint = matchedEducationInstitution.endpoint;
        }
        setEducationInstitutionID(userEducationInstitutionID);
        if (userEducationInstiutionEndpoint !== null) {
          setEducationInstitutionEndpoint(userEducationInstiutionEndpoint);
          if (validateEndpoint(userEducationInstiutionEndpoint, true)) {
            checkSignupEndpointUniqueness("signup_endpoint", userEducationInstiutionEndpoint, function(isSignupEndpointUnique) {
              checkEndpointUniqueness("endpoint", userEducationInstiutionEndpoint, userEducationInstiutionEndpoint, function(isEndPointUnique) {
                setFormValues({
                  ...formValues,
                  [name]: value.name || value,
                  "signup_endpoint": userEducationInstiutionEndpoint,
                  "endpoint": userEducationInstiutionEndpoint,
                });
                setFormErrors({
                  ...formErrors,
                  [name]: null,
                  "signup_endpoint": isSignupEndpointUnique ? "This link is already in use" : null,
                  "endpoint": isEndPointUnique ? "This link is already in use" : null,
                });
                clientValuesSet = true;
              });
            });
          }
        }
      }
      if (!clientValuesSet) {
        setFormValues({
          ...formValues,
          [name]: value.name || value,
        });
        setFormErrors({
          ...formErrors,
          [name]: null
        });
      }
    } else if (name === "email_domains") {
      let formValuesCopy = copyArray(formValues);
      formValuesCopy["email_domains"][id].value = value;
      if (id === (formValuesCopy["email_domains"].length-1)) {
        formValuesCopy["email_domains"].push({
          id: formValuesCopy["email_domains"].length,
          value: "",
          placeholder: emailDomainsPlaceholder,
          error: null,
        });
      }
      setFormValues(formValuesCopy);
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (name === "signup_endpoint" && validateEndpoint(value, false)) {
      checkSignupEndpointUniqueness(name, value);
    }

    if (name === "endpoint" && validateEndpoint(value, false)) {
      checkEndpointUniqueness(name, value, educationInstitutionEndpoint);
    }

  };

  const validateEndpoint = (value, shouldSetErrors) => {

    let isValid = true;
    let endpointError = null;

    if (!value || value === "") {
      isValid = false;
      endpointError = "You must specify a link";
    } else if (value.length < 3) {
      isValid = false;
      endpointError = "You must specify a link ending with at least 3 characters";
    } else if (!/[a-zA-Z-0-9]+/.test(value)) {
      isValid = false;
      endpointError = "Your link ending can only contain letters, numbers and '-' characters";
    } else if (value.length > 15 && !value.includes("-")) {
      isValid = false;
      endpointError = "Please use a shorter link or use the '-' character to make it more readable";
    }

    if (shouldSetErrors) {
      setFormErrors({
        ...formErrors,
        "endpoint": endpointError
      });
    }

    return isValid;

  }

  const checkEndpointUniqueness = async (name, value, currentEndpoint, callback) => {
    // Check the endpoint is unique
    setCheckingEndpointUniqueness(true);
    const submitData = createSubmitData({
      endpoint: value,
      current_endpoint: currentEndpoint,
    });
    axios.post('/api/v1/check_endpoint_is_unique', submitData).then(res => {
      let errorMsg = null;
      if (!res.data.unique) {
        errorMsg = "This link is already in use";
        setEndpointUnique(false);
      } else {
        setEndpointUnique(true);
        if (!callback) {
          setFormValues({
            ...formValues,
            [name]: value.name || value,
          });
        }
      }
      if (!callback) {
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
      setCheckingEndpointUniqueness(false);
      if (callback) {
        callback();
      }
    });
  }

  const checkSignupEndpointUniqueness = async (name, value, callback) => {
    // Check the endpoint is unique
    setCheckingSignupEndpointUniqueness(true);
    const submitData = createSubmitData({
      endpoint: value,
    });
    axios.post('/api/v1/check_signup_endpoint_is_unique', submitData).then(res => {
      let errorMsg = null;
      if (!res.data.unique) {
        errorMsg = "This link is already in use";
        setSignupEndpointUnique(false);
      } else {
        setSignupEndpointUnique(true);
        if (!callback) {
          setFormValues({
            ...formValues,
            [name]: value.name || value,
          });
        }
      }
      if (!callback) {
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
      setCheckingSignupEndpointUniqueness(false);
      if (callback) {
        callback();
      }
    });
  }

  const validateField = (e, id) => {

    const value = e.target.value;
    const name = e.target.name;

    if (name === "education_institution_name") {
      const val = context.education_institutions_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((value !== null) ? cleanStringForComparison(value.toLowerCase()) : value));
      if (!val && value && value !== "") {
        const errorMsg = value === "" ? "This is not a valid education institution" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
    } else if (name === "signup_endpoint") {
      if (validateEndpoint(value, true)) {
        checkSignupEndpointUniqueness(name, value);
      }
    } else if (name === "endpoint") {
      if (validateEndpoint(value, true)) {
        checkEndpointUniqueness(name, value, educationInstitutionEndpoint);
      }
    } else if (name === "email_domains") {
      let formValuesCopy = copyArray(formValues);
      if (id === 0 && value === "") {
        formValuesCopy["email_domains"][id].error = "You must specify at least one valid email domain";
      } else {
        if (value !== "" && !validateEmailDomain(value)) {
          formValuesCopy["email_domains"][id].error = "This is not a valid email domain";
        } else {
          formValuesCopy["email_domains"][id].error = null;
        }
      }
      setFormValues(formValuesCopy);
    }

  };

  const validateForm = (setErrors) => {
    let isValid = true;
    let newErrors = initialErrorState;

    if (!educationInstitutionID || educationInstitutionID < 0) isValid = false;

    if (formValues.education_institution_name === "") {
      newErrors = {...newErrors, organisation: "This field is required"};
      isValid = false;
    }

    if (typeof educationInstitutionID === "number" && educationInstitutionID >= 0) {

      if (!validateEndpoint(formValues.signup_endpoint, false) || isSignupEndpointUnique === false) {
        isValid = false;
      }

      if (!validateEndpoint(formValues.endpoint, false) || isEndpointUnique === false) {
        isValid = false;
      }
  
      for (let i=0; i<formValues.email_domains.length; i++) {
        if (i === 0 && formValues.email_domains[i].value === "") {
          isValid = false;
        } else if (formValues.email_domains[i].value !== "" && !validateEmailDomain(formValues.email_domains[i].value)) {
          isValid = false;
        }
      }

    }

    setErrors && setFormErrors(newErrors);
    return isValid;
  };

  const submitForm = () => {
    if (!validateForm(true)) return null;
    setIsLoading(true);

    const submitData = createSubmitData({
      details: formValues,
      education_institution_id: educationInstitutionID,
    });
    axios.post('/api/v1/create_signup_link', submitData).then((res) => {
      setIsLoading(false);
      if (res.data) {
        if (res.data.succeeded) {
          setSignupLink(res.data.link);
          setEducationInstitutionName(res.data.education_institution_name);
        } else if (res.data.error) {
          setError(res.data.error);
          console.log(res.data.error);
        }
      }
    });
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Create signup link</title>
        <meta id="meta-description" name="description" content="CareersPro - Create signup link" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="CareersPro - Create signup link" pageWelcome={`Welcome, ${context && context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        {context && signupLink && educationInstitutionName && (
          <TopSectionSuccess>
            <SubTitle>Signup link generated</SubTitle>
            <TopTextSuccess align="center">Share this link with {educationInstitutionName} to create their organisation dashboard for access to CareersPro</TopTextSuccess>
            <LinkWrap><a href={signupLink} target="_blank" rel="noopener noreferrer">{signupLink}</a></LinkWrap>
            <CopyLinkButtonWrap>
              <PrimaryButton onClick={() => {navigator.clipboard.writeText(signupLink)}}>Copy</PrimaryButton>
            </CopyLinkButtonWrap>
            <BackButtonContainer>
              <Link to={`/organisations/dashboard`}>
                <SecondaryButton>Back to dashboard</SecondaryButton>
              </Link>
            </BackButtonContainer>
          </TopSectionSuccess>
        )}
        {context && !signupLink && (
          <>
            <Grid>
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <Form>
                    <SubTitle>Create signup link - education institution</SubTitle>
                    <InputWrap>
                      <Label>Education institution</Label>
                      <HintText>Type, then select from available options.</HintText>
                      <AutocompleteField
                        placeholder="e.g. Woking College"
                        suggestions={context.education_institutions_autocomplete_options}
                        handleChange={it => handleInputChange(it, 'education_institution_name')}
                        error={formErrors['education_institution_name']}
                        onBlur={validateField}
                      />
                    </InputWrap>
                    {typeof educationInstitutionID === "number" && educationInstitutionID >= 0 && (
                      <>
                        <InputWrap>
                          <Label>Signup link endpoint</Label>
                          <HintText>This is the end of the link you'll share with the customer so they can signup</HintText>
                          <Input
                            type="text"
                            name="signup_endpoint"
                            onBlur={validateField}
                            handleChange={(it) => handleInputChange(it, 'signup_endpoint')}
                            error={formErrors['signup_endpoint']}
                            left_label={''}
                            inputValue={educationInstitutionEndpoint}
                          />
                          <HintText>Link will be: </HintText><BoldHintText>{context.site_url + '/organisations/signup/' + formValues['signup_endpoint'] || educationInstitutionEndpoint}</BoldHintText>
                        </InputWrap>
                        <InputWrap>
                          <Label>Link</Label>
                          <HintText>The link that pupils/students will go to to access CareersPro (the customer can change this)</HintText>
                          <Input
                            type="text"
                            name="endpoint"
                            onBlur={validateField}
                            handleChange={(it) => handleInputChange(it, 'endpoint')}
                            error={formErrors['endpoint']}
                            left_label={context.site_url + '/'}
                            inputValue={educationInstitutionEndpoint}
                          />
                        </InputWrap>
                        <InputWrap>
                          <Label>Email domains</Label>
                          <HintText>The end of the education institution's email addresses, e.g. @sthn.ac.uk.  Only staff and pupils/students with email addresses matching these domains will be able to access CareersPro (the customer can modify/add these)</HintText>
                          {formValues["email_domains"].map(item => (
                            <MultiInputWrap key={item.id}>
                              <Input
                                type="text"
                                name="email_domains"
                                placeholder={item.placeholder}
                                id={item.id}
                                onBlur={e => validateField(e, item.id)}
                                handleChange={(it) => handleInputChange(it, 'email_domains', item.id)}
                                error={item.error}
                                inputValue={item.value}
                                prefix="@"
                              />
                            </MultiInputWrap>
                          ))}
                        </InputWrap>
                      </>
                    )}
                    <SignupButtonWrap>
                      {validateForm(false) ?
                        (checkingSignupEndpointUniqueness !== true && checkingEndpointUniqueness !== true) ? (
                          <PrimaryButton onClick={() => submitForm()}>Generate signup link</PrimaryButton>
                        ) : (
                          <Loader />
                        ) :
                        (
                          <Tooltip text="Please enter the required details above">
                            <GrayButton disabled>Generate signup link</GrayButton>
                          </Tooltip>
                        )
                      }
                    </SignupButtonWrap>
                    {error && <Error align="center">{error}</Error>}
                  </Form>
                </Col>
              </Row>
            </Grid>
            <BackButtonContainer>
              <Link to={`/organisations/dashboard`}>
                <SecondaryButton>Back to dashboard</SecondaryButton>
              </Link>
            </BackButtonContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default CreateSignupLinkEducationInstitution;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const MultiInputWrap = styled.div`
  margin-bottom: 12px;
`;

const Form = styled.div`
  margin: auto;
  max-width: 450px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 32px 0 32px;
`;

const TopSectionSuccess = styled.div`
  margin-top: 20px;
`;

const TopTextSuccess = styled(P)`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 356px;
  }
`;

const SignupButtonWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
  padding-top: 8px;
`;

const Error = styled(P)`
  color: #FA054F;
`;

const LinkWrap = styled.div`
  text-align: center;
  padding: 20px;
  white-space: pre-wrap; /* CSS3 */    
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */    
  white-space: -o-pre-wrap; /* Opera 7 */    
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

const CopyLinkButtonWrap = styled.div`
  text-align: center;
`;

const BoldHintText = styled(HintText)`
  font-weight: 700;
`
