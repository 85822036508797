/* Last screen at the end of the quiz just before final results if the user doesn't have access
   to their final results - gives them the opportunity to pay to access their results */

import React, { useState, useEffect, createRef } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import MetaTags from 'react-meta-tags';
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

import { useStateValue } from '../../services/state';
import { useGetScreenData } from '../../hooks/getDataHooks';
import layout from '../../config/layout';
import colors from '../../config/colors';
import { createSubmitData } from '../../utils/helper';

import ConfettiLayout from '../../ui/layout/ConfettiLayout';
import CheckoutForm from './CheckoutForm';
import LoadingScreen from '../LoadingScreen';
import { H2, P, PSmall } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton, LCGPurpleButton } from '../../ui/buttons/Buttons';
import Insights from '../Insights';
import FeaturesCard from '../../components/FeaturesCard';
import IconPadlock from '../../assets/icon-padlock.svg';
import PathwaysInfo from '../../components/PathwaysInfo';
import ResultsCardSimple from '../../components/ResultsCardSimple';
import { Tag } from '../../ui/tag/Tag';

const PaymentScreen = () => {

  const [{ user_id, context, loading }] = useStateValue();
  const [ isLoading, setIsLoading ]= useState(true);

  const [ packagePurchased, setPackagePurchased ] = useState(null);

  const history = useHistory();

  const unlockResultsSecondTitle = createRef();

  let numUnlockClicks = 0;
  let timestampFirstUnlockClick = 0;
  let unlocking = false;

  const handleUnlockClick = () => {
    if (!unlocking) {
      let currentTimestamp = new Date().getTime();
      if (currentTimestamp < (timestampFirstUnlockClick + 5000)) {
        // If this click is within 5 seconds of the first one, then increment
        numUnlockClicks++;
      } else {
        // Otherwise, start again
        timestampFirstUnlockClick = currentTimestamp;
        numUnlockClicks = 1;
      }
      if (numUnlockClicks === 5) {
        unlocking = true;
        const submitData = createSubmitData({
          user_id: user_id,
        });
        axios.post('/api/v1/handleUnlockBypass', submitData).then((res) => {
          if (res.status === 200) {
            history.push(`/${user_id}/results`);
          }
        }).catch(error => {
          console.log("Unlock bypass disabled");
        });
      }
    }
  };

  let paymentMatch = useRouteMatch("/:userId/payment");
  let upgradeMatch = useRouteMatch("/:userId/upgrade");
  let thankYouMatch = useRouteMatch("/:userId/thankyou");
  let match = ( paymentMatch ? paymentMatch : ( (upgradeMatch ? upgradeMatch : thankYouMatch ? thankYouMatch : null ) ) );

  useGetScreenData((upgradeMatch ? 'upgrade' : 'payment'), setIsLoading, match.params.userId);

  useEffect(() => {
    if (packagePurchased || (context && context.paid)) {
      let parametersInURL = window.location.href.includes("?") ? window.location.href.slice(window.location.href.indexOf("?")) : "";
      history.push(`${(!user_id) ? "" : "/" + user_id}/thankyou${parametersInURL}`); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, packagePurchased]);

  // Set the stripePublicAPIKey
  var stripePublicAPIKey = "";
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Dev environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY_DEV;
  } else {
    // Production environment
    stripePublicAPIKey = process.env.REACT_APP_STRIPE_API_KEY;
  }

  if (loading || isLoading) return <LoadingScreen />;

  // let percentageOff = null;
  // if (context.unlock_origninal_price_pence !== null) {
  //   // Round to nearest 5%
  //   percentageOff = Math.round((1 - (context.unlock_price_pence / context.unlock_origninal_price_pence)) * 100 / 5) * 5;
  // }
  // let benefitsItemIndex = 0;

  let pricePence = 0;
  let priceText = "";
  const priceCurrency = context.price_currency;

  if (packagePurchased === "premium" || context.package === "premium") {
    pricePence = context.unlock_premium_price_price;
    priceText = context.unlock_premium_price_text;;
  } else if (upgradeMatch || packagePurchased === "upgrade") {
    pricePence = context.upgrade_price_pence;
    priceText = context.upgrade_price_text;
  } else {
    pricePence = context.unlock_price_price;
    priceText = context.unlock_price_text;
  }

  return (
    <>
      <MetaTags>
        <title>{(upgradeMatch || packagePurchased === "upgrade") ? "Upgrade your results" : "Unlock your results"}</title>
        <meta id="meta-description" name="description" content={(upgradeMatch || packagePurchased === "upgrade") ? "Upgrade your results" : "Unlock your results"} />
      </MetaTags>
      <Amplitude eventProperties={{
        num_careers: context.num_careers,
        price_pence: pricePence,
        price_currency: priceCurrency,
        price_text: priceText,
      }} userProperties={{
        user_id: user_id,
        paid: (packagePurchased ? true : null),
      }}>
        <LogOnMount eventType={(upgradeMatch || packagePurchased === "upgrade") ? "View Upgrade Screen" : "View Payment Screen"}>
          {packagePurchased || (context && context.paid) ? (
            <ConfettiLayout
              title={(upgradeMatch || packagePurchased === "upgrade") ? "Upgraded to premium" : "Careers unlocked"}
              hasConfetti={true}
              sideMargin={true}
            >
              <TopSectionSuccess>
                <TopTextSuccess align="center">Thank you for your payment of {priceText} {packagePurchased === "premium" || context.package === "premium" ? "for the premium package" : (packagePurchased === "upgrade" ? "to upgrade to the premium package" : "for the standard package")}.</TopTextSuccess>
                {packagePurchased !== "upgrade" && (
                  <TopTextSuccess align="center">We have emailed your results to you.  If the email doesn't arrive, please check your spam folder or contact us at support@careerspro.co.uk.</TopTextSuccess>
                )}
                <TopTextSuccess align="center">Click the button below to access {packagePurchased === "upgrade" ? "all premium features..." : "your full results..."}</TopTextSuccess>
                <ButtonWrap>
                  <Link to={`/${user_id}/results`}>
                    <PrimaryButton>View {packagePurchased === "upgrade" ? "results" : "all careers"}</PrimaryButton>
                  </Link>
                </ButtonWrap>
              </TopSectionSuccess>
            </ConfettiLayout>
          ) : context && (
            <ConfettiLayout
              hasConfetti={true}
              sideMargin={true}
            >
              {!upgradeMatch && (
                <Title align="center"><span onClick={() => handleUnlockClick()}>{context.contact_details && context.contact_details.first_name ? context.contact_details.first_name + "'s" : "Your"}</span> results</Title>
              )}
              {upgradeMatch && (
                <Title align="center">Upgrade to premium for {context.upgrade_price_text}</Title>
              )}
              {!upgradeMatch && (<Insights />)}
              {context.has_free_careers && context.message_above_free_results && (
                <>
                  <MessageAboveResults>{ReactHtmlParser(context.message_above_free_results)}</MessageAboveResults>
                  <TextCenter pt_desktop="20px" pt_mobile="20px" pb_desktop="52px" pb_mobile="32px">
                    <LCGPurpleButton onClick={ () => { unlockResultsSecondTitle.current.scrollIntoView({behavior:"smooth"}); } }>Unlock your full results</LCGPurpleButton>
                  </TextCenter>
                  {context && context.final_results && (
                    <Grid>
                      <Row>
                        <Col xs={12} md={8} mdOffset={2}>
                          {context.final_results.map((item, key) => (
                            key < 2 && (
                              <div key={`result_${item.career_id}`}>
                                <ResultsCardSimple
                                  key={`result_${item.career_id}`}
                                  imageUrl=""
                                  title={item.career_name}
                                  ctaLabel="Explore"
                                  handleCtaClick={ () => { unlockResultsSecondTitle.current.scrollIntoView({behavior:"smooth"}); } }
                                  ctaImage={IconPadlock}
                                  textUnderButton="Upgrade to view your personalised to-do list to get into this career, along with your transferable skills and more"
                                >
                                  <TagWrap>
                                    {item.labels.map(label => (
                                      <Tag key={`${item.career_id}_${label.key}`} color={label.positive ? colors.LCG_GREEN : colors.LCG_DARK_PURPLE} bgColor={label.positive ? colors.LCG_GREEN_FADE : colors.LCG_DARK_PURPLE_FADE}>{label.name}</Tag>
                                    ))}
                                    {item.is_skills_match && (
                                      <Tag key="skills_match" color={colors.LCG_DARK_PURPLE} bgColor={colors.LCG_DARK_PURPLE_FADE}>STRONG SKILLS MATCH</Tag>
                                    )}
                                  </TagWrap>
                                  <PSmall align="center">{item.career_description}</PSmall>
                                  {item.num_pathways > 0 && (
                                    <PathwaysInfo user_id={user_id} pathwaysSummary={item.pathways_summary} sortType="time" />
                                  )}
                                  {item.pathways_unavailable && (
                                    <PathwaysUnavailable bold align="center">Pathways unavailable.</PathwaysUnavailable>
                                  )}
                                </ResultsCardSimple>
                              </div>
                            )
                          ))}
                          <SecondTitle align="center" ref={unlockResultsSecondTitle}>Unlock your full results</SecondTitle>
                        </Col>
                      </Row>
                    </Grid>
                  )}
                </>
              )}
              <FeaturesCard price={context.unlock_price_text} premium_price={context.unlock_premium_price_text} upgrade={upgradeMatch} />
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <StripeProvider apiKey={stripePublicAPIKey}>
                    <Elements>
                      <CheckoutForm setIsLoading={setIsLoading} isLoading={isLoading} setPackagePurchased={setPackagePurchased} upgrade={upgradeMatch} contactDetails={context.contact_details} />
                    </Elements>
                  </StripeProvider>
                </Col>
              </Row>
              {((context && context.paid) || upgradeMatch) && (
                <ButtonWrap pb>
                  <Link to={`/${user_id}/results`}>
                    <SecondaryButton>Back to results</SecondaryButton>
                  </Link>
                </ButtonWrap>
              )}
            </ConfettiLayout>
          )}
        </LogOnMount>
      </Amplitude>
    </>
  );
};

export default PaymentScreen;

const Title = styled(H2)`
  margin-bottom: 32px;
  margin-top: 46px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 12px;
  }
`;

const SecondTitle = styled(H2)`
  margin-top: 52px;
  margin-bottom: 52px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 42px;
    margin-bottom: 42px;
  }
`;

// const TopSectionPayment = styled.div`
//   padding-bottom: 20px;
// `;

// const TopText = styled(P)`
//   text-align: center;
//   margin-bottom: 8px;
// `;

// const LineThroughText = styled.span`
//   text-decoration: line-through;
// `;

const TopSectionSuccess = styled.div`
  margin-top: 20px;
`;

const TopTextSuccess = styled(P)`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 356px;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;

const MessageAboveResults = styled(P)`
  text-align: center;
  padding-bottom: 32px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
    max-width: 356px;
  }
`

const TextCenter = styled.div`
  text-align: center;
  ${props => props.pt_desktop  && `padding-top:${props.pt_desktop};`};
  ${props => props.pb_desktop  && `padding-bottom:${props.pb_desktop};`};

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => props.pt_mobile  && `padding-top:${props.pt_mobile};`};
    ${props => props.pb_mobile  && `padding-bottom:${props.pb_mobile};`};
  }
`;

const TagWrap = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const PathwaysUnavailable = styled(PSmall)`
  width: 100%;
  margin-bottom: 20px;
  color: ${colors.TEXT_DARK};
`;
