/* Final results > Career screen > Expanded part at the top */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../../config/colors';

import { P, Ul, PSmall } from '../../../ui/typography/Typography';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';


const RevealContent = ({ item }) => {
  return (
    <Container>
      <Grid>
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            {item.also_known_as && item.also_known_as.length > 0 && (
              <>
                <P>Also known as:</P>
                <PSmall>
                {item.also_known_as.map((name, i) => {
                    if (i === 0) {
                      return name;
                    } else {
                      return (', ' +  name);
                    }
                  })}
                </PSmall>
              </>
            )}

            {item.similar_to && item.similar_to.length > 0 && (
              <>
                <P>Similar to:</P>
                <PSmall>{item.similar_to.map((name, i) => {
                    if (i === 0) {
                      return name;
                    } else {
                      return (', ' +  name);
                    }
                  })}
                </PSmall>
              </>
            )}

            <P>Day to day, you'll be...</P>
            <Ul>
              {item.tasks && item.tasks.map((task) => (
                <li key={task}>{task}</li>
              ))}
            </Ul>

            {item.specific_types && item.specific_types.length > 0 && (
              <>
                <P>Specific types:</P>
                <PSmall>{item.specific_types.map((name, i) => {
                    if (i === 0) {
                      return name;
                    } else {
                      return (', ' +  name);
                    }
                  })}
                </PSmall>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </Container>


  );
};

export default RevealContent;

RevealContent.propTypes = {
  item: PropTypes.object.isRequired
};

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 16px;
  background-color: ${colors.LCG_LIGHTEST_GREY};
`;
