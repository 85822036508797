/* Titles used throughout the homepage */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import layout from '../../config/layout';
// import colors from '../../config/colors';

import { H2, H3 } from '../../ui/typography/Typography';

const Title = ({ label, children, is_subtitle }) => {
  return (
    <Container>
      {label && (<H2 bold h3>{label}</H2>)}
      {!is_subtitle && (<H3 h1>{children}</H3>)}
      {is_subtitle && (<H3 h2>{children}</H3>)}
    </Container>
  );
};

export default Title;

Title.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

const Container = styled.div`
  margin-bottom: 64px;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin-bottom: 16px;
  }
`;
