/* Homepage > Terms link at in the footer */

import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';

import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid } from 'react-styled-flexboxgrid';
import TermsContent from './TermsContent';

const termsContent = {
  "v1.0": <TermsContent />,
};

const TermsScreen = () => {
  let match = useRouteMatch("/terms/:version");
  const history = useHistory();

  if (match && !termsContent[match.params.version]) {
    history.push('/404');
  }

  return (
    <>
      <MetaTags>
        <title>Terms & Conditions</title>
        <meta id="meta-description" name="description" content="CareersPro's terms and conditions" />
      </MetaTags>
      <Container>
        <Grid>
          {termsContent[match ? match.params.version : 'v1.0']}
        </Grid>
      </Container>
    </>
  );
};

export default TermsScreen;

const Container = styled.div`
  padding: 80px 0;
`;
