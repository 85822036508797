/* Career selection screen after the quiz */

import React, { useState, useEffect } from 'react';
import styled from  'styled-components';
import { Amplitude } from '@amplitude/react-amplitude';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

import { copyArray } from '../../utils/copyArray';
import { useStateValue } from "../../services/state";
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';
import { getNamesByIDs } from '../../utils/getNamesByIDs';

import { Col, Row } from 'react-styled-flexboxgrid';
import { PrimaryButton, SecondaryButton, GrayButton } from  '../../ui/buttons/Buttons';
import CheckboxCard from '../../components/CheckboxCard';
import LoadingScreen from '../LoadingScreen';
import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import CareerSelectionInfoScreen from './CareerSelectionInfoScreen';
import RestartFlow from '../RestartFlow';


const TopCareers = () => {
  const [ careerButtonsActive, setCareerButtonsActive ] = useState([]);
  const [{ showCareerSelectionInfoScreen, context, user_id, careersPrevSelected, loading, screen_name }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingMessage, setLoadingMessage ] = useState();
  const history = useHistory();
  const match = useRouteMatch("/:userId/career-selection/:sourceId");

  useEffect(() => {
    if (context && context.top_careers) {
      window.scrollTo(0, 0);
    }
  }, [context]);

  useGetScreenData('career-selection', setIsLoading, match.params.userId, match.params.sourceId);

  useEffect(() => {
   return history.listen((location, type) => {
     const getData = async () => {
      dispatch({type: 'startLoading'});
      let arr = location.pathname.split('/').filter(a => a !== '');
      const resp = await fetchData('career-selection', match.params.userId, arr[arr.length - 1]);
      if (resp.status === 200) {
       dispatch({type: 'updateResponse', data: resp.data});
      }
    };
    type === 'POP' && getData()
   })
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history])


  if (showCareerSelectionInfoScreen) return <CareerSelectionInfoScreen />;

  const handleChange = (selected_item) => {
    let copy = copyArray(careerButtonsActive);
    const existingItem = copy.findIndex(item => item.career_id === selected_item.career_id);

    if (existingItem > -1) {
      copy.splice(existingItem, 1)
    } else {
      copy.push(selected_item);
    }
    setCareerButtonsActive(copy);
  };

  const next = (logEvent) => {

    let firstLoadingMessage = "";

    if (context.loading_message &&
        ( !context.loading_message.includes("people similar to you like") || context.num_careers_selected_so_far > 0 || careerButtonsActive.length > 0 ) ) {
      setLoadingMessage(context.loading_message);
    } else if (context.loading_messages && context.loading_messages.length > 0) {
      firstLoadingMessage = context.loading_messages[0];
      setLoadingMessage(firstLoadingMessage);
      context.loading_messages.shift();
    }

    setIsLoading(true);

    // Get the career ids of those selected and post back to the server
    const careersSelected = careerButtonsActive.map(item => {
      return item.career_id;
    });
    const careersShown = context.top_careers.map(item => {
      return item.career_id;
    });
    logEvent('Completed Career Selection Screen', {
      careers: careersSelected ? getNamesByIDs(careersSelected, context.all_careers, null) : null,
      career_ids: careersSelected,
      num_careers_selected: careersSelected.length,
    });
    if (context.source_url === "similar-likes") {
      logEvent('Reached Final Results', {});
    }
    dispatch({type: 'update', values: {careersPrevSelected: [...careersPrevSelected, ...careersSelected]}});

    const formData = createSubmitData({
      user_id: user_id,
      all_careers_shown: careersShown,
      selected_careers: careersSelected,
      source_url: context.source_url,
    });

    axios.post('/api/v1/careersSelected', formData).then((resp) => {
      let url = `/${resp.data.user_id}/${resp.data.screen.name}/${resp.data.screen.context.source_url ? resp.data.screen.context.source_url : ''}`;
      if (context.loading_message) {
        setTimeout(() => {
          dispatch({type: 'update', values: {window_top: 0}});
          setIsLoading(false);
          dispatch({type: 'updateResponse', data: resp.data});
          setLoadingMessage();
          history.push(url);
        }, 2500);
      } else if (context.loading_messages && context.loading_messages.length > 0) {
        setTimeout(() => {
          setSeriesOfLoadingMessages(firstLoadingMessage, context.loading_messages, function() {
            dispatch({type: 'update', values: {window_top: 0}});
            setIsLoading(false);
            dispatch({type: 'updateResponse', data: resp.data});
            setLoadingMessage();
            history.push(url);
          });
        }, 2500);
      } else {
        dispatch({type: 'update', values: {window_top: 0}});
        setIsLoading(false);
        dispatch({type: 'updateResponse', data: resp.data});
        history.push(url);
      }

    });
    setCareerButtonsActive([]);
  };

  const setSeriesOfLoadingMessages = (latestLoadingMessage, remainingLoadingMessages, callback) => {
    if (remainingLoadingMessages.length > 0) {
      latestLoadingMessage = remainingLoadingMessages[0]
      setLoadingMessage(latestLoadingMessage);
      setTimeout(() => {
        remainingLoadingMessages.shift();
        setSeriesOfLoadingMessages(latestLoadingMessage, remainingLoadingMessages, callback);
      }, 2500);
    } else {
      callback();
    }
  }

  const getCareerUserProps = () => {
    const careersSelected = careerButtonsActive.map(item => {
      return item.career_id;
    });
    const newCareersObj = [...careersPrevSelected, ...careersSelected];
    return {
      careers: newCareersObj ? getNamesByIDs(newCareersObj, context.all_careers, null) : null,
      career_ids: newCareersObj,
      num_careers_selected: newCareersObj.length
    };
  };

  if (loading || isLoading || screen_name !== 'career-selection') return <LoadingScreen text={loadingMessage} />;

  const getTitle = () => {
    let title;
    let subtitle;

    if (context.source_url === "multiple-qualities") {
      title = "Narrow It Down";
      subtitle="Select your favourite careers from the list below, that all match more than one of your top qualities...";
    } else if (context.source_url === "similar-likes") {
      title = "Other Careers You Might Like";
      subtitle = "Select your last few favourite careers from the list below, that are based on what people similar to you liked...";
    } else if (context.source_name !== null) {
      title = context.source_name + " Careers";
      subtitle = "Select your favourite careers from the list below, that are related to the quality <b>" + context.source_name.toLowerCase() + "</b>...";
    }

    return {
      title: title,
      subtitle: subtitle,
    };
  };

  var numRequiredCareersToSelect = 0;
  var disabledButtonText = "";

  if (context.source_url === "similar-likes") {

    var totalNumRequiredCareersToSelect = 6;
    numRequiredCareersToSelect = Math.max(totalNumRequiredCareersToSelect - context.num_careers_selected_so_far, 0);
    var numCareersToSelect = numRequiredCareersToSelect - careerButtonsActive.length;

    disabledButtonText = "Select careers";
    if (numCareersToSelect > 0) {
      if (numCareersToSelect === 1) {
        disabledButtonText = "Please select at least one more";
      } else if (numCareersToSelect === 2) {
        disabledButtonText = "Please select at least two";
      } else if (numCareersToSelect === 3) {
        disabledButtonText = "Please select at least three";
      } else if (numCareersToSelect === 4) {
        disabledButtonText = "Please select at least four";
      } else if (numCareersToSelect === 5) {
        disabledButtonText = "Please select at least five";
      }
    }
    
  }

  return (
    <>
      <MetaTags>
        <title>Top Careers</title>
        <meta id="meta-description" name="description" content="Select your favourite careers" />
      </MetaTags>
      <ThreeDotsBgLayout
        title={getTitle().title}
        subtitle={getTitle().subtitle}
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            {context.top_careers.map(item => {
              return (
                <CardContainer key={item.career_id}>
                  <CheckboxCard
                    description={item.career_description}
                    handleClick={(it) => handleChange(it)}
                    tags={item.top_qualities ? item.top_qualities : [] }
                    title={item.career_name}
                    value={item}
                    testid="career_selection"
                  />
                </CardContainer>
              );
            })}
          </Col>
          <Col xs={12} md={8} mdOffset={2}>
            <Amplitude userProperties={getCareerUserProps()}>
              {({ logEvent }) => (
                <ButtonContainer>
                  {careerButtonsActive.length < numRequiredCareersToSelect ? (
                    <GrayButton disabled>{disabledButtonText}</GrayButton>
                  ) : (
                    careerButtonsActive.length > 0 ? (
                      <PrimaryButton onClick={() => next(logEvent)} data-testid="next">Continue</PrimaryButton>
                    ) : (
                      <SecondaryButton onClick={() => next(logEvent)} data-testid="skip">Skip</SecondaryButton>
                    )
                  )}
                </ButtonContainer>
              )}
            </Amplitude>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default TopCareers;

const CardContainer = styled.div`
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  margin-top: 43px;
  text-align: center;
`;
