/* Dashboard (pay-as-you-go) > Add users */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { getPriceText } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P } from '../../ui/typography/Typography';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import SelectBar from '../../components/SelectBar';

import AddUsersManuallyScreen from './AddUsersManuallyScreen';
import AddUsersFromCSVScreen from './AddUsersFromCSVScreen';
import AddUsersWithAPIScreen from './AddUsersWithAPIScreen';

const AddUsersScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);

  const addUserOptionsNonAdmin = [{ id:'manual', label: 'Add manually'}, {id:'csv', label: 'Add from CSV' }];
  const addUserOptionsAdmin = [{ id:'manual', label: 'Add manually'}, {id:'csv', label: 'Add from CSV' }, {id:'api', label: 'Add with API'}];
  
  const [ addUserOption, setAddUserOption ] = useState({ id:'manual', label: 'Add manually'});

  useGetScreenData('organisations/add_users', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/add_users', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/add_users") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUserOptionChange = (item) => {
    setAddUserOption(item);
  };

  if (loading || isLoading || !context) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Add users</title>
        <meta id="meta-description" name="description" content="CareersPro - Add users" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name + " - Add users"} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        {context.num_licences === 0 && !context.internal && (
          <>
            <Text pt>You'll need to purchase more licences before you can add users.</Text>
            <Text pt>If you would like to purchase more licences then contact your LCG Regional Account Director.</Text>
          </>
        )}
        {context.num_licences > 0 && !context.internal && (
          <Text pt>You have {context.num_licences.toLocaleString()} licence{context.num_licences !== 1 ? `s` : ``} remaining</Text>
        )}
        {context.num_licences === -1 && !context.internal && (
          <Text pt>You have unlimited licenses.</Text>
        )}
        {!context.prepay && !context.internal && !context.is_college && (
          <Text pt>You will be invoiced {getPriceText(context.per_user_net_price_pence_for_invoicing)} + VAT per user added</Text>
        )}
        {(context.num_licences > 0 || context.num_licences === -1) && (
          <Text pt>Add new students and send them email invitations. If the email or student ID matches an existing student, their details will be updated and an invitation will not be sent out.</Text>
        )}
        {context.internal && (
          <Text pt>As an internal user, you can add users at no cost</Text>
        )}
        {(context.num_licences > 0 || context.num_licences === -1 || context.internal) && (
          <>
            <InputWrap side_padding>
              <SelectBar values={context.admin ? addUserOptionsAdmin : addUserOptionsNonAdmin} handleChange={(item) => handleAddUserOptionChange(item)} selectedValue={addUserOption} />
            </InputWrap>
            {addUserOption && addUserOption.id === 'manual' && (
              <AddUsersManuallyScreen />
            )}
            {addUserOption && addUserOption.id === 'csv' && (
              <AddUsersFromCSVScreen />
            )}
            {context.admin && addUserOption && addUserOption.id === 'api' && (
              <AddUsersWithAPIScreen />
            )}
          </>
        )}
        <BackButtonContainer>
          <Link to={`/organisations/dashboard`}>
            <SecondaryButton>Back to dashboard</SecondaryButton>
          </Link>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default AddUsersScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 70px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 150px;
`;

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const InputWrap = styled.div`
  margin-top: 32px;
  margin-bottom: 20px;

  ${props => props.side_padding && "padding-left: 20px;"}
  ${props => props.side_padding && "padding-right: 20px;"}
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 40px 0 32px;
`;
