/* Homepage > Organisations */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory } from 'react-router-dom';
import ReactPlayer from "react-player";

import { useStateValue } from '../../services/state';
import { useGetScreenData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import TopBar from '../../components/TopBar';
import FeaturesCard from '../../components/FeaturesCard';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { H1, H2, H3, P, PSmall } from '../../ui/typography/Typography';
import FeatureTick from '../../assets/feature-tick.svg';
import { PrimaryButton, SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';

const OrganisationScreen = () => {

  const history = useHistory();

  const [{ context, loading }] = useStateValue();
  const [ isLoading, setIsLoading ]= useState(true);

  const orgBenefits = [
    { name: "Your logo on a unique landing page for your users" },
    { name: "Dashboard of all your users so you can track their progress" },
    { name: "Download a career report for each user, with a detailed analysis of their interests, top careers, pathways into them and transferable skills" },
    { name: "Onboarding and ongoing support from a member of our team" },
  ];

  useGetScreenData('organisations', setIsLoading, null, null);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let benefitsKey = 0;

  if (loading || isLoading) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Organisations</title>
        <meta id="meta-description" name="description" content="CareersPro - Organisations" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="Organisations" showLogin={true} sticky />
        <CircleOne />
        <CircleTwo />
        <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <Title align="center">Enhance your careers guidance</Title>
              <Text pb>If you provide career support to people, CareersPro is the perfect complement.  Whether you’re a university, career coach or provide outplacement services.</Text>
              <Text pb>Purchase access for your users in bulk at a discount, so they can use all the great features of CareersPro...</Text>
            </Col>
          </Row>
        </Grid>
        <FeaturesContainer>
          <FeaturesCard organisation={true} include_skills={true} />
        </FeaturesContainer>
        <Grid>
          <Row>
            <Col xs={12} md={6} mdOffset={3}>
              <OrgBenefitsContainer>
                <SubTitle align="center">In addition, you'll get:</SubTitle>
                {orgBenefits.map(item => (
                  <Row middle="xs" key={benefitsKey++}>
                    <Col xs={2} md={2}>
                      <Text pb><TickImage src={FeatureTick} alt="" /></Text>
                    </Col>
                    <Col xs={10} md={10}>
                      <P>{item.name}</P>
                    </Col>
                  </Row>
                ))}
              </OrgBenefitsContainer>
            </Col>
          </Row>
        </Grid>
        {/* <VideoContainer>
          <Video>
            <ReactPlayerResponsive
              url="https://youtu.be/p-kygsjAKh8"
              width="100%"
              height="100%"
              controls={true}
              config={{
                youtube: {
                  playerVars: { cc_load_policy: 1 }
                }
              }}
            />
          </Video>
        </VideoContainer> */}
        {context && context.b2b_packages && (
          <>
            {/* <SubTitle align="center">Choose the best plan for your organisation</SubTitle>
            <Text pb>Based on how many user licences you want to start with</Text> */}
            <Grid>
              <Row>
                {/* {context.b2b_packages.map((item, i) => (
                  <Col key={i} xs={12} md={4}>
                    <PackageCard left={i % 3 === 0} center={i % 3 === 1} right={i % 3 === 2}>
                      {item.discount && (
                        <DiscountRibbonContainer>
                          <DiscountRibbon><Text>{item.discount} off</Text></DiscountRibbon>
                        </DiscountRibbonContainer>
                      )}
                      <PackageCardTitle>{item.name}</PackageCardTitle>
                      <Text>{item.min_num_users}{item.max_num_users !== null ? `-${item.max_num_users}` : `+`} users</Text>
                      <PackageCardPrice>{item.price_text}</PackageCardPrice>
                      <Text>per user (exc VAT)</Text>
                      {item.price_pence ?
                        <PackageCardButton onClick={() => {history.push(`/organisations/signup/${item.name.toLowerCase()}`);}}>Sign up</PackageCardButton> :
                        <PackageCardButton onClick={() => {history.push(`/organisations/contact`);}}>Contact sales</PackageCardButton>
                      }
                    </PackageCard>
                  </Col>
                ))} */}
                <Col xs={12} md={6}>
                  <PackageCard left_two_only={true}>
                    <PackageCardTitle>Education</PackageCardTitle>
                    <TextSmall side_padding>Improve graduate outcomes & overall rankings</TextSmall>
                    <PackageCardPrice>Get in touch</PackageCardPrice>
                    <TextSmall side_padding>Guide students into careers they’ll love</TextSmall>
                    <PackageCardButton onClick={() => {history.push(`/organisations/contact`);}}>Contact sales</PackageCardButton>
                  </PackageCard>
                </Col>
                <Col xs={12} md={6}>
                  <PackageCard right_two_only={true}>
                    <PackageCardTitle>Coaching services</PackageCardTitle>
                    <TextSmall side_padding>Improve efficiency and impact of coaching sessions</TextSmall>
                    <PackageCardPrice>Get in touch</PackageCardPrice>
                    <TextSmall side_padding>Add tangible value for your clients</TextSmall>
                    <PackageCardButton onClick={() => {history.push(`/organisations/contact`);}}>Contact sales</PackageCardButton>
                  </PackageCard>
                </Col>
              </Row>
            </Grid>
          </>
        )}
        <BackButtonContainer>
          <SecondaryButton onClick={() => {history.goBack()}}>Back</SecondaryButton>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default OrganisationScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const Title = styled(H1)`
  margin: 32px 0 52px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin: 4px 0 20px 0;
  }
`;

const SubTitle = styled(H3)`
  margin: 20px 0 20px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin: 12px 0 12px 0;
  }
`;

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.side_padding && "padding-left: 8px; padding-right: 8px;"}

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.side_padding && "padding-left: 4px; padding-right: 4px;"}
  }
`;

const TextSmall = styled(PSmall)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.side_padding && "padding-left: 8px; padding-right: 8px;"}

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.side_padding && "padding-left: 4px; padding-right: 4px;"}
  }
`;

const FeaturesContainer = styled.div`
  margin: 0 10px 0 10px;
`

const OrgBenefitsContainer = styled.div`
  max-width: 450px;
  margin: auto;
  margin-bottom: 20px;

  @media (max-width: ${layout.breakpoints.MD}) {
    max-width: 350px;
    margin-bottom: 12px;
  }
`

const TickImage = styled.img`
  max-width: 32px;
  margin-top: 8px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 26px;
    margin-top: 4px;
  }
`;

const PackageCard = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  ${props => props.left && "margin: 0 0 40px auto;"}
  ${props => props.center && "margin: 0 auto 40px auto;"}
  ${props => props.right && "margin: 0 auto 40px 0;"}
  ${props => props.left_two_only && "margin: 0 12px 40px auto;"}
  ${props => props.right_two_only && "margin: 0 auto 40px 12px;"}
  padding: 32px 0;
  position: relative;
  max-width: 300px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 24px 0;
    margin: 0 auto 40px auto;
  }
`;

// const DiscountRibbonContainer = styled.div`
//   position: absolute;
//   top: 0;
//   width: 150px;
//   height: 150px;
//   overflow: hidden;
// `

// const DiscountRibbon = styled.div`
//   background-color: ${colors.LCG_DARK_PURPLE};
//   margin-top: 40px;
//   margin-left: -10px;
//   transform: translateY(-50%) translateX(-50%) translateX(50px) rotate(-45deg);
//   color: #1f1f1f;
//   font-size: 14px;
//   font-weight: 600;

//   @media(max-width: ${layout.breakpoints.MD}) {
//     margin-top: 35px;
//   margin-left: -15px;
//   }
// `

const PackageCardTitle = styled(H3)`
  text-align: center;
  margin: 0 8px 8px 8px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: 0 8px 4px 8px;
  }
`;

const PackageCardPrice = styled(H2)`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.BG_GRAY};
  margin-bottom: 12px;
`;

const PackageCardButton = styled(PrimaryButton)`
  margin-top: 12px;
  margin-bottom: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const VideoContainer = styled.div`
  padding: 0px 32px 52px 32px;
  max-width: 1024px;
  margin: auto;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 0px 20px 32px 20px;
  }
`;

const Video = styled.div`
  position: relative;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)
`;

const ReactPlayerResponsive = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
