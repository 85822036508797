import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from '../config/colors';

const Loader = ({ children, isLight }) => {
  return (
    <Container>
      <Spinner>
      </Spinner>
      {children && (
        <TextContainer>
          {children}
        </TextContainer>
      )}
    </Container>

  );
};

export default Loader;

Loader.propTypes = {
  children: PropTypes.node,
};

const draw = keyframes`
  0% {
    stroke-dashoffset: 422.16;
  }
  50% {
    stroke-dasharray: 211.08;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const TextContainer = styled.div`
  margin-top: 32px;
`;

export const Spinner = styled.div`
    border: 5px solid ${colors.LCG_LIGHT_GREY};
    border-top: 5px solid ${colors.LCG_DARK_GREY};
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.5s linear infinite;
    margin: 20px auto;

    @keyframes spin{
        0%{
            transform: rotate(0deg);
        } 100%{
            transform: rotate(360deg);
        }
    }
`;