/* Homepage > Get started > Student & studying "Other" > Course screen */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { validatePostcode } from '../../utils/validation';
import { useStateValue } from "../../services/state";
import { cleanString } from '../../utils/helper';
import { createSubmitData } from '../../utils/helper';
import { useHistory } from 'react-router-dom';
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { copyArray } from '../../utils/copyArray';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Multiselect from '../../components/multiselect/Multiselect';
import { Label, Error, HintText } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';

const OtherCourseScreen = () => {

  const [{ user_id, loading, context }, dispatch] = useStateValue();

  const [course, setCourse] = useState([
    {id: 0, value: "", placeholder: "e.g. Diploma in Visual Merchandising"},
    {id: 1, value: "", placeholder: ""},
    {id: 2, value: "", placeholder: ""},
  ]);

  const [ courseLocation, setCourseLocation ] = useState({value: "", placeholder: "e.g. London School of Trends"});
  
  const [ postcode, setPostcode ] = useState('');
  const [ postcodeError, setPostcodeError ] = useState(null);

  const [ relocate, setRelocate ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false }
  ]);

  const [ generalError, setGeneralError ] = useState();
  const [ isLoading, setIsLoading ] = useState([]);
  const history = useHistory();
  
  const match = useRouteMatch("/:userId/course");

  const valueFormatting = (value) => value.toUpperCase();

  useGetScreenData('course', setIsLoading, match.params.userId);

  const handleCourseChange = (val, id) => {
    let courseCopy = copyArray(course);

    courseCopy[id].value = val.name || val;

    if (id === (course.length-1)) {
      courseCopy.push({value: "", id: course.length});
    }

    setCourse(courseCopy);
  };

  const handleCourseLocationChange = (value) => {
    let copy = copyArray(courseLocation);
    copy.value = value.name || value;
    setCourseLocation(copy);
  };

  const checkPostcode = e => {
    // Postcode validity
    const value = e.target.value;
    const error = validatePostcode(cleanString(value)) ? null : 'This is not a valid UK postcode';
    setPostcodeError(value === "" ? null : error);
  };

  const handleRelocateChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setRelocate(values)
  };

  const validateForm = () => {
    let isValid = true;

    if (!course.find(item => item.value !== "")) isValid = false;
    if (courseLocation.value === "") isValid = false;
    if (!validatePostcode(cleanString(postcode))) isValid = false;
    if (context.age >= 24 && !relocate.find(item => item.selected === true)) isValid = false;

    return isValid;
  };

  const getCourses = () => {
    let courses = [];
    // eslint-disable-next-line no-unused-vars
    for (const item of course) {
      if (item.value !== "") courses.push(`${item.value}`);
    }
    return courses;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {
      setIsLoading(true);

      const courses = getCourses();

      const formData = createSubmitData({
        course_details: {
          other_qualifications: courses,
          other_course_location: courseLocation.value,
          postcode: cleanString(postcode),
          open_to_relocating: (context.age >= 24 ? relocate.find(a => a.selected === true).value : null),
        },
        user_id: user_id,
      });
      axios.post('/api/v1/handleOtherCourseInput', formData).then((res) => {
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('field-errors')) {
              // eslint-disable-next-line no-unused-vars
              for (const item of error['field-errors']) {
                if (item.hasOwnProperty('postcode')) {
                  const postcodeErr = item.postcode.reduce((i, err) => (err + ''), '');
                  setPostcodeError(postcodeErr);
                }
              }
            } else {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          dispatch({type: 'updateResponse', data: res.data});
          logEvent('Completed Other Course Input', {
            other_qualifications_incomplete: (courses.length > 0 ? courses : null),
            other_course_location: (courseLocation.value !== "" ? courseLocation.value : null),
            postcode:  cleanString(postcode),
            open_to_relocating: (context.age >= 24 ? relocate.find(a => a.selected === true).value : null),
          });
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }
      })
    }
  };

  if (loading || isLoading) return <LoadingScreen />

  const courses = getCourses();

  return (
    <>
      <MetaTags>
        <title>Course</title>
        <meta id="meta-description" name="description" content="Input your course information" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Your course"
        subtitle="This will help us build a personalised pathway for you into your careers of interest."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <LabelSpaced>What course are you studying?</LabelSpaced>
                <HintText>You can enter multiple courses if applicable.</HintText>
                {course.map(item => (
                  <MultiInputWrap key={item.id}>
                    <Input
                      placeholder={item.placeholder}
                      id={item.id}
                      handleChange={(it) => handleCourseChange(it, item.id)}
                    />
                  </MultiInputWrap>
                ))}
              </InputWrap>
              <InputWrap>
                <Label>Where are you studying?</Label>
                <Input
                  placeholder={courseLocation.placeholder}
                  handleChange={(it) => handleCourseLocationChange(it)}
                />
              </InputWrap>
              <InputWrap>
                <Input
                  onBlur={checkPostcode}
                  type="text"
                  label="What's your postcode?"
                  handleChange={value => {setPostcode(value.toUpperCase()); setPostcodeError(null)}}
                  valueFormatting={valueFormatting}
                  error={postcodeError}
                  placeholder="SW1A 2AA"
                />
              </InputWrap>
              {context.age >= 24 && (
                <InputWrap>
                  <Label>Are you open to relocating?</Label>
                  <Multiselect
                    values={relocate}
                    handleChange={handleRelocateChange}
                    name=""
                  />
                </InputWrap>
              )}
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude userProperties={{
                    other_qualifications_incomplete: (courses.length > 0 ? courses : null),
                    other_course_location: (courseLocation.value !== "" ? courseLocation.value : null),
                    postcode: postcode,
                    open_to_relocating: (context.age >= 24 ? relocate.find(a => a.selected === true).value : null),
                  }}>
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)} data-testid="next">Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <Tooltip text="Please enter your course and location details above">
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>

            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default OtherCourseScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const MultiInputWrap = styled.div`
  margin-bottom: 12px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const LabelSpaced = styled(Label)`
  margin-top: 32px;
`;
