import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Logo from '../assets/logo/careersPro_logo_transparent_S.svg';
import LogoLarge from '../assets/logo/careersPro_logo_transparent_S.svg';
import layout from '../config/layout';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';


const Header = ({ logo_alignment, large }) => {
  const logo_url = large ? LogoLarge : Logo;
  return (
    <Container centered={logo_alignment !== 'left'}>
      <Grid>
        <Row>
          <StyledCol xs={12} centered={logo_alignment !== 'left'}>
            <Link to="/">
              <LogoDesktop src={logo_url} alt="CareersPro Logo" />
              <MobileLogo src={logo_url} alt="CareersPro Logo" />
            </Link>
          </StyledCol>
        </Row>
      </Grid>
    </Container>
  );
};

export default Header;

Header.propTypes = {
  logo_alignment: PropTypes.oneOf(['left', 'center']),
  large: PropTypes.bool,
};

Header.defaultProps = {
  logo_alignment: 'left'
};

const Container = styled.div`
  padding: ${props => props.centered ? '20' : '26'}px 0;
`;

const StyledCol = styled(Col)`
  text-align: ${props => props.centered ? 'center' : 'left'};
`;

const MobileLogo = styled.img`
  @media(min-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`;

const LogoDesktop = styled.img`
  @media(max-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`;
