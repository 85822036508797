import { valueFormatting } from './formatting';

export function validateEmail(email) {

  var emailTrimmed = email.trim();
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(emailTrimmed).toLowerCase());

}

export function validateEmailDomain(emailDomain) {

  var emailDomainTrimmed = emailDomain.trim().replace(/@/g, "");
  // eslint-disable-next-line
  var re = /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(emailDomainTrimmed).toLowerCase());

}

export function doesEmailDomainMatch (email, emailDomains) {
  const emailLowerCase = email.toLowerCase();
  let match = false;
  if (emailDomains) {
    for (let i=0; i<emailDomains.length; i++) {
      if (validateEmailDomain(emailDomains[i]) && emailLowerCase.includes(emailDomains[i])) {
        match = true;
      }
    }
  }
  return match;
}

export const validatePhone = (phone) => {

  var phoneTrimmed = phone.trim();

  var re = /^(?:0|\+?44)(?:\d\s?){9,10}$/;

  return re.test(String(phoneTrimmed).toLowerCase());

}

export const validatePostcode = (value) => {
  const regexp = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
  return regexp.test(value);
};

export const validateAge = (age) => {
  let error = null;
  const regex = /^[1-9]\d*$/g ;
  if (!regex.test(age)) {
    error = 'This is not a valid number';
  } else if (parseInt(age) < 9) {
    error = 'You must be at least 9 years old';
  } else if (parseInt(age) >  120) {
    error = 'This is not a valid age';
  }

  return error;
};

export const validateSalary = (salary) => {
  const salary_amount = valueFormatting(salary, false);
  if (salary_amount === "" || salary_amount > 100000000) {
    return "This is not a valid salary";
  } else if (salary_amount < 1000) {
    return "Please enter your annual salary";
  }
};
