/* Screen to enable internal users to generate free access links for individuals */

import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import axios from 'axios';

import layout from '../config/layout';
import { createSubmitData } from '../utils/helper';

import ConfettiLayout from '../ui/layout/ConfettiLayout';
import ThreeDotsBgLayout from '../ui/layout/ThreeDotsBgLayout';
import LoadingScreen from './LoadingScreen';
import { H3 } from '../ui/typography/Typography';
import { PrimaryButton } from '../ui/buttons/Buttons';
import Input from '../ui/form/Input'

const PaymentScreen = () => {

  const [ isLoading, setIsLoading ]= useState(false);

  const [ formState, setFormState ] = useState({
    first_name: "",
    last_name: "",
    email: "",
    inviter_email: "",
  });

  const [ link, setLink ] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const submitData = createSubmitData({
      contact_details: {
        first_name: formState.first_name,
        last_name: formState.last_name,
        email: formState.email,
      },
      inviter_email: formState.inviter_email,
    });
    axios.post('/api/v1/generateAccessLink', submitData).then((res) => {
      setLink(res.data.link);
      setIsLoading(false);
    });
  };

  const handleInputChange = (value, name) => {
    setFormState({
      ...formState,
      [name]: value,
    })
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <MetaTags>
        <title>Generate access link</title>
        <meta id="meta-description" name="description" content="Generate access link" />
      </MetaTags>
      {link ? (
        <ConfettiLayout
          title="Access link generated"
          hasConfetti={true}
        >
          <LinkWrap><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></LinkWrap>
            <CopyLinkButtonWrap>
              <PrimaryButton onClick={() => {navigator.clipboard.writeText(link)}}>Copy link</PrimaryButton>
            </CopyLinkButtonWrap>
        </ConfettiLayout>
      ) : (
        <ThreeDotsBgLayout>
          <Title align="center">Generate access link</Title>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <InputWrap>
              <Input label="First name (optional)" type="text" name="first_name" handleChange={e => handleInputChange(e, 'first_name')} />
            </InputWrap>
            <InputWrap>
              <Input label="Last name (optional)" type="text" name="last_name" handleChange={e => handleInputChange(e, 'last_name')} />
            </InputWrap>
            <InputWrap>
              <Input label="Email (optional)" type="text" name="email" handleChange={e => handleInputChange(e, 'email')} />
            </InputWrap>
            <InputWrap>
              <Input label="Your email (inviter)" type="text" name="email" handleChange={e => handleInputChange(e, 'inviter_email')} />
            </InputWrap>
            <ButtonWrap>
              <PrimaryButton>Generate link</PrimaryButton>
            </ButtonWrap>
          </Form>
        </ThreeDotsBgLayout>
      )}
    </>
  );
};

export default PaymentScreen;

const Title = styled(H3)`
  margin-bottom: 32px;
  margin-top: 46px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 12px;
  }
`;

const Form = styled.form`
  max-width: 352px;
  margin: auto;
  position: relative;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;

const LinkWrap = styled.div`
  text-align: center;
  padding: 20px;
  white-space: pre-wrap; /* CSS3 */    
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */    
  white-space: -o-pre-wrap; /* Opera 7 */    
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

const CopyLinkButtonWrap = styled.div`
  text-align: center;
`;
