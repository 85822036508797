/* Homepage > Get started > In education at College > College screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { cleanStringForComparison, createSubmitData } from '../../utils/helper';
import { copyArray } from '../../utils/copyArray';
import { getNameByID, getNamesByIDs } from '../../utils/getNamesByIDs';
import { useHistory } from 'react-router-dom';
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Multiselect from '../../components/multiselect/Multiselect';
import { Label, HintText, Error } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import AutocompleteField from '../../ui/form/AutocompleteField';

const CollegeScreen = () => {

  const [{ user_id, context, loading }, dispatch] = useStateValue();

  const [ studyingAlevels, setStudyingAlevels ] = useState([
    { name: 'A-levels', selected: false, id: 0, value: true },
    { name: 'Vocational course', selected: false, id: 1, value: false }
  ]);

  const [ studyingVocational, setStudyingVocational ] = useState([
    { name: 'Vocational course', selected: false, id: 0, value: false }
  ]);

  const [ yearOfStudy, setYearOfStudy ] = useState([
    { name: 'First year', selected: false, id: 0, value: 1 },
    { name: 'Second year', selected: false, id: 1, value: 2 }
  ]);

  let subjectValues = [
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. English Literature", id: 1, error: null },
    { value: "", placeholder: "e.g. Chemistry", id: 2, error: null },
    { value: "", placeholder: "e.g. Psychology", id: 3, error: null }
  ];
  const [ subjects, setSubjects ] = useState(subjectValues);
  const [ alevels, setAlevels ] = useState(null);

  const [vocationalQualifications, setVocationalQualifications] = useState([
    {id: 0, value: "", placeholder: "e.g. BTEC in Business"},
    {id: 1, value: "", placeholder: "e.g. NVQ in Social Care"},
    {id: 2, value: "", placeholder: ""},
  ]);
  const [ otherQualifications, setOtherQualifications ] = useState(null);

  const [ college, setCollege ] = useState({value: "", placeholder: "Your college name", error: null});
  const [ collegeID, setCollegeID ] = useState(null);

  const [ relocate, setRelocate ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false }
  ]);

  const [ goToUniversity, setGoToUniversity ] = useState([
    { name: 'Yes', selected: false, id: 0, value: "yes" },
    { name: 'Maybe', selected: false, id: 1, value: "maybe" },
    { name: 'No', selected: false, id: 2, value: "no" }
  ]);

  const [ generalError, setGeneralError ] = useState();

  const [ isLoading, setIsLoading ] = useState([]);
  const [ loadingText, setLoadingText ] = useState();

  const history = useHistory();
  const match = useRouteMatch("/:userId/college");

  useGetScreenData('college', setIsLoading, match.params.userId);

  useEffect(() => {
    if (context && context.education_institution_id !== null) {
      setCollegeID(context.education_institution_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleStudyingAlevelsChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setStudyingAlevels(values);
    setStudyingVocational(values);

    // Any change should nullify alevels and vocational courses
    setAlevels(null);
    setOtherQualifications(null);
  };

  const handleYearOfStudyChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setYearOfStudy(values);
  };

  const onSubjectFieldBlur = (item, id) => {
    const val = context.alevel_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(subjects);
      if (item !== "" || id === 0) {
        copy[id].error = "This is not a valid subject";
      }
      setSubjects(copy);
    }
  };

  const handleSubjectInputFieldChange = (value, id) => {
    let copy = copyArray(subjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }
    setSubjects(copy);
    prepData(copy, vocationalQualifications, college);
  };

  const handleVocationalQualificationsChange = (val, id) => {
    let vqCopy = copyArray(vocationalQualifications);

    vqCopy[id].value = val.name || val;

    if (id === (vocationalQualifications.length-1)) {
      vqCopy.push({value: "", id: vocationalQualifications.length});
    }

    setVocationalQualifications(vqCopy);
    prepData(subjects, vqCopy, college);
  };

  const onCollegeFieldBlur = (item) => {
    const val = context.pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(college);
      copy.error = "This is not a valid college";
      setCollege(copy);
    }
  };

  const handleCollegeInputFieldChange = (value) => {
    let copy = copyArray(college);

    copy.error = null;
    copy.value = value.name || value;

    setCollege(copy);
    prepData(subjects, vocationalQualifications, copy);
  };

  const handleRelocateChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setRelocate(values);
  };

  const handleGoToUniversityChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setGoToUniversity(values);
  };

  const prepData = (subjs, vocationalSubjects, userCollege) => {
    let qualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of subjs) {
      if (context.alevel_autocomplete_options) {
        const val = context.alevel_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          qualifications.push({
            id: val.id,
          })
        }
      }
    }
    setAlevels(qualifications);

    let otherQualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of vocationalSubjects) {
      if (subject.value !== "") otherQualifications.push(`${subject.value}`);
    }
    setOtherQualifications(otherQualifications);

    if (!context.education_institution_id && context.pre_university_autocomplete_options) {
      let userCollegeID = null;
      const matchedCollege =
        context.pre_university_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((userCollege.value !== null) ? cleanStringForComparison(userCollege.value.toLowerCase()) : userCollege.value));
      if (matchedCollege) {
        userCollegeID = matchedCollege.id;
      }
      setCollegeID(userCollegeID);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const userStudyingAlevels = studyingAlevels.find(item => item.selected === true);
    const userYearOfStudy = yearOfStudy.find(item => item.selected === true);
    if (!userStudyingAlevels) isValid = false;
    if (userStudyingAlevels && userStudyingAlevels.value === true && !userYearOfStudy) isValid = false;
    if (userStudyingAlevels && userStudyingAlevels.value === true && (!alevels || alevels.length === 0)) isValid = false;
    if (userStudyingAlevels && userStudyingAlevels.value === false && (!otherQualifications || otherQualifications.length === 0)) isValid = false;
    if (!collegeID || collegeID < 0) isValid = false;
    if (userStudyingAlevels && userStudyingAlevels.value === false && context.age >= 24 && !relocate.find(item => item.selected === true)) isValid = false;
    if (userStudyingAlevels && userStudyingAlevels.value === true && !goToUniversity.find(item => item.selected === true)) isValid = false;
    return isValid;
  };

  const submitForm = (skip, logEvent) => {
    if (skip) {
      setIsLoading(true);
      const formData = createSubmitData({
        college_details: {
          studying_alevels: null,
          year_of_study: null,
          qualifications: [],
          college: collegeID,
          open_to_relocating: null,
          aspire_degree: null,
        },
        user_id: user_id,
        skipped: true,
      });
      axios.post('/api/v1/handleCollegeInput', formData).then((res) => {
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('non-field-errors')) {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          dispatch({type: 'updateResponse', data: res.data});
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }
      });
    } else if (validateForm()) {
      const userStudyingAlevels = studyingAlevels.find(item => item.selected === true);
      const userYearOfStudy = yearOfStudy.find(item => item.selected === true);

      if (context.age < 24 && userStudyingAlevels.value === true && goToUniversity.find(item => item.selected === true).value === "yes") {
        // No longer needed as we ask for work experience before the career quiz for all college users now
        // setLoadingText(context.loading_message);
        setLoadingText(null);
      }
      setIsLoading(true);

      const formData = createSubmitData({
        college_details: {
          studying_alevels: userStudyingAlevels.value,
          year_of_study: (userStudyingAlevels.value === true) ? userYearOfStudy.value : null,
          qualifications: (userStudyingAlevels.value === true) ? alevels : otherQualifications,
          college: collegeID,
          open_to_relocating: (userStudyingAlevels.value === false && context.age >= 24) ? relocate.find(a => a.selected === true).value : null,
          aspire_degree: (userStudyingAlevels.value === true) ? goToUniversity.find(item => item.selected === true).value : null,
        },
        user_id: user_id,
      });
      axios.post('/api/v1/handleCollegeInput', formData).then((res) => {
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('non-field-errors')) {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          dispatch({type: 'updateResponse', data: res.data});
          logEvent('Completed College Input', {
            studying_alevels: userStudyingAlevels.value,
            year_of_study: (userStudyingAlevels.value === true) ? userYearOfStudy.value : null,
            school_year: (userStudyingAlevels.value === true) ? ( (userYearOfStudy.value === 1) ? 12 : 13 ) : null,
            alevels: (userStudyingAlevels.value === true) ? getNamesByIDs(alevels, context.alevel_autocomplete_options, "id") : null,
            other_qualifications_incomplete: (userStudyingAlevels.value === false) ? otherQualifications : null,
            alevel_school: (userStudyingAlevels.value === true) ? getNameByID(collegeID, context.pre_university_autocomplete_options) : null,
            college: getNameByID(collegeID, context.pre_university_autocomplete_options),
            open_to_relocating: (userStudyingAlevels.value === false && context.age >= 24) ? relocate.find(a => a.selected === true).value : null,
            aspire_degree: (userStudyingAlevels.value === true) ? goToUniversity.find(item => item.selected === true).value : null,
          });
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }
      });
    }
  };

  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  const userStudyingAlevels = studyingAlevels.find(a => a.selected === true);
  const userYearOfStudy = yearOfStudy.find(item => item.selected === true);

  return (
    <>
      <MetaTags>
        <title>Your current learning</title>
        <meta id="meta-description" name="description" content="Input your current learning information" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Your current learning"
        subtitle="This will help us build a personalised pathway for you into your careers of interest."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              {context.is_lc_org === true && (
                <>
                  <InputWrap>
                    <Label>What are you studying at Learning Curve Group?</Label>
                    <Multiselect
                      values={studyingVocational}
                      handleChange={handleStudyingAlevelsChange}
                      name=""
                    />
                  </InputWrap> 
                </>  
              )}
              {context.is_lc_org === false && (
                <>
                  <InputWrap>
                    <Label>What are you studying at college?</Label>
                    <Multiselect
                      values={studyingAlevels}
                      handleChange={handleStudyingAlevelsChange}
                      name=""
                    />
                  </InputWrap>
                </>  
              )}
              {userStudyingAlevels && userStudyingAlevels.value === true && (
                <>
                  <InputWrap>
                    <Label>What year of study are you in?</Label>
                    <Multiselect
                      values={yearOfStudy}
                      handleChange={handleYearOfStudyChange}
                      name=""
                    />
                  </InputWrap>
                  <InputWrap>
                    <LabelNarrow>What subjects are you studying for your A-levels?</LabelNarrow>
                    <HintText>Type a subject and select from available options. If you cannot find a suitable subject simply leave it blank.</HintText>
                    {subjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.alevel_autocomplete_options}
                          handleChange={it => handleSubjectInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onSubjectFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                  </InputWrap>
                </>
              )}
              {userStudyingAlevels && userStudyingAlevels.value === false && (
                <InputWrap>
                  <LabelSpaced>What course are you studying?</LabelSpaced>
                  <HintText>You can enter multiple courses if applicable.</HintText>
                  {vocationalQualifications.map(item => (
                    <MultiInputWrap key={item.id}>
                      <Input
                        label={item.label}
                        hint={item.hint}
                        placeholder={item.placeholder}
                        id={item.id}
                        handleChange={(it) => handleVocationalQualificationsChange(it, item.id)}
                        error={item.error}
                      />
                    </MultiInputWrap>
                  ))}
                </InputWrap>
              )}
              {!context.education_institution_id && (
                <InputWrap>
                  <LabelSpaced>What college do you go to?</LabelSpaced>
                  <HintText>Type, then select from available options.</HintText>
                  <AutocompleteField
                    placeholder={college.placeholder}
                    suggestions={context.pre_university_autocomplete_options}
                    handleChange={it => handleCollegeInputFieldChange(it)}
                    error={college.error}
                    onBlur={e => onCollegeFieldBlur(e.target.value)}
                  />
                </InputWrap>
              )}
              {userStudyingAlevels && userStudyingAlevels.value === false && context.age >= 24 && (
                <InputWrap>
                  <Label>Are you open to relocating?</Label>
                  <Multiselect
                    values={relocate}
                    handleChange={handleRelocateChange}
                    name=""
                  />
                </InputWrap>
              )}
              {userStudyingAlevels && userStudyingAlevels.value === true && (
                <InputWrap>
                  <Label>Do you plan to go to university?</Label>
                  <Multiselect
                    values={goToUniversity}
                    handleChange={handleGoToUniversityChange}
                    name=""
                  />
                </InputWrap>
              )}
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude userProperties={{
                    studying_alevels: userStudyingAlevels.value,
                    year_of_study: (userStudyingAlevels.value === true) ? userYearOfStudy.value : null,
                    school_year: (userStudyingAlevels.value === true) ? ( (userYearOfStudy.value === 1) ? 12 : 13 ) : null,
                    alevels: (userStudyingAlevels.value === true) ? getNamesByIDs(alevels, context.alevel_autocomplete_options, "id") : null,
                    other_qualifications_incomplete: (userStudyingAlevels.value === false) ? otherQualifications : null,
                    alevel_school: (userStudyingAlevels.value === true) ? getNameByID(collegeID, context.pre_university_autocomplete_options) : null,
                    college: getNameByID(collegeID, context.pre_university_autocomplete_options),
                    open_to_relocating: (userStudyingAlevels.value === false && context.age >= 24) ? relocate.find(a => a.selected === true).value : null,
                    aspire_degree: (userStudyingAlevels.value === true) ? goToUniversity.find(item => item.selected === true).value : null,
                  }}>
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(false, logEvent)}>Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <Tooltip text="Please enter your college and course details above">
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
              {context.education_institution_id && (
                <ButtonWrap>
                  <PrimaryButton onClick={() => submitForm(true, null)}>Skip</PrimaryButton>
                </ButtonWrap>
              )}
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default CollegeScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 392px;
`;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const MultiInputWrap = styled.div`
  margin-bottom: 12px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const LabelNarrow = styled(Label)`
  width: 300px;
`;

const AutocompleteWrap = styled.div`
  margin-bottom: 12px;
`;

const LabelSpaced = styled(Label)`
  margin-top: 32px;
`;
