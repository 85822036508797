import React from 'react';
import styled from 'styled-components';
import { CircleRed, CirclePurple, ShapeGreenUp, ShapeLCGPurpleDown } from './Shapes';
import layout from '../../config/layout';

const BgTwoDotsTwoShapes = () => {
  return (
    <>
      <ShapeRed />
      <ShapePurple />
      <ShapeGreen />
      <ShapeLCGPurple />
    </>
  );
};

export default BgTwoDotsTwoShapes;

const ShapeRed = styled(CircleRed)`
  top: -34px;
  left: -42px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: auto;
    right: -102px;
    width: 115px;
    top: 180px;
  }
`;

const ShapePurple = styled(CirclePurple)`
  bottom: 39px;
  right: 160px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 440px;
    right: auto;
    width: 48;
    left: -45px;
    z-index: -1;
  }
`;

const ShapeGreen = styled(ShapeGreenUp)`
  bottom: 0;
  left: -152px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 628px;
    bottom: -203px;
    left: -438px;
  }
`;

const ShapeLCGPurple = styled(ShapeLCGPurpleDown)`
  top: 120px;
  right: -164px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 460px;
    right: -320px;
    top: -35px;
  }
`;
