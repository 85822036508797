/* Ratings (feedback) modal (ref. FeedbackModal.js), then select a star-rating and this section
   will appear underneath */

import React from 'react';
import axios from 'axios';
import { Amplitude } from "@amplitude/react-amplitude";

import { useStateValue } from "../../services/state";
import { createSubmitData } from '../../utils/helper';

import ResultsFeedback from '../../components/ResultsFeedback';


const Feedback = ({ hasPathways, closeModal }) => {
  const [{ user_id}] = useStateValue();

  const submitFeedback = (results, logEvent) => {
    if (results.feedback !== "") {
      logEvent('Submitted Feedback', {
        feedback: results.feedback,
        rating: results.stars,
      });
      const submitData = createSubmitData({
        user_id: user_id,
        feedback: results.feedback,
        rating: results.stars,
        permission_to_share: results.permission_to_share,
      });
      axios.post('/api/v1/handleFeedbackInput', submitData);
    }
    closeModal();
  };

  return (
    <Amplitude>
      {({ logEvent }) => (
        <ResultsFeedback
          title={`How useful are your results${hasPathways ? ' and pathways' : ''}?`}
          textAreaTitle="Your feedback helps us improve – what could we do better?"
          placeholder="Any feedback you may provide is appreciated..."
          buttonLabel="Submit feedback"
          handleSubmit={result => submitFeedback(result, logEvent)}
        />
      )}
    </Amplitude>
  );
};

export default Feedback;
