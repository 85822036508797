import styled from 'styled-components';
import colors from '../../config/colors';

export const Tag = styled.div`
  background-color: ${props => props.bgColor ? props.bgColor : colors.LCG_DARK_BLUE_FADE};
  border-radius: 4px;
  color: ${props => props.color ? props.color : colors.LCG_DARK_BLUE};
  display: inline-block;
  ${props => !props.dynamic_font  && `font-size: 13px;`};
  line-height: 18px;
  margin: 2px;
  padding: 1px 4px;
  font-weight: 700;
  text-transform: uppercase;
`;
