import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PSmall } from '../ui/typography/Typography';
import colors from '../config/colors';
import layout from '../config/layout';
import { Tag } from '../ui/tag/Tag';

const PathwaysInfo = ({ user_id, pathwaysSummary, pathway, sortType, light }) => {

  // Get the pathway we should show

  let pathwayTitle = null;
  let pathwayIDToShow = null;
  let pathwaySummaryToShow = null;

  if (pathwaysSummary && sortType) {
    if (sortType === "time") {
      pathwayTitle = "Shortest pathway";
      pathwayIDToShow = pathwaysSummary.shortest;
    } else if (sortType === "cost") {
      pathwayTitle = "Cheapest pathway";
      pathwayIDToShow = pathwaysSummary.cheapest;
    } else if (sortType === "salary") {
      pathwayTitle = "Highest salary pathway";
      pathwayIDToShow = pathwaysSummary.highest_salary;
    } else if (sortType === "skills" || sortType === "jobs") {
      pathwayTitle = "Shortest pathway";
      pathwayIDToShow = pathwaysSummary.shortest;
    }
  }

  if (pathwaysSummary) {
    for (let i=0; i<pathwaysSummary.pathways.length; i++) {
      if (pathwaysSummary.pathways[i].id === pathwayIDToShow) {
        pathwaySummaryToShow = pathwaysSummary.pathways[i];
      }
    }
  } else if (pathway) {
    pathwaySummaryToShow = pathway;
  }

  let keyDetailIndex = 0;

  return (
    <Container>
      {pathwaySummaryToShow && (
        <>
          {!light && pathwayTitle && (<Title bold align="center">{pathwayTitle}</Title>)}
          {!light && pathwaySummaryToShow.labels.length > 0 && (
            <TagWrap>
              {pathwaySummaryToShow.labels.map(label => (
                <Tag key={label.key} color={colors.LCG_DARK_PURPLE} bgColor={colors.LCG_DARK_PURPLE_FADE}>{label.name}</Tag>
              ))}
          </TagWrap>
          )}
          <ItemContainer>
            <ItemRow>
              {pathwaySummaryToShow.time && user_id && (<ItemTitle align="center">Time</ItemTitle>)}
              {pathwaySummaryToShow.cost && (<ItemTitle align="center">Cost</ItemTitle>)}
              {pathwaySummaryToShow.starting_salary && (<ItemTitle align="center">Starting salary</ItemTitle>)}
            </ItemRow>
            <ItemRow>
              {pathwaySummaryToShow.time && user_id && (<ItemBody color={colors.TEXT_DARK}>{pathwaySummaryToShow.time}</ItemBody>)}
              {pathwaySummaryToShow.cost && (<ItemBody color={colors.TEXT_DARK}>{pathwaySummaryToShow.cost}</ItemBody>)}
              {pathwaySummaryToShow.starting_salary && (<ItemBody color={colors.TEXT_DARK}>{pathwaySummaryToShow.starting_salary}</ItemBody>)}
            </ItemRow>
          </ItemContainer>
          {!light && (
            <KeyDetailsContainer>
              {pathwaySummaryToShow.key_details_array.map(keyDetail => (
                <KeyDetail key={keyDetailIndex++}>{keyDetail}</KeyDetail>
              ))}
            </KeyDetailsContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default PathwaysInfo;

PathwaysInfo.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  cost: PropTypes.string,
  funding: PropTypes.string,
};

const Container = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 60%;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 90%;
  }
`;

const ItemRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemContents = styled(PSmall)`
  display: flex;
  text-align: center;
  display: inline-block;
  flex: 1;
  padding: 4px 0px 5px 0px;
  line-height: 1;
  margin-bottom: 8px;
`;

const ItemTitle = styled(ItemContents)`
  margin-bottom: 0;
`;

const ItemBody = styled(ItemContents)`
  color: ${colors.TEXT_DARK};
`;

const Title = styled(PSmall)`
  width: 100%;
  margin-bottom: 4px;
  color: ${colors.TEXT_DARK};
`;

const TagWrap = styled.div`
  text-align: center;
  margin-bottom: 6px;
`;

const KeyDetailsContainer = styled.div`
  padding-bottom: 16px;
`;

const KeyDetail = styled(PSmall)`
  color: ${colors.TEXT_DARK};
  font-style: italic;
  text-align: center;
  margin-bottom: 2px;
`;
