import React from 'react';
import styled from 'styled-components';
import { CircleRed, ShapeLCGPurpleUp, CirclePurple } from './Shapes';
import layout from '../../config/layout';

const BgTwoDotsLCGPurpleLine = ({ noRedCircle }) => {
  return (
    <>
      {!noRedCircle && (<ShapeRed />)}
      <ShapePurple />
      <ShapeLCGPurple />
    </>
  );
};

export default BgTwoDotsLCGPurpleLine;

export const ShapeRed = styled(CircleRed)`
  top: 500px;
  right: -58px;

  @media(max-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`;

export const ShapePurple = styled(CirclePurple)`
  top: 150px;
  left: -33px;

  @media(max-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`;

export const ShapeLCGPurple = styled(ShapeLCGPurpleUp)`
  top: 20px;
  right: -20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 306px;
    right: -70px;
    top: -10px;
  }
`;
