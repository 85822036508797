// Breakpoints
const VSM = 350;
const SM = 768;
const MD = 1024;
const LG = 1200;
const XL = 1440;

const layout = {
  breakpoints: {
    VSM: `${VSM}px`,
    SM: `${SM}px`,
    MD: `${MD}px`,
    LG: `${LG}px`,
    XL: `${XL}px`
  },
  numericalBreakpoints: {
    VSM,
    SM,
    MD,
    LG,
    XL
  }
};

export default layout;
