import React from 'react';
import styled from 'styled-components';
import { LineBlue, LineDarkBlue, ShapeGreenDown, ShapeGreenUp, ShapePurpleDown, ShapePurple, ShapePurpleFlat, LineRed } from './Shapes';
import layout from '../../config/layout';

const BgBlueLineGreenLine = () => {
  return (
    <>
      <ShapeBlue />
      <ShapeGreen />
    </>
  );
};

export default BgBlueLineGreenLine;

export const BgBlueLine = () => {
  return (
    <ShapeBlue />
  );
};

export const BgDarkBlueLine = () => {
  return (
    <ShapeDarkBlue />
  );
};

export const BgGreenLine = () => {
  return (
    <ShapeGreen />
  );
};

export const BgGreenLine2 = () => {
  return (
    <ShapeGreen2 />
  );
};

export const BgPurpleLine = () => {
  return (
    <ShapePurple1 />
  );
};

export const BgPurpleLine2 = () => {
  return (
    <ShapePurple2 />
  );
};

export const BgPurpleLine3 = () => {
  return (
    <ShapePurple3 />
  );
};

export const BgRedLine = () => {
  return (
    <ShapeRed />
  );
};

const ShapeBlue = styled(LineBlue)`
  top: 30px;
  left: 50%;
  margin-left: -800px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 444px;
    left: -73px;
    margin-left: 0;
    top: -30px;
  }
`;

const ShapeDarkBlue = styled(LineDarkBlue)`
  top: 30px;
  left: 50%;
  margin-left: -800px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 444px;
    left: -73px;
    margin-left: 0;
    top: -5px;
  }
`;

const ShapeGreen = styled(ShapeGreenDown)`
  top: -70px;
  left: -50px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: -120px;
    width: 417px;
    left: -50px;
  }
`;

const ShapeGreen2 = styled(ShapeGreenUp)`
  top: 620px;
  left: 0px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: -120px;
    width: 417px;
    right: -136px;
  }
`;

const ShapePurple1 = styled(ShapePurpleDown)`
  top: -60px;
  left: -120px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 417px;
    left: -96px;
  }
`;

const ShapePurple2 = styled(ShapePurpleFlat)`
  top: -30px;
  right: -120px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 417px;
    top: -60px;
    right: -96px;
  }
`;

const ShapePurple3 = styled(ShapePurple)`
  top: -50px;
  right: -120px;
  z-index: -1;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 417px;
    right: -96px;
    top: -80px;
  }
`;

const ShapeRed = styled(LineRed)`
  top: 10px;
  right: -20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    width: 306px;
    right: -70px;
    top: -20px;
  }
`;
