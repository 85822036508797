import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from '../config/colors';
import { PSmall, H5 } from '../ui/typography/Typography';
import layout from'../config/layout';

const QuestionnaireCard = ({ title, description, handleClick, firstLoad, testid }) => {
  return (
    <Container onClick={handleClick} firstLoad={firstLoad} data-testid={testid} id={testid}>
      <H5Styled align="center" color={colors.LCG_DARK_GREY}>{title}</H5Styled>
      <Description align="center" color={colors.GRAY}>{description}</Description>
    </Container>
  );
};

export default QuestionnaireCard;

QuestionnaireCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  firstLoad: PropTypes.bool,
  testid: PropTypes.string,
};

const animateCards = keyframes`
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
`;


const Container = styled.button`
  display: block;
  padding: 32px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  border-radius: 20px;
  background-color: ${colors.WHITE};
  border: 0;
  animation: ${props => props.firstLoad ? animateCards : 'none'} 0.32s forwards;

  &:hover {
    box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_PURPLE}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  }

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 20px;
  }

  &:active {
    background-color: ${colors.BG_GRAY};
  }
`;

const H5Styled = styled(H5)`
  margin-bottom: 8px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-bottom: 4px;
  }
`;

const Description = styled(PSmall)`
  margin-bottom: 0;
`;
