/* Education screen > Masters & PhD tabs
   Ref. EducationScreen.js
   May need to select "Yes" for "Do you have a degree?" to see the Masters & PhD tabs */

import React, { useState } from 'react';
import styled from 'styled-components';

import { copyArray } from '../../utils/copyArray';
import { cleanStringForComparison } from '../../utils/helper';

import AutocompleteField from '../../ui/form/AutocompleteField';
import { Label, HintText } from '../../ui/typography/Typography';
import Checkbox from '../../ui/form/Checkbox';

const MastersPhd = ({
  handleChange,
  optionsOne,
  optionsTwo,
  optionsThree,
  university_autocomplete_options,
  subjectValuesOne,
  subjectValuesTwo,
  subjectValuesThree,
  labelOne,
  hintOne,
  labelTwo,
  hintTwo,
  labelThree,
  hintThree,
  whereLabelOne,
  whereLabelTwo,
  whereLabelThree,
  noAdd
}) => {

  const [subjectsOne, setSubjectsOne] = useState(subjectValuesOne);
  const [subjectsTwo, setSubjectsTwo] = useState(subjectValuesTwo);
  const [subjectsThree, setSubjectsThree] = useState(subjectValuesThree);

  const [universityOne, setUniversityOne] = useState({value: "", placeholder: "Your university name", error: null, university_outside_uk: false});
  const [universityTwo, setUniversityTwo] = useState({value: "", placeholder: "Your university name", error: null, university_outside_uk: false});
  const [universityThree, setUniversityThree] = useState({value: "", placeholder: "Your university name", error: null, university_outside_uk: false});

  const onFieldBlur = (item, id, type) => {
    let options = {1: optionsOne, 2: optionsTwo, 3: optionsThree};
    let subjects = {1: subjectsOne, 2: subjectsTwo, 3: subjectsThree};
    const val = options[type].strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(subjects[type]);
      if (item !== "" || (id === 0 && type === 1)) {
        copy[id].error = "This is not a valid subject";
      }

      type === 1 && setSubjectsOne(copy);
      type === 2 && setSubjectsTwo(copy);
      type === 3 && setSubjectsThree(copy);
    }
  };

  const onUniversityFieldBlur = (item, type) => {
    let userUniversities = {1: universityOne, 2: universityTwo, 3: universityThree};
    const val = university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(userUniversities[type]);
      copy.error = "This is not a valid university";

      type === 1 && setUniversityOne(copy);
      type === 2 && setUniversityTwo(copy);
      type === 3 && setUniversityThree(copy);
    }
  };

  const handleInputFieldChange = (value, id, type) => {
    let subjects = {1: subjectsOne, 2: subjectsTwo, 3: subjectsThree};
    let copy = copyArray(subjects[type]);
    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1) && (!noAdd || (noAdd && type > 1))) {
      copy.push({ value: "", id: copy.length, error: null })
    }

    type === 1 && setSubjectsOne(copy);
    type === 2 && setSubjectsTwo(copy);
    type === 3 && setSubjectsThree(copy);

    let userUniversities = {1: universityOne, 2: universityTwo, 3: universityThree};
    prepData(copy, userUniversities[type], type);
  };

  const handleUniversityInputFieldChange = (value, type) => {
    let userUniversities = {1: universityOne, 2: universityTwo, 3: universityThree};
    let copy = copyArray(userUniversities[type]);

    copy.error = null;
    copy.value = value.name || value;

    type === 1 && setUniversityOne(copy);
    type === 2 && setUniversityTwo(copy);
    type === 3 && setUniversityThree(copy);

    let subjects = {1: subjectsOne, 2: subjectsTwo, 3: subjectsThree};
    prepData(subjects[type], copy, type);
  };

  const handleUniversityOutsideUKChange = (value, type) => {
    let userUniversities = {1: universityOne, 2: universityTwo, 3: universityThree};
    let copy = copyArray(userUniversities[type]);

    copy.university_outside_uk = value;

    type === 1 && setUniversityOne(copy);
    type === 2 && setUniversityTwo(copy);
    type === 3 && setUniversityThree(copy);

    let subjects = {1: subjectsOne, 2: subjectsTwo, 3: subjectsThree};
    prepData(subjects[type], copy, type);
  };

  const prepData = (subjs, userUniversity, type) => {
    const subjsOne = type === 1 ? subjs : subjectsOne;
    const subjsTwo = type === 2 ? subjs : subjectsTwo;

    let qualificationsOne = getQualifications(subjsOne, optionsOne);
    let qualificationsTwo = getQualifications(subjsTwo, optionsTwo);

    let userUniversityOne = type === 1 ? userUniversity : universityOne;
    let userUniversityTwo = type === 2 ? userUniversity : universityTwo;

    let userUniversityIDOne = getUniversityID(userUniversityOne, university_autocomplete_options);
    let userUniversityIDTwo = getUniversityID(userUniversityTwo, university_autocomplete_options);

    if (subjectValuesThree) {
      const subjsThree = type === 3 ? subjs : subjectsThree;
      let qualificationsThree = getQualifications(subjsThree, optionsThree);
      let userUniversityThree = type === 3 ? userUniversity : universityThree;
      let userUniversityIDThree = getUniversityID(userUniversityThree, university_autocomplete_options);
      handleChange(qualificationsOne, userUniversityIDOne, qualificationsTwo, userUniversityIDTwo, qualificationsThree, userUniversityIDThree);
    } else {
      handleChange(null, null, qualificationsOne, userUniversityIDOne, qualificationsTwo, userUniversityIDTwo);
    }
  };

  const getQualifications = (subjs, options) => {
    let qualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of subjs) {
      if (options) {
        const val = options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));

        if (val) {
          qualifications.push({
            id: val.id,
          })
        } 
      }
    }

    return qualifications
  }

  const getUniversityID = (userUniversity, options) => {
    let universityID = null;
    if (options) {
      const matchedUniversity = options.strings.find(it =>
        cleanStringForComparison(it.name.toLowerCase()) === ((userUniversity.value !== null) ? cleanStringForComparison(userUniversity.value.toLowerCase()) : userUniversity.value));
      if (userUniversity.university_outside_uk) {
        universityID = -1;
      } else if (matchedUniversity) {
        universityID = matchedUniversity.id;
      }
    }
    return universityID;
  }


  return (
    <>
      <Container>
        <Label>{labelOne}</Label>
        <HintText>{hintOne}</HintText>
        {subjectsOne.map(item => (
          <InputWrap key={item.id}>
            <AutocompleteField
              placeholder={item.placeholder}
              suggestions={optionsOne}
              handleChange={it => handleInputFieldChange(it, item.id, 1)}
              error={item.error}
              onBlur={e => onFieldBlur(e.target.value, item.id, 1)}
              id={item.id}
            />
          </InputWrap>
        ))}
      </Container>
      <Container>
        <Label>{whereLabelOne}</Label>
        <HintText>Type, then select from available options.</HintText>
        <InputWrap>
          <AutocompleteField
            placeholder={universityOne.placeholder}
            suggestions={university_autocomplete_options}
            handleChange={it => handleUniversityInputFieldChange(it, 1)}
            error={universityOne.error}
            onBlur={e => onUniversityFieldBlur(e.target.value, 1)}
            disabled={universityOne.university_outside_uk}
          />
        </InputWrap>
        <InputWrap>
          <Checkbox
            handleChange={it => handleUniversityOutsideUKChange(it, 1)}
            label="I studied at a university outside the UK"
            name="university_one_outside_uk"
          />
        </InputWrap>
      </Container>
      <Container>
        <Label>{labelTwo}</Label>
        <HintText>{hintTwo}</HintText>
        {subjectsTwo.map(item => (
          <InputWrap key={item.id}>
            <AutocompleteField
              placeholder={item.placeholder}
              suggestions={optionsOne}
              handleChange={it => handleInputFieldChange(it, item.id, 2)}
              error={item.error}
              onBlur={e => onFieldBlur(e.target.value, item.id, 2)}
              id={item.id}
            />
          </InputWrap>
        ))}
      </Container>
      <Container>
        <Label>{whereLabelTwo}</Label>
        <HintText>Type, then select from available options.</HintText>
        <InputWrap>
          <AutocompleteField
            placeholder={universityTwo.placeholder}
            suggestions={university_autocomplete_options}
            handleChange={it => handleUniversityInputFieldChange(it, 2)}
            error={universityTwo.error}
            onBlur={e => onUniversityFieldBlur(e.target.value, 2)}
            disabled={universityTwo.university_outside_uk}
          />
        </InputWrap>
        <InputWrap>
          <Checkbox
            handleChange={it => handleUniversityOutsideUKChange(it, 2)}
            label="I studied at a university outside the UK"
            name="university_two_outside_uk"
          />
        </InputWrap>
      </Container>
      {subjectValuesThree && whereLabelThree && (
        <>
          <Container>
            <Label>{labelThree}</Label>
            <HintText>{hintThree}</HintText>
            {subjectsThree.map(item => (
              <InputWrap key={item.id}>
                <AutocompleteField
                  placeholder={item.placeholder}
                  suggestions={optionsThree}
                  handleChange={it => handleInputFieldChange(it, item.id, 3)}
                  error={item.error}
                  onBlur={e => onFieldBlur(e.target.value, item.id, 3)}
                  id={item.id}
                />
              </InputWrap>
            ))}
          </Container>
          <Container>
            <Label>{whereLabelThree}</Label>
            <HintText>Type, then select from available options.</HintText>
            <InputWrap>
              <AutocompleteField
                placeholder={universityThree.placeholder}
                suggestions={university_autocomplete_options}
                handleChange={it => handleUniversityInputFieldChange(it, 3)}
                error={universityThree.error}
                onBlur={e => onUniversityFieldBlur(e.target.value, 3)}
                disabled={universityThree.university_outside_uk}
              />
            </InputWrap>
            <InputWrap>
              <Checkbox
                handleChange={it => handleUniversityOutsideUKChange(it, 3)}
                label="I studied at a university outside the UK"
                name="university_three_outside_uk"
              />
            </InputWrap>
          </Container>
        </>
      )}
    </>
  );
};

export default MastersPhd;

const Container = styled.div`
  margin-bottom: 32px;
`;

const InputWrap = styled.div`
  margin-bottom: 12px;
`;
