import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { H3, P, PSmall } from '../../ui/typography/Typography';
import { Tag } from '../../ui/tag/Tag';
import { PrimaryButton } from '../../ui/buttons/Buttons';

const ResultsCard = ({
  imageUrl,
  title,
  subtitle,
  tags,
  description,
  ctaLabel,
  ctaUrl,
  ctaLabelTwo,
  ctaUrlTwo,
  children,
  labelOpen,
  labelClosed,
  handleCtaClick,
  handleCtaTwoClick,
  extraContent
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      {imageUrl && (
        <ImageContainer url={imageUrl}></ImageContainer>
      )}
      <ContentContainer>
        <Title align="center">{title}</Title>
        <PSmall align="center">{subtitle}</PSmall>
        {tags && (
          <TagContainer>
            {tags.map(item => (
              <Tag key={item}>#{item}</Tag>
            ))}
          </TagContainer>
        )}
        {description && (
          <P align="center">{description}</P>
        )}
        {extraContent && extraContent}
        {ctaLabel && (
          <TextCenter>
            {ctaUrl ? (
              <a href={ctaUrl}>
                <PrimaryButton>{ctaLabel}</PrimaryButton>
              </a>
            ) : (
              <PrimaryButton onClick={handleCtaClick}>{ctaLabel}</PrimaryButton>
            )}
          </TextCenter>
        )}

        {ctaLabelTwo && (
          <TextCenterMarginTop>
            {ctaUrlTwo ? (
              <a href={ctaUrl}>
                <PrimaryButton>{ctaLabel}</PrimaryButton>
              </a>
            ) : (
              <PrimaryButton onClick={handleCtaTwoClick}>{ctaLabelTwo}</PrimaryButton>
            )}
          </TextCenterMarginTop>
        )}
      </ContentContainer>
      {children && (
        <>
          <InlayContainer open={open}>{children}</InlayContainer>
          <OpenButton onClick={() => setOpen(!open)}>{open ? labelOpen : labelClosed}</OpenButton>
        </>
      )}
    </Container>
  );
};

export default ResultsCard;

ResultsCard.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tags: PropTypes.array,
  description: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  children: PropTypes.node,
  labelOpen: PropTypes.string.isRequired,
  labelClosed: PropTypes.string.isRequired,
  handleCtaClick: PropTypes.func,
  extraContent: PropTypes.any,
};

ResultsCard.defaultProps = {
  labelOpen: 'Collapse',
  labelClosed: 'Learn more',
};

const Container = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  margin-bottom: 32px;
  background-color: ${colors.WHITE};
`;

const ImageContainer = styled.div`
  background-image: url(${props => props.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 20px 0 0;
  height: 124px;
  overflow: hidden;
  width: 100%;
`;

const Title = styled(H3)`
  margin-bottom: 4px;
`;

const ContentContainer = styled.div`
  padding: 32px;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const TextCenterMarginTop = styled(TextCenter)`
  margin-top: 12px;
`;

const TagContainer = styled(TextCenter)`
  margin-bottom: 16px;
`;

const OpenButton = styled.div`
  border-top: 1px solid ${colors.LCG_LIGHT_GREY};
  color: ${colors.LCG_LIGHT_PURPLE};
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  padding: 12px 32px;
  text-align: center;

  &:hover {
    color: ${colors.LCG_LIGHT_PURPLE_DARK};
  }
`;

const InlayContainer = styled.div`
  background-color: ${colors.BG_GRAY};
  max-height: ${props => props.open ? '1500px' : 0};
  overflow: hidden;
  padding: ${props => props.open ? '32px' : '0 32px'};
  transition: all 0.5s;
`;
