import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';

const SelectButton = ({ label, testid }) => {

  return (
    <Container data-testid={testid}>
      {label}
    </Container>
  );
};

export default SelectButton;

SelectButton.propTypes = {
  label: PropTypes.string,
  testid: PropTypes.string
};

const Container = styled.button`
  background-color: ${colors.LCG_LIGHT_PURPLE};
  border-radius: 8px;
  border: 0;
  color: ${colors.TEXT_BODY};
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding: 8px 10px;
  width: 100%;

  &:hover,
  &:active {
    outline: none;
    color: ${colors.LCG_LIGHT_PURPLE_FADE};
  }

  &:focus {
    outline: none;
  }
`;
