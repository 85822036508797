export const getNullifiedAmplitudeUserProperties = (adId, source, medium) => {
  return {
    user_id: 'null',
    have_degree: 'null',
    aspire_degree: 'null',
    qualities: 'null',
    num_qualities_selected: 'null',
    careers: 'null',
    num_careers_selected: 'null',
    location: 'null',
    postcode: 'null',
    live_in_the_uk: 'null',
    open_to_relocating: 'null',
    employment_status: 'null',
    in_fulltime_education: 'null',
    place_of_study: 'null',
    age: 'null',
    salary: 'null',
    homeless: 'null',
    highest_education_level: 'null',
    alevels: 'null',
    alevel_school: 'null',
    alevel_school_from_home: 'null',
    alevel_school_outside_uk: 'null',
    degree: 'null',
    degree_university: 'null',
    degree_university_outside_uk: 'null',
    masters: 'null',
    masters_university: 'null',
    masters_university_outside_uk: 'null',
    phd: 'null',
    phd_university: 'null',
    phd_university_outside_uk: 'null',
    rating: 'null',
    ad_id: adId ? adId : 'null',
    source: source ? source : 'null',
    medium: medium ? medium : 'null',
  };
};
