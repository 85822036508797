/* Screen for extra team members to create accounts when invited by the dashboard admin
   Flow to add team members: Dashboard > Manage team > Add team member */

import React, { useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useRouteMatch, Link } from 'react-router-dom';
import axios from 'axios';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { useStateValue } from '../../services/state';
import { createSubmitData } from '../../utils/helper';
import { useGetScreenData } from '../../hooks/getDataHooks';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { PSmall, H3, P } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import Loader from  '../../components/Loader';
import LoadingScreen from '../LoadingScreen';

const CreateAccountScreen = () => {

  const [{ context }] = useStateValue();

  const match = useRouteMatch("/organisations/create-account/:id");
  const organisationUserId = match && match.params && match.params.id ? match.params.id : null;

  const [ isLoading, setIsLoading ] = useState(true);

  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();

  const [ isAccountCreated, setAccountCreated ] = useState(false);

  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

  const [passwordStrength, setPasswordStrength] = useState(null);

  const defaultFormValues = {
    password: "",
    confirm_password: "",
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    password: null,
    confirm_password: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  useGetScreenData('organisations/create-account', setIsLoading, null, null, {
    organisation_user_id: organisationUserId,
  });

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  const handleInputChange = (value, name) => {
    if (name === "password") {
      if (value.length >= 8) {
        if (value.length >= 15) {
          setPasswordStrength("very strong");
        } else if(strongPassword.test(value) || value.length >= 12){
          setPasswordStrength("strong");
        } else if(mediumPassword.test(value) || value.length >= 10){
          setPasswordStrength("medium");
        } else{
          setPasswordStrength("weak");
        }
      } else {
        setPasswordStrength(null);
      }
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {

    const value = e.target.value;
    const name = e.target.name;

    if (name === "password") {

      let errorMsg = null;
      if (value === "") {
        errorMsg = "You must provide a password";
      } else if (value.length < 8) {
        errorMsg = "Your password must be at least 8 characters long";
      }

      const errorMsgConfirmPassword =
        value !== "" && formValues.confirm_password !== "" && value !== formValues.confirm_password ? "Passwords don't match" : null;

      const confirmPasswordName = "confirm_password";
      setFormErrors({
        ...formErrors,
        [name]: errorMsg,
        [confirmPasswordName]: errorMsgConfirmPassword
      });

    } else if (name === "confirm_password") {
      const errorMsg = value !== formValues.password ? "Passwords don't match" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    }

  };

  const validateForm = () => {
    let isValid = true;
    if (formValues.password === "" || formValues.password.length < 8) {
      isValid = false;
    } else if (formValues.confirm_password !== formValues.password) {
      isValid = false;
    }
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm() || processing) return null;
    setProcessing(true);

    const submitData = createSubmitData({
      organisation_user_id: organisationUserId,
      password: formValues.password,
    });
    axios.post('/api/v1/create_account', submitData).then((res) => {
      setProcessing(false);
      if (res.data && res.data.succeeded === true) {
        localStorage.setItem("organisation_user_id", res.data.organisation_user_id);
        localStorage.setItem("session_id", res.data.session_id);
        setAccountCreated(true);
      } else if (res.data && res.data.error) {
        setError(res.data.error);
        console.log(res.data.error);
      }
    });
  }

  if (isLoading || !context || !context.organisation_name || !context.first_name || !context.email) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Create account</title>
        <meta id="meta-description" name="description" content="CareersPro - Create account" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name + " - New team member sign up"} />
        <CircleOne />
        <CircleTwo />
          <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              {!isAccountCreated && (
                <>
                  <Title>Please create your account</Title>
                  <P align="center">Hi {context.first_name}, you have been invited to the CareersPro dashboard for {context.organisation_name}.</P>
                  <P align="center">Your login email address is <BoldText>{context.email}</BoldText>.</P>
                  <P align="center">Please enter a password below to create your account.</P>
                  <Form>
                    <InputWrap>
                      <Input type="password" name="password" label="New password" handleChange={(it) => handleInputChange(it, 'password')} onBlur={validateField} error={!passwordStrength ? formErrors['password'] : null} />
                      {passwordStrength && (
                        <PasswordMessageContainer>
                          <PasswordMessage strength={passwordStrength}>Your password strength is {passwordStrength}</PasswordMessage>
                        </PasswordMessageContainer>
                      )}
                    </InputWrap>
                    <InputWrap>
                      <Input type="password" name="confirm_password" label="Confirm password" handleChange={(it) => handleInputChange(it, 'confirm_password')} handleKeyDown={onKeyDownHandler} onBlur={validateField} error={formErrors['confirm_password']} />
                    </InputWrap>
                    <ButtonContainer>
                      {processing ? (
                        <Loader />
                      ) : validateForm() ? (
                        <PrimaryButton onClick={() => submitForm()}>Create account</PrimaryButton>
                      ) : (
                        <Tooltip text="Please enter your new password">
                          <GrayButton disabled>Create account</GrayButton>
                        </Tooltip>
                      )}
                    </ButtonContainer>
                    <SignupButtonSubText align="center">By creating an account up you agree to our <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></SignupButtonSubText>
                    {error && (
                      <Error>
                        <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                      </Error>
                    )}
                  </Form>
                </>
              )}
              {isAccountCreated && (
                <>
                  <Title>Account created</Title>
                  <P align="center">Thanks for creating a new account with CareersPro.</P>
                  {context.enable_2fa ? (
                    <>
                      <P align="center">Before you can access the {context.organisation_name} dashboard, you are required to set up two-factor authentication to make your account more secure.  Click the button below to set this up...</P>
                      <ViewDashboardButtonContainer>
                        <Link to={`/organisations/two-factor`}>
                          <PrimaryButton>Set up two-factor authentication</PrimaryButton>
                        </Link>
                      </ViewDashboardButtonContainer>
                    </>
                    ) : (
                      <>
                        <P align="center">Click the button below to view the {context.organisation_name} dashboard where you can invite users and track their progress...</P>
                        <ViewDashboardButtonContainer>
                          <Link to={`/organisations/dashboard`}>
                            <PrimaryButton>View dashboard</PrimaryButton>
                          </Link>
                        </ViewDashboardButtonContainer>
                      </>
                    )}
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </Container>
    </>
  );
};

export default CreateAccountScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const Title = styled(H3)`
  margin-top: 20px;
  text-align: center;
`

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 32px;
  max-width: 356px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const PasswordMessageContainer = styled.div`
  margin-top: 4px;
`;

const PasswordMessage = styled(PSmall)`
  ${props => props.strength === "weak" && `color: ${colors.RED_FADE};`};
  ${props => props.strength === "medium" && `color: ${colors.LCG_DARK_PURPLE};`};
  ${props => props.strength.includes( "strong") && `color: ${colors.LCG_GREEN_FADE};`};
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const ViewDashboardButtonContainer = styled.div`
  padding-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const Error = styled.div`
  margin-top: 4px;
  text-align: center;
`;

const BoldText = styled.span`
  font-weight: bold;
`

const SignupButtonSubText = styled(PSmall)`
  margin-top: 20px;
`;