import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';
import layout from '../config/layout';
import { P, PSmall } from '../ui/typography/Typography';
import QuoteBg from '../assets/quote-bg.svg';
import IconQuote from '../assets/icons/icon-quote.svg';
import IconStar from '../assets/icons/icon-star.svg';

const Quote = ({ text, stars, author }) => {
  return (
    <Container>
      <QuoteMark>
        <img src={IconQuote} alt="" />
      </QuoteMark>
      <PStyled align="center" bold color={colors.TEXT_DARK}>{text}</PStyled>
      <PSmall align="center" color={colors.TEXT_DARK}>{author}</PSmall>
      <StarContainer>
        {
          [...Array(stars)].map((item, i) => (
            <Star key={i} src={IconStar} alt="Star" />
          ))
        }
      </StarContainer>
    </Container>
  );
};

export default Quote;

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
};

const Container = styled.div`
  background-image: url(${QuoteBg});
  background-position: center 7px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  max-width: 356px;
  width: 100%;
  height: 220px;
  margin: 0 auto 0 auto;
`;

const QuoteMark = styled.div`
  align-items: center;
  background-color: ${colors.LCG_DARK_PURPLE};
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
  margin: 0 auto 8px;
`;

const PStyled = styled(P)`
  margin-bottom: 6px;
  padding: 0 20px;
`;

const StarContainer = styled.div`
  padding-bottom: 40px;
  text-align: center;

  @media(min-width: ${layout.breakpoints.MD}) {
    padding-bottom: 25px;
  }
`;

const Star = styled.img`
  margin: 0 2px;
`;
