/* Homepage > Organisations > Login */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import { useGetScreenData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createSubmitData } from '../../utils/helper';
import { validateEmail } from '../../utils/validation';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { PSmall } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import Loader from  '../../components/Loader';
import LoadingScreen from '../LoadingScreen';
import { MoreLinkA } from '../../ui/buttons/Link';

const LoginScreen = () => {

  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);

  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();

  const defaultFormValues = {
    email: "",
    password: "",
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const [ educationInstitution, setEducationInstitution ] = useState(null);

  const match = useRouteMatch("/organisations/login/:source");
  const source = (match && match.params) ? match.params.source : null;

  useGetScreenData('organisations/login', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const getEducationInstitution = async (source) => {
      const apiUrl = `/api/v1/getSourceHomepageAttributes?source=${source}`;
      try {
        setIsLoading(true);
        const resp = await axios.get(apiUrl);
        setIsLoading(false);
        if (resp && resp.data && resp.data.education_institution) {
          setEducationInstitution(resp.data.education_institution);
        } else {
          setEducationInstitution(null);
        }
      } catch(error) {
        setIsLoading(false);
        console.log(error.response);
      }
    };

    if (source !== null) {
      getEducationInstitution(source);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    if (!validateEmail(formValues.email)) {
      isValid = false;
    } else if (formValues.password === "") {
      isValid = false;
    }
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm() || processing) return null;
    setProcessing(true);

    const submitData = createSubmitData({
      email: formValues.email,
      password: formValues.password,
    });
    axios.post('/api/v1/login', submitData).then((res) => {
      setProcessing(false);
      if (res.data && res.data.success === true) {
        localStorage.setItem("organisation_user_id", res.data.organisation_user_id);
        localStorage.setItem("session_id", res.data.session_id);
        setFormValues(defaultFormValues);
        history.push(`/organisations/two-factor`);
      } else {
        setError(res.data.error);
      }
    });
  }

  if (isLoading) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Organisation Login</title>
        <meta id="meta-description" name="description" content="CareersPro - Organisation login" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="Organisations - Login" />
        <CircleOne />
        <CircleTwo />
        <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <Form>
                <InputWrap>
                  <Input type="text" name="email" label="Email" handleChange={(it) => handleInputChange(it, 'email')} />
                </InputWrap>
                <InputWrap>
                  <Input type="password" name="password" label="Password" handleChange={(it) => handleInputChange(it, 'password')} handleKeyDown={onKeyDownHandler} />
                </InputWrap>
                <ButtonContainer>
                  {processing ? (
                    <Loader />
                  ) : validateForm() ? (
                    <PrimaryButton onClick={() => submitForm()}>Login</PrimaryButton>
                  ) : (
                    <Tooltip text="Please enter your details above">
                      <GrayButton disabled>Login</GrayButton>
                    </Tooltip>
                  )}
                </ButtonContainer>
                {error && (
                  <Error>
                    <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                  </Error>
                )}
                <LinkWrap>
                  <MoreLinkA onClick={() => history.push('/organisations/password-reset')} noArrow>Forgot password</MoreLinkA>
                </LinkWrap>
              </Form>
            </Col>
          </Row>
        </Grid>
        <BackButtonContainer>
          <SecondaryButton onClick={() => {history.push('/' + (educationInstitution ? educationInstitution.endpoint : ""))}}>Back to {educationInstitution ? educationInstitution.name : ""} homepage</SecondaryButton>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default LoginScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 32px;
  max-width: 356px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const Error = styled.div`
  margin-top: 4px;
  text-align: center;
`;

const LinkWrap = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
`;