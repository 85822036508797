/* Insights shown at the top of the payment screen or when tapping "Overview" in final results */

import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import { useStateValue } from "../services/state";
import layout from  '../config/layout';
import { P } from '../ui/typography/Typography';

import IconInsightTitle from '../assets/icon_insight_title.svg';
import IconInsightItem from '../assets/icon_insight_item.svg';

const Insights = () => {
  const [{ context }] = useStateValue();

  let insightKey = 0;
  let insightBodyKey = 0;

  return (
    <InsightsSection>
      {context.final_results_insights && context.final_results_insights.map(item => (
        <Insight key={insightKey++}>
          <InsightTitle>
            <InsightTitleIcon src={IconInsightTitle} /><InsightText>{ReactHtmlParser(item.title)}</InsightText>
          </InsightTitle>
          {item.body.map(body_item => (
            <InsightBodyItem key={insightBodyKey++}>
              <InsightItemIcon src={IconInsightItem} /><InsightText>{ReactHtmlParser(body_item)}</InsightText>
            </InsightBodyItem>
          ))}
        </Insight>
      ))}
    </InsightsSection>
  );
};

export default Insights;

const InsightsSection = styled.div`
  padding-bottom: 20px;
  padding-left: 50px;
  max-width: 450px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 352px;
    padding-left: 30px;
  }

  @media(max-width: ${layout.breakpoints.VSM}) {
    padding-left: 0px;
  }
`;

const Insight = styled.div`
  margin-bottom: 20px;
`

const InsightTitle = styled.div`
  display: flex;
  margin-bottom: 4px;
`

const InsightTitleIcon = styled.img`
  max-width: 48px;
  margin-right: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 32px;
    margin-right: 8px;
  }
`

const InsightText = styled(P)`
  margin-top: auto;
  margin-bottom: auto;
`

const InsightItemIcon = styled.img`
  max-width: 36px;
  margin-right: 8px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 24px;
  }
`

const InsightBodyItem = styled.div`
  display: flex;
  margin-left: 30px;
  margin-bottom: 4px;
`
