import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';
import { P, H5 } from '../ui/typography/Typography';
import { Tag } from '../ui/tag/Tag';
import IconTick from '../assets/icon-tick.svg';

const CheckboxCard = ({
  description,
  handleClick,
  tags,
  labels,
  title,
  value,
  highlight,
  note,
  is_selected,
  is_disabled,
  children,
  testid
}) => {
  const [selected, setSelected] = useState(is_selected ? is_selected : false);

  const onClick = () => {
    if (!is_disabled) {
      handleClick(value);
      setSelected(!selected);
    }
  };

  let tagIndex = 0;
  let labelIndex = 0;

  return (
    <Container onClick={onClick} selected={selected} disabled={is_disabled} data-testid={testid}>
      <H5 align="center">{title}</H5>
      {tags && tags.length > 0 && (
        <TagContainer>
          {tags.map(tag => (
            <Tag key={tagIndex++}>#{tag}</Tag>
          ))}
        </TagContainer>
      )}
      {labels && labels.length > 0 && (
        <TagContainer>
          {labels.map(label => (
            <Tag key={labelIndex++} color={label.positive ? colors.LCG_GREEN : colors.LCG_DARK_PURPLE} bgColor={label.positive ? colors.LCG_GREEN_FADE : colors.LCG_DARK_PURPLE_FADE}>{label.name}</Tag>
          ))}
        </TagContainer>
      )}
      {description && (
        <P align="center" margin="16px 0 0 0">{description}</P>
      )}
      {children && children}
      {highlight && (<P align="center" margin="16px 0 0 0" bold>{highlight}</P>)}
      {note && (<P align="center" margin="16px 0 0 0" italic>{note}</P>)}
    </Container>
  );
};

export default CheckboxCard;

CheckboxCard.propTypes = {
  description: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  tags: PropTypes.array,
  labels: PropTypes.array,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  highlight: PropTypes.string,
  note: PropTypes.string,
  children: PropTypes.node,
  testid: PropTypes.string,
};


const Container = styled.button`
  background-color: ${colors.WHITE};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${props => props.disabled ? colors.LCG_LIGHT_PURPLE : props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_GREY}, 0px 8px 16px ${props => props.selected ? 'transparent' : colors.LCG_LIGHT_GREY};
  cursor: pointer;
  display: block;
  padding: 32px;
  position: relative;
  width: 100%;
  border: 0;

  &::after {
    background-color: ${props => props.disabled ? colors.GRAY : props.selected ? colors.LCG_LIGHT_PURPLE :  colors.LCG_LIGHTER_GREY};
    background-image: url(${IconTick});
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 100%;
    content: "";
    height: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => props.disabled ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_PURPLE}, 0px 8px 16px ${props => props.selected ? 'transparent' : colors.LCG_LIGHT_GREY};
  }

  &:disabled {
    cursor: default;
    background-color: ${colors.GRAY_LIGHT};

    &:hover {
      background-color: ${colors.GRAY_LIGHT};
    }
  }
`;

const TagContainer = styled.div`
  margin-top: 8px;
  text-align: center;
`;
