/* Homepage > Organisations > Login > Forgot password */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createSubmitData } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import Input from '../../ui/form/Input';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { PSmall, H3, P } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import Loader from  '../../components/Loader';
import LoadingScreen from '../LoadingScreen';
import { validateEmail } from '../../utils/validation';

const PasswordResetScreen = () => {

  const match = useRouteMatch("/organisations/password-reset/:id");
  const passwordResetID = match && match.params && match.params.id ? match.params.id : null;

  const history = useHistory();

  const [ isLoading, setIsLoading ] = useState(true);

  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();

  const [ isEmailSent, setIsEmailSent ] = useState(false);
  const [ isPasswordChanged, setIsPasswordChanged ] = useState(false);

  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

  const [passwordStrength, setPasswordStrength] = useState(null);

  const defaultFormValues = {
    password: "",
    confirm_password: "",
    email: "",
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const initialErrorState = {
    password: null,
    confirm_password: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialErrorState);

  useEffect(() => {
    const isPasswordResetIDValid = async (passwordResetID) => {
      if (passwordResetID) {
        const apiUrl = `/api/v1/is_password_reset_id_valid?id=${passwordResetID}`;
        try {
          const resp = await axios.get(apiUrl);
          if (resp.data && resp.data.success) {
            setIsLoading(false);
            if (resp.data.processed) {
              setIsPasswordChanged(true);
            }
          } else {
            history.push('/organisations/login');
          }
        } catch(error) {
          history.push('/organisations/login');
        }
      } else {
        setIsLoading(false);
      }
    };
    isPasswordResetIDValid(passwordResetID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  const handleInputChange = (value, name) => {
    if (name === "password") {
      if (value.length >= 8) {
        if (value.length >= 15) {
          setPasswordStrength("very strong");
        } else if(strongPassword.test(value) || value.length >= 12){
          setPasswordStrength("strong");
        } else if(mediumPassword.test(value) || value.length >= 10){
          setPasswordStrength("medium");
        } else{
          setPasswordStrength("weak");
        }
      } else {
        setPasswordStrength(null);
      }
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {

    const value = e.target.value;
    const name = e.target.name;

    if (name === "password") {

      let errorMsg = null;
      if (value === "") {
        errorMsg = "You must provide a password";
      } else if (value.length < 8) {
        errorMsg = "Your password must be at least 8 characters long";
      }

      const errorMsgConfirmPassword =
        value !== "" && formValues.confirm_password !== "" && value !== formValues.confirm_password ? "Passwords don't match" : null;

      const confirmPasswordName = "confirm_password";
      setFormErrors({
        ...formErrors,
        [name]: errorMsg,
        [confirmPasswordName]: errorMsgConfirmPassword
      });

    } else if (name === "confirm_password") {
      const errorMsg = value !== formValues.password ? "Passwords don't match" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    } else if (name === "email") {
      let errorMsg = null;
      if (!validateEmail(value)) {
        errorMsg = "This is not a valid email";
      }
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    }

  };

  const validateForm = () => {
    let isValid = true;
    if (passwordResetID) {
      if (formValues.password === "" || formValues.password.length < 8) {
        isValid = false;
      } else if (formValues.confirm_password !== formValues.password) {
        isValid = false;
      }
    } else {
      if (!validateEmail(formValues.email)) {
        isValid = false;
      }
    }
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm() || processing) return null;
    setProcessing(true);

    if (passwordResetID) {
      const submitData = createSubmitData({
        password_reset_id: passwordResetID,
        password: formValues.password,
      });
      axios.post('/api/v1/password_reset', submitData).then((res) => {
        setProcessing(false);
        if (res.data && res.data.success === true) {
          setIsPasswordChanged(true);
        } else {
          setError(res.data.error);
        }
      });
    } else {
      const submitData = createSubmitData({
        email: formValues.email,
      });
      axios.post('/api/v1/request_password_reset', submitData).then((res) => {
        setProcessing(false);
        if (res.data && res.data.success === true) {
          setIsEmailSent(true);
        } else {
          setError(res.data.error);
        }
      });
    }
  }

  if (isLoading) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Password reset</title>
        <meta id="meta-description" name="description" content="CareersPro - Password reset" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="Organisations - Password reset" />
        <CircleOne />
        <CircleTwo />
          <Grid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              {!passwordResetID && !isEmailSent && (
                <>
                  <Title>Trouble logging in?</Title>
                  <P align="center">Enter your email and we'll send you a link to get back into your account.</P>
                  <Form>
                    <InputWrap>
                      <Input type="text" name="email" label="Email" handleChange={(it) => handleInputChange(it, 'email')} onBlur={validateField} error={formErrors['email']} />
                    </InputWrap>
                    <ButtonContainer>
                      {processing ? (
                        <Loader />
                      ) : validateForm() ? (
                        <PrimaryButton onClick={() => submitForm()}>Request password reset</PrimaryButton>
                      ) : (
                        <Tooltip text="Please enter your email address">
                          <GrayButton disabled>Request password reset</GrayButton>
                        </Tooltip>
                      )}
                    </ButtonContainer>
                    {error && (
                      <Error>
                        <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                      </Error>
                    )}
                  </Form>
                  <BackButtonContainer>
                    <SecondaryButton onClick={() => {history.push('/organisations/login')}}>Back to login</SecondaryButton>
                  </BackButtonContainer>
                </>
              )}
              {!passwordResetID && isEmailSent && (
                <>
                  <Title>Email sent</Title>
                  <P align="center">We sent an email to {formValues.email} with a link to reset your password</P>
                </>
              )}
              {passwordResetID && !isPasswordChanged && (
                <>
                  <Title>Reset password</Title>
                  <Form>
                    <InputWrap>
                      <Input type="password" name="password" label="New password" handleChange={(it) => handleInputChange(it, 'password')} onBlur={validateField} error={!passwordStrength ? formErrors['password'] : null} />
                      {passwordStrength && (
                        <PasswordMessageContainer>
                          <PasswordMessage strength={passwordStrength}>Your password strength is {passwordStrength}</PasswordMessage>
                        </PasswordMessageContainer>
                      )}
                    </InputWrap>
                    <InputWrap>
                      <Input type="password" name="confirm_password" label="Confirm password" handleChange={(it) => handleInputChange(it, 'confirm_password')} handleKeyDown={onKeyDownHandler} onBlur={validateField} error={formErrors['confirm_password']} />
                    </InputWrap>
                    <ButtonContainer>
                      {processing ? (
                        <Loader />
                      ) : validateForm() ? (
                        <PrimaryButton onClick={() => submitForm()}>Reset password</PrimaryButton>
                      ) : (
                        <Tooltip text="Please enter your new password">
                          <GrayButton disabled>Reset password</GrayButton>
                        </Tooltip>
                      )}
                    </ButtonContainer>
                    {error && (
                      <Error>
                        <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
                      </Error>
                    )}
                  </Form>
                  <BackButtonContainer>
                    <SecondaryButton onClick={() => {history.push('/organisations/login')}}>Back to login</SecondaryButton>
                  </BackButtonContainer>
                </>
              )}
              {passwordResetID && isPasswordChanged && (
                <>
                  <Title>Password changed</Title>
                  <P align="center">Your password has been successfully changed.</P>
                  <BackButtonContainer>
                    <PrimaryButton onClick={() => {history.push('/organisations/login')}}>Login</PrimaryButton>
                  </BackButtonContainer>
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </Container>
    </>
  );
};

export default PasswordResetScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const Title = styled(H3)`
  margin-top: 20px;
  text-align: center;
`

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 32px;
  max-width: 356px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const PasswordMessageContainer = styled.div`
  margin-top: 4px;
`;

const PasswordMessage = styled(PSmall)`
  ${props => props.strength === "weak" && `color: ${colors.LCG_DARK_PURPLE};`};
  ${props => props.strength === "medium" && `color: ${colors.LCG_LIGHT_PURPLE};`};
  ${props => props.strength.includes( "strong") && `color: ${colors.LCG_DARK_PURPLE};`};
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 52px 0 32px;
`;

const Error = styled.div`
  margin-top: 4px;
  text-align: center;
`;