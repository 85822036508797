/* Final results > Email results (at top) */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from "@amplitude/react-amplitude";

import { createSubmitData } from '../../utils/helper';
import { validateEmail } from '../../utils/validation';
import { useStateValue } from "../../services/state";

import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Modal from '../../components/Modal';
import Input from '../../ui/form/Input';
import Checkbox from '../../ui/form/Checkbox';
import Tooltip from '../../components/Tooltip';
import { H4, P, PSmall } from '../../ui/typography/Typography';

const EmailResults = ({ setLoadingMsg, handlePostEmailSent, emailPreviouslySent }) => {
  const [{ user_id }] = useStateValue();
  const [ open, setOpen ] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    subscribed: false,
  });

  const initialEmailErrorState = {
    first_name: null,
    last_name: null,
    email: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submitForm = (logEvent) => {
    if (validateForm(true)) {
      logEvent('Email Results', {is_subscribed: formValues.subscribed});

      const submitData = createSubmitData({
        user_id: user_id,
        contact_details: formValues,
      });
      axios.post('/api/v1/handleEmailScreenInput/', submitData);

      localStorage.setItem("emails_sent", true);

      setOpen(false);
      setFormValues({
        first_name: "",
        last_name: "",
        email: "",
        subscribed: false,
      });

      handlePostEmailSent();
    }
  }

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "first_name"|| name === "last_name") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    } else if ( name === 'email') {
      const errorMsg = !validateEmail(value) ? "This is not a valid email" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let errors = {...initialEmailErrorState};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if (key !== 'subscribed') {
        if (value === "") {
          formValid = false;
          errors = {
            ...errors,
            [key]: "This field is required"
          }
        } else if ( key === 'email') {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        }
      }
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  return (
    <>
      <ButtonWrap>
        <SecondaryButton onClick={() => setOpen(true)}>Email your results</SecondaryButton>
      </ButtonWrap>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        large
        hasCloseButton
      >
        <H4 align="center">{emailPreviouslySent ? `Email your results` : `Email yourself the results`}</H4>
        <P align="center">{emailPreviouslySent ? `Email a copy of your results to yourself, your career coach or a careers advisor` : `Easily return to your results by emailing a link to your inbox.`}</P>
        <Form>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} name="first_name" label="First name" error={formErrors['first_name']} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} name="last_name" label="Last name" error={formErrors['last_name']} />
          </InputWrap>
          <InputWrap>
            <Input type="email" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} name="email" label="Email address" error={formErrors['email']} />
          </InputWrap>
          {!emailPreviouslySent && (
            <InputWrap>
              <Checkbox
                handleChange={(it) => handleInputChange(it, 'subscribed')}
                label="Check this box if you’re happy for us to stay in touch, so we can better help you discover and get into your dream career"
                name="subscribed"
              />
            </InputWrap>
          )}
          <ButtonContainer>
            {!validateForm(false) ? (
              <Tooltip text="Please enter your contact details above">
                <GrayButton disabled>Email results</GrayButton>
              </Tooltip>
            ) : (
              <Amplitude userProperties={{emailed_results: true, email_subscribed: formValues.subscribed}}>
                {({logEvent}) => (
                  <>
                    <PrimaryButton onClick={() => submitForm(logEvent)}>Email results</PrimaryButton>
                    <PrivacyText align="center">By tapping <b>Email results</b> you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></PrivacyText>
                  </>
                )}
              </Amplitude>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
    </>
  );
};

export default EmailResults;

const ButtonWrap = styled.div`
  text-align: center;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 12px;
  max-width: 352px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const PrivacyText = styled(PSmall)`
  margin-top: 10px;
`;
