/* Final results > Career screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useRouteMatch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import MetaTags from 'react-meta-tags';
import ReactPlayer from "react-player";

import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { createSubmitData, getParameterByName } from '../../utils/helper';
import layout from  '../../config/layout';
import { copyArray } from '../../utils/copyArray';

import Header from  '../../components/Header';
import AboutCard from './AboutCard/AboutCard';
import LoadingScreen from '../LoadingScreen';
import CourseSection from './CourseSection';
import JobSection from './JobSection';
import CareerFeedback from './CareerFeedback';
import TakeQuizSection from './TakeQuizSection';
import BgThreeDots from '../../ui/bg/BgThreeDots';
import Pathways from './Pathways';
import Modal from '../../components/Modal';
import PathwayModalContent from './PathwayModalContent';
import { Link } from 'react-router-dom';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import IconVideo from '../../assets/icon-video.svg';
import { H3, P } from '../../ui/typography/Typography';
import IconPlay from '../../assets/icon-play.svg';
import IconSkills from '../../assets/icon-skills.svg';
import { Tag } from '../../ui/tag/Tag';
import colors from '../../config/colors';
import { MoreLinkA } from '../../ui/buttons/Link';
import UnlockPremiumCard from '../../components/UnlockPremiumCard';

const CareerScreen = () => {
  const [{ user_id, loading, adzuna_jobs, context }, dispatch] = useStateValue();
  const [ selectedPathway, setSelectedPathway ] = useState();
  const [ open, setOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const [ videoLoaded, setVideoLoaded ] = useState(false);
  const [ playVideo, setPlayVideo ] = useState(false);
  const [ videoEnded, setVideoEnded ] = useState(false);

  const [ numSkillsToShow, setNumSkillsToShow ] = useState(4);
  const [ skillsSelected, setSkillsSelected ] = useState([]);

  const history = useHistory();
  let careerMatch = useRouteMatch("/career/:careerUrl");
  let pathwayMatch = useRouteMatch("/career/:careerUrl/pathways/:pathwayId");
  let userCareerMath = useRouteMatch("/:userId/career/:careerUrl");
  let userPathwayMatch = useRouteMatch("/:userId/career/:careerUrl/pathways/:pathwayId");
  let match = (pathwayMatch ? pathwayMatch : (careerMatch ? careerMatch : (userPathwayMatch ? userPathwayMatch : (userCareerMath ? userCareerMath : null))));

  useGetScreenData('career', setIsLoading, (match ? match.params.userId : null), (match ? match.params.careerUrl : null));

  let location = useLocation();
   useEffect(() => {
     if (!match.params.pathwayId && context && (context.career_id > -1) && open) {
      setSelectedPathway();
      setOpen(false);
    } else if (match.params.pathwayId && context && context.career_id > -1) {
       const currentPathway = context.pathways.find(it => it.pathway_url.toString() === match.params.pathwayId);
       setSelectedPathway(currentPathway);
       !open && setOpen(true);
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

  useEffect(() => {
    if (match.params.pathwayId && context && context.career_id > -1) {
      const currentPathway = context.pathways.find(it => it.pathway_url.toString() === match.params.pathwayId);
      setSelectedPathway(currentPathway);
      !open && setOpen(true);
    }

    if (context && context.career_id >= 0 && (!adzuna_jobs || !adzuna_jobs[context.career_id]) && context.live_in_the_uk !== false) {
      window.scrollTo(0, 0);
      if (user_id) {
        getAdzunaJobs(context);
      }
    }

    if (!user_id && context && context.career_id >= 0) {
      const submitData = createSubmitData({
        career_id: context.career_id,
      });
      axios.post('/api/v1/viewCareerGeneric', submitData);
    }

    if (context && context.is_skills_match) {
      setNumSkillsToShow(8);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const getAdzunaJobs = (career) => {
    axios.post('/api/v1/get_job_info_from_adzuna', {
      user_id: match.params.userId,
      job: career,
    }).then((res) => {
      const adzunaJobs = {...adzuna_jobs, [career.career_id]: res.data[0]};
      dispatch({type: 'update', values: {adzuna_jobs: adzunaJobs}})
    });
  };

  if (loading || isLoading) return <LoadingScreen />;

  const openPathwayModal = pw => {
    let parametersInURL = window.location.href.includes("?") ? window.location.href.slice(window.location.href.indexOf("?")) : "";
    history.push(`${(!user_id) ? "" : "/" + user_id}/career/${context.career_url}/pathways/${pw.pathway_url}${parametersInURL}`);
    setSelectedPathway(pw);
    setOpen(true);

    if (match.params.userId) {
      const submitData = createSubmitData({
        user_id: match.params.userId,
        pathway_id: pw.id,
        career_id: context.career_id,
      });
      axios.post('/api/v1/viewPathwayClicked', submitData);
    }
  };

  const closePathwayModal = () => {
    let parametersInURL = window.location.href.includes("?") ? window.location.href.slice(window.location.href.indexOf("?")) : "";
    history.push(`${(!user_id) ? "" : "/" + user_id}/career/${context.career_url}${parametersInURL}`);
    setSelectedPathway();
    setOpen(false);
  };

  const getStarted = async (adId, source, medium) => {
    dispatch({type: 'startLoading'});
    const formData = createSubmitData({
      ad_id: adId,
      source: source,
      source_career: context.career_id,
      medium: medium,
    });

    axios.post('/api/v1/getStarted', formData).then(resp => {
      const screen = resp.data.screen;
      dispatch({type: 'updateResponse', data: resp.data});
      if (screen.name === 'situation') {
        history.push(`/${resp.data.user_id}/situation`);
      } else {
        history.push(`/${resp.data.user_id}/${screen.name}/${screen.context.question_number ? screen.context.question_number : ''}`)
      }
    });
  };

  const shownVideo = ()  => {
    if (user_id) {
      // Update on server
      const submitData = createSubmitData({
        user_id: match.params.userId,
        career_id: context.career_id,
      })
      axios.post('/api/v1/shownVideo', submitData);
    }
  };

  const startVideo = (logEvent)  => {
    // Update state
    setVideoEnded(false);
    setPlayVideo(true);

    // Log Amplitude event
    logEvent('Start Video', {});

    if (user_id) {
      // Update on server
      const submitData = createSubmitData({
        user_id: match.params.userId,
        career_id: context.career_id,
      })
      axios.post('/api/v1/startVideo', submitData);
    }
  };

  const endVideo = (logEvent)  => {
    // Update state
    setPlayVideo(false);
    setVideoEnded(true);

    // Log Amplitude event
    logEvent('Completed Video', {});

    if (user_id) {
      // Update on server
      const submitData = createSubmitData({
        user_id: match.params.userId,
        career_id: context.career_id,
      })
      axios.post('/api/v1/completeVideo', submitData);
    }
  };

  const selectSkill = (skillKey, logEvent)  => {
    let copy = copyArray(skillsSelected);
    const index = copy.indexOf(skillKey);
    if (index >= 0) {
      // Deselect
      copy.splice(index, 1);
    } else {
      // Select
      copy.push(skillKey);

      // Get skill name and id
      let skillName = "";
      let skillID = -1;
      for (let i=0; i<context.skills.length; i++) {
        if (context.skills[i].key === skillKey) {
          skillName = context.skills[i].name;
          skillID = context.skills[i].id;
        }
      }

      // Log Amplitude event
      logEvent('Skill Selected', {skill: skillName, list_position: (skillKey+1)});

      if (user_id) {
        // Update on server
        const submitData = createSubmitData({
          user_id: match.params.userId,
          career_id: context.career_id,
          skill_id: skillID,
        })
        axios.post('/api/v1/skillSelected', submitData);
      }
    }
    setSkillsSelected(copy);
  };

  const adId = getParameterByName('utm_content');
  const source = getParameterByName('utm_source');
  const medium = getParameterByName('utm_medium');
  const feedback = getParameterByName('feedback');

  return (
    <>
      <MetaTags>
        <title>{context.career_name} pathways</title>
        <meta id="meta-description" name="description" content={(user_id) ? ("Find career happiness as " + context.career_name_with_prefix) : ("Step-by-step instructions for how to become " + context.career_name_with_prefix)} />
      </MetaTags>
      <Amplitude eventProperties={{career_id: context.career_id, career_name: context.career_name}}>
        {({ logEvent }) => (
          <LogOnMount eventType="View Career">
            <Container blur={context && context.blur}>
              <BgThreeDots addMobile />
              <Header logo_alignment="center" large />
              <AboutCard user_id={user_id} item={context} context={context} />
              {context.live_in_the_uk !== false && user_id && (
                <ButtonWrap nomargin={context.pathways && context.pathways.length === 0}>
                  <Link to={`/${user_id}/results`}>
                    <SecondaryButton>Back to results</SecondaryButton>
                  </Link>
                </ButtonWrap>
              )}
              {!user_id && feedback && <CareerFeedback user_id={user_id} career_id={context.career_id} num_pathways={context.pathways ? context.pathways.length : 0} />}
              {context && context.video && (
                <>
                  <VideoTitle align="center">Day in the life video</VideoTitle>
                  <VideoContainer>
                    {context.package !== "standard" && (
                      <Video>
                        {!videoEnded && (
                          <ReactPlayerResponsive
                            url={context.video.link}
                            width="100%"
                            height="100%"
                            playing={playVideo}
                            controls={true}
                            onProgress={(e) => {if (context.video.end > 0 && e.playedSeconds >= context.video.end) {endVideo(logEvent);}}}
                            onReady={() => {setVideoLoaded(true); shownVideo();}}
                            onEnded={() => {endVideo(logEvent);}}
                            config={{
                              youtube: {
                                playerVars: { cc_load_policy: 1 }
                              }
                            }}
                          />
                        )}
                        {videoLoaded && !playVideo && (
                          <div onClick={() => {startVideo(logEvent);}}>
                            <VideoThumbnail
                              width="100%"
                              height="100%"
                              src={context.video.thumbnail}
                            />
                            <VideoPlayIconContainer>
                              <VideoPlayIcon src={IconPlay} alt="Play" />
                            </VideoPlayIconContainer>
                          </div>
                        )}
                      </Video>
                    )}
                    {context.package === "standard" && (
                      <UnlockPremiumCard user_id={user_id} price={context.upgrade_to_premium_price} />
                    )}
                  </VideoContainer>
                </>
              )}
              {user_id && context.learning_curve_user === true && (
                <CourseSection item={context} />
              )}
              {(!user_id || (context && context.should_show_pathways)) && (
                <>
                  <Pathways user_id={user_id} first_name={context ? context.first_name : null} item={context} handleButtonClick={pw => {openPathwayModal(pw)}} />
                  <Modal
                    open={open}
                    handleClose={closePathwayModal}
                    hasCloseButton
                    large
                    hideBottomPaddingMobile
                  >
                    <PathwayModalContent pathway={selectedPathway} career={context} first_name={context ? context.first_name : null} />
                  </Modal>
                </>
              )}
              {user_id && context && context.skills && context.skills.length > 0 && (
                <SkillsContainer>
                  <SkillsTitle align="center">Transferable skills</SkillsTitle>
                  <P align="center">{context && context.first_name ? context.first_name + ", you" : "You"} may have the below transferable skills, relevant to this career.  Tap on the skills to learn more.</P>
                  {context.package !== "standard" && context.skills.map(skill =>
                    skill.key < numSkillsToShow && (
                      <SkillContainer key={skill.key} onClick={() => {selectSkill(skill.key, logEvent)}} selected>
                        {skillsSelected.includes(skill.key) && (
                          <SkillDetailContainer>
                            <SkillDetailTopLabel>{skill.name}</SkillDetailTopLabel>
                            {skill.description && skill.description !== null && (
                              <SkillDetailTitle>Skill description</SkillDetailTitle>
                            )}
                            {skill.description && skill.description !== null && (
                              <SkillDetailBody>{skill.description}</SkillDetailBody>
                            )}
                            <SkillDetailTitle pt>Jobs you may have acquired this skill from</SkillDetailTitle>
                            <SkillDetailBody>{skill.past_jobs}</SkillDetailBody>
                          </SkillDetailContainer>
                        )}
                        {!skillsSelected.includes(skill.key) && (
                          <SkillsTagContainer>
                            <SkillsTag dynamic_font>{skill.name}</SkillsTag>
                          </SkillsTagContainer>
                        )}
                      </SkillContainer>
                    )
                  )}
                  {context.package !== "standard" && context.skills.length > numSkillsToShow && false && (
                    <LinkWrap>
                      <MoreLinkA onClick={() => setNumSkillsToShow(numSkillsToShow + 8)} arrowUp>Load more</MoreLinkA>
                    </LinkWrap>
                  )}
                  {context.package === "standard" && (
                    <UnlockPremiumCard user_id={user_id} price={context.upgrade_to_premium_price} />
                  )}
                </SkillsContainer>
              )}
              {user_id && context.live_in_the_uk !== false && (
                <JobSection item={context} />
              )}
              {!user_id && <TakeQuizSection handleQuizStart={getStarted} adId={adId} source={source} medium={medium} careerID={context.career_id} />}
              {user_id && (
                <ButtonWrap pb>
                  <Link to={`/${user_id}/results`}>
                    <SecondaryButton>Back to results</SecondaryButton>
                  </Link>
                </ButtonWrap>
              )}
              {!user_id && (
                <ButtonWrap pb>
                  <Link to={`/`}>
                    <SecondaryButton>Go to homepage</SecondaryButton>
                  </Link>
                </ButtonWrap>
              )}
            </Container>
          </LogOnMount>
        )}
      </Amplitude>
    </>
  );
};

export default CareerScreen;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  ${props => props.blur && `filter: blur(7px) brightness(1);`};
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin: 32px 0 ${props => props.nomargin ? 0 : '32px'};
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;

const VideoTitle = styled(H3)`
  margin: 40px 0 20px 0;
  padding-top: 50px;
  background-image: url(${IconVideo});
  background-repeat: no-repeat;
  background-position: top center;
`;

const VideoContainer = styled.div`
  padding: 0 20px 20px 20px;
  max-width: 768px;
  margin: auto;
`;

const Video = styled.div`
  position: relative;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)
`;

const ReactPlayerResponsive = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoPlayIconContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
`;

const VideoPlayIcon = styled.img`
  position: relative;
  left: -15%;
  height: 30%;
  width: 30%;
`;

const SkillsContainer = styled.div`
  padding: 5px 1rem 20px 1rem;
  max-width: 768px;
  margin: auto;
`;

const SkillContainer = styled.div`
  ${props => props.selected  && `padding-top: 5px;`};
  cursor: pointer;
`;

const SkillsTitle = styled(H3)`
  margin: 10px 0 20px 0;
  padding-top: 50px;
  background-image: url(${IconSkills});
  background-repeat: no-repeat;
  background-position: top center;
`;

const SkillsTagContainer = styled.div`
  text-align: center;
  display: block;
`;

const SkillsTag = styled(Tag)`
  margin: 4px 0 10px 0;
  padding: 5px 8px;
  color: ${colors.LCG_DARK_PURPLE};
  background-color: ${colors.LCG_DARK_PURPLE_FADE};
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: 6px 0 10px 0;
  }

  &:hover {
    color: ${colors.WHITE};
    background-color: ${colors.LCG_DARK_PURPLE};
  }
`;

const SkillDetailContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  margin: 18px 0 10px 0;
  background-color: ${colors.WHITE};
  padding: 44px 32px 32px 32px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 36px 16px 16px 16px;
  }
`;

const SkillDetailTopLabel = styled.div`
  line-height: 18px;
  background-color: ${colors.LCG_DARK_PURPLE};
  color: ${colors.WHITE};
  position: absolute;
  padding: 5px 8px;
  font-weight: 700;
  border-radius: 4px;
  left: 50%;
  top: -14px;
  transform: translateX(-50%);
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY};

  @media(max-width: ${layout.breakpoints.MD}) {
    top: -12px;
  }
`;

const SkillText = styled(P)`
  display: flex;
  text-align: center;
  display: inline-block;
  flex: 1;
  padding: 4px 0px 5px 0px;
  margin-bottom: 8px;
  width: 100%;
`;

const SkillDetailTitle = styled(SkillText)`
  margin-bottom: 0;
  ${props => props.pt  && `padding-top: 10px;`};
`;

const SkillDetailBody = styled(SkillText)`
  color: ${colors.TEXT_DARK};
`;

const LinkWrap = styled.div`
  padding-top: 20px;
  text-align: center;
`;
