import React, { useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';

import { createSubmitData } from '../utils/helper';
import { fetchData } from '../hooks/getDataHooks';

import layout from '../config/layout';
import colors from '../config/colors';

import iconMenu from '../assets/icon-menu.svg';
import iconClose from '../assets/icon-close.svg';
import LogoSmall from '../assets/logo/careersPro_logo_grey.svg';
import LogoLarge from '../assets/logo/careersPro_logo_transparent_S.svg';

import { PrimaryButton } from '../ui/buttons/Buttons';
import { H4 } from '../ui/typography/Typography';

const TopBar = ({ pageTitle, pageWelcome, showLogin, showLogout, showCareersProLogo, sticky, logoLink, logoutEndpoint, showMenuIcon, menuItems, isCollege }) => {

  const [ screenSize, setScreenSize ] = useState(null);
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  const [ showMenu, setShowMenu ] = useState(false);

  const history = useHistory();

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      const resp = await fetchData('organisations/dashboard', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        if (resp.data.screen.name === "organisations/dashboard") {
          setIsLoggedIn(true);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const login = () => {
    if (isLoggedIn) {
      history.push(`/organisations/dashboard`);
    } else {
      history.push(`/organisations/login`);
    }
  }

  const logout = () => {
    const submitData = createSubmitData({session_id: localStorage.getItem("session_id")});
    axios.post('/api/v1/logout', submitData).then((res) => {});
    localStorage.removeItem("organisation_user_id");
    localStorage.removeItem("session_id");
    if (logoutEndpoint) {
      history.push(`/` + logoutEndpoint);
    } else {
      history.push(`/organisations/login`);
    }
  }

  let CareersProLogo = LogoLarge;
  if (screenSize <= layout.numericalBreakpoints.VSM) {
    CareersProLogo = LogoSmall;
  }

  return (
    <MenuContainer sticky={sticky}>
      <MenuRow>
        <Left xs={6} style={{alignItems:'center'}}>  
          {showMenuIcon && (
            !showMenu ? (
              <MenuIcon src={iconMenu} alt="Open menu" onClick={() => {setShowMenu(true)}} />
            ) : (
              <MenuIcon src={iconClose} alt="Close menu" onClick={() => {setShowMenu(false)}} />
            )
          )}
          {showCareersProLogo ? (
            <img src={CareersProLogo} alt="CareersPro Logo" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}} />
          ) : (
            <Link to={logoLink ? logoLink : "/"} style={{cursor:'pointer'}}>
              <img src={LogoSmall} alt="CareersPro Logo" />
            </Link>
          )}
          {(screenSize > layout.numericalBreakpoints.MD || !pageWelcome) && (<PageTitle>{pageTitle}</PageTitle>)}
        </Left>
        <Right xs={6}>
          {pageWelcome && (<PageWelcome>{pageWelcome}</PageWelcome>)}
          {showLogin && (
            <PrimaryButton small={screenSize <= layout.numericalBreakpoints.MD} onClick={() => {login();}}>{isLoggedIn ? "View dashboard" : "Login"}</PrimaryButton>
          )}
          {!showLogin && showLogout && (<PrimaryButton small={screenSize <= layout.numericalBreakpoints.MD} onClick={() => {logout();}}>Logout</PrimaryButton>)}
        </Right>
      </MenuRow>
      {showMenuIcon && menuItems && (
        <MenuContents showMenu={showMenu}>
          {menuItems.map(menu_item => (
            isCollege || !menu_item.colleges_only ? (
              <Link key={menu_item.key} to={menu_item.href}>
                <MenuLink onClick={() => {setShowMenu(false)}}>{menu_item.menu_name}</MenuLink>
              </Link>
             ) : null
          ))}
        </MenuContents>
      )}
    </MenuContainer>
  )
};

export default TopBar;

const MenuContainer = styled.div`
  background-color: ${colors.LCG_GREY};
  ${props => props.sticky && "position: -webkit-sticky;"}
  ${props => props.sticky && "position: sticky;"}
  top: 0;
  z-index: 1;
  margin-bottom: 20px;
`;

const MenuRow = styled.div`
  padding: 10px 20px 10px 20px;
  display: flex;
  line-height: 0;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 10px 12px 10px 12px;
  }
`

const MenuContents = styled.div`
  margin-top: ${props => props.showMenu ? '20px' : 0};
  padding-bottom: ${props => props.showMenu ? '5px' : 0};
  max-height: ${props => props.showMenu ? '1000px' : 0};
  overflow: hidden;
  transition: max-height 0.65s;
  width: 100%;  
`

const MenuIcon = styled.img`
  vertical-align: middle;
  margin-left: 12px;
  width: 36px;
  margin-right: 20px;
`;

const MenuLink = styled(H4)`
  text-align: center;
  padding-bottom: 20px;
  color: ${colors.WHITE}
`

const Left = styled.div`
  margin: auto auto auto 0;
  text-align: center;
  display: flex;
`;

const Right = styled.div`
  margin: auto 0 auto auto;
  text-align: right;
  display: flex;

  @media (min-width: ${layout.breakpoints.MD}) {
    display: flex;
  }
`;

const PageTitle = styled(H4)`
  margin: auto 0 auto 20px;
  color: ${colors.WHITE};

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: auto 0 auto 12px;
  }
`
const PageWelcome = styled(H4)`
  margin: auto 20px auto 0;
  color: ${colors.WHITE};

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: auto 12px auto 0;
  }
`
