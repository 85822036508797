/* Homepage > Get started > Any employment status except student > Location screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import axios from 'axios';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch, useHistory } from 'react-router-dom';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import { PrimaryButton, LCGPurpleButton, GrayButton } from '../../ui/buttons/Buttons';
import { P, HintText } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';
import AutocompleteField from '../../ui/form/AutocompleteField';
import { copyArray } from '../../utils/copyArray';
import { cleanStringForComparison, createSubmitData } from '../../utils/helper';
import Tooltip from '../../components/Tooltip';

const TakeQuizScreen = () => {

  const [ careerToAdd, setCareerToAdd ] = useState({value: "", placeholder: "e.g. detective, software engineer...", error: null});
  const [ careerToAddID, setCareerToAddID ] = useState(null);
  const [ careerToAddName, setCareerToAddName ] = useState(null);

  const [isLoading, setIsLoading] = useState([]);
  const [ loadingText, setLoadingText ] = useState();
  const [{ loading, context }, dispatch] = useStateValue();

  const [ careersAutocompleteOptions, setCareersAutocompleteOptions ] = useState(null);

  const history = useHistory();
  const match = useRouteMatch("/:userId/take-quiz");

  useGetScreenData('take-quiz', setIsLoading, match.params.userId);

  useEffect(() => {
    // Set the careersAutocompleteOptions
    if (context && context.careers_autocomplete_options) {
      setCareersAutocompleteOptions(context.careers_autocomplete_options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const startQuiz = () => {
    setLoadingText(context.loading_message);
    setIsLoading(true);

    const submitData = createSubmitData({
      user_id: match.params.userId,
    });

    axios.post('/api/v1/handleTakeQuiz', submitData).then((res) => {
      if (res.data.screen.context.question_number) {
        dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
        setTimeout(() => {
          setIsLoading(false);
          setLoadingText(null);
          history.push(`/${match.params.userId}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
        }, 2500);
      } else {
        setIsLoading(false);
        setLoadingText(null);
        history.push(`/${match.params.userId}/${res.data.screen.name}`);
      }
    });
  };

  const getMatchedCareer = (item) => {
    const itemLowerCase = (item !== null) ? cleanStringForComparison(item.toLowerCase()) : item;
    let matchedCareer = null;
    if (careersAutocompleteOptions) {
      for (let i=0; i<careersAutocompleteOptions.strings.length && !matchedCareer; i++) {
        if (cleanStringForComparison(careersAutocompleteOptions.strings[i].name.toLowerCase()) === itemLowerCase) {
          matchedCareer = {
            id: careersAutocompleteOptions.strings[i].id,
            name: careersAutocompleteOptions.strings[i].name
          };
        } else {
          for (let j=0; j<careersAutocompleteOptions.strings[i].aka.length && !matchedCareer; j++) {
            if (cleanStringForComparison(careersAutocompleteOptions.strings[i].aka[j].name.toLowerCase()) === itemLowerCase) {
              matchedCareer = {
                id: careersAutocompleteOptions.strings[i].id,
                name: careersAutocompleteOptions.strings[i].name + " (" + careersAutocompleteOptions.strings[i].aka[j].name + ")"
              };
            }
          }
        }
      }
    }
    return matchedCareer;
  };

  const onSearchFieldBlur = (item) => {
    let matchedCareer = getMatchedCareer(item);
    if (!matchedCareer && item !== '') {
      let copy = copyArray(careerToAdd);
      copy.error = "This is not a valid career";
      setCareerToAdd(copy);
    }
  };

  const handleSearchFieldChange = (value) => {
    let copy = copyArray(careerToAdd);

    copy.error = null;
    copy.value = value.name || value;

    setCareerToAdd(copy);
    prepData(copy);
  };

  const prepData = (userCareer) => {
    let userCareerToAddID = null;
    let userCareerToAddName = null;
    let matchedCareer = getMatchedCareer(userCareer.value);
    if (matchedCareer) {
      userCareerToAddID = matchedCareer.id;
      userCareerToAddName = matchedCareer.name;
    }
    setCareerToAddID(userCareerToAddID);
    setCareerToAddName(userCareerToAddName);
  };

  const validateForm = () => {
    let isValid = true;
    if (!careerToAddID || careerToAddID < 0) isValid = false;
    return isValid;
  };

  const goToCareer = () => {
    setLoadingText("Loading " + careerToAddName + "...");
    setIsLoading(true);

    const submitData = createSubmitData({
      user_id: match.params.userId,
      career_id: careerToAddID,
    });

    axios.post('/api/v1/handleGoToCareer', submitData).then((res) => {
      history.push(`/${match.params.userId}/${res.data.screen.name}`);
      setTimeout(() => {
        setIsLoading(false);
        setLoadingText(null);
      }, 2000);
    });
  };

  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  return (
    <>
      <MetaTags>
        <title>Take our career quiz?</title>
        <meta id="meta-description" name="description" content="Take our career quiz?" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Take our career quiz?"
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <Text>If you're not sure what career to pursue, or would like some inspiration, take our AI-powered career quiz and get matched to careers that match your interests</Text>
              <ButtonWrap>
                <PrimaryButton onClick={() => startQuiz()} data-testid="next">Start quiz</PrimaryButton>
              </ButtonWrap>
            </QuestionContainer>
            <QuestionContainer>
              <Text>Or if you're set on pursuing a specific career, you can skip the quiz and jump straight to that career by searching for it below...</Text>
              <HintText>Type a career and select from available options to add to your list</HintText>
              <AutocompleteField
                placeholder={careerToAdd.placeholder}
                suggestions={careersAutocompleteOptions}
                handleChange={it => handleSearchFieldChange(it)}
                onBlur={e => onSearchFieldBlur(e.target.value)}
                error={careerToAdd.error}
              />
              <ButtonWrap>
                {validateForm() ? (
                  <LCGPurpleButton onClick={() => {goToCareer()}}>Go to career</LCGPurpleButton>
                ) : (
                  <Tooltip text="Type and select a valid career in the box above">
                    <GrayButton disabled>Go to career</GrayButton>
                  </Tooltip>
                )}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
      </ThreeDotsBgLayout>
    </>
  );
};

export default TakeQuizScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const Text = styled(P)`
  text-align: center;
`

const ButtonWrap = styled.div`
  margin-top: 32px;
  margin-bottom: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 20px;
    margin-bottom: 32px;
  }
`;
