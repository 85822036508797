import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import colors from '../config/colors';
import IconPlus from '../assets/icons/plus.svg';
import IconMinus from '../assets/icons/minus.svg';
import { P, PSmall } from '../ui/typography/Typography';

const IncrementInput = ({ values, lowestValue, highestValue, startValue, handleChange, reverse, canEdit, width, extra_text_to_show }) => {

  const [inputBoxValue, setInputBoxValue ] = useState(startValue);
  const [selectedValue, setSelectedValue ] = useState(startValue);
  const [inputValueError, setInputValueError ] = useState(null);

  const setValidValue = value => {
    setInputBoxValue(value);
    setSelectedValue(parseInt(value));
    validateField(value, true);
  }

  const handleValueIncrement = increment => {
    if (values) {
      const valueIndex = values.findIndex(item => item.id === selectedValue.id);
      if (selectedValue.id !== values[0].id && !increment) {
        setSelectedValue(values[valueIndex - 1]);
        handleChange(values[valueIndex - 1]);
      }
      if (selectedValue.id !== values[values.length - 1].id && increment) {
        setSelectedValue(values[valueIndex + 1]);
        handleChange(values[valueIndex + 1]);
      }
    } else if (lowestValue !== null && highestValue !== null) {
      let incrementValue = 0;
      if (increment) {
        if (selectedValue >= 2000) {
          incrementValue = 500;
        } else if (selectedValue >= 1000) {
          incrementValue = 100;
        } else if (selectedValue >= 200) {
          incrementValue = 50;
        } else if (selectedValue >= 100) {
          incrementValue = 10;
        } else if (selectedValue >= 20) {
          incrementValue = 5;
        } else {
          incrementValue = 1;
        }
      } else {
        if (selectedValue <= 20) {
          incrementValue = -1;
        } else if (selectedValue <= 100) {
          incrementValue = -5;
        } else if (selectedValue <= 200) {
          incrementValue = -10;
        } else if (selectedValue <= 1000) {
          incrementValue = -50;
        } else if (selectedValue <= 2000) {
          incrementValue = -100;
        } else {
          incrementValue = -500;
        }
      }

      if (increment) {
        let newValue = Math.floor((selectedValue + incrementValue) / incrementValue) * incrementValue;
        setValidValue(Math.min(newValue, highestValue));
        handleChange(Math.min(newValue, highestValue));
      } else {
        let newValue = selectedValue + incrementValue;
        if (selectedValue % Math.abs(incrementValue) !== 0) {
          newValue =  Math.floor(selectedValue / Math.abs(incrementValue)) * Math.abs(incrementValue);
        }
        setValidValue(Math.max(newValue, lowestValue));
        handleChange(Math.max(newValue, lowestValue));
      }
    }
  };

  const validateField = (value, addError) => {
    let valueAsNumber = Number(value);
    let isValid = false;
    let error = null;

    if (typeof valueAsNumber !== "number") {
      error = "Please enter a number";
    } else if (valueAsNumber - Math.floor(valueAsNumber) !== 0) {
      error = "Please enter a whole number";
    } else if (lowestValue !== null && valueAsNumber < lowestValue) {
      error = "Please enter a value greater than or equal to " + lowestValue.toLocaleString();
    } else if (highestValue !== null && valueAsNumber > highestValue) {
      error = "Please enter a value less than or equal to " + highestValue.toLocaleString();
    } else {
      error = null;
      isValid = true;
    }

    if (addError) {
      setInputValueError(error);
    }

    return isValid;
  };

  const handleValueChange = (e) => {
    let newValue = e.target.value;
    if (newValue === "" || /^\d+$/.test(newValue)) {
      // Digits only
      setInputBoxValue(newValue);
    }
    if (validateField(e.target.value, false)) {
      setSelectedValue(parseInt(newValue));
      handleChange(parseInt(newValue));
    } else {
      handleChange(null);
    }
  };

  return(
    <>
      <ParentContainer>
        <Container width={width}>
          {reverse ? (
            <ButtonPlus onClick={() => handleValueIncrement(true)} />
          ) : (
            <ButtonMinus onClick={() => handleValueIncrement(false)} />
          )}
          {selectedValue.name || !canEdit ?
            <Value>{selectedValue.name !== null ? selectedValue.name : selectedValue}</Value> :
            <InputContainer>
              <InputBox
                type="text"
                error={inputValueError}
                value={inputBoxValue}
                onBlur={(it) => validateField(it.target.value, true)}
                onChange={(it) => handleValueChange(it)}
                disabled={false}
                text_align="center"
              />
            </InputContainer>
          }
          {reverse ? (
            <ButtonMinus onClick={() => handleValueIncrement(false)} />
          ) : (
            <ButtonPlus onClick={() => handleValueIncrement(true)} />
          )}
        </Container>
        {extra_text_to_show && (<ExtraTextToShow>{extra_text_to_show}</ExtraTextToShow>)}
      </ParentContainer>
      {inputValueError && (
        <Error>
          <PSmall color={colors.LCG_DARK_PURPLE}>{inputValueError}</PSmall>
        </Error>
      )}
    </>
  );
};

export default IncrementInput;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Container = styled.div`
  border-radius: 8px;
  background: ${colors.LCG_DARK_GREY};
  display: flex;
  width: ${props => props.width ? props.width : "140px"};
  overflow: hidden;
`;

const ExtraTextToShow = styled(P)`
  margin-bottom: 0;
  margin-left: 20px;
`

const ButtonStyle = css`
  width: 48px;
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;

  &:active,
  &:focus {
    outline: none;
  }
`;

const ButtonMinus = styled.button`
  ${ButtonStyle};
  background-image: url(${IconMinus});
`;

const ButtonPlus = styled.button`
  ${ButtonStyle};
  background-image: url(${IconPlus});
`;

const Value = styled.div`
  flex: 1;
  margin: 2px 0;
  background-color: ${colors.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  position: relative;
  width: 50%;
`;

const InputBox = styled.input`
  ${props => props.error && `background-color: ${colors.LCG_DARK_PURPLE_FADE};`}
  border-radius: 8px;
  border: 0;
  border: 2px solid ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_DARK_GREY};
  font-size: 20px;
  line-height: 30px;
  padding: 8px 10px;
  width: 100%;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  ${props => props.text_align && `text-align: ${props.text_align};`}

  &:focus {
    border-color: ${colors.LCG_LIGHT_PURPLE};
    outline: none;
  }

  &:disabled {
    background-color: ${colors.LCG_DARK_GREY};
  }

`;

const Error = styled.div`
  margin-top: 4px;
`;
