/* Dashboard (pay-as-you-go) > Add users ("Add from CSV" tab) */

import React, { useState, createRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';

import { useStateValue } from '../../services/state';

import layout from '../../config/layout';
import colors from '../../config/colors';

import { validateEmail } from '../../utils/validation';
import { createOrgSubmitData, getPriceText } from '../../utils/helper';

import { P, PSmall, PVSmall, Error } from '../../ui/typography/Typography';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
import Checkbox from '../../ui/form/Checkbox';
import LoadingScreen from '../LoadingScreen';
import CSVExampleImage from '../../assets/csv_example.png';
import CSVExampleImageCollege from '../../assets/csv_example_college.png';

const AddUsersFromCSVScreen = () => {

  const history = useHistory();

  const [{ context, loading }] = useStateValue();

  const [users, setUsers] = useState([]);
  const [invite, setInvite] = useState(false);

  const [ csvError, setCSVError] = useState(null);
  const [ generalError, setGeneralError ] = useState(null);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ loadingText, setLoadingText ] = useState();

  const inputFile = createRef();

  const importFromCSV = () => {
    inputFile.current.click();
  }

  const setCSVErrorAndReserUsers = (csvError) => {
    setCSVError(csvError);
    setUsers([]);
  }

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let input = event.target;
    if (!input) {
      setCSVErrorAndReserUsers("Error: Your browser does not properly implement the event object.");
    } else if (!input.files) {
      setCSVErrorAndReserUsers("Error: Your browser does not support the `files` property of the file input.");
    } else if (!input.files[0]) {
      setCSVErrorAndReserUsers("Error: No file selected.");
    } else {
      let file = input.files[0];
      if (!file.type || !file.type.includes("csv")) {
        setCSVErrorAndReserUsers("Error: You must select a CSV file (a file ending in '.csv')");
      } else {
        let fr = new FileReader();
        fr.readAsText(file, "UTF-8");
        fr.onload = function(evt) {
          let parsedData = Papa.parse(evt.target.result, {
            delimiter: ",",
            header: true
          });
          if (!parsedData || !parsedData.meta || !parsedData.meta.fields) {
            setCSVErrorAndReserUsers("Error: Couldn't parse the header fields");
          } else {
            let foundFirstNameHeader = false;
            let foundLastNameHeader = false;
            let foundEmailHeader = false;
            var foundStudentIDHeader = false;
            var foundLearnerStatusHeader = false;
            for (let i=0; i<parsedData.meta.fields.length; i++) {
              if (parsedData.meta.fields[i] === "first name") {
                foundFirstNameHeader = true;
              } else if (parsedData.meta.fields[i] === "last name") {
                foundLastNameHeader = true;
              } else if (parsedData.meta.fields[i] === "email") {
                foundEmailHeader = true;
              } else if (parsedData.meta.fields[i] === "student id") {
                foundStudentIDHeader = true;
              } else if (parsedData.meta.fields[i] === "learner status") {
                foundLearnerStatusHeader = true;
              }
            }
            if (!foundFirstNameHeader) {
              setCSVErrorAndReserUsers("Error: 'first name' header is missing");
            } else if (!foundLastNameHeader) {
              setCSVErrorAndReserUsers("Error: 'last name' header is missing");
            } else if (!foundEmailHeader) {
              setCSVErrorAndReserUsers("Error: 'email' header is missing");
            } else if (!foundStudentIDHeader) {
              setCSVErrorAndReserUsers("Error: 'student id' header is missing");
            } else if (!foundLearnerStatusHeader) {
              setCSVErrorAndReserUsers("Error: 'learner status' header is missing");
            } else if (!parsedData.data || parsedData.data.length === 0) {
              setCSVErrorAndReserUsers("Error: No users found");
            } else {
              let csvError = null;
              let validUsers = [];
              for (let i=0; i<parsedData.data.length && !csvError; i++) {
                if ( (parsedData.data[i]["first name"] && parsedData.data[i]["first name"] !== "") ||
                     (parsedData.data[i]["last name"] && parsedData.data[i]["last name"] !== "") ||
                     (parsedData.data[i]["email"] && parsedData.data[i]["email"] !== "") ||
                     (parsedData.data[i]["student id"] && parsedData.data[i]["student id"] !== "") ) {
                  // At least one of the values has been specified, so they must all be valid otherwise we'll show an error
                  if (!parsedData.data[i]["first name"] || parsedData.data[i]["first name"] === "") {
                    csvError = "First name missing for user";
                    if (parsedData.data[i]["email"] && parsedData.data[i]["email"] !== "") {
                      csvError += " with email '" + parsedData.data[i]["email"] + "'";
                    } else if (parsedData.data[i]["student id"] && parsedData.data[i]["student id"] !== "") {
                      csvError += " with student id '" + parsedData.data[i]["student id"] + "'";
                    } else {
                      csvError += " with last name '" + parsedData.data[i]["last name"] + "'";
                    }
                  } else if (!parsedData.data[i]["last name"] || parsedData.data[i]["last name"] === "") {
                    csvError = "Last name missing for user";
                    if (parsedData.data[i]["email"] && parsedData.data[i]["email"] !== "") {
                      csvError += " with email '" + parsedData.data[i]["email"] + "'";
                    } else if (parsedData.data[i]["student id"] && parsedData.data[i]["student id"] !== "") {
                      csvError += " with student id '" + parsedData.data[i]["student id"] + "'";
                    } else {
                      csvError += " with first name '" + parsedData.data[i]["first name"] + "'";
                    }
                  } else if (!parsedData.data[i]["email"] || parsedData.data[i]["email"] === "") {
                    csvError = "Email missing for user";
                    if (parsedData.data[i]["last name"] && parsedData.data[i]["last name"] !== "") {
                      csvError += " with last name '" + parsedData.data[i]["last name"] + "'";
                    } else if (parsedData.data[i]["student id"] && parsedData.data[i]["student id"] !== "") {
                      csvError += " with student id '" + parsedData.data[i]["student id"] + "'";
                    } else {
                      csvError += " with first name '" + parsedData.data[i]["first name"] + "'";
                    }
                  } else if (!parsedData.data[i]["student id"] || parsedData.data[i]["student id"] === "") {
                    csvError = "Student id missing for user";
                    if (parsedData.data[i]["last name"] && parsedData.data[i]["last name"] !== "") {
                      csvError += " with last name '" + parsedData.data[i]["last name"] + "'";
                    } else if (parsedData.data[i]["email"] && parsedData.data[i]["email"] !== "") {
                      csvError += " with email '" + parsedData.data[i]["email"] + "'";
                    } else {
                      csvError += " with first name '" + parsedData.data[i]["first name"] + "'";
                    }
                  } else if (!parsedData.data[i]["learner status"] || parsedData.data[i]["learner status"] === "") {
                    csvError = "Learner status missing for user";
                    if (parsedData.data[i]["last name"] && parsedData.data[i]["last name"] !== "") {
                      csvError += " with last name '" + parsedData.data[i]["last name"] + "'";
                    } else if (parsedData.data[i]["email"] && parsedData.data[i]["email"] !== "") {
                      csvError += " with email '" + parsedData.data[i]["email"] + "'";
                    } else if (parsedData.data[i]["student id"] && parsedData.data[i]["student id"] !== "") {
                      csvError += " with student id '" + parsedData.data[i]["student id"] + "'";
                    } else {
                      csvError += " with first name '" + parsedData.data[i]["first name"] + "'";
                    }
                  } else if (!validateEmail(parsedData.data[i]["email"])) {
                    csvError = "Invalid email specified: '" + parsedData.data[i]["email"] + "'";
                  } else {
                    validUsers.push({
                      first_name: parsedData.data[i]["first name"],
                      last_name: parsedData.data[i]["last name"],
                      email: parsedData.data[i]["email"].trim(),
                    });
                      
                    validUsers[validUsers.length-1].student_id = parsedData.data[i]["student id"].trim();
                    validUsers[validUsers.length-1].learner_status = parsedData.data[i]["learner status"];
                    if (parsedData.data[i]["department"]) {
                      validUsers[validUsers.length-1].department = parsedData.data[i]["department"];
                    }
                    if (parsedData.data[i]["course code"]) {
                      validUsers[validUsers.length-1].course_code = parsedData.data[i]["course code"];
                    }
                    if (parsedData.data[i]["course title"]) {
                      validUsers[validUsers.length-1].course_title = parsedData.data[i]["course title"];
                    }
                    if (parsedData.data[i]["age group"]) {
                      validUsers[validUsers.length-1].age_group = parsedData.data[i]["age group"];
                    }
                    if (parsedData.data[i]["ethnicity"]) {
                      validUsers[validUsers.length-1].ethnicity = parsedData.data[i]["ethnicity"];
                    }
                    if (parsedData.data[i]["sex"]) {
                      validUsers[validUsers.length-1].sex = parsedData.data[i]["sex"];
                    }

                    // Now go through context.users to see if this user exists by their email or student id, and
                    // if so set "new" to false, otherwise set "new" to true (ignore the case when comparing)
                    let found = false;
                    for (let j = 0; j < context.users.length; j++) {
                      if ((context.users[j].email && context.users[j].email.toLowerCase() === parsedData.data[i]["email"].toLowerCase()) ||
                          (context.users[j].student_id && context.users[j].student_id.toLowerCase() === parsedData.data[i]["student id"].toLowerCase())) {
                        validUsers[validUsers.length-1].new = false;
                        found = true;
                        break;
                      }
                    }
                    if (!found) {
                      validUsers[validUsers.length-1].new = true;
                    }
                  }
                }
              }
              if (csvError) {
                setCSVErrorAndReserUsers("Error: " + csvError);
              } else {
                if (validUsers.length === 0) {
                  setCSVErrorAndReserUsers("Error: No users found");
                } else {
                  setCSVErrorAndReserUsers(null);
                  setUsers(validUsers);
                }
              }
            }
          }
        }
      }
    }
    // Reset the form in case the same file is uploaded again
    event.target.value = '';
  }

  const validateForm = () => {
    let isValid = false;
    if (users.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  const submitForm = () => {
    if (!validateForm()) return;

    setIsLoading(true);

    const orgSubmitData = createOrgSubmitData({
      organisation_id: context.organisation_id,
      education_institution_id: context.education_institution_id,
      organisation_user_id: context.organisation_user_id,
      users: users,
      send_emails: invite,
      source: "csv",
    });

    axios.post('/api/v1/create_users', orgSubmitData).then((res) => {
      if (res.status === 201) {
        let prefix = newUsers.length > 0 && existingUsers.length > 0 ? `Adding/updating ` : newUsers.length > 0 ? `Adding ` : `Updating `;
        setLoadingText(prefix + users.length.toLocaleString() + " user" + (users.length > 1 ? "s" : "") + "...");
        setTimeout(() => {
          setIsLoading(false);
          history.push(`/organisations/dashboard`);
        }, 2000);
      } else {
        setIsLoading(false);
        if (res.data.error) {
          setGeneralError(res.data.error);
        }
      }
    }).catch((error) => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          setGeneralError(error.response.data.error);
        } else if (error.response.statusText) {
          setGeneralError(error.response.statusText);
        }
        if (error.response.status === 401) {
          localStorage.removeItem("organisation_user_id");
          localStorage.removeItem("session_id");
          history.push(`/organisations/login`);
        }
      }
    });
  };

  if (loading || isLoading || !context) return <LoadingScreen text={loadingText} />;

  let newUsers = [];
  let existingUsers = [];
  if (users && users.length > 0) {
    for (let i = 0; i < users.length; i++) {
      if (users[i].new === false) {
        existingUsers.push(users[i]);
      } else {
        newUsers.push(users[i]);
      }
    }
  }

  return (
    <Container>
      <Text>Ensure your CSV has 5 columns called:</Text>
      <Text bold>first name, last name, email student id, learner status</Text>
      {/*<img src={context.is_college ? CSVExampleImageCollege : CSVExampleImage} alt="CSV example" />*/}
      <img src={CSVExampleImageCollege} alt="CSV example" />
      <Text pt>You can also include the below optional fields.  This information will be stored against each student and included when you export their details from the main dashboard.</Text>
      <Text bold>department, course code, course title, age group, ethnicity, sex</Text>
      <LinkWrap>
        <DownloadLink underline href={context.site_url + '/files/csv_example.csv'} download>Download template</DownloadLink>
      </LinkWrap>
      <ImportButtonWrap>
        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e) => onChangeFile(e)} />
        <PrimaryButton onClick={() => importFromCSV()}>Import from CSV</PrimaryButton>
        {csvError && (<ErrorStyled>{csvError}</ErrorStyled>)}
      </ImportButtonWrap>
      {newUsers.length > 0 && (
        <UsersContainer>
          <Text bold>New users to add:</Text>
          {newUsers.map((item, key) => (
            <PSmall key={key}>{key+1}. {item.first_name} {item.last_name} &lt;{item.email}&gt;, {item.student_id}</PSmall>
          ))}
        </UsersContainer>
      )}
      {existingUsers.length > 0 && (
        <UsersContainer>
          <Text bold>Existing users to update:</Text>
          {existingUsers.map((item, key) => (
            <PSmall key={key}>{key+1}. {item.first_name} {item.last_name} &lt;{item.email}&gt;, {item.student_id}</PSmall>
          ))}
        </UsersContainer>
      )}
      <CheckboxWrap>
        <Checkbox
          label={"Invite these users via email"}
          name="invite"
          handleChange={e => setInvite(e)}
        />
      </CheckboxWrap>
      <ButtonWrap>
        {users.length > 0 ? (
          <PrimaryButton onClick={() => submitForm()}>{context.is_college ? (newUsers.length > 0 && existingUsers.length > 0 ? `Add/update ` : newUsers.length > 0 ? `Add ` : `Update `) : `Add `}{users.length.toLocaleString()} users</PrimaryButton>
        ) : (
          <Tooltip text="Please import a valid CSV">
            <GrayButton>{context.is_college ? `Add/update users` : `Add users`}</GrayButton>
          </Tooltip>
        )}
      </ButtonWrap>
      {users.length > 0 && !context.internal && context.num_licences !== -1 && (
          <SubmitButtonUnderText text-align="center">This will use up {users.length.toLocaleString()} of your licences, leaving you with {(context.num_licences - users.length).toLocaleString()} remaining</SubmitButtonUnderText>
      )}
      {generalError && <ErrorStyled>{generalError}</ErrorStyled>}
    </Container>
  );
};

export default AddUsersFromCSVScreen;

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px 20px 8px 20px;
  text-align: center;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 12px 4px 12px;
  }
`

const Text = styled(P)`
  text-align: center;
  ${props => props.pt ? "padding-top: 20px;" : ""};
`

const CheckboxWrap = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;

  @media (max-width: ${layout.breakpoints.SM}) {
    padding-top: 12px;
    padding-bottom: 0px;
  }
`

const ButtonWrap = styled.div`
  margin-bottom: 8px;
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const ImportButtonWrap = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const SubmitButtonUnderText = styled(PVSmall)`
  text-align: center;
  padding-top: 8px;
`

const ErrorStyled = styled(Error)`
  text-align: center;
`

const LinkWrap = styled.div`
  padding-top: 12px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 8px;
  }
`

const DownloadLink = styled.a`
  color: ${colors.TEXT_DARK};
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: ${colors.LCG_LIGHT_PURPLE_DARK};
  }
`

const UsersContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 12px;
    padding-bottom: 8px;
  }
`
