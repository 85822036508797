import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import layout from '../../config/layout';
import { P, PSmall, Label, HintText } from '../typography/Typography';

const Input = ({
  error,
  inputValue,
  handleChange,
  handleKeyDown,
  type,
  placeholder,
  label,
  left_label,
  hint,
  testid,
  valueFormatting,
  onBlur,
  id,
  name,
  disabled,
  required,
  width,
  text_align,
  prefix,
}) => {
  const [value, setValue] = useState((inputValue) ? inputValue : "");

  const handleSelect = (e) => {
    let newValue = e.target.value
    if (valueFormatting) {
      newValue = valueFormatting(newValue);
    }
    setValue(newValue);
    handleChange(newValue);
  };

  return (
    <Container width={width}>
      {label && (
        <Label htmlFor={value}>{label}{required && (<Required> *</Required>)}</Label>
      )}
      {hint && (
        <HintText>{hint}</HintText>
      )}
      <InputContainer left_label={left_label}>
        {left_label && (
          <PLeftLabel>{left_label}</PLeftLabel>
        )}
        <InputBox
          type={type}
          error={error && error}
          value={((prefix && value && value.length > 0 && value.charAt(0) !== prefix) ? prefix : '') + value}
          onChange={handleSelect}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          id={id} name={name}
          data-testid={testid}
          onBlur={onBlur}
          disabled={disabled}
          text_align={text_align}
        />
      </InputContainer>
      {error && (
        <Error>
          <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
        </Error>
      )}
    </Container>
  );
};

export default Input;

Input.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  testid: PropTypes.string,
  width: PropTypes.string,
  text_align: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};

const Container = styled.div`
  position: relative;
  width: ${props => props.width ? props.width : "100%"};
`;

const InputContainer = styled.div`
  ${props => props.left_label && `display: flex;`}
`

const PLeftLabel = styled(P)`
  margin: 8px 4px 0 0;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin: 12px 4px 0 0;
  }
`

const InputBox = styled.input`
  background-color: ${props => props.error ? colors.LCG_DARK_PURPLE_FADE : 'transparent'};
  border-radius: 8px;
  border: 0;
  border: 2px solid ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_LIGHT_GREY};
  font-size: 20px;
  line-height: 30px;
  padding: 8px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${props => props.text_align && `text-align: ${props.text_align};`};

  &:focus {
    border-color: ${colors.LCG_LIGHT_PURPLE};
    outline: none;
  }

  &:disabled {
    background-color: ${colors.LCG_LIGHTER_GREY};
  }
`;

const Error = styled.div`
  margin-top: 4px;
`;

const Required = styled.span`
  color: ${colors.LCG_DARK_PURPLE};
`;
