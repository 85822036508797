/* Homepage > Get started > In education at University > University screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { cleanStringForComparison, createSubmitData } from '../../utils/helper';
import { copyArray } from '../../utils/copyArray';
import { getNameByID, getNamesByIDs } from '../../utils/getNamesByIDs';
import { useHistory } from 'react-router-dom';
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Multiselect from '../../components/multiselect/Multiselect';
import { Label, HintText, Error } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import AutocompleteField from '../../ui/form/AutocompleteField';
import Checkbox from '../../ui/form/Checkbox';

const UniversityScreen = () => {

  const generateGraduationYears = () => {
    let graduationYears = [];
    // Compute a list of years for the next 6 years, starting with this year (up to the last day of the year,
    // just to give people the option in case their degree/masters/phd runs later than usual)
    var currentYear = (new Date()).getFullYear();
    for (var i=0; i<7; i++) {
      graduationYears.push({
        name: "" + (currentYear + i),
        selected: false,
        id: i,
        value: (currentYear + i),
      });
    }
    return graduationYears;
  };

  const [{ user_id, context, loading }, dispatch] = useStateValue();

  const [ studyingTowards, setStudyingTowards ] = useState([
    { name: 'Undergrad degree', selected: false, id: 0, value: "degree" },
    { name: 'Masters', selected: false, id: 1, value: "masters" },
    { name: 'PhD', selected: false, id: 2, value: "phd" },
    { name: 'Other', selected: false, id: 3, value: "other" }
  ]);

  const [ degreeSubjects, setDegreeSubjects ] = useState([
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. French", id: 1, error: null },
    { value: "", placeholder: "e.g. Business", id: 2, error: null }
  ]);
  const [ validatedDegreeSubjects, setValidatedDegreeSubjects ] = useState([]);

  const [ mastersSubjects, setMastersSubjects ] = useState([
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. French", id: 1, error: null },
    { value: "", placeholder: "e.g. Business", id: 2, error: null }
  ]);
  const [ validatedMastersSubjects, setValidatedMastersSubjects ] = useState([]);

  const [ phdSubjects, setPhdSubjects ] = useState([
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. French", id: 1, error: null },
    { value: "", placeholder: "e.g. Business", id: 2, error: null }
  ]);
  const [ validatedPhDSubjects, setValidatedPhDSubjects ] = useState([]);

  const [ otherCourse, setOtherCourse ] = useState({value: "", placeholder: "e.g. Foundation degree in Engineering"});

  const [ currentUniversity, setCurrentUniversity ] = useState({value: "", placeholder: "Your university name", error: null});
  const [ validatedCurrentUniversity, setValidatedCurrentUniversity ] = useState(null);

  const [ graduationYear, setGraduationYear ] = useState(generateGraduationYears());

  const [ degreeGrade, setDegreeGrade ] = useState([
    { name: '1st', selected: false, id: 0, value: "1st" },
    { name: '2:1', selected: false, id: 1, value: "2:1" },
    { name: '2:2', selected: false, id: 2, value: "2:2" },
    { name: '3rd', selected: false, id: 3, value: "3rd" },
    { name: 'I\'m not sure', selected: false, id: 4, value: "unsure" }
  ]);

  const [ mastersUniversity, setMastersUniversity ] = useState({value: "", placeholder: "Your masters university name", error: null, university_outside_uk: false});
  const [ validatedMastersUniversity, setValidatedMastersUniversity ] = useState(null);

  const [ degreeUniversity, setDegreeUniversity ] = useState({value: "", placeholder: "Your undergrad university name", error: null, university_outside_uk: false});
  const [ validatedDegreeUniversity, setValidatedDegreeUniversity ] = useState(null);

  const [ alevelSchool, setAlevelSchool ] = useState({value: "", placeholder: "Your school or college name", error: null, home_schooled: false, school_outside_uk: false});
  const [ validatedAlevelSchool, setValidatedAlevelSchool ] = useState(null);

  const [ sameSchoolForGCSEAlevel, setSameSchoolForGCSEAlevel ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false },
  ]);

  const [ gcseSchool, setGCSESchool ] = useState({value: "", placeholder: "Your school name", error: null, home_schooled: false, school_outside_uk: false});
  const [ validatedGCSESchool, setValidatedGCSESchool ] = useState(null);


  const [ generalError, setGeneralError ] = useState();

  const [ isLoading, setIsLoading ] = useState([]);

  const history = useHistory();
  const match = useRouteMatch("/:userId/university");

  useGetScreenData('university', setIsLoading, match.params.userId);

  useEffect(() => {
    if (context && context.education_institution_id !== null) {
      setValidatedCurrentUniversity(context.education_institution_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const resetMultiSelectState = (attribute, setMethod) => {
    const values = [...attribute];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      value.selected = false;
    }
    setMethod(values);
  };

  const resetSingleTextualInputState = (attribute, setMethod, setValidatedAttributeMethod, extraAttributesToNullify) => {
    let copy = copyArray(attribute);
    copy.value = "";
    copy.error = null;
    for (var i=0; i<extraAttributesToNullify.length; i++) {
      copy[extraAttributesToNullify[i]] = null;
    }
    setMethod(copy);
    if (setValidatedAttributeMethod) setValidatedAttributeMethod(null);
  };

  const resetMultiTextualInputState = (attribute, setMethod, setValidatedAttributeMethod) => {
    let copy = copyArray(attribute);
    // eslint-disable-next-line no-unused-vars
    for (const value of copy) {
      value.value = "";
      value.error = null;
    }
    setMethod(copy);
    if (setValidatedAttributeMethod) setValidatedAttributeMethod([]);
  };


  const handleStudyingTowardsChange = (id, data) => {
    const values = [...data];
    var isNowSelected = false;
    var otherSelected = false;
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
        if (value.selected) {
          isNowSelected = true;
          if (value.value === "other") {
            otherSelected = true;
          }
        }
      } else {
        value.selected = false;
      }
    }
    setStudyingTowards(values);

    // Any change should nullify all university information below
    resetMultiTextualInputState(degreeSubjects, setDegreeSubjects, setValidatedDegreeSubjects);
    resetMultiTextualInputState(mastersSubjects, setMastersSubjects, setValidatedMastersSubjects);
    resetMultiTextualInputState(phdSubjects, setPhdSubjects, setValidatedPhDSubjects);
    resetSingleTextualInputState(otherCourse, setOtherCourse, null, []);
    resetMultiSelectState(degreeGrade, setDegreeGrade);
    resetSingleTextualInputState(mastersUniversity, setMastersUniversity, setValidatedMastersUniversity, ["university_outside_uk"]);
    resetSingleTextualInputState(degreeUniversity, setDegreeUniversity, setValidatedDegreeUniversity, ["university_outside_uk"]);

    // Nullify school information if no item is selected, or "Other" is selected
    if (!isNowSelected || otherSelected) {
      resetSingleTextualInputState(alevelSchool, setAlevelSchool, setValidatedAlevelSchool, ["home_schooled", "school_outside_uk"]);
      resetMultiSelectState(sameSchoolForGCSEAlevel, setSameSchoolForGCSEAlevel);
      resetSingleTextualInputState(gcseSchool, setGCSESchool, setValidatedGCSESchool, ["home_schooled", "school_outside_uk"]);
    }

    // Nullify current university and graduation year if no item is now selected
    if (!isNowSelected) {
      if (context && context.education_institution_id === null) {
        resetSingleTextualInputState(currentUniversity, setCurrentUniversity, setValidatedCurrentUniversity, []);
      }
      resetMultiSelectState(graduationYear, setGraduationYear);
    }
  };

  const onDegreeFieldBlur = (item, id) => {
    const val = context.degree_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(degreeSubjects);
      if (item !== "" || id === 0) {
        copy[id].error = "This is not a valid subject";
      }
      setDegreeSubjects(copy);
    }
  };

  const handleDegreeInputFieldChange = (value, id) => {
    let copy = copyArray(degreeSubjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }
    setDegreeSubjects(copy);
    prepDegreeData(copy);
  };

  const prepDegreeData = (degreeSubjectsCopy) => {
    let validatedDegreeSubjectsToSave = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of degreeSubjectsCopy) {
      if (context.degree_autocomplete_options) {
        const val = context.degree_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          validatedDegreeSubjectsToSave.push({
            id: val.id,
          })
        }
      }
    }
    setValidatedDegreeSubjects(validatedDegreeSubjectsToSave);
  };

  const onMastersFieldBlur = (item, id) => {
    const userStudyingTowards = studyingTowards.find(a => a.selected === true);
    const val = context.masters_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(mastersSubjects);
      if (item !== "" || (id === 0 && userStudyingTowards && userStudyingTowards.value === "masters")) {
        copy[id].error = "This is not a valid subject";
      }
      setMastersSubjects(copy);
    }
  };

  const handleMastersInputFieldChange = (value, id) => {
    let copy = copyArray(mastersSubjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }
    setMastersSubjects(copy);
    prepMastersData(copy);
  };

  const prepMastersData = (mastersSubjectsCopy) => {
    let validatedMastersSubjectsToSave = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of mastersSubjectsCopy) {
      if (context.masters_autocomplete_options) {
        const val = context.masters_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          validatedMastersSubjectsToSave.push({
            id: val.id,
          })
        }
      }
    }
    setValidatedMastersSubjects(validatedMastersSubjectsToSave);
  };

  const onPhDFieldBlur = (item, id) => {
    const val = context.phd_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(phdSubjects);
      if (item !== "" || id === 0) {
        copy[id].error = "This is not a valid subject";
      }
      setPhdSubjects(copy);
    }
  };

  const handlePhDInputFieldChange = (value, id) => {
    let copy = copyArray(phdSubjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }
    setPhdSubjects(copy);
    prepPhDData(copy);
  };

  const prepPhDData = (phdSubjectsCopy) => {
    let validatedPhDSubjectsToSave = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of phdSubjectsCopy) {
      if (context.phd_autocomplete_options) {
        const val = context.phd_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          validatedPhDSubjectsToSave.push({
            id: val.id,
          })
        }
      }
    }
    setValidatedPhDSubjects(validatedPhDSubjectsToSave);
  };

  const handleOtherCourseChange = (value) => {
    let copy = copyArray(otherCourse);
    copy.value = value.name || value;
    setOtherCourse(copy);
  };

  const onUniversityFieldBlur = (item, attribute, setMethod) => {
    const val = context.university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(attribute);
      copy.error = "This is not a valid university";
      setMethod(copy);
    }
  };

  const handleUniversityInputFieldChange = (value, attribute, setMethod, setValidatedMethod) => {
    let copy = copyArray(attribute);

    copy.error = null;
    copy.value = value.name || value;

    setMethod(copy);
    prepUniversityData(copy, setValidatedMethod);
  };

  const handleUniversityOutsideUKChange = (value, attribute, setMethod, setValidatedMethod) => {
    let copy = copyArray(attribute);

    copy.university_outside_uk = value;

    setMethod(copy);
    prepUniversityData(copy, setValidatedMethod);
  };

  const prepUniversityData = (universityCopy, setValidatedMethod) => {
    let userValidatedCurrentUniversity = null;
    if (context.university_autocomplete_options) {
      const matchedUniversity = context.university_autocomplete_options.strings.find(it =>
        cleanStringForComparison(it.name.toLowerCase()) === ((universityCopy.value !== null) ? cleanStringForComparison(universityCopy.value.toLowerCase()) : universityCopy.value));
      if (universityCopy.university_outside_uk) {
        userValidatedCurrentUniversity = -1;
      } else if (matchedUniversity) {
        userValidatedCurrentUniversity = matchedUniversity.id;
      }
    }
    setValidatedMethod(userValidatedCurrentUniversity);
  };

  const onSchoolFieldBlur = (item, attribute, setMethod, errorMessage) => {
    const val = context.pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(attribute);
      copy.error = errorMessage;
      setMethod(copy);
    }
  };

  const handleSchoolInputFieldChange = (value, attribute, setMethod, setValidatedMethod) => {
    let copy = copyArray(attribute);

    copy.error = null;
    copy.value = value.name || value;

    setMethod(copy);
    prepSchoolData(copy, setValidatedMethod);
  };

  const handleSchoolCheckboxChange = (value, attribute, setMethod, setValidatedMethod, attributeName) => {
    let copy = copyArray(attribute);

    copy[attributeName] = value;

    setMethod(copy);
    prepSchoolData(copy, setValidatedMethod);
  };

  const prepSchoolData = (schoolCopy, setValidatedMethod) => {
    let userValidatedCurrentSchool = null;
    if (context.pre_university_autocomplete_options) {
      const matchedSchool =
        context.pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((schoolCopy.value !== null) ? cleanStringForComparison(schoolCopy.value.toLowerCase()) : schoolCopy.value));
      if (schoolCopy.home_schooled) {
        userValidatedCurrentSchool = -1;
      } else if (schoolCopy.school_outside_uk) {
        userValidatedCurrentSchool = -2;
      } else if (matchedSchool) {
        userValidatedCurrentSchool = matchedSchool.id;
      }
    }
    setValidatedMethod(userValidatedCurrentSchool);
  };

  const handleSameSchoolChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setSameSchoolForGCSEAlevel(values);

    // Any change should nullify GCSE school information
    resetSingleTextualInputState(gcseSchool, setGCSESchool, setValidatedGCSESchool, ["home_schooled", "school_outside_uk"]);
  };

  const handleGraduationYearChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setGraduationYear(values);
  };

  const validateForm = () => {
    let isValid = true;

    const userStudyingTowards = studyingTowards.find(a => a.selected === true);
    const userGraduationYear = graduationYear.find(a => a.selected === true);
    const userSameSchoolForGCSEAlevel = sameSchoolForGCSEAlevel.find(a => a.selected === true);

    if (!userStudyingTowards) isValid = false;

    if ( userStudyingTowards && userStudyingTowards.value === "degree" && validatedDegreeSubjects.length === 0 ) isValid = false;

    if ( userStudyingTowards && userStudyingTowards.value === "masters" && 
        ( validatedMastersSubjects.length === 0 || validatedDegreeSubjects.length === 0 || !validatedDegreeUniversity ) ) isValid = false;

    if ( userStudyingTowards && userStudyingTowards.value === "phd" &&
        ( validatedPhDSubjects.length === 0 ||
          ( validatedMastersSubjects.length > 0 && !validatedMastersUniversity ) ||
          validatedDegreeSubjects.length === 0 || !validatedDegreeUniversity) )
      isValid = false;

    if ( userStudyingTowards && userStudyingTowards.value === "other" && otherCourse.value === "" ) isValid = false;
    if ( !validatedCurrentUniversity ) isValid = false;
    if ( !userGraduationYear ) isValid = false;

    if ( userStudyingTowards && userStudyingTowards.value !== "other" &&
         ( !validatedAlevelSchool || !userSameSchoolForGCSEAlevel ||
           ( userSameSchoolForGCSEAlevel.value === false && !validatedGCSESchool ) ) ) isValid = false;

    return isValid;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {
      const userStudyingTowards = studyingTowards.find(a => a.selected === true);
      const userGraduationYear = graduationYear.find(a => a.selected === true);
      const userSameSchoolForGCSEAlevel = sameSchoolForGCSEAlevel.find(a => a.selected === true);

      setIsLoading(true);

      let incompleteQualifications = [];
      let otherQualifications = [];
      let userDegreeUniversity = null;
      let userMastersUniversity = null;
      let userPhdUniversity = null;

      if (userStudyingTowards.value === "degree") {
        incompleteQualifications = [...validatedDegreeSubjects];
        userDegreeUniversity = validatedCurrentUniversity;
      } else if (userStudyingTowards.value === "masters") {
        incompleteQualifications = [...validatedMastersSubjects];
        userMastersUniversity = validatedCurrentUniversity;
      } else if (userStudyingTowards.value === "phd") {
        incompleteQualifications = [...validatedPhDSubjects];
        userPhdUniversity = validatedCurrentUniversity;
      } else if (userStudyingTowards.value === "other") {
        otherQualifications.push(otherCourse.value);
      }

      let completedQualifications = [];
      if (userStudyingTowards.value === "phd") {
        completedQualifications = [...validatedMastersSubjects, ...validatedDegreeSubjects];
      } else if (userStudyingTowards.value === "masters") {
        completedQualifications = [...validatedDegreeSubjects];
      }

      const formData = createSubmitData({
        university_details: {
          university_studying_towards: userStudyingTowards.value,
          incomplete_qualifications: incompleteQualifications,
          complete_qualifications: completedQualifications,
          other_qualifications: otherQualifications,
          current_university: validatedCurrentUniversity,
          graduation_year: userGraduationYear.value,
          masters_university: validatedMastersUniversity,
          degree_university: validatedDegreeUniversity,
          alevel_school: validatedAlevelSchool,
          gcse_school: (userSameSchoolForGCSEAlevel && userSameSchoolForGCSEAlevel.value === false) ? validatedGCSESchool : validatedAlevelSchool,
        },
        user_id: user_id,
      });
      axios.post('/api/v1/handleUniversityInput', formData).then((res) => {
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('non-field-errors')) {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          dispatch({type: 'updateResponse', data: res.data});
          logEvent('Completed University Input', {
            university_studying_towards: userStudyingTowards.value,
            degree_incomplete: (userStudyingTowards.value === "degree" && validatedDegreeSubjects.length > 0) ? getNamesByIDs(validatedDegreeSubjects, context.degree_autocomplete_options, "id") : null,
            masters_incomplete: (userStudyingTowards.value === "masters" && validatedMastersSubjects.length > 0) ? getNamesByIDs(validatedMastersSubjects, context.masters_autocomplete_options, "id") : null,
            phd_incomplete: (userStudyingTowards.value === "phd" && validatedPhDSubjects.length > 0) ? getNamesByIDs(validatedPhDSubjects, context.phd_autocomplete_options, "id") : null,
            other_qualifications_incomplete: (otherQualifications.length > 0) ? otherQualifications : null,
            current_university: (validatedCurrentUniversity ? getNameByID(validatedCurrentUniversity, context.university_autocomplete_options) : null),
            degree: ((userStudyingTowards.value === "masters" || userStudyingTowards.value === "phd") ? getNamesByIDs(validatedDegreeSubjects, context.degree_autocomplete_options, "id") : null),
            degree_university: (userDegreeUniversity ? getNameByID(userDegreeUniversity, context.university_autocomplete_options) : getNameByID(validatedDegreeUniversity, context.university_autocomplete_options)),
            degree_university_outside_uk: (userDegreeUniversity === -1 || validatedDegreeUniversity === -1) ? true : null,
            masters: (userStudyingTowards.value === "phd" ? getNamesByIDs(validatedMastersSubjects, context.masters_autocomplete_options, "id") : null),
            masters_university: (userMastersUniversity ? getNameByID(userMastersUniversity, context.university_autocomplete_options) : getNameByID(validatedMastersUniversity, context.university_autocomplete_options)),
            masters_university_outside_uk: (userMastersUniversity === -1 || validatedMastersUniversity === -1) ? true : null,
            phd_university: (userPhdUniversity ? getNameByID(userPhdUniversity, context.university_autocomplete_options) : null),
            graduation_year: userGraduationYear.value,
            alevel_school: (validatedAlevelSchool ? getNameByID(validatedAlevelSchool, context.pre_university_autocomplete_options) : null),
            alevel_gcse_same_school: (userSameSchoolForGCSEAlevel ? userSameSchoolForGCSEAlevel.value : null),
            gcse_school: (userSameSchoolForGCSEAlevel && userSameSchoolForGCSEAlevel.value === false) ? getNameByID(validatedGCSESchool, context.pre_university_autocomplete_options) : (validatedAlevelSchool ? getNameByID(validatedAlevelSchool, context.pre_university_autocomplete_options) : null)
          });
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }
      })
    }
  };

  if (loading || isLoading) return <LoadingScreen />

  const userStudyingTowards = studyingTowards.find(a => a.selected === true);
  const userGraduationYear = graduationYear.find(a => a.selected === true);
  const userSameSchoolForGCSEAlevel = sameSchoolForGCSEAlevel.find(a => a.selected === true);

  let otherQualifications = [];
  let userDegreeUniversity = null;
  let userMastersUniversity = null;
  let userPhdUniversity = null;
  if (userStudyingTowards) {
    if (userStudyingTowards.value === "degree") {
      userDegreeUniversity = validatedCurrentUniversity;
    } else if (userStudyingTowards.value === "masters") {
      userMastersUniversity = validatedCurrentUniversity;
    } else if (userStudyingTowards.value === "phd") {
      userPhdUniversity = validatedCurrentUniversity;
    } else if (userStudyingTowards.value === "other") {
      otherQualifications.push(otherCourse.value);
    }
  }

  return (
    <>
      <MetaTags>
        <title>Your university</title>
        <meta id="meta-description" name="description" content="Input your university information" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Your university"
        subtitle="This will help us build a personalised pathway for you into your careers of interest."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <Label>What are you studying towards?</Label>
                <Multiselect
                  values={studyingTowards}
                  handleChange={handleStudyingTowardsChange}
                  name=""
                />
              </InputWrap>
              {userStudyingTowards && userStudyingTowards.value !== "other" && (
                <>
                  <InputWrap>
                    <Label>What subject are you studying?</Label>
                    <HintText>Type a subject and select from available options. You can enter multiple subjects if applicable.</HintText>
                    {userStudyingTowards.value === "degree" && degreeSubjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.degree_autocomplete_options}
                          handleChange={it => handleDegreeInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onDegreeFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                    {userStudyingTowards.value === "masters" && mastersSubjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.masters_autocomplete_options}
                          handleChange={it => handleMastersInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onMastersFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                    {userStudyingTowards.value === "phd" && phdSubjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.phd_autocomplete_options}
                          handleChange={it => handlePhDInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onPhDFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                  </InputWrap>
                </>
              )}
              {userStudyingTowards && userStudyingTowards.value === "other" && (
                <InputWrap>
                  <Label>What course are you studying?</Label>
                  <Input
                    placeholder={otherCourse.placeholder}
                    handleChange={(it) => handleOtherCourseChange(it)}
                  />
                </InputWrap>
              )}
              {userStudyingTowards && (
                <>
                  {!context.education_institution_id && (
                    <InputWrap>
                      <Label>What university do you go to?</Label>
                      <HintText>Type, then select from available options.</HintText>
                      <AutocompleteField
                        placeholder={currentUniversity.placeholder}
                        suggestions={context.university_autocomplete_options}
                        handleChange={it => handleUniversityInputFieldChange(it, currentUniversity, setCurrentUniversity, setValidatedCurrentUniversity)}
                        error={currentUniversity.error}
                        onBlur={e => onUniversityFieldBlur(e.target.value, currentUniversity, setCurrentUniversity)}
                      />
                    </InputWrap>
                  )}
                  <InputWrap>
                    <Label>What year do you graduate?</Label>
                    <Multiselect
                      values={graduationYear}
                      handleChange={handleGraduationYearChange}
                      name=""
                    />
                  </InputWrap>
                </>
              )}
              {userStudyingTowards && userStudyingTowards.value === "phd" && (
                <>
                  <InputWrap>
                    <LabelNarrow>If you have a masters, enter the subject you studied here?</LabelNarrow>
                    <HintText>Type a subject and select from available options. You can enter multiple subjects if you have more than one masters.</HintText>
                    {mastersSubjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.masters_autocomplete_options}
                          handleChange={it => handleMastersInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onMastersFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                  </InputWrap>
                  <InputWrap>
                    <Label>Where did you study your masters?</Label>
                    <HintText>Type, then select from available options.</HintText>
                    <AutocompleteField
                      placeholder={mastersUniversity.placeholder}
                      suggestions={context.university_autocomplete_options}
                      handleChange={it => handleUniversityInputFieldChange(it, mastersUniversity, setMastersUniversity, setValidatedMastersUniversity)}
                      error={mastersUniversity.error}
                      onBlur={e => onUniversityFieldBlur(e.target.value, mastersUniversity, setMastersUniversity)}
                      disabled={mastersUniversity.university_outside_uk}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleUniversityOutsideUKChange(it, mastersUniversity, setMastersUniversity, setValidatedMastersUniversity)}
                      label="I studied at a university outside the UK"
                      name=""
                    />
                  </InputWrap>
                </>
              )}
              {userStudyingTowards && (userStudyingTowards.value === "masters" || userStudyingTowards.value === "phd") && (
                <>
                  <InputWrap>
                    <LabelNarrow>What subject was your undergrad degree in?</LabelNarrow>
                    <HintText>Type a subject and select from available options. You can enter multiple subjects if you have a joint honours, or a degree with a major and a minor.</HintText>
                    {degreeSubjects.map(item => (
                      <AutocompleteWrap key={item.id}>
                        <AutocompleteField
                          placeholder={item.placeholder}
                          suggestions={context.degree_autocomplete_options}
                          handleChange={it => handleDegreeInputFieldChange(it, item.id)}
                          error={item.error}
                          onBlur={e => onDegreeFieldBlur(e.target.value, item.id)}
                        />
                      </AutocompleteWrap>
                    ))}
                  </InputWrap>
                  <InputWrap>
                    <Label>Where did you study your undergrad degree?</Label>
                    <HintText>Type, then select from available options.</HintText>
                    <AutocompleteField
                      placeholder={degreeUniversity.placeholder}
                      suggestions={context.university_autocomplete_options}
                      handleChange={it => handleUniversityInputFieldChange(it, degreeUniversity, setDegreeUniversity, setValidatedDegreeUniversity)}
                      error={degreeUniversity.error}
                      onBlur={e => onUniversityFieldBlur(e.target.value, degreeUniversity, setDegreeUniversity)}
                      disabled={degreeUniversity.university_outside_uk}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleUniversityOutsideUKChange(it, degreeUniversity, setDegreeUniversity, setValidatedDegreeUniversity)}
                      label="I studied at a university outside the UK"
                      name=""
                    />
                  </InputWrap>
                </>
              )}
              {userStudyingTowards && userStudyingTowards.value !== "other" && (
                <>
                  <InputWrap>
                    <Label>Where did you study your A-levels?</Label>
                    <HintText>Type, then select from available options.</HintText>
                    <AutocompleteField
                      placeholder={alevelSchool.placeholder}
                      suggestions={context.pre_university_autocomplete_options}
                      handleChange={it => handleSchoolInputFieldChange(it, alevelSchool, setAlevelSchool, setValidatedAlevelSchool)}
                      error={alevelSchool.error}
                      onBlur={e => onSchoolFieldBlur(e.target.value, alevelSchool, setAlevelSchool, "This is not a valid school or college")}
                      disabled={alevelSchool.home_schooled || alevelSchool.school_outside_uk}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleSchoolCheckboxChange(it, alevelSchool, setAlevelSchool, setValidatedAlevelSchool, "home_schooled")}
                      label="I was home-schooled"
                      name=""
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleSchoolCheckboxChange(it, alevelSchool, setAlevelSchool, setValidatedAlevelSchool, "school_outside_uk")}
                      label="I went to school outside the UK"
                      name=""
                    />
                  </InputWrap>
                  <InputWrap>
                    <Label>Did you study your GCSEs at the same school as your A-levels?</Label>
                    <Multiselect
                      values={sameSchoolForGCSEAlevel}
                      handleChange={handleSameSchoolChange}
                      name=""
                    />
                  </InputWrap>
                </>
              )}
              {userStudyingTowards && userStudyingTowards.value !== "other" &&
               userSameSchoolForGCSEAlevel && userSameSchoolForGCSEAlevel.value === false && (
                <>
                  <InputWrap>
                    <Label>Where did you study your GCSEs?</Label>
                    <HintText>Type, then select from available options.</HintText>
                    <AutocompleteField
                      placeholder={gcseSchool.placeholder}
                      suggestions={context.pre_university_autocomplete_options}
                      handleChange={it => handleSchoolInputFieldChange(it, gcseSchool, setGCSESchool, setValidatedGCSESchool)}
                      error={gcseSchool.error}
                      onBlur={e => onSchoolFieldBlur(e.target.value, gcseSchool, setGCSESchool, "This is not a valid school")}
                      disabled={gcseSchool.home_schooled || gcseSchool.school_outside_uk}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleSchoolCheckboxChange(it, gcseSchool, setGCSESchool, setValidatedGCSESchool, "home_schooled")}
                      label="I was home-schooled"
                      name=""
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleSchoolCheckboxChange(it, gcseSchool, setGCSESchool, setValidatedGCSESchool, "school_outside_uk")}
                      label="I went to school outside the UK"
                      name=""
                    />
                  </InputWrap>
                </>
              )}
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude userProperties={{
                    university_studying_towards: userStudyingTowards.value,
                    degree_incomplete: (userStudyingTowards.value === "degree" && validatedDegreeSubjects.length > 0) ? getNamesByIDs(validatedDegreeSubjects, context.degree_autocomplete_options, "id") : null,
                    masters_incomplete: (userStudyingTowards.value === "masters" && validatedMastersSubjects.length > 0) ? getNamesByIDs(validatedMastersSubjects, context.masters_autocomplete_options, "id") : null,
                    phd_incomplete: (userStudyingTowards.value === "phd" && validatedPhDSubjects.length > 0) ? getNamesByIDs(validatedPhDSubjects, context.phd_autocomplete_options, "id") : null,
                    other_qualifications_incomplete: (otherQualifications.length > 0) ? otherQualifications : null,
                    current_university: (validatedCurrentUniversity ? getNameByID(validatedCurrentUniversity, context.university_autocomplete_options) : null),
                    degree: ((userStudyingTowards.value === "masters" || userStudyingTowards.value === "phd") ? getNamesByIDs(validatedDegreeSubjects, context.degree_autocomplete_options, "id") : null),
                    degree_university: (userDegreeUniversity ? getNameByID(userDegreeUniversity, context.university_autocomplete_options) : getNameByID(validatedDegreeUniversity, context.university_autocomplete_options)),
                    degree_university_outside_uk: (userDegreeUniversity === -1 || validatedDegreeUniversity === -1) ? true : null,
                    masters: (userStudyingTowards.value === "phd" ? getNamesByIDs(validatedMastersSubjects, context.masters_autocomplete_options, "id") : null),
                    masters_university: (userMastersUniversity ? getNameByID(userMastersUniversity, context.university_autocomplete_options) : getNameByID(validatedMastersUniversity, context.university_autocomplete_options)),
                    masters_university_outside_uk: (userMastersUniversity === -1 || validatedMastersUniversity === -1) ? true : null,
                    phd_university: (userPhdUniversity ? getNameByID(userPhdUniversity, context.university_autocomplete_options) : null),
                    graduation_year: userGraduationYear.value,
                    alevel_school: (validatedAlevelSchool ? getNameByID(validatedAlevelSchool, context.pre_university_autocomplete_options) : null),
                    alevel_gcse_same_school: (userSameSchoolForGCSEAlevel ? userSameSchoolForGCSEAlevel.value : null),
                    gcse_school: (userSameSchoolForGCSEAlevel && userSameSchoolForGCSEAlevel.value === false) ? getNameByID(validatedGCSESchool, context.pre_university_autocomplete_options) : (validatedAlevelSchool ? getNameByID(validatedAlevelSchool, context.pre_university_autocomplete_options) : null)
                  }}>
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)}>Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <Tooltip text="Please enter your education details above">
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default UniversityScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const AutocompleteWrap = styled.div`
  margin-bottom: 12px;
`;

const LabelNarrow = styled(Label)`
  width: 300px; 
`;
