import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookies from "js-cookie";
import runScripts from './scripts';
import { useStateValue } from "../services/state";

import colors from '../config/colors';
import layout from '../config/layout';

import { PSmall } from '../ui/typography/Typography';
import { PrimaryButton } from '../ui/buttons/Buttons';

const CookieBanner = () => {
  // eslint-disable-next-line
  const [ _ , dispatch] = useStateValue();

  useEffect(() => {
    runScripts();
  },[]);

  const cookieValue = Cookies.get("cookies");
  const cookieLocalStorage = localStorage.getItem("cookies");

  const acceptCookies = () => {
    Cookies.set('cookies', 'on', {expires: 14});
    localStorage.setItem("cookies", true);
    runScripts();
    dispatch({type: 'update', values: {window_top: window.scrollY, cookies: 'on'}});
  };

  if (cookieValue === "on" || cookieLocalStorage === true || cookieLocalStorage === "true") return null;

  return (
    <Container>
      <Text align="center">We use cookies to ensure you get the best experience on our website.<br /><a href="/privacy#cookies" target="_blank" rel="noopener noreferrer">Learn more</a></Text>
      <ButtonWrap>
        <PrimaryButton onClick={acceptCookies} data-testid="cookie_accept">Got it!</PrimaryButton>
      </ButtonWrap>
    </Container>
  );
};

export default CookieBanner;

const Container = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 20px;
  bottom: 32px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  max-width: calc(100% - 32px);
  padding: 32px;
  position: fixed;
  right: 32px;
  width: 579px;
  z-index: 100;

  @media(max-width: ${layout.breakpoints.MD}) {
    bottom: 16px;
    left: 50%;
    padding: 16px;
    transform: translateX(-50%);
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const Text = styled(PSmall)`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;
