/* Legacy utility functions for Courses section of career screen */

import axios from 'axios';

export const viewCourses = (user_id, link, careerID) => {

  axios.post('/api/v1/viewCourses', {
    user_id: user_id,
    career_id: careerID,
  }).then((res) => {});

  // var urlFriendlyJobName = careerName.toLowerCase().replace(/ /g, '%20').replace(/\s/g,'').replace(/[^a-z0-9%]/gi, '');

  // var coursesURL = "https://www.udemy.com/courses/search/";
  // coursesURL += "?q=" + urlFriendlyJobName;
  const url = link.match(/^http[s]?:\/\//) ? link : 'http://' + link;
  window.open(url, '_blank')
};


export const viewCourse = (link, careerID, courseID, user_id) => {

  axios.post('/api/v1/viewCourse', {
    user_id: user_id,
    career_id: careerID,
    course_id: courseID,
  });
  const url = link.match(/^http[s]?:\/\//) ? link : 'http://' + link;
  window.open(url, '_blank')
};

export const getViewCoursesButtonLabel = (item) => {
  console.log(item);
  if (item.learning_curve_group_courses && item.learning_curve_group_courses.length > 0) {
    return 'View courses';
  } else if (item.udemy_affiliate_link || item.alternative_courses_link) {
    return 'View paid courses';
  }

  return null;
};

export const shorterDescription = (description) => {
  var descriptionSentences = description.split(".");
  var shorterDescription = "";

  for (var i=0; i<descriptionSentences.length; i++) {
    if (shorterDescription.length < 100) {
      shorterDescription += descriptionSentences[i] + ". ";
    }
  }

  return shorterDescription;
};
