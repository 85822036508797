/* Terms content v.1.0 (latest that is shown by default) - ref. TermsScreen.js */

import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { H1, H2, H3, P, PSmall, Ol, Ul } from '../../ui/typography/Typography';
import { Link } from 'react-router-dom';

const PrivacyContent = () => {

  return (
    <>
      <Row>
        <Col xs={12}>
          <H2 align="center">CareersPro</H2>
          <H1 align="center">Terms</H1>
        </Col>
      </Row>
      <RowStyled>
        <Col xs={12} md={10}>
          <H2>Introduction</H2>
          <P>You will be able to access most areas of this Website without registering your details with us. Certain areas of this Website are only open to you if you register.</P>
          <P>We may revise these terms and conditions at any time by updating this page. You should check this Website from time to time to review the current terms and conditions, because they are binding on you. Certain provisions of these terms and conditions may be superseded by expressly designated legal notices or terms located on particular pages of this Website. If you do not wish to accept any new terms and conditions after we have posted them on the website, you should not continue to use this Website.</P>
          <H2>Licence</H2>
          <P>The “Partner Area” of our Website allows customers and prospective customers to view our financial and commercial information. This area of our site may only be accessed by registered users and the information contained within this area is our confidential information. Users with access to this area must safeguard and keep confidential any and all such confidential information, and any such users must not use or disclose such confidential information to any third party. The obligations set out in this clause 2.1 shall not apply to any information to the extent it is publicly available or becomes publicly available through no act or omission of that party or to the extent that it is required to be disclosed by law or by order of a court of competent jurisdiction or other competent authority.</P>
          <P>The “Vision Area” of our Website allows educational institutions we work with to view information in relation to their students. This area of our Website may only be accessed by registered users. To the extent that you have access to the Vision Area, you acknowledge and agree that for the purposes of the The Data Protection Act 2018 and the General Data Protection Regulation (GDPR), you are the data controller in relation to the personal data accessible in the Vision Area and you are responsible for obtaining appropriate consents from those persons in respect of whom we hold and process personal data. We will only process such personal data in accordance with your instructions. You shall indemnify us and keep us indemnified against all costs, expenses (including legal and other professional fees and expenses), losses, damages and other liabilities of whatever nature (whether contractual, tortious or otherwise) suffered or incurred by us and arising out of or in connection with any breach by you, your employees, representatives, agents or sub-contractors, of the obligations under this Clause 2.2.</P>
          <P>You may not print and download extracts from the Vision Area or the Partner Area. You are otherwise permitted to print and download extracts from this Website for your own use on the following basis:</P>
          <Ul>
            <li>2.1 - no documents or related graphics on this Website (whether physical or digital copies) are modified in any way;</li>
            <li>2.2 - no graphics, photographs, video or audio sequences on this Website are used separately from accompanying text;</li>
            <li>2.3 - you must not use any part of the content on our Website for commercial purposes without obtaining our prior written permission to do so;</li>
            <li>2.4 - if you print off, copy or download any part of our Website in breach of these terms, without prejudice to any other right or remedy available to us, your right to use our Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made; and</li>
            <li>2.5 - our copyright, trade marks and permission notices are visible in all extracts and our status (including any identified contributors) as the author of content is acknowledged.</li>
          </Ul>
          <P>Unless otherwise stated, the copyright and other intellectual property rights in all material on this Website (including without limitation photographs and graphical images) are owned by us or our licensors. For the purposes of these terms and conditions, any use of extracts from this Website other than in accordance with this clause 2 for any purpose is prohibited. If you breach any of the terms in these terms and conditions, your permission to use this Website automatically terminates and you must immediately destroy any downloaded or printed extracts from this Website.</P>
          <P>Subject to clause 2.3, no part of this Website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without our prior written permission.</P>
          <P>Any rights not expressly granted in these terms are reserved.</P>
          <H2>Service Access</H2>
          <P>While we endeavour to ensure that this Website is normally available 24 hours a day, we will not be liable if for any reason this Website is unavailable at any time or for any period.</P>
          <P>We do not guarantee that our Website (or any part of it) will always be available or that access to it will be uninterrupted. Access to our Website is permitted on a temporary basis. We may suspend, withdraw, change or discontinue all or any part of our Website without notice, including in the case of system failure, maintenance or repair, or for reasons beyond our control.</P>
          <P>You are responsible for making all arrangements necessary for you to have access to our Website.</P>
          <P>You are responsible for ensuring that all persons who access our Website through your internet connection are aware of these terms and conditions and other applicable terms and conditions, and that they comply with them.</P>
          <H2>Visitor material and conduct</H2>
          <P>Other than personally identifiable information, which is covered under the Privacy Policy, any material you transmit or post to this Website will be considered non-confidential and non-proprietary. We will have no obligations with respect to such material. We and our nominees will be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial purposes.</P>
          <P>Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our Website, you must not post or transmit to or from this Website any material:</P>
          <Ol>
            <li>that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience;</li>
            <li>for which you have not obtained all necessary licences and/or approvals;</li>
            <li>which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party, in the UK or any other country in the world; or</li>
            <li>which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li>
          </Ol>
          <P>You warrant that any material you post or transmit to or from our Website will comply with the standards set out in these terms and conditions and you will be liable to us and indemnify us for any breach of that warranty. If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of the relevant warranty.</P>
          <P>You may not misuse the Website (including, without limitation, by hacking) or knowingly introduce viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful.</P>
          <P>We will fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone posting any harmful material. We have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our Website constitutes a violation of their intellectual property rights, or of their right to privacy.</P>
          <P>We will fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone posting any harmful material. We have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our Website constitutes a violation of their intellectual property rights, or of their right to privacy.</P>
          <P>We have the right to remove any posting you make on our Website if, in our opinion, your post does not comply with the content standards set out in these terms and conditions.</P>
          <H2>Links to and from other websites</H2>
          <P>Links to third party websites on this Website are provided solely for your convenience. If you use these links, you leave this Website. We have not reviewed all of these third party websites, and do not control and are not responsible for these websites or their content or availability. We therefore do not endorse or make any representations about them, or any material found on them, or any results that may be obtained from using them. If you decide to access any of the third party websites linked to on this Website, you do so entirely at your own risk.</P>
          <P>If you would like to link to this Website, you may only do so on the basis that you link to, but do not replicate, the home page of this Website, you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, and subject to the following conditions:</P>
          <Ul>
            <li>5.1 - you do not remove, distort or otherwise alter the size or appearance of the Learning Curve Group (https://www.learningcurvegroup.co.uk/) logo;</li>
            <li>5.2 - you do not create a frame or any other browser or border environment around this Website;</li>
            <li>5.3 - you do not in any way imply that we are endorsing any products or services other than our own;</li>
            <li>5.4 - you do not misrepresent your relationship with us nor present any other false information about us;</li>
            <li>5.5 - you do not otherwise use any trade marks displayed on this Website without our express written permission;</li>
            <li>5.6 - you do not link from a website that is not owned by you; and</li>
            <li>5.7 - your website (in which you are linking) complies with all applicable laws and regulations and does not contain content that is distasteful, offensive or controversial, or infringes any intellectual property rights or other rights of any other person.</li>
          </Ul>
          <P>We expressly reserve the right to revoke the right granted (the right to link to this Website) without notice.</P>
          <P>You shall fully indemnify us for any loss or damage we or any of our group companies may suffer or incur as a result of your breach of clause 5.2. If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of Clause 5.2.</P>
          <H2>Registration</H2>
          <P>To register to use those areas of our Website open only to registered users, you must be over eighteen years of age and you must apply in writing (or by e-mail) using the addresses detailed in these terms and conditions. We reserve the right, in our sole discretion, to reject any such applications for registration.</P>
          <P>Each registration is for a single user only. We do not permit you to share your user name and password with any other person nor with multiple users on a network.</P>
          <P>Responsibility for the security of any passwords issued rests with you and if you know or suspect that someone else knows your password, you should contact us immediately.</P>
          <P>We may suspend or cancel your registration immediately at our reasonable discretion if you breach any of your obligations under these terms and conditions.</P>
          <H2>Disclaimer</H2>
          <P>While we endeavour to ensure that the information on this Website is correct, we do not warrant the accuracy and completeness of the material on this Website. We may make changes to the material on this Website, or to the products and prices described in it, at any time without notice. The material on this Website may be out of date, and we make no commitment to update such material. The Content on our Website is provided for general information only and it is not intended to be advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content of our Website.</P>
          <P>The material on this Website is provided “as is” without any conditions, warranties or other terms of any kind. Accordingly, to the maximum extent permitted by law, we provide you with this Website on the basis that we exclude all representations, warranties, conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality, fitness for purpose, and the use of reasonable care and skill) which but for these terms and conditions might have effect in relation to this Website.</P>
          <P>We do not guarantee that our site will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform in order to access our Website. You should use your own virus protection software.</P>
          <H2>Liability</H2>
          <P>We, any other party (whether or not involved in creating, producing, maintaining or delivering this Website), and any of our group companies and the officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including, without limitation, any</P>
          <Ol>
            <li>indirect, special, punitive or consequential loss or damages; or</li>
            <li>whether direct or indirect, any
              <Ul>
                <li>(i) loss of income,</li>
                <li>(ii) loss of profits,</li>
                <li>(iii) loss of or damage to goodwill,</li>
                <li>(iv) loss of or damage to data,</li>
                <li>(v) loss of contracts,</li>
                <li>(vi) loss of use of money, or</li>
                <li>(vii) loss or damage arising from or connected in any way to business interruption; in each case whether in tort (including without limitation negligence), contract or otherwise) in connection with this Website in any way or in connection with the use, inability to use or the results of use of this Website, any websites linked to this Website or the material on such websites, including but not limited to loss or damage due to viruses that may infect your computer equipment, software, data or other property on account of your access to, use of, or browsing this Website or your downloading of any material from this Website or any websites linked to this Website.</li>
              </Ul>
            </li>
          </Ol>
          <P>Nothing in these terms and conditions shall exclude or limit our liability for (i) death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977); (ii) fraud or fraudulent misrepresentation; (iii) misrepresentation as to a fundamental matter; or (iv) any liability which cannot be excluded or limited under applicable law.</P>
          <P>If your use of material on this Website results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof.</P>
          <P>You agree to indemnify us fully, defend and hold us, and our officers, directors, employees and agents, harmless from and against all claims, liability, damages, losses, costs (including reasonable legal fees) arising out of any breach of the terms and conditions by you, or your use of this Website, or the use by any other person using your registration details. If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of any of these terms and conditions, you use of this Website, or the use by any other person of your registration details.</P>
          <H2>Governing Law & Jurisdiction</H2>
          <P>If you are a consumer, please note that these terms and conditions, their subject matter and the formation of an agreement with you, are governed by and shall be construed in accordance with English law. If you are a consumer, you and we both agree that the courts of England and Wales will have non-exclusive jurisdiction over any dispute arising out of or in connection with these terms and conditions. If you are a business, these terms and conditions, their subject matter and the formation of an agreement with you, are governed by and shall be construed in accordance with English law. If you are a business, disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the English courts.</P>
          <P>We do not warrant that materials/items for sale on the Website are appropriate or available for use outside the United Kingdom. It is prohibited to access the Website from territories where its contents are illegal or unlawful. If you access this Website from locations outside the United Kingdom, you do so at your own risk and you are responsible for compliance with local laws.</P>
          <H2>Miscellaneous</H2>
          <P>You may not assign, sub-license or otherwise transfer any of your rights under these terms and conditions.</P>
          <P>If any provision of these terms and conditions is found by any court of competent jurisdiction to be invalid, the invalidity of that provision will not affect the validity of the remaining provisions, which shall continue to have full force and effect.</P>
          <P>Only the parties to these terms and conditions may seek to enforce them under the Contracts (Rights of Third Parties) Act 19</P>
          <H2>Accessibility</H2>
          <P>This accessibility statement applies to www.learningcurvegroup.co.uk</P>
          <P>This website is run by Learning Curve Group. We want as many people as possible to be able to use this website. For example, that means you should be able to:</P>
          <Ul>
            <li>navigate most of the website using just a keyboard</li>
            <li>navigate most of the website using speech recognition software</li>
            <li>listen to most of the website using a screen reader</li>
          </Ul>
          <P>We've also made the website text as simple as possible to understand.</P>
          <H3Styled>How accessible this website is</H3Styled>
          <P>We know some parts of this website are not fully accessible:</P>
          <Ul>
            <li>Some older PDF documents are not fully accessible to screen reader software</li>
            <li>Some pages have a colour contrast that is not as accessible as it could be</li>
          </Ul>
          <H3Styled>Feedback and contact information</H3Styled>
          <P>Documents can be made available in a variety of different formats and languages, on request. If you would like more information about this or would like to request an alternative format or language please contact Adaptations@learningcurvegroup.co.uk</P>
          <H3Styled>Reporting accessibility problems with this website</H3Styled>
          <P>We're always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we're not meeting accessibility requirements, contact Adaptations@learningcurvegroup.co.uk</P>
        </Col>
      </RowStyled>
    </>
  );
};

export default PrivacyContent;

const RowStyled = styled(Row)`
  justify-content: center;
`;

const H3Styled = styled(H3)`
  margin-top: 48px;
  margin-bottom: 16px;
`;
