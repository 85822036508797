/* Very last screen before final results for users coming through a "free" access link where we don't
   have their contact details yet
   Example link users can come from: https://careerspro.co.uk/lcg */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';
import { validateEmail } from '../../utils/validation';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { PSmall } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import { Error } from '../../ui/typography/Typography';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import Checkbox from '../../ui/form/Checkbox';

const UserSignupScreen = () => {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ generalError, setGeneralError ] = useState('');
  
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    subscribed: false,
  });

  const initialEmailErrorState = {
    first_name: null,
    last_name: null,
    email: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  const [{ user_id, loading, context }, dispatch] = useStateValue();

  const history = useHistory();
  const match = useRouteMatch("/:userId/signup");

  useGetScreenData('signup', setIsLoading, match.params.userId);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "first_name"|| name === "last_name") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    } else if ( name === 'email') {
      const errorMsg = !validateEmail(value) ? "This is not a valid email" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let errors = {...initialEmailErrorState};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if (key !== 'subscribed') {
        if (value === "") {
          formValid = false;
          errors = {
            ...errors,
            [key]: "This field is required"
          }
        } else if ( key === 'email') {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        }
      }
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {

      setIsLoading(true);

      const submitData = createSubmitData({
        user_id: user_id,
        contact_details: formValues,
      });

      axios.post('/api/v1/handleSignupInput', submitData).then((res) => {
        dispatch({type: 'updateResponse', data: res.data});
        if (!res.data.errors) {
          logEvent('Completed Signup', {
            email_subscribed: formValues.subscribed
          });
        }
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('non-field-errors')) {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }

      })
    }
  };

  if (loading || isLoading) return <LoadingScreen />

  return (
    <>
      <MetaTags>
        <title>Create account</title>
        <meta id="meta-description" name="description" content="Create account" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Create account"
        subtitle="Create an account to view your results. "
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} name="first_name" label="First name" error={formErrors['first_name']} />
              </InputWrap>
              <InputWrap>
                <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} name="last_name" label="Last name" error={formErrors['last_name']} />
              </InputWrap>
              <InputWrap>
                <Input type="email" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} name="email" label="Email address" error={formErrors['email']} />
              </InputWrap>
              {context && context.show_checkbox && (
                <InputWrap>
                  <Checkbox
                    handleChange={(it) => handleInputChange(it, 'subscribed')}
                    label="Check this box if you’re happy for us to stay in touch, so we can better help you discover and get into your dream career"
                    name="subscribed"
                  />
                </InputWrap>
              )}
              <ButtonWrap>
                {!validateForm(false) ? (
                  <Tooltip text="Please enter your contact details above">
                    <GrayButton disabled>View results</GrayButton>
                  </Tooltip>
                ) : (
                  <Amplitude userProperties={{email_subscribed: formValues.subscribed}}>
                    {({logEvent}) => (
                      <>
                        <PrimaryButton onClick={() => submitForm(logEvent)}>View results</PrimaryButton>
                        <PrivacyText align="center">By tapping <b>View results</b> you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a>{context.education_institution_name && (` and you consent to your data being shared with ${context.education_institution_name} for the purpose of supporting your career exploration`)}</PrivacyText>
                      </>
                    )}
                  </Amplitude>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default UserSignupScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const PrivacyText = styled(PSmall)`
  margin-top: 20px;
`;
