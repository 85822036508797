import styled, { css } from 'styled-components';
import colors from '../../config/colors';
import layout from '../../config/layout';
import { Link } from 'react-router-dom';
import IconArrowUp from '../../assets/icon-arrow-blue-up.svg';
import IconArrowDown from '../../assets/icon-arrow-blue-down.svg';

const FooterLinkStyle = css`
  color: ${colors.GRAY_DARK};
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 16px;
  text-decoration: underline;

  &:not([href]):not([tabindex]) {
    color: ${colors.GRAY_DARK};
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterLink = styled(Link)`
  ${FooterLinkStyle};
`;

export const FooterLinkA = styled.a`
  ${FooterLinkStyle};
`;

const MoreLinkStyle = css`
  background-image: url(${props => props.arrowUp ? IconArrowUp : props => props.noArrow ? null : IconArrowDown});
  background-position: right center;
  background-repeat: no-repeat;
  color: ${colors.LCG_LIGHT_PURPLE};
  font-size: 20px;
  line-height: 24px;
  padding-right: ${props => props.noArrow ? '0px' : '16px'};
  cursor: pointer;

  &:hover {
    color: ${colors.LCG_LIGHT_PURPLE_DARK};
    text-decoration: none;
  }

  &:not([href]):not([tabindex]) {
    color: ${colors.LCG_LIGHT_PURPLE};

    &:hover {
      color: ${colors.LCG_LIGHT_PURPLE_DARK};
      text-decoration: none;
    }
  }

  @media(max-width: ${layout.breakpoints.MD}) {
    background-position: right 7px;
    font-size: 16px;
    padding-right: 15px;
  }
`;

export const MoreLink = styled(Link)`
  ${MoreLinkStyle};
`;

export const MoreLinkA = styled.a`
  ${MoreLinkStyle};
`;
