/* Homepage */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from '../../services/state';
import { useGetCompletions, useGetIP, fetchData } from '../../hooks/getDataHooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createSubmitData, getParameterByName } from '../../utils/helper';

// import Header from '../../components/Header';
import Menu from '../../components/Menu';
import TopBar from '../../components/TopBar';
import SectionTop from './SectionTop';
import KeyFeaturesSection from './KeyFeaturesSection';
import TestimonialsV2Section from './TestimonialsV2Section';
import AboutUsSection from './AboutUsSection';
// import PricingSection from './PricingSection';
import FAQSection from './FAQSection';
import Footer from '../../components/Footer.js';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import LoadingScreen from '../LoadingScreen';
import LogoLarge from '../../assets/logo/careersPro_logo_transparent_S.svg';

const HomeScreen = () => {
  const [{ completions, ip, price_headline, price_disclaimer, price, premium_price, loading, context }, dispatch] = useStateValue();
  const history = useHistory();
  
  const match = useRouteMatch("/:userId");
  const userId = (match && match.params) ? match.params.userId : null;

  // Test whether a string could be a userId or not
    // It could be if it's >15 characters and has no "-" characters in it
    // Otherwise it's a different endpoint for an education institution, traffic
    // partner or other
  const couldBeUserId = (userId) => {
    if (userId && userId.length > 15 && !userId.includes("-")) {
      return true;
    } else {
      return false;
    }
  };

  // Get the source of the page - will be the endpoint (e.g. careerspro.co.uk/cdi) if the endpoint is not a userId
  // otherwise we'll assume the endpoint is a user id and we'll use the utm_source parameter
  const source = !couldBeUserId(userId) ? userId : getParameterByName('utm_source');

  const [ shortenedHomepage, setShortenedHomepage ] = useState(false);
  const [ siteEmbed, setSiteEmbed ] = useState(false);

  const [ free, setFree ] = useState(false);
  const [ freeAccessText, setFreeAccessText ] = useState(null);
  const [ trafficPartnerLogo, setTrafficPartnerLogo ] = useState(null);

  const [ educationInstitution, setEducationInstitution ] = useState(null);

  const [ isLoading, setIsLoading ] = useState(false);

  const [ userHasAccess, setUserHasAccess ] = useState(false);
  const [ isCollege, setIsCollege ] = useState(false);

  const [ showMenu, setShowMenu ] = useState(false);

  const menuItems = [
    { menu_name: "How it works", top_bar_name: "Features", id: "features", key: 0, shortened: true, education_institution: true },
    { menu_name: "What our customers say", top_bar_name: "Testimonials", id: "customers", key: 1, shortened: true, education_institution: true },
    // { menu_name: "About us", top_bar_name: "About us", id: "aboutus", key: 2, shortened: false, education_institution: false },
    // { menu_name: "Pricing", top_bar_name: "Pricing", id: "pricing", key: 3 },
    // { menu_name: "FAQs", top_bar_name: "FAQs", id: "faqs", key: 4, shortened: false, education_institution: false },
    { menu_name: "Organisations", top_bar_name: "Organisations", href: "/organisations", id: "organisations", key: 5, shortened: true, education_institution: false },
  ];

  useGetCompletions();
  useGetIP();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getAndUpdateUserState = async (user_id, userSetOnURL) => {
      const apiUrl = `/api/v1/getUserState?user_id=${user_id}`;
      try {
        setIsLoading(true);
        const resp = await axios.get(apiUrl);
        if (source !== null) {
          getSourceHomepageAttributes(source);
        } else {
          setIsLoading(false);
        }
        updateUserState(resp, userSetOnURL);
      } catch(error) {
        setIsLoading(false);
        console.log(error.response);
      }
    };

    const updateUserState = (resp, userSetOnURL) => {
      if (resp && resp.status === 200) {
        if (userSetOnURL) {
          // User set on URL, so nullify the state of the app to wipe any previous user in local storage
          localStorage.setItem("user_id", null);
          localStorage.setItem("results", null);
          localStorage.setItem("locked", null);
          localStorage.setItem("paid", null);
          localStorage.setItem("emails_sent", null);
          dispatch({type: 'restart'});
        }
        dispatch({type: 'updateResponse', data: resp.data});
        if (userSetOnURL && resp.data.user_id && resp.data.screen.name) {
          // User set on the URL, and user and screen name returned (i.e. not homepage, so they have started), so update the state and redirect to latest state
          let dataUrl = `${(!resp.data.user_id) ? "" : "/" + resp.data.user_id}/${resp.data.screen.name}`;
          if (resp.data.screen.context.question_number) {
            dataUrl += `/${resp.data.screen.context.question_number}`;
          } else if (resp.data.screen.context.source_url) {
            dataUrl += `/${resp.data.screen.context.source_url}`;
          }
          history.push(dataUrl);
        } else if (resp.data.screen && resp.data.screen.context && resp.data.screen.context.has_access) {
          // User and screen name not returned (i.e. homepage - the user hasn't started yet) and the user has access, so set userHasAccess to true
          // for how to handle the "Get started" button later and other homepage copy, but don't redirect the user in case they returned to the homepage
          setUserHasAccess(true);
          setIsCollege(resp.data.screen.context.is_college);
        }
      }
    };

    const getSourceHomepageAttributes = async (source) => {
      const apiUrl = `/api/v1/getSourceHomepageAttributes?source=${source}`;
      try {
        setIsLoading(true);
        const resp = await axios.get(apiUrl);
        setIsLoading(false);
        if (resp && resp.data) {
          if (resp.data.skip_homepage === true && !couldBeUserId(userId) && (localStorage.getItem("user_id") === null || localStorage.getItem("user_id") === "null")) {
            getStarted(
              getParameterByName('utm_content'),
              source,
              getParameterByName('utm_medium')
            );
          } else {
            if (resp.data.skip_homepage === true) {
              setSiteEmbed(true);
            }
            if (resp.data.shortened_homepage === true) {
              setShortenedHomepage(true);
            } else {
              setShortenedHomepage(false);
            }
            if (resp.data.free) {
              setFree(resp.data.free);
            } else {
              setFree(false);
            }
            if (resp.data.free_access_text) {
              setFreeAccessText(resp.data.free_access_text);
            } else {
              setFreeAccessText(null);
            }
            if (resp.data.logo) {
              setTrafficPartnerLogo(resp.data.logo);
            } else {
              setTrafficPartnerLogo(null);
            }
            if (resp.data.education_institution) {
              setEducationInstitution(resp.data.education_institution);
            } else {
              setEducationInstitution(null);
            }
          }
        }
      } catch(error) {
        setIsLoading(false);
        console.log(error.response);
      }
    };

    if (couldBeUserId(userId)) {
      // If a userId is specified as part of the URL, then fetch its state
      getAndUpdateUserState(userId, true);
    } else if (localStorage.getItem("user_id") !== null && localStorage.getItem("user_id") !== "null") {
      // If a userId is not specified on the URL, but there is one in local storage (i.e. the user has started), then fetch the state just to update the homepage copy
      // if the user has access
      getAndUpdateUserState(localStorage.getItem("user_id"), false);
    } else if (source !== null) {
      getSourceHomepageAttributes(source);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStarted = async (adId, sourcePassedThrough, medium) => {
    if (couldBeUserId(userId) && userHasAccess) {
      if (isCollege) {
        history.push(`/${userId}/contact`);
      } else {
        history.push(`/${userId}/situation`);
      }
    } else {
      dispatch({type: 'startLoading'});
      const formData = createSubmitData({
        ad_id: adId,
        source: source,
        medium: medium,
        ip: ip,
        free: free,
        education_institution_id: (educationInstitution ? educationInstitution.id : null),
      });
  
      axios.post('/api/v1/getStarted', formData).then(resp => {
        const screen = resp.data.screen;
        dispatch({type: 'updateResponse', data: resp.data});
        if (screen.name === 'situation') {
          history.push(`/${resp.data.user_id}/situation`);
        } else {
          history.push(`/${resp.data.user_id}/${screen.name}/${screen.context.question_number ? screen.context.question_number : ''}`)
        }
      });
    }
  };

  const startAgain = (adId, sourcePassedThrough, medium) => {
    dispatch({type: 'startLoading'});
    localStorage.setItem("user_id", null);
    localStorage.setItem("results", null);
    localStorage.setItem("locked", null);
    localStorage.setItem("paid", null);
    localStorage.setItem("emails_sent", null);
    dispatch({type: 'restart'});
    getStarted(adId, source, medium);
  };

  const resume = async (userID, screenName) => {
    dispatch({type: 'startLoading'});
    const resp = await fetchData(screenName, userID, null);
    if (resp.status === 200) {
      dispatch({type: 'updateResponse', data: resp.data});
      if (resp.data.screen.name === 'quiz') {
        history.push(`/${resp.data.user_id}/${resp.data.screen.name}/${resp.data.screen.context.question_number ? resp.data.screen.context.question_number : ''}`);
      } else if (resp.data.user_id) {
        history.push(`/${resp.data.user_id}/${resp.data.screen.name}/${resp.data.screen.context.source_url ? resp.data.screen.context.source_url : ''}`);
      } else {
        // No user_id is returned from the server, so this is the special case of a user with access but who hasn't got beyond the situation screen, so
        // direct them to that now that they have pressed "Resume"
        history.push(`/${userID}/situation`);
      }
    }
  }

  // const goToSection = (id) => {
  //   setShowMenu(false);

  //   if (id === "top") {
  //     window.scrollTo({top: 0});
  //   } else {
  //     window.location.href = "#" + id;
  //   }
  // };

  if (loading || isLoading) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - The AI-powered career planning platform</title>
        <meta id="meta-description" name="description" content="The AI-powered career planning platform. For career changes big and small, get the insights and facts you need to find a way forward." />
      </MetaTags>
      <Container>
        {!siteEmbed && (
            context && context.organisation_name && context.has_access ?
          (
            <TopBar showCareersProLogo={true} pageWelcome={context.first_name ? `Welcome, ${context.first_name}` : null} sticky />
          ) :
          (
            <Menu handleQuizStart={getStarted} menuItems={menuItems} showMenu={showMenu} setShowMenu={setShowMenu} shortenedHomepage={shortenedHomepage} educationInstitution={educationInstitution} />
          )
        )}
        {siteEmbed && (
          <LogoContainer>
            <img src={LogoLarge} alt="CareersPro Logo" />
          </LogoContainer>
        )}
        <CircleOne />
        <CircleTwo />
        {/* <Header logo_alignment="center" large /> */}
        <SectionTop handleQuizStart={getStarted} handleStartAgain={startAgain} handleResume={resume} price_headline={price_headline} price_disclaimer={price_disclaimer} userHasAccess={userHasAccess} freeAccessText={freeAccessText} educationInstitution={educationInstitution} completions={completions} organisationName={context ? context.organisation_name : null} organisationLogo={context && context.organisation_logo ? context.organisation_logo : ( trafficPartnerLogo ? trafficPartnerLogo : null ) } />
        {!siteEmbed && (
          <>
            <KeyFeaturesSection handleQuizStart={getStarted} price_headline={price_headline} price_disclaimer={price_disclaimer} userHasAccess={userHasAccess} freeAccessText={freeAccessText} educationInstitution={educationInstitution} />
            <TestimonialsV2Section completions={completions} />
            {/* {!shortenedHomepage && (!context || !context.organisation_name || !context.has_access) && (<AboutUsSection />)} */}
            {/* <PricingSection price={price} premium_price={premium_price} /> */}
            {/* {!shortenedHomepage && (!context || !context.organisation_name || !context.has_access) && (<FAQSection price={price} premium_price={premium_price} />)} */}
          </>
        )}
        <Footer handleQuizStart={getStarted} price_headline={price_headline} price_disclaimer={price_disclaimer} userHasAccess={userHasAccess} />
      </Container>
    </>
  );
};

export default HomeScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    right: 0px;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  padding: 32px 0px 32px 0px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 20px 0px 20px 0px;
  }
`
