/* Homepage for signed up school/college > Get started > Contact screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';
import { validateEmail, validateAge, doesEmailDomainMatch } from '../../utils/validation';
import { copyArray } from '../../utils/copyArray';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
import { Error, Label } from '../../ui/typography/Typography';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import Multiselect from '../../components/multiselect/Multiselect';

const ContactDetailsScreen = () => {

  const [ isLoading, setIsLoading ] = useState(true);
  const [ generalError, setGeneralError ] = useState('');

  const [ currentStudent, setCurrentStudent ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false }
  ]);

  const [ showEmailAddress, setShowEmailAddress ] = useState(false);
  
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    student_id: "",
    age: "",
  });

  const initialEmailErrorState = {
    first_name: null,
    last_name: null,
    email: null,
    student_id: null,
    age: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  const [{ user_id, loading, context }, dispatch] = useStateValue();

  const history = useHistory();
  const match = useRouteMatch("/:userId/contact");

  useGetScreenData('contact', setIsLoading, match.params.userId);

  useEffect(() => {
    if (context) {
      if (context.education_institution_subtype !== "college") {
        setShowEmailAddress(true);
      }
      const formValuesCopy = copyArray(formValues);
      if (context.first_name) {
        formValuesCopy["first_name"] = context.first_name;
      }
      if (context.last_name) {
        formValuesCopy["last_name"] = context.last_name;
      }
      if (context.email) {
        formValuesCopy["email"] = context.email;
      }
      if (context.student_id) {
        formValuesCopy["student_id"] = context.student_id;
      }
      setFormValues(formValuesCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleCurrentlyStudyingChange = (id, data) => {
    const values = [...data];
    var isNowSelected = false;
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
        if (value.selected) {
          isNowSelected = true;
        }
      } else {
        value.selected = false;
      }
    }
    setCurrentStudent(values);

    // If the user deselected an option (so that none are now selected), hide the email address
    if (isNowSelected) {
      setShowEmailAddress(true);
    } else {
      setShowEmailAddress(false);
    }
  };

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    const userCurrentStudent = currentStudent.find(item => item.selected === true);
    const isCurrentStudent = userCurrentStudent && userCurrentStudent.value;

    if (name === "first_name" || name === "last_name") {
      const errorMsg = value === "" ? "You must provide your " + (name === "first_name" ? "first name" : "last name") : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    } else if ( name === 'email') {
      if ((context.education_institution_subtype !== "college" || isCurrentStudent) && context.is_lc_org === false) {
        const errorMsg = (!validateEmail(value) || !doesEmailDomainMatch(value, context.email_domains)) ? "You must provide your " + context.education_institution_name + " email" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      } else {
        const errorMsg = !validateEmail(value) ? "You must provide you a valid email" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
    } else if ( name === 'student_id') {
      const errorMsg = (isCurrentStudent && value === "") ? "You must provide your student ID" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    } else if ( name === 'age') {
      const errorMsg = validateAge(value);
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      });
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let errors = {...initialEmailErrorState};

    const userCurrentStudent = currentStudent.find(item => item.selected === true);
    const isCurrentStudent = userCurrentStudent && userCurrentStudent.value;

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if ((key === "first_name" || key === "last_name") && value === "") {
        formValid = false;
        errors = {
          ...errors,
          [key]: "This field is required"
        }
      } else if (key === 'email') {
        if ((context.education_institution_subtype !== "college" || isCurrentStudent) && context.is_lc_org === false) {
          if (!validateEmail(value) || !doesEmailDomainMatch(value, context.email_domains)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "You must provide your " + context.education_institution_name + " email"
            }
          }
        } else {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        }
      } else if (key === "student_id" && isCurrentStudent && value === "") {
        formValid = false;
        errors = {
          ...errors,
          [key]: "This field is required"
        }
      } else if (key === 'age' && context.education_institution_subtype !== "secondary_school") {
        const ageError = validateAge(value);
        if (ageError) {
          formValid = false;
          errors = {
            ...errors,
            [key]: ageError,
          }
        }
      }
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {

      setIsLoading(true);

      const submitData = createSubmitData({
        user_id: user_id,
        details: formValues,
      });

      axios.post('/api/v1/handleContactInput', submitData).then((res) => {
        dispatch({type: 'updateResponse', data: res.data});
        if (!res.data.errors) {
          logEvent('Completed Contact Details', {});
        }
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('non-field-errors')) {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }

      })
    }
  };

  if (loading || isLoading) return <LoadingScreen />

  const userCurrentStudent = currentStudent.find(item => item.selected === true);
  const isCurrentStudent = userCurrentStudent && userCurrentStudent.value;

  return (
    <>
      <MetaTags>
        <title>{context.education_institution_subtype === "secondary_school" ? "Contact details" : "About you"}</title>
        <meta id="meta-description" name="description" content="Contact details" />
      </MetaTags>
      <ThreeDotsBgLayout
        title={context.education_institution_subtype === "secondary_school" ? "Contact details" : "About you"}
        subtitle="We'll email your PDF career report and a link to your final results when you finish the quiz."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} name="first_name" label="First name" error={formErrors['first_name']} inputValue={context.first_name ? context.first_name  : ""} disabled={context.first_name && context.first_name.length > 0} />
              </InputWrap>
              <InputWrap>
                <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} name="last_name" label="Last name" error={formErrors['last_name']} inputValue={context.last_name ? context.last_name  : ""} disabled={context.last_name && context.last_name.length > 0} />
              </InputWrap>
              {context.education_institution_subtype === "college" && (!context.email || context.email === "") && (
                <InputWrap>
                  <Label>Are you currently studying at {context.is_lc_org === true ? "Learning Curve Group" : context.education_institution_name}?</Label>
                  <Multiselect
                    values={currentStudent}
                    handleChange={handleCurrentlyStudyingChange}
                    name=""
                  />
                </InputWrap>
              )}
              {(showEmailAddress || (context.email && context.email.length > 0)) && (
                <InputWrap>
                  <Input type="email" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} name="email" label={isCurrentStudent ? context.is_lc_org === true ? "Email address" : context.education_institution_name + " email" : "Email address"} error={formErrors['email']} inputValue={context.email ? context.email  : ""} disabled={context.email && context.email.length > 0} />
                </InputWrap>
              )}
              {(isCurrentStudent || (context.student_id && context.student_id.length > 0)) && (
                <InputWrap>
                  <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'student_id')} name="student_id" label="What is your student ID?" error={formErrors['student_id']} inputValue={context.student_id ? context.student_id  : ""} disabled={context.student_id && context.student_id.length > 0} />
                </InputWrap>
              )}
              <InputWrap>
                <Input type="number" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'age')} name="age" label="How old are you?" error={formErrors['age']} placeholder={context.education_institution_subtype === "college" ? "18" : "21"} />
              </InputWrap>
              <ButtonWrap>
                {!validateForm(false) ? (
                  <Tooltip text="Please enter your details above">
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                ) : (
                  <Amplitude userProperties={{}}>
                    {({logEvent}) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)}>Next</PrimaryButton>
                    )}
                  </Amplitude>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default ContactDetailsScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;
