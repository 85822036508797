import React from 'react';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import moment from 'moment';
// import { Amplitude } from '@amplitude/react-amplitude';

import { FooterLink } from '../ui/buttons/Link';
import layout from '../config/layout';
import { PSmall } from '../ui/typography/Typography';
// import { getNullifiedAmplitudeUserProperties } from '../utils/getNullifiedAmplitudeUserProperties';
// import { PrimaryButton } from '../ui/buttons/Buttons';
// import { getParameterByName } from '../utils/helper';
import colors from '../config/colors';

import iconInstagram from '../assets/icon-instagram.svg';
import iconTiktok from '../assets/icon-tiktok.svg';
import iconFacebook from '../assets/icon-facebook.svg';
// import iconTwitter from '../assets/icon-twitter.svg';
// import iconLinkedIn from '../assets/icon-linkedin.svg';

const Footer = ({ handleQuizStart, price_headline, price_disclaimer, userHasAccess, hideSocialMedia, showLinkToWebsite }) => {

  // const adId = getParameterByName('utm_content');
  // const source = getParameterByName('utm_source');
  // const medium = getParameterByName('utm_medium');

  // let userID = localStorage.getItem("user_id");
  // if (userID === "null") {
  //   userID = null;
  // }

  // const fullAccessText = "You have been gifted with full access 🎁";

  // const shouldShowDisclaimerText = false;

  return (
    <Container showLinkToWebsite={showLinkToWebsite}>
      {/* {!userID && (
        <ButtonWrap>
          <Amplitude userProperties={getNullifiedAmplitudeUserProperties(adId, source, medium)}>
            {({ logEvent }) =>
              <ButtonContainer>
                <PrimaryButton id="start_button" onClick={() => {handleQuizStart(adId, source, medium); logEvent('Clicked Get Started');}}>Get started</PrimaryButton>
                {price_headline && !userHasAccess && shouldShowDisclaimerText && (
                  <DisclaimerText align="center" mt="12px">{price_headline}</DisclaimerText>
                )}
                {userHasAccess && shouldShowDisclaimerText && (
                  <DisclaimerText align="center" mt="12px">{fullAccessText}</DisclaimerText>
                )}
                {price_disclaimer && !userHasAccess && shouldShowDisclaimerText && (
                  <DisclaimerText align="center">{price_disclaimer}</DisclaimerText>
                )}
              </ButtonContainer>
            }
          </Amplitude>
        </ButtonWrap>
      )} */}
      <GridStyled>
        {!hideSocialMedia && (
          <RowStyled>
            <ColStyled>
              <SocialMediaIcon pr><a href="https://www.instagram.com/_learningcurvegroup/" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconInstagram} alt="Instagram" /></a></SocialMediaIcon>
              <SocialMediaIcon pr><a href="https://www.tiktok.com/tag/learningcurvegroup/" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconTiktok} alt="Tiktok" /></a></SocialMediaIcon>
              <SocialMediaIcon><a href="https://en-gb.facebook.com/LearningCurveGroup" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconFacebook} alt="Facebook" /></a></SocialMediaIcon>
              {/* <SocialMediaIcon><a href="https://twitter.com/woulduratherb" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconTwitter} alt="Twitter" /></a></SocialMediaIcon>
              <SocialMediaIcon><a href="https://www.linkedin.com/company/would-you-rather-be" target="_blank" rel="noopener noreferrer"><SocialMediaImage src={iconLinkedIn} alt="LinkedIn" /></a></SocialMediaIcon> */}
            </ColStyled>
          </RowStyled>
        )}
        <RowStyled>
          <Col>
            <FooterLink to="/terms" target="_blank" rel="noopener noreferrer">Terms</FooterLink>
            <FooterLink to="/privacy" target="_blank" rel="noopener noreferrer">Privacy</FooterLink>
          </Col>
        </RowStyled>
        <Row>
          <Col xs={12}>
            {/* <EmailUs align="center" showLinkToWebsite={showLinkToWebsite}>
              {showLinkToWebsite ? 
                "Visit our homepage at www.careerspro.co.uk or get in touch at careers-pro@learningcurvegroup.co.uk" : 
                "Get in touch at careers-pro@learningcurvegroup.co.uk"
              }
            </EmailUs> */}
            <Copyright align="center" showLinkToWebsite={showLinkToWebsite}>Copyright © {moment().format('YYYY')} Learning Curve Group Ltd. All rights reserved.</Copyright>
          </Col>
        </Row>
      </GridStyled>
    </Container>
  );
};

export default Footer;


const Container = styled.div`
  padding: 16px 0;
  ${props => props.showLinkToWebsite && `padding-top: 36px;`};

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 36px 0;
    ${props => props.showLinkToWebsite && `padding-top: 56px;`};
  }
`;

const GridStyled = styled(Grid)`
  width: 100%;
`

const RowStyled = styled(Row)`
  justify-content: center;
`;

const ColStyled = styled(Col)`
  padding-bottom: 12px;
`;

const SocialMediaIcon = styled.div`
  padding-right: ${props => props.pr ? '12px' : '0px'};
  display: inline;
`;

const SocialMediaImage = styled.img`
  width: 35px;
`

const EmailUs = styled(PSmall)`
  color: ${colors.TEXT_DARK};
  margin-top: 12px;
  ${props => props.showLinkToWebsite && `font-size: 14px; line-height: 20px;`};
`;

const Copyright = styled(PSmall)`
  color: ${colors.TEXT_DARK};
  margin-top: 0px;
  ${props => props.showLinkToWebsite && `font-size: 14px; line-height: 20px;`};
`;

// const ButtonWrap = styled.div`
//   margin-top: 20px;
//   margin-bottom: 52px;
//   text-align: center;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     margin-top: 32px;
//     margin-bottom: 12px;
//   }
// `;

// const ButtonContainer = styled.div`
//   margin: 10px auto 52px auto;
//   display: block
// `

// const DisclaimerText = styled(PVSmall)`
//   color: ${colors.TEXT_DARK};
//   margin-top: ${props => props.mt ? props.mt : '0px'};
//   margin-bottom: 4px;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     max-width: 420px;
//     margin-left: auto;
//     margin-right: auto;
//     position: relative;
//   }
// `;
