/* Final results > Career screen > View free courses */

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Amplitude } from "@amplitude/react-amplitude";

import { useStateValue } from "../../services/state";
import { viewCourses, viewCourse, getViewCoursesButtonLabel, shorterDescription } from './getCourses';
import { createSubmitData } from '../../utils/helper';

import { H3, P } from '../../ui/typography/Typography';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import ResultsModalCard from '../../components/resultsmodal/ResultsModalCard';
import ResultsModal from '../../components/resultsmodal/ResultsModal';

import IconExternalLink from '../../assets/icon-external-link.svg';
import IconCircleTick from '../../assets/icon-circle-tick.svg';


const CourseSection = ({ item }) => {
  const [{ user_id }] = useStateValue();
  const [ open, setOpen ] = useState(false);

  const handleViewCourses = (logEvent) => {
    logEvent('Clicked View Courses', {career_id: item.career_id});
    if (item.learning_curve_group_courses && item.learning_curve_group_courses.length > 0) {
      const submitData = createSubmitData({
        user_id: user_id,
        career_id: item.career_id,
      });
      axios.post('/api/v1/viewFreeCoursesClicked', submitData);
      setOpen(true);
    } else if (item.udemy_affiliate_link || item.alternative_courses_link) {
      var viewCoursesLink = null;

      if (item.udemy_affiliate_link !== null) {
        viewCoursesLink = item.udemy_affiliate_link;
      } else if (item.alternative_courses_link !== null) {
        viewCoursesLink = item.alternative_courses_link;
      }
      viewCourses(user_id,viewCoursesLink, item.career_id);
    }
  };

  if (!getViewCoursesButtonLabel(item)) return null;

  const skillsMatch = item.skills_match ? (Math.round(item.skills_match * 100) + "%") : null;

  return (
    <>
      <Container>
        <Title align="center">Improve your skills</Title>
        <P align="center">
          {skillsMatch && `You have a skill match of ${skillsMatch} with ${item.career_name_with_prefix}. `}
          {(item.learning_curve_group_courses && item.learning_curve_group_courses.length === 0) ? 'Improve your skills from the comfort of your own home by exploring relevant paid online courses' : 'Improve your skills with these part-time courses, which might be fully-funded.'}
        </P>
        {item.learning_curve_group_courses && item.learning_curve_group_courses.length > 0 && (
          <P align="center">Each one is equivalent to a GCSE A* to C.</P>
        )}
        <ButtonWrap>
          <Amplitude>
            {({ logEvent }) => (
              <SecondaryButton onClick={() => handleViewCourses(logEvent)}>
                {getViewCoursesButtonLabel(item)}
                {item.learning_curve_group_courses && item.learning_curve_group_courses.length === 0 && <Image src={IconExternalLink} alt="" />}
              </SecondaryButton>
            )}
          </Amplitude>
        </ButtonWrap>
      </Container>

      <ResultsModal
        open={open}
        setOpen={setOpen}
        title={`Want to become a ${item.career_name}?`}
        icon={IconCircleTick}
        results={[]}
        handleShowMore={() => {}}
      >
        <>
          <P align="center">
            {skillsMatch && `You have a skill match of ${skillsMatch} with ${item.career_name_with_prefix}. `}
            Improve your skills with these part-time courses, which might be fully-funded.
          </P>
          <P align="center">Each one is equivalent to a GCSE A* to C.</P>
          {item.learning_curve_group_courses && (item.learning_curve_group_courses.map(course => (
            <ResultsModalCard
              key={course.id}
              title={course.alternative_name}
              subtitle={`Length: ${course.length}`}
              description={shorterDescription(course.description)}
              ctaLabel="Enrol"
              handleCtaClick={() => viewCourse(course.url, item.career_id, course.id,  user_id)}
            >
            </ResultsModalCard>
          )))}
        </>
      </ResultsModal>
    </>
  );
};

export default CourseSection;

const Container = styled.div`
  margin-top: 32px;
  padding-bottom: 20px;
  margin-left: 8px;
  margin-right: 8px;
`;

const Title = styled(H3)`
  padding-top: 50px;
  background-image: url(${IconCircleTick});
  background-repeat: no-repeat;
  background-position: top center;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const Image = styled.img`
  margin-left: 4px;
  padding-bottom: 2px;
`;
