/* Education screen > A-levels tab
   Ref. EducationScreen.js
   May need to select "No" for "Do you have a degree?" to see the A-level tab */

import React, { useState } from 'react';
import styled from 'styled-components';

import { copyArray } from '../../utils/copyArray';
import { cleanStringForComparison } from '../../utils/helper';

import AutocompleteField from '../../ui/form/AutocompleteField';
import Input from '../../ui/form/Input';
import { Label, HintText } from '../../ui/typography/Typography';
import IncrementInput from '../../components/IncrementInput';
import Checkbox from '../../ui/form/Checkbox';

const Alevels = ({ handleChange, options, pre_university_autocomplete_options }) => {
  let grades = [
    { "id": 0, "name": "F" },
    { "id": 1, "name": "E" },
    { "id": 2, "name": "D" },
    { "id": 3, "name": "C" },
    { "id": 4, "name": "B" },
    { "id": 5, "name": "A" },
    { "id": 6, "name": "A*" }
  ];

  let subjectValues = [
    { value: "", placeholder: "e.g. Mathematics", grade: "C", id: 0, error: null },
    { value: "", placeholder: "e.g. English Literature", grade: "C", id: 1, error: null },
    { value: "", placeholder: "e.g. Chemistry", grade: "C", id: 2, error: null },
    { value: "", placeholder: "e.g. Psychology", grade: "C", id: 3, error: null }
  ];

  const [alternativeQualifications, setAlternativeQualifications] = useState([
    {id: 0, value: "", placeholder: "e.g. BTEC in Business"},
    {id: 1, value: "", placeholder: "e.g. NVQ in Social Care"},
    {id: 2, value: "", placeholder: ""},
  ]);

  const [subjects, setSubjects] = useState(subjectValues);

  const [school, setSchool] = useState({value: "", placeholder: "Your school or college name", error: null, home_schooled: false, school_outside_uk: false});

  const onFieldBlur = (item, id) => {
    const val = options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(subjects);
      if (item !== "") {
        copy[id].error = "This is not a valid subject";
      }
      setSubjects(copy);
    }
  };

  const onSchoolFieldBlur = (item) => {
    const val = pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(school);
      copy.error = "This is not a valid school or college";
      setSchool(copy);
    }
  };

  const handleInputFieldChange = (value, id) => {
    let copy = copyArray(subjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", grade: "C", id: copy.length, error: null })
    }
    setSubjects(copy);
    prepData(copy, alternativeQualifications, school);
  };

  const handleSchoolInputFieldChange = (value) => {
    let copy = copyArray(school);

    copy.error = null;
    copy.value = value.name || value;

    setSchool(copy);
    prepData(subjects, alternativeQualifications, copy);
  };

  const handleHomeSchooledChange = (value) => {
    let copy = copyArray(school);

    copy.home_schooled = value;

    setSchool(copy);
    prepData(subjects, alternativeQualifications, copy);
  };

  const handleSchoolOutsideUKChange = (value) => {
    let copy = copyArray(school);

    copy.school_outside_uk = value;

    setSchool(copy);
    prepData(subjects, alternativeQualifications, copy);
  };

  const handleGradeChange = (value, id) => {
    let copy = copyArray(subjects);

    copy[id].grade = value.name || value;

    setSubjects(copy);
    prepData(copy, alternativeQualifications, school);
  };

  const handleOtherQualificationsChange = (val, id) => {
    let aqCopy = copyArray(alternativeQualifications);

    aqCopy[id].value = val.name || val;

    if (id === (alternativeQualifications.length-1)) {
      aqCopy.push({value: "", id: alternativeQualifications.length});
    }

    setAlternativeQualifications(aqCopy);
    prepData(subjects, aqCopy, school);
  };

  const prepData = (subjs, otherSubjects, userSchool) => {
    let qualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of subjs) {
      if (options) {
        const val = options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          qualifications.push({
            id: val.id,
            grade: subject.grade,
          })
        }
      }
    }

    let otherQualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of otherSubjects) {
      if (subject.value !== "") otherQualifications.push(`${subject.value}`);
    }

    let alevelSchool = null;
    if (pre_university_autocomplete_options) {
      const matchedSchool =
        pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((userSchool.value !== null) ? cleanStringForComparison(userSchool.value.toLowerCase()) : userSchool.value));
      if (userSchool.home_schooled) {
        alevelSchool = -1;
      } else if (userSchool.school_outside_uk) {
        alevelSchool = -2;
      } else if (matchedSchool) {
        alevelSchool = matchedSchool.id;
      }
    }

    handleChange(qualifications, otherQualifications, alevelSchool);
  };


  return (
    <>
      <Label>What subjects and grades did you achieve in your A-levels or Highers?</Label>
      <HintText>Type a subject and select from available options. If you cannot find a suitable subject simply leave it blank.</HintText>
      {subjects.map(item => (
        <InputWrapFlex key={item.id}>
          <AutocompleteWrap>
            <AutocompleteField
              placeholder={item.placeholder}
              suggestions={options}
              handleChange={it => handleInputFieldChange(it, item.id)}
              error={item.error}
              onBlur={e => onFieldBlur(e.target.value, item.id)}
            />
          </AutocompleteWrap>
          <div>
            <IncrementInput values={grades} startValue={grades[3]} handleChange={it => handleGradeChange(it, item.id)} />
          </div>
        </InputWrapFlex>
      ))}
      <LabelSpaced>Do you have any A-level equivalent qualifications?</LabelSpaced>
      {alternativeQualifications.map(item => (
        <InputWrap key={item.id}>
          <Input
            label={item.label}
            hint={item.hint}
            placeholder={item.placeholder}
            id={item.id}
            handleChange={(it) => handleOtherQualificationsChange(it, item.id)}
            error={item.error}
          />
        </InputWrap>
      ))}
      <LabelSpaced>Where did you study your A-levels?</LabelSpaced>
      <HintText>Select from available options.</HintText>
      <InputWrap>
        <AutocompleteField
          placeholder={school.placeholder}
          suggestions={pre_university_autocomplete_options}
          handleChange={it => handleSchoolInputFieldChange(it)}
          error={school.error}
          onBlur={e => onSchoolFieldBlur(e.target.value)}
          disabled={school.home_schooled || school.school_outside_uk}
        />
      </InputWrap>
      <InputWrap>
        <Checkbox
          handleChange={it => handleHomeSchooledChange(it)}
          label="I was home-schooled"
          name="home_schooled"
        />
      </InputWrap>
      <InputWrap>
        <Checkbox
          handleChange={it => handleSchoolOutsideUKChange(it)}
          label="I went to school outside the UK"
          name="school_outside_uk"
        />
      </InputWrap>
    </>
  );
};

export default Alevels;

const InputWrapFlex = styled.div`
  margin-bottom: 12px;
  display: flex;
`;

const InputWrap = styled.div`
  margin-bottom: 12px;
`;

const AutocompleteWrap = styled.div`
  margin-right: 4px;
`;

const LabelSpaced = styled(Label)`
  margin-top: 32px;
`;
