import { css } from 'styled-components';
import layout from '../../config/layout';
import ConfettiMobile from '../../assets/confetti-mobile.svg';
import Confetti from '../../assets/confetti.svg';
import Bg from '../../assets/bg-purple.svg';

export const ConfettiBg = css`
  background-image: url(${Confetti});
  background-repeat: no-repeat;
  background-position: center top;

  @media(max-width: ${layout.breakpoints.MD}) {
    background-image: url(${ConfettiMobile});
    background-repeat: repeat-x;
  }
`;

export const ColorBg = css`
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
`;
