/* 404 screen */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import layout from '../config/layout';

import { P, H1 } from '../ui/typography/Typography';
import BgTwoDotsTwoShapes from '../ui/bg/BgTwoDotsTwoShapes';
import Header from '../components/Header';
import { PrimaryButton } from '../ui/buttons/Buttons';


const NotFoundScreen = () => {
  return (
    <>
      <MetaTags>
        <title>Page Not Found</title>
        <meta id="meta-description" name="description" content="Sorry, the page you're looking for was not found." />
        <meta name="robots" content="noindex" />
      </MetaTags>
      <Container>
        <BgTwoDotsTwoShapes />
        <Header logo_alignment="center" />
        <ContentContainer>
          <Content>
            <Title align="center">404</Title>
            <P align="center">Sorry! The page you're looking for was not found!</P>
            <ButtonWrap>
              <Link to="/">
                <PrimaryButton>Go to home page</PrimaryButton>
              </Link>
            </ButtonWrap>
          </Content>
        </ContentContainer>
      </Container>
    </>
  );

};

export default NotFoundScreen;

const Title = styled(H1)`
  font-size: 180px;
  line-height: 180px;

  @media(max-width: ${layout.breakpoints.MD}) {
    font-size: 90px;
    line-height: 90px;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  max-width: 544px;
  padding: 0 20px 100px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;


const ButtonWrap = styled.div`
  margin-top: 32px;
  text-align: center;
  margin-bottom: 12px;
`;
