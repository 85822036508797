/* Homepage > Get started > Situation screen */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import { validateAge, validateSalary } from '../../utils/validation';
import layout from '../../config/layout';
import { valueFormatting } from '../../utils/formatting';
import { useStateValue } from "../../services/state";
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Multiselect from '../../components/multiselect/Multiselect';
import { Label } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import { Error } from '../../ui/typography/Typography';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import Warning from '../../components/Warning';
import Footer from '../../components/Footer.js';

const SituationScreen = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingText, setLoadingText ] = useState();
  const [ generalError, setGeneralError ] = useState('');
  const [ age, setAge ] = useState('');
  const [ ageError, setAgeError ] = useState(null);
  const [ salary, setSalary ] = useState('');
  const [ salaryError, setSalaryError ] = useState(null);
  const [ location, setLocation ] = useState([
    { name: 'England', selected: false, id: 0, value: 'england' },
    { name: 'Wales', selected: false, id: 1, value: 'wales' },
    { name: 'Scotland', selected: false, id: 2, value: 'scotland' },
    { name: 'Northern Ireland', selected: false, id: 3, value: 'northern_ireland' },
    { name: 'Outside the UK', selected: false, id: 4, value: 'outside_uk' },
  ]);
  const [ inFulltimeEducation, setInFulltimeEducation ] = useState([
    { name: 'Yes', selected: false, id: 0, value: true },
    { name: 'No', selected: false, id: 1, value: false }
  ]);
  const [ employmentStatus, setEmploymentStatus ] = useState([
    { name: 'Employed', selected: false, id: 0, value: 'employed' },
    { name: 'Self-employed', selected: false, id: 1, value: 'employed' },
    { name: 'Unemployed', selected: false, id: 2, value: 'unemployed' },
    { name: 'Student', selected: false, id: 3, value: 'student' },
    { name: 'Retired', selected: false, id: 4, value: 'retired' },
  ]);
  const [ placeOfStudy, setPlaceOfStudy ] = useState([
    { name: 'Secondary school', selected: false, id: 0, value: 'secondary_school' },
    { name: 'College', selected: false, id: 1, value: 'college' },
    { name: 'University', selected: false, id: 2, value: 'university' },
    { name: 'Other', selected: false, id: 3, value: 'other' },
  ]);

  const [{ user_id, loading, context }, dispatch] = useStateValue();

  const history = useHistory();
  const match = useRouteMatch("/:userId/situation");

  useGetScreenData('situation', setIsLoading, match.params.userId);

  const handleLocationChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setLocation(values);
  };

  const handleInFulltimeEducationChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setInFulltimeEducation(values);
  };

  const handleEmploymentStatusChange = (id, data) => {
    const values = [...employmentStatus];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setEmploymentStatus(values);
  };

  const handlePlaceOfStudyChange = (id, data) => {
    const values = [...placeOfStudy];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setPlaceOfStudy(values);
  };

  const checkSalary = () => setSalaryError(validateSalary(salary));
  const checkAge = () => setAgeError(validateAge(age));

  const validateForm = () => {

    let isValid = true;
    const userLocation = location.find(item => item.selected === true);
    const userInFulltimeEducation = inFulltimeEducation.find(item => item.selected === true);
    const userEmploymentStatus = employmentStatus.find(item => item.selected === true);
    const userPlaceOfStudy = placeOfStudy.find(item => item.selected === true);

    if (validateAge(age)) isValid = false;

    if (!userLocation) {
      isValid = false;
    } else if (age >= 16 && age <= 21 && !userInFulltimeEducation) {
      isValid = false;
    } else if ( ( (age >= 16 && age <= 21 && userInFulltimeEducation.value === false) || age >= 22 ) && !userEmploymentStatus ) {
      isValid = false;
    } else if (
      ((age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === false) || age >= 22) &&
      userEmploymentStatus && userEmploymentStatus.value === 'employed' &&
      userLocation.value !== "outside_uk" && validateSalary(salary) ) {
      isValid = false;
    } else if (
      age &&
      ( (age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === true) ||
        (age >= 22 && userEmploymentStatus && userEmploymentStatus.value === "student") ) &&
      userLocation && (userLocation.value === "england" || userLocation.value === "wales" || userLocation.value === "northern_ireland") &&
      !userPlaceOfStudy ) {
      isValid = false;
    }

    return isValid;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {
      const userLocation = location.find(item => item.selected === true);
      const userInFulltimeEducation = inFulltimeEducation.find(item => item.selected === true);
      const userEmploymentStatus = employmentStatus.find(item => item.selected === true);
      const userPlaceOfStudy = placeOfStudy.find(item => item.selected === true);

      if (age < 22 && userLocation && userLocation.value !== "england" && userLocation.value !== "wales" && userLocation.value !== "northern_ireland") {
        setLoadingText(context.loading_message); 
      }
      setIsLoading(true);

      let situationDetails = {
        age: age,
        location: userLocation.value,
      };

      if (age && age >= 16 && age <= 21 && userInFulltimeEducation) {
        situationDetails = {...situationDetails, in_fulltime_education: userInFulltimeEducation.value };
      }

      if ( age &&
           ( (age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === false) || age >= 22 ) &&
           userEmploymentStatus ) {
        situationDetails = {...situationDetails, employment_status: userEmploymentStatus.value };
      }

      if (userEmploymentStatus && userEmploymentStatus.value === 'employed' && userLocation.value !== "outside_uk") {
        let salaryToSubmit = valueFormatting(salary, false);
        if (salaryToSubmit === "") {
          salaryToSubmit = null;
        }
        situationDetails = {...situationDetails, salary: salaryToSubmit };
      }

      if (
        age &&
        ( (age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === true) ||
          (age >= 22 && userEmploymentStatus && userEmploymentStatus.value === "student") ) &&
        userLocation && (userLocation.value === "england" || userLocation.value === "wales" || userLocation.value === "northern_ireland") &&
        userPlaceOfStudy ) {
          situationDetails = {...situationDetails, place_of_study: userPlaceOfStudy.value };
      }

      const submitData = createSubmitData({ situation_details: situationDetails, user_id: user_id });

      axios.post('/api/v1/handleSituationInput', submitData).then((res) => {
        dispatch({type: 'updateResponse', data: res.data});
        if (!res.data.errors) {
          logEvent('Completed Situation Input', {
            age: situationDetails.age,
            location: situationDetails.location,
            live_in_the_uk: (situationDetails.location !== "outside_uk"),
            in_fulltime_education: situationDetails.in_fulltime_education,
            employment_status: situationDetails.employment_status,
            salary: situationDetails.salary,
            place_of_study: situationDetails.place_of_study,
          });
        }
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('field-errors')) {
              // eslint-disable-next-line no-unused-vars
              for (const item of error['field-errors']) {
                if (item.hasOwnProperty('salary')) {
                  const salaryErr = item.salary.reduce((i, err) => (err + ''), '');
                  setSalaryError(salaryErr);
                }
                if (item.hasOwnProperty('age')) {
                  const ageErr = item.age.reduce((i, err) => (err + ''), '');
                  setAgeError(ageErr);
                }
              }
            } else {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }

      })
    }
  };


  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  const userLocation = location.find(item => item.selected === true);
  const userInFulltimeEducation = inFulltimeEducation.find(item => item.selected === true);
  const userEmploymentStatus = employmentStatus.find(item => item.selected === true);
  const userPlaceOfStudy = placeOfStudy.find(item => item.selected === true);

  let captureEmploymentStatus = false;
  if ( age && ( (age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === false) || age >= 22 ) ) {
    captureEmploymentStatus = true;
  }

  let capturePlaceOfStudy = false;
  if ( age &&
       ( (age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === true) ||
         (age >= 22 && userEmploymentStatus && userEmploymentStatus.value === "student") ) &&
       userLocation && (userLocation.value === "england" || userLocation.value === "wales" || userLocation.value === "northern_ireland") ) {
    capturePlaceOfStudy = true;
  }

  return (
    <>
      <MetaTags>
        <title>Situation</title>
        <meta id="meta-description" name="description" content="Input your situation" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Let’s kick things off"
        subtitle="To get started, we have a few questions to better understand you..."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <Input
                  onBlur={checkAge}
                  type="number"
                  label="How old are you?"
                  handleChange={(val) => {setAge(val); setAgeError(null);}}
                  error={ageError}
                  placeholder="30"
                  testid="age"
                />
              </InputWrap>
              <InputWrap>
                <Label>Where do you live?</Label>
                <Multiselect
                  values={location}
                  handleChange={handleLocationChange}
                  name=""
                  testid="location"
                />
              </InputWrap>
              {userLocation && (userLocation.value === "scotland" || userLocation.value === "outside_uk") && !context.organisation_id && !context.education_institution_id && (
                <WarningWrap>
                  <Warning text="We're still working on some features for your area, so it's free for now 😊" />
                </WarningWrap>
              )}
              {age && age >= 16 && age <= 21 && (
                <InputWrap>
                <Label>Are you in full-time education?</Label>
                <Multiselect
                  values={inFulltimeEducation}
                  handleChange={handleInFulltimeEducationChange}
                  name=""
                  testid="full_time_education"
                />
              </InputWrap>
              )}
              {captureEmploymentStatus && (
                <InputWrap>
                  <Label>What's your employment status?</Label>
                  <Multiselect
                    values={(age && age >= 16 && age <= 21 && userInFulltimeEducation && userInFulltimeEducation.value === false) ? employmentStatus.slice(0, 3) : employmentStatus}
                    handleChange={handleEmploymentStatusChange}
                    name=""
                    testid="employment"
                  />
                </InputWrap>
              )}
              {captureEmploymentStatus &&
                 userEmploymentStatus && userEmploymentStatus.value === "employed" && userLocation && userLocation.value !== "outside_uk" && (
                <InputWrap>
                  <Input
                    onBlur={checkSalary}
                    type="text"
                    pattern="\d*"
                    label="Approximately, what's your current annual salary?"
                    inputValue={salary}
                    handleChange={(val) => {setSalary(val); setSalaryError(null);}}
                    valueFormatting={value => valueFormatting(value, true)}
                    error={salaryError}
                    placeholder="£25,000"
                    testid="salary"
                  />
                </InputWrap>
              )}
              {capturePlaceOfStudy && (
                <InputWrap>
                  <Label>{(age && age >= 16 && age <= 21) ? "What stage of education are you at?" : "Where are you studying?"}</Label>
                  <Multiselect
                    values={(age && age >= 22) ? placeOfStudy.slice(1, 4) : placeOfStudy}
                    handleChange={handlePlaceOfStudyChange}
                    name=""
                    testid="school_stage"
                  />
                </InputWrap>
              )}
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude
                    userProperties={{
                      age: age,
                      location: userLocation.value,
                      live_in_the_uk: (userLocation.value !== "outside_uk"),
                      in_fulltime_education: ( (age && age >= 16 && age <= 21 && userInFulltimeEducation) ? userInFulltimeEducation.value : null ),
                      employment_status: (captureEmploymentStatus && userEmploymentStatus) ? userEmploymentStatus.value : null,
                      salary: valueFormatting(salary, false),
                      place_of_study: (capturePlaceOfStudy && userPlaceOfStudy) ? userPlaceOfStudy.value : null,
                      gender: context.gender,
                    }}
                  >
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)} data-testid="next">Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <Tooltip text="Please enter your situation details above">
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
        {context && context.show_footer && (
          <Footer hideSocialMedia showLinkToWebsite />
        )}
      </ThreeDotsBgLayout>
    </>
  );
};

export default SituationScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const WarningWrap = styled.div`
  margin-bottom: 32px;
`;
