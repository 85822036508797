import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import layout from '../../config/layout';
import { ConfettiBg } from '../../ui/bg/BgStyles';
import BgThreeDots from '../../ui/bg/BgThreeDots';
import Header from '../../components/Header';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { H1, P } from '../../ui/typography/Typography';


const ConfettiLayout = ({ title, children, subtitle, hasConfetti, sideMargin }) => {
  return (
    <Container hasConfetti={hasConfetti} sideMargin={sideMargin}>
      {!hasConfetti && (
        <BgThreeDots addMobile />
      )}
      <Header logo_alignment="center" large />
      <Grid>
        <Row>
          <Col xs={10} xsOffset={1} md={10} mdOffset={1}>
            <Title align="center" hasSubtitle={subtitle}>{title}</Title>
            {subtitle && <P align="center">{subtitle}</P>}
          </Col>
        </Row>
      </Grid>
      {children}
    </Container>
  );
};

export default ConfettiLayout;

ConfettiLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.string,
  hasConfetti: PropTypes.bool,
  sideMargin: PropTypes.bool,
};

const Container = styled.div`
  ${props => props.hasConfetti && ConfettiBg}
  min-height: ${props => props.hasConfetti ? '100vh' : 'auto'};
  ${props => props.sideMargin && 'margin: 0 10px 0 10px;'}
  overflow: hidden;
`;

const Title = styled(H1)`
  margin-bottom: 4px;
`;
