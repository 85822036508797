import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';
import { P } from '../ui/typography/Typography';
import layout from  '../config/layout';

const SelectBar = ({ values, handleChange, selectedValue, disabled, testid, ignoreScreenSize }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (typeof(selectedValue) === 'object') {
      setSelected(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectItem = (selected_item) => {
    if (!disabled) {
      setSelected(selected_item);
      handleChange(selected_item);
    }
  };

  return (
    <Container ignoreScreenSize={ignoreScreenSize}>
      {values.map(item => (
        <Item selected={selected && selected.id === item.id} disabled={disabled} onClick={() => onSelectItem(item)} key={item.id} data-testid={testid} ignoreScreenSize={ignoreScreenSize}>
          <P nomargin color={selected && selected.id === item.id ? colors.WHITE : colors.GRAY_DARK} desktop_styles_only={ignoreScreenSize}>{item.label}</P>
        </Item>
      ))}
    </Container>
  );
};

export default SelectBar;

SelectBar.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.any.isRequired,
  })).isRequired,
  selectedValue: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  testid: PropTypes.string,
  ignoreScreenSize: PropTypes.bool,
};


const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => !props.ignoreScreenSize && `border-radius: 0px;`}
  }
`;

const Item = styled.button`
  padding: 8px 16px;
  background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHTEST_GREY};
  margin: 1px;
  cursor: pointer;
  border: 0;

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media(max-width: ${layout.breakpoints.MD}) {
      ${props => !props.ignoreScreenSize && `border-top-left-radius: 0px;`}
      ${props => !props.ignoreScreenSize && `border-bottom-left-radius: 0px;`}
    }
  }

  &:last-child {
    margin-right: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @media(max-width: ${layout.breakpoints.MD}) {
      ${props => !props.ignoreScreenSize && `border-top-right-radius: 0px;`}
      ${props => !props.ignoreScreenSize && `border-bottom-right-radius: 0px;`}
    }
  }

  &:hover {
    background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_PURPLE_FADE};
  }

  &:disabled {
    cursor: default;
    background-color: ${props => props.selected ? colors.GRAY : colors.GRAY_FADE};
    color: ${colors.WHITE};

    &:hover {
      background-color: ${props => props.selected ? colors.GRAY : colors.GRAY_FADE};
      color: ${colors.WHITE};
    }
  }
`;
