import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MultiselectItem from './MultiselectItem';

const Multiselect = ({ values, handleChange, name, disabled, testid }) => {
  const selectItem = (item) => {
    handleChange(item.id, values, name);
  };

  return (
    <Container>
      {values.map(item => (
        <ItemWrap onClick={() => {if (!disabled) selectItem(item)}} key={item.id}>
          <MultiselectItem
            label={item.friendly_name || item.name}
            item_selected={item.selected}
            disabled={disabled}
            testid={testid}
          />
        </ItemWrap>
      ))}
    </Container>
  );
};

export default Multiselect;

Multiselect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    friendly_name: PropTypes.string,
    name: PropTypes.any,
    id: PropTypes.any.isRequired,
  })).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

const Container = styled.div`
  border-radius: 8px;
  margin: auto;
  max-width: 100%;
  overflow: hidden;
`;

const ItemWrap = styled.div`
  margin-top: 2px;

  &:first-child {
    margin-top: 0;
  }
`;
