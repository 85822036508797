/* Dashboard > Manage team (top right) */

import React, { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import axios from 'axios';

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createOrgSubmitData } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P, H3 } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import Checkbox from '../../ui/form/Checkbox';

const TeamScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ screenSize, setScreenSize ] = useState(null);

  const [ emailSendMessages, setEmailSendMessages ] = useState([]);
  const [ sendingInvite, setSendingInvite ] = useState(null);

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetScreenData('organisations/team', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/team', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/team") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendInvite = (userId) => {
    setSendingInvite(userId);
    const orgSubmitData = createOrgSubmitData({
      team_member_org_user_id: userId,
    });
    axios.post('/api/v1/send_email_invitation_to_org_team_member', orgSubmitData).then((res) => {
      if (res.status === 200) {
        emailSendMessages[userId] = res.data;
        setEmailSendMessages(emailSendMessages);
        setSendingInvite(null);
      } else {
        emailSendMessages[userId] = {
          success: false,
          message: "Failed to send email",
        };
        setEmailSendMessages(emailSendMessages);
        setSendingInvite(null);
      }
    }).catch((error) => {
      emailSendMessages[userId] = {
        success: false,
        message: "Failed to send email",
      };
      setEmailSendMessages(emailSendMessages);
      setSendingInvite(null);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("organisation_user_id");
        localStorage.removeItem("session_id");
        history.push(`/organisations/login`);
      }
    });
  }

  const updateTeamMemberActiveStatus = async (userId, newActiveValue) => {
    const orgSubmitData = createOrgSubmitData({
      team_member_org_user_id: userId,
      new_active_value: newActiveValue,
    });
    axios.post('/api/v1/update_team_member_active_status', orgSubmitData).then(res => {
      dispatch({type: "updateResponse", data:res.data});
    });
  }

  const updateEnable2FA = async (userId, it) => {
    const orgSubmitData = createOrgSubmitData({
      team_member_org_user_id: userId,
      enable_2fa: it,
    });
    axios.post('/api/v1/update_enable_2fa', orgSubmitData).then(res => {
      dispatch({type: "updateResponse", data:res.data});
    });
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Manage team</title>
        <meta id="meta-description" name="description" content="CareersPro - Manage team" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        <SubTitle>Team</SubTitle>
        <ButtonContainer>
        <Link to={`/organisations/add_team_member`}>
          <PrimaryButton>Add team member</PrimaryButton>
        </Link>
        </ButtonContainer>
        {context.team_members && context.team_members.length > 0 && (
          <UsersTable>
            <thead>
              <TableRow header>
                <TableHeaderCell pl><TableText header>Name</TableText></TableHeaderCell>
                {screenSize > layout.numericalBreakpoints.SM && (
                  <TableHeaderCell><TableText header>Email</TableText></TableHeaderCell>
                )}
                {screenSize > layout.numericalBreakpoints.VSM && (
                  <TableHeaderCell><TableText header>Invite status</TableText></TableHeaderCell>
                )}
                <TableHeaderCell pr={!screenSize > layout.numericalBreakpoints.VSM} ><TableText header>Remove</TableText></TableHeaderCell>
                {screenSize > layout.numericalBreakpoints.SM && (
                  <TableHeaderCell pr><TableText header>Enable 2FA</TableText></TableHeaderCell>
                )}
              </TableRow>
            </thead>
            <tbody>
              {context.team_members.map((item, key) => (
                <TableRow key={key} id={key}>
                  <TableCell pl><TableText>{item.name}</TableText></TableCell>
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableCell><TableText>{item.email}</TableText></TableCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.VSM && (
                    <TableCell>
                      {sendingInvite === item.id ? (
                        <TableText>Sending invite...</TableText>
                      ) :
                      (emailSendMessages[item.id] ? (
                        <TableText error={!emailSendMessages[item.id].success}>{emailSendMessages[item.id].message}</TableText>
                      ) :
                      (item.admin ? (
                        <TableText>Admin</TableText>
                      ) :
                      (!item.active ? (
                        <TableText>Removed</TableText>
                      ) :
                      (item.account_created ? (
                        <TableText>Invite accepted</TableText>
                      ) :
                      (
                        <SecondaryButton
                          small={screenSize <= layout.numericalBreakpoints.MD}
                          onClick={() => {
                            sendInvite(item.id)
                          }}>
                          Resend invite
                        </SecondaryButton>
                      )))))}
                    </TableCell>
                  )}
                  <TableCell>
                    {!item.admin && (
                      item.active ? (
                        <PrimaryButton
                          small={screenSize <= layout.numericalBreakpoints.MD}
                          onClick={() => {
                            updateTeamMemberActiveStatus(item.id, false)
                          }}>
                          Remove
                        </PrimaryButton>
                      ) : (
                        <SecondaryButton
                          small={screenSize <= layout.numericalBreakpoints.MD}
                          onClick={() => {
                            updateTeamMemberActiveStatus(item.id, true)
                          }}>
                          Restore
                        </SecondaryButton>
                      )
                    )}
                  </TableCell>
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableCell pr>
                      {!item.admin && (
                        <Checkbox
                          name="enable_2fa"
                          handleChange={(it) => updateEnable2FA(item.id, it)}
                          defaultChecked={item.enabled_2fa}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </tbody>
          </UsersTable>
        )}
        <BackButtonContainer>
          <Link to={`/organisations/dashboard`}>
            <SecondaryButton>Back to dashboard</SecondaryButton>
          </Link>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default TeamScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 250px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 40px;
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const UsersTable = styled(Table)`
  margin: auto;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  ${props => `padding: 12px ${props.pr ? "20px": "8px"} 12px ${props.pl ? "20px": "8px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 8px ${props.pr ? "12px": "4px"} 8px ${props.pl ? "12px": "4px"};`}
  }
`

const TableRow = styled.tr`
  ${props => props.header && `background-color: ${colors.BG_LIGHT_GRAY};`}
  ${props => !props.header && props.id % 2 === 0 && `background-color: ${colors.WHITE};`}
`

const TableText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin: auto;
  ${props => props.header && "font-weight: bold;"}
  ${props => props.status_id === 0 && `color: ${colors.LCG_DARK_PURPLE_FADE};`}
  ${props => props.status_id === 1 && `color: ${colors.LCG_LIGHT_PURPLE};`}
  ${props => props.status_id === 2 && `color: ${colors.LCG_DARK_PURPLE};`}
  ${props => props.error && `color: ${colors.LCG_DARK_PURPLE};`}
`

const TableCell = styled.td`
  ${props => `padding: 20px ${props.pr ? "32px": "20px"} 20px ${props.pl ? "32px": "20px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  }
`

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 32px 0 32px;
  padding-top: 20px;
`;
