import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../config/colors';
import layout from  '../config/layout';

import { P, H4 } from '../ui/typography/Typography';
import { LCGPurpleButton } from '../ui/buttons/Buttons';

const UnlockPremiumCard = ({user_id, price}) => {
  const history = useHistory();
  return (
    <UnlockCard>
      <H4>This is a premium feature</H4>
      <Text>Access this feature and all other premium features for just {price}</Text>
      <LCGPurpleButton onClick={() => {history.push(`/${user_id}/upgrade`);}}>Unlock</LCGPurpleButton>
    </UnlockCard>
  );
};

export default UnlockPremiumCard;

const UnlockCard = styled.div`
  border-radius: 20px;
  background-color: ${colors.LCG_DARK_PURPLE};
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  margin: 0 auto 40px auto;
  padding: 32px 32px 32px 32px;
  position: relative;
  max-width: 450px;
  text-align: center;

  @media (max-width: ${layout.breakpoints.MD}) {
    max-width: 350px;
  }
`;

const Text = styled(P)`
  margin: 16px 0 24px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin: 16px 0 16px 0;
  }
`
