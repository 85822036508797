/* Loading screen */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import { P } from '../ui/typography/Typography';
import BgThreeDots from '../ui/bg/BgThreeDots';
import Loader from  '../components/Loader';
import colors from '../config/colors';

const LoadingScreen = ({ text }) => {
  return (
    <Container>
      <BgThreeDots />
      <LoaderContainer>
        <Loader>
          {text && (<TextContainer align="Center">{ReactHtmlParser(text)}</TextContainer>)}
        </Loader>
      </LoaderContainer>
    </Container>
  );

};

export default LoadingScreen;

LoadingScreen.propTypes = {
  text: PropTypes.any,
};

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: ${colors.WHITE};
  z-index: 100;
  overflow: hidden;
`;

const LoaderContainer = styled.div`
  max-width: 520px;
  padding: 0 20px;
`;

const TextContainer = styled(P)`
  min-height: 50px;
`
