/* Quiz hints and tips screen that appears after all the input screens and just before the start of the quiz */

import React from 'react';
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude';

import { useStateValue } from '../../services/state';

import InfoScreen from '../InfoScreen';

const PreQuizInfoScreen = () => {
  // eslint-disable-next-line
  const [_, dispatch] = useStateValue();
  return (
    <Amplitude>
      <LogOnMount eventType="pre quiz screen">
        <InfoScreen
          title="Career Quiz Hints & Tips"
          text={[
            '<b>1. Go with your gut!</b>',
            'We’re going to ask you lots of quick-fire questions, so don’t overthink them.',
            '<b>2. Don’t worry if a career feels unrealistic</b>',
            'Choose careers you think you’d enjoy the most, even if they feel unrealistic.  We just need to build a picture of what interests you the most.',
            '<b>3. Some careers might come up twice</b>',
            'Don’t worry, this is just so we can understand how you feel about it in comparison to other careers',
            '<b>4. We’ll auto-save your answers as you go</b>',
          ]}
          buttonLabel="Continue"
          handleButtonClick={() => dispatch({type: 'update', values: { showPreQuizInfoScreen: false }})}
        />
      </LogOnMount>
    </Amplitude>
  );
};

export default PreQuizInfoScreen;
