/* Homepage > Get started > In education at Secondary school > Secondary school screen */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { validatePostcode } from '../../utils/validation';
import { useStateValue } from "../../services/state";
import { cleanString, cleanStringForComparison, createSubmitData } from '../../utils/helper';
import { copyArray } from '../../utils/copyArray';
import { getNameByID, getNamesByIDs } from '../../utils/getNamesByIDs';
import { useHistory } from 'react-router-dom';
import { useGetScreenData } from '../../hooks/getDataHooks';
import { useRouteMatch } from 'react-router-dom';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../../ui/form/Input';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Multiselect from '../../components/multiselect/Multiselect';
import { Label, HintText, Error } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';
import AutocompleteField from '../../ui/form/AutocompleteField';
import Checkbox from '../../ui/form/Checkbox';

const SecondarySchoolScreen = () => {

  const [{ user_id, context, loading }, dispatch] = useStateValue();

  let schoolYears = [
    { name: 'Year 5', selected: false, id: 0, value: 5 },
    { name: 'Year 6', selected: false, id: 1, value: 6 },
    { name: 'Year 7', selected: false, id: 2, value: 7 },
    { name: 'Year 8', selected: false, id: 3, value: 8 },
    { name: 'Year 9', selected: false, id: 4, value: 9 },
    { name: 'Year 10', selected: false, id: 5, value: 10 },
    { name: 'Year 11', selected: false, id: 6, value: 11 },
    { name: 'Year 12', selected: false, id: 7, value: 12 },
    { name: 'Year 13', selected: false, id: 8, value: 13 }
  ];
  const [ schoolYear, setSchoolYear ] = useState(schoolYears);

  let subjectValues = [
    { value: "", placeholder: "e.g. Mathematics", id: 0, error: null },
    { value: "", placeholder: "e.g. English Literature", id: 1, error: null },
    { value: "", placeholder: "e.g. Chemistry", id: 2, error: null },
    { value: "", placeholder: "e.g. Psychology", id: 3, error: null }
  ];
  const [ subjects, setSubjects ] = useState(subjectValues);
  const [ alevels, setAlevels ] = useState(null);

  const [ school, setSchool ] = useState({value: "", placeholder: "Your school or college name", error: null, home_schooled: false});
  const [ schoolID, setSchoolID ] = useState(null);
  const [ postcode, setPostcode ] = useState('');

  const [ goToUniversity, setGoToUniversity ] = useState([
    { name: 'Yes', selected: false, id: 0, value: "yes" },
    { name: 'Maybe', selected: false, id: 1, value: "maybe" },
    { name: 'No', selected: false, id: 2, value: "no" }
  ]);

  const [ generalError, setGeneralError ] = useState();
  const [ postcodeError, setPostcodeError ] = useState(null);

  const [ isLoading, setIsLoading ] = useState([]);

  const history = useHistory();
  const match = useRouteMatch("/:userId/secondary-school");

  const valueFormatting = (value) => value.toUpperCase();

  useGetScreenData('secondary-school', setIsLoading, match.params.userId);

  useEffect(() => {
    if (context && context.education_institution_id !== null) {
      setSchoolID(context.education_institution_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleSchoolYearChange = (id, data) => {
    const values = [...data];
    let shouldNullifyAlevels = true;
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
        if (value.selected && (value.value === 12 || value.value === 13)) {
          shouldNullifyAlevels = false;
        }
      } else {
        value.selected = false;
      }
    }
    setSchoolYear(values);

    // If the user took any action except for selected year 12 or 13, then nullify a-levels (as their action would
    // cause these fields to be and will be blank when they are reinstated)
    if (shouldNullifyAlevels) {
      setAlevels(null);
    }
  };

  const onSubjectFieldBlur = (item, id) => {
    const val = context.alevel_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val) {
      let copy = copyArray(subjects);
      if (item !== "" || id === 0) {
        copy[id].error = "This is not a valid subject";
      }
      setSubjects(copy);
    }
  };

  const onSchoolFieldBlur = (item) => {
    const val = context.pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item && item !== "") {
      let copy = copyArray(school);
      copy.error = "This is not a valid school or college";
      setSchool(copy);
    }
  };

  const checkPostcode = e => {
    // Postcode validity
    const value = e.target.value;
    const error = validatePostcode(cleanString(value)) ? null : 'This is not a valid UK postcode';
    setPostcodeError(value === "" ? null : error);
  };

  const handleSubjectInputFieldChange = (value, id) => {
    let copy = copyArray(subjects);

    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ value: "", id: copy.length, error: null })
    }
    setSubjects(copy);
    prepData(copy, school);
  };

  const handleSchoolInputFieldChange = (value) => {
    let copy = copyArray(school);

    copy.error = null;
    copy.value = value.name || value;

    setSchool(copy);
    prepData(subjects, copy);
  };

  const handleHomeSchooledChange = (value) => {
    let copy = copyArray(school);

    copy.home_schooled = value;

    setSchool(copy);
    prepData(subjects, copy);
  };

  const handleGoToUniversityChange = (id, data) => {
    const values = [...data];
    // eslint-disable-next-line no-unused-vars
    for (const value of values) {
      if (value.id === id) {
        value.selected = !value.selected;
      } else {
        value.selected = false;
      }
    }
    setGoToUniversity(values);
  };

  const prepData = (subjs, userSchool) => {
    let qualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const subject of subjs) {
      if (context.alevel_autocomplete_options) {
        const val = context.alevel_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((subject.value !== null) ? cleanStringForComparison(subject.value.toLowerCase()) : subject.value));
        if (val) {
          qualifications.push({
            id: val.id,
          })
        }
      }
    }
    setAlevels(qualifications);

    if (!context.education_institution_id && context.pre_university_autocomplete_options) {
      let userSchoolID = null;
      const matchedSchool =
        context.pre_university_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((userSchool.value !== null) ? cleanStringForComparison(userSchool.value.toLowerCase()) : userSchool.value));
      if (userSchool.home_schooled) {
        userSchoolID = -1;
      } else if (matchedSchool) {
        userSchoolID = matchedSchool.id;
      }
      setSchoolID(userSchoolID);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const userSchoolYear = schoolYear.find(item => item.selected === true);
    if (!userSchoolYear) isValid = false;
    if (userSchoolYear && userSchoolYear.value >= 12 && (!alevels || alevels.length === 0)) isValid = false;
    if (!schoolID || schoolID < -1) isValid = false;
    if (school.home_schooled && !validatePostcode(cleanString(postcode))) isValid = false;
    if (!goToUniversity.find(item => item.selected === true)) isValid = false;
    return isValid;
  };

  const submitForm = (logEvent) => {
    if (validateForm()) {
      const userSchoolYear = schoolYear.find(a => a.selected === true);

      setIsLoading(true);

      const formData = createSubmitData({
        school_details: {
          school_year: userSchoolYear.value,
          qualifications: alevels,
          school: schoolID,
          postcode: (school.home_schooled) ? cleanString(postcode) : null,
          aspire_degree: goToUniversity.find(item => item.selected === true).value,
        },
        user_id: user_id,
      });
      axios.post('/api/v1/handleSecondarySchoolInput', formData).then((res) => {
        if (res.data.errors) {
          setIsLoading(false);
          // eslint-disable-next-line no-unused-vars
          for (const error of res.data.errors) {
            if (error.hasOwnProperty('field-errors')) {
              // eslint-disable-next-line no-unused-vars
              for (const item of error['field-errors']) {
                if (item.hasOwnProperty('postcode')) {
                  const postcodeErr = item.postcode.reduce((i, err) => (err + ''), '');
                  setPostcodeError(postcodeErr);
                }
              }
            } else {
              setGeneralError(error['non-field-errors']);
            }
          }
        } else {
          dispatch({type: 'updateResponse', data: res.data});
          logEvent('Completed Secondary School Input', {
            school_year: userSchoolYear.value,
            alevels: getNamesByIDs(alevels, context.alevel_autocomplete_options, "id"),
            gcse_school: (userSchoolYear.value < 12) ? getNameByID(schoolID, context.pre_university_autocomplete_options) : null,
            gcse_school_from_home: (userSchoolYear.value < 12 && schoolID === -1) ? true : null,
            alevel_school: (userSchoolYear.value >= 12) ? getNameByID(schoolID, context.pre_university_autocomplete_options) : null,
            alevel_school_from_home: (userSchoolYear.value >= 12 && schoolID === -1) ? true : null,
            postcode: (school.home_schooled) ? cleanString(postcode) : null,
            aspire_degree: goToUniversity.find(a => a.selected === true).value,
          });
          if (res.data.screen.context.question_number) {
            dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
            setTimeout(() => {
              setIsLoading(false);
              history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
            }, 2500);
          } else {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}`);
          }
        }
      })
    }
  };

  if (loading || isLoading) return <LoadingScreen />

  const userSchoolYear = schoolYear.find(a => a.selected === true);

  return (
    <>
      <MetaTags>
        <title>Your school</title>
        <meta id="meta-description" name="description" content="Input your school information" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Your school"
        subtitle="This will help us build a personalised pathway for you into your careers of interest."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <InputWrap>
                <Label>What year are you in?</Label>
                <Multiselect
                  values={schoolYear}
                  handleChange={handleSchoolYearChange}
                  name=""
                  testid="year"
                />
              </InputWrap>
              {userSchoolYear && userSchoolYear.value >= 12 && (
                <InputWrap>
                  <LabelNarrow>What subjects are you studying for your A-levels?</LabelNarrow>
                  <HintText>Type a subject and select from available options. If you cannot find a suitable subject simply leave it blank.</HintText>
                  {subjects.map(item => (
                    <AutocompleteWrap key={item.id}>
                      <AutocompleteField
                        placeholder={item.placeholder}
                        suggestions={context.alevel_autocomplete_options}
                        handleChange={it => handleSubjectInputFieldChange(it, item.id)}
                        error={item.error}
                        onBlur={e => onSubjectFieldBlur(e.target.value, item.id)}
                      />
                    </AutocompleteWrap>
                  ))}
                </InputWrap>
              )}
              {!context.education_institution_id && (
                <>
                  <InputWrap>
                    <LabelSpaced>What school do you go to?</LabelSpaced>
                    <HintText>Type, then select from available options.</HintText>
                    <AutocompleteField
                      placeholder={school.placeholder}
                      suggestions={context.pre_university_autocomplete_options}
                      handleChange={it => handleSchoolInputFieldChange(it)}
                      error={school.error}
                      onBlur={e => onSchoolFieldBlur(e.target.value)}
                      disabled={school.home_schooled}
                    />
                  </InputWrap>
                  <InputWrap>
                    <Checkbox
                      handleChange={it => handleHomeSchooledChange(it)}
                      label="I am home-schooled"
                      name="home_schooled"
                      defaultChecked={school.home_schooled}
                      testid="home_schooled"
                    />
                  </InputWrap>
                  {school.home_schooled && (
                    <InputWrap>
                      <Input
                        onBlur={checkPostcode}
                        type="text"
                        label="What's your postcode?"
                        inputValue={postcode}
                        handleChange={value => {setPostcode(value.toUpperCase()); setPostcodeError(null)}}
                        valueFormatting={valueFormatting}
                        error={postcodeError}
                        placeholder="SW1A 2AA"
                        testid="postcode"
                      />
                    </InputWrap>
                  )}
                </>
              )}
              <InputWrap>
                <Label>Do you plan to go to university?</Label>
                <Multiselect
                  values={goToUniversity}
                  handleChange={handleGoToUniversityChange}
                  name=""
                />
              </InputWrap>
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude userProperties={{
                    school_year: schoolYear.find(a => a.selected === true).value,
                    alevels: getNamesByIDs(alevels, context.alevel_autocomplete_options, "id"),
                    gcse_school: (userSchoolYear.value < 12) ? getNameByID(schoolID, context.pre_university_autocomplete_options) : null,
                    gcse_school_from_home: (userSchoolYear.value < 12 && schoolID === -1) ? true : null,
                    alevel_school: (userSchoolYear.value >= 12) ? getNameByID(schoolID, context.pre_university_autocomplete_options) : null,
                    alevel_school_from_home: (userSchoolYear.value >= 12 && schoolID === -1) ? true : null,
                    postcode: (school.home_schooled) ? cleanString(postcode) : null,
                    aspire_degree: goToUniversity.find(a => a.selected === true).value,
                  }}>
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)} data-testid="next">Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <Tooltip text={(userSchoolYear && userSchoolYear.value >= 12) ? "Please enter your school and subject details and your aspirations above" : "Please enter your school details and aspirations above"}>
                    <GrayButton disabled>Next</GrayButton>
                  </Tooltip>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default SecondarySchoolScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const LabelNarrow = styled(Label)`
  width: 300px;
`;

const AutocompleteWrap = styled.div`
  margin-bottom: 12px;
`;

const LabelSpaced = styled(Label)`
  margin-top: 32px;
`;
