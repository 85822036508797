/* Dashboard (for pay-as-you-go non-prepay customer) > View invoices (towards the top) */

import React, { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';
import { Table } from "react-bootstrap";

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { getPriceText } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P } from '../../ui/typography/Typography';
import { SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';

const InvoicesScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ screenSize, setScreenSize ] = useState(null);

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetScreenData('organisations/invoices', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/invoices', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/invoices") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || isLoading || !context) return <LoadingScreen />;

  return(
    <>
      <MetaTags>
        <title>CareersPro - Invoices</title>
        <meta id="meta-description" name="description" content="CareersPro - Invoices" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name + " - Invoices"} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        {context.prepay && !context.internal && (
          <Text pt pb>This screen only shows the amounts you were invoiced for, not your purchases that you made directly through the dashboard.</Text>
        )}
        {context.invoices && context.invoices.length > 0 && (
          <InvoicesTable>
            <thead>
              <TableRow header>
                <TableHeaderCell><TableText header>Month</TableText></TableHeaderCell>
                {screenSize > layout.numericalBreakpoints.VSM && (
                  <TableHeaderCell><TableText header># users added</TableText></TableHeaderCell>
                )}
                {screenSize > layout.numericalBreakpoints.SM && (
                  <TableHeaderCell><TableText header>Net cost</TableText></TableHeaderCell>
                )}
                {screenSize > layout.numericalBreakpoints.SM && (
                  <TableHeaderCell><TableText header>VAT</TableText></TableHeaderCell>
                )}
                <TableHeaderCell><TableText header>Total cost</TableText></TableHeaderCell>
              </TableRow>
            </thead>
            <tbody>
              {context.invoices.map((item, key) => (
                <TableRow key={key} id={key}>
                  <TableCell pl>
                    <TableText>{item.month}</TableText>
                  </TableCell>
                  {screenSize > layout.numericalBreakpoints.VSM && (
                    <TableCell>
                      <TableText>{item.num_users_added.toLocaleString()}</TableText>
                    </TableCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableCell>
                      <TableText>{getPriceText(item.net_cost_pence)}</TableText>
                    </TableCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableCell>
                      <TableText>{getPriceText(item.vat_cost_pence)}</TableText>
                    </TableCell>
                  )}
                  <TableCell>
                    <TableText>{getPriceText(item.total_cost_pence)}</TableText>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </InvoicesTable>
        )}
        <BackButtonContainer>
          <Link to={`/organisations/dashboard`}>
            <SecondaryButton>Back to dashboard</SecondaryButton>
          </Link>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default InvoicesScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 250px;
`;

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const InvoicesTable = styled(Table)`
  margin: 52px auto 32px auto;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  padding: 12px 20px 12px 20px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 8px 12px 8px 12px;
  }
`

const TableRow = styled.tr`
  ${props => props.header && `background-color: ${colors.WHITE};`}
  ${props => !props.header && props.id % 2 === 0 && `background-color: ${colors.WHITE};`}
`

const TableText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin: auto;
  ${props => props.header && "font-weight: bold;"}
  ${props => props.status_id === 0 && `color: ${colors.LCG_DARK_PURPLE_FADE};`}
  ${props => props.status_id === 1 && `color: ${colors.LCG_LIGHT_PURPLE};`}
  ${props => props.status_id === 2 && `color: ${colors.LCG_DARK_PURPLE};`}
  ${props => props.error && `color: ${colors.LCG_DARK_PURPLE};`}
`

const TableCell = styled.td`
  ${props => `padding: 20px ${props.pr ? "32px": "20px"} 20px ${props.pl ? "32px": "20px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  text-align: center;
  }
`

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;
