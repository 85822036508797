/* Homepage > Top section */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';

import layout from '../../config/layout';
import { getParameterByName } from '../../utils/helper';
import colors from '../../config/colors';
import { getNullifiedAmplitudeUserProperties } from '../../utils/getNullifiedAmplitudeUserProperties';

import { H1, P, PVSmall } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
// import Quote from '../../components/Quote';
// import MaskedImage from '../../components/MaskedImage';
import Modal from '../../components/Modal';
import screenshotOverview from '../../assets/screenshot_quiz_cropped_purple.svg';

// let quoteInterval;

const SectionTop = ({ handleQuizStart, handleStartAgain, handleResume, price_headline, price_disclaimer, userHasAccess, freeAccessText, educationInstitution, completions, organisationName, organisationLogo }) => {

  const [openStartAgain, setOpenStartAgain] = useState(false);

  // const showTestimonialImage1 = (getRandomInt(2) === 0);  // 50/50 true or false

  // let images = ['police', 'doctor', 'artist', 'office', 'scientist', 'chef'];

  // const [firstImage, setFirstImage] = useState(getRandomInt(images.length));
  // const [secondImage, setSecondImage] = useState((firstImage === (images.length - 1)) ? 0 : firstImage + 1);

  // const manURL = process.env.PUBLIC_URL + '/images/home/man-'+(getRandomInt(3) + 1)+ '-' + images[firstImage] + '.jpg';
  // const womanURL = process.env.PUBLIC_URL + '/images/home/woman-'+(getRandomInt(3) + 1)+ '-' + images[secondImage] + '.jpg';

  // var leftImageUrl = manURL;
  // var rightImageUrl = womanURL;

  // if (getRandomInt(2) === 0) { // 0 or 1
  //   leftImageUrl = womanURL;
  //   rightImageUrl = manURL;
  // }

  // const quotes = [
  //   {quote: 'Fab, really helpful', author: 'Rachel B, Surrey'},
  //   {quote: 'Opened my eyes to help available', author: 'Rob H, Great Houghton'},
  //   {quote: 'This helped a lot!!! It has now become clear to me what sort of jobs I\'m interested in and what jobs I should be applying for. Thanks!', author: 'Anon, Pontypridd'},
  //   {quote: 'Amazing experience', author: 'Anon, Newcastle'},
  //   {quote: 'It was informative and awesome, thank you!', author: 'Kyle D'},
  //   {quote: 'I\'m thinking of moving on from my current job but I was having trouble considering what else I could do. This has given me more ideas of jobs to look into. Thanks.', author: 'Anon'},
  //   {quote: 'Information given has been excellent', author: 'Anne N'},
  //   {quote: 'This is amazing. Really inspiring ideas', author: 'Imogen B'},
  //   {quote: 'These choices gave me an insight to potential future careers - which I was stuck on', author: 'Anon'},
  //   {quote: 'It was a great help. Very useful survey. Thanks', author: 'Anon'},
  //   {quote: 'Really helpful, thanks. Something like this 12 years ago would have changed my life', author: 'MH, London'},
  //   {quote: 'Wow', author: 'Anon, Spalding'},
  //   {quote: 'Very impressed', author: 'Anon, Rugeley'},
  //   {quote: 'Really good options and made me really think about what I would rather be doing than working from home all the time!', author: 'Adam, Exeter'},
  //   {quote: 'Interesting choices that I felt accurately reflected my personality. The test gave me lots of valid and reasonable options to match my traits', author: 'Eva, Glasgow'},
  // ];

  // const [quoteNumber, setQuoteNumber] = useState(getRandomInt(quotes.length));

  // useEffect(() => {
  //   quoteInterval = setInterval(() => {
  //     setFirstImage(prevFirstImage => prevFirstImage !== (images.length - 1) ? prevFirstImage + 1 : 0);
  //     setSecondImage(prevSecondImage => prevSecondImage !== (images.length - 1) ? prevSecondImage + 1 : 0);
  //     setQuoteNumber(preQuoteNumber => preQuoteNumber !== (quotes.length - 1) ? preQuoteNumber + 1 : 0);
  //   }, 5000);
  //   return () => {
  //     clearInterval(quoteInterval)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const adId = getParameterByName('utm_content');
  const source = getParameterByName('utm_source');
  const medium = getParameterByName('utm_medium');

  let userID = localStorage.getItem("user_id");
  if (userID === "null") {
    userID = null;
  }

  let haveResults = (localStorage.getItem("results") === true || localStorage.getItem("results") === "true");
  let areResultsLocked = (localStorage.getItem("locked") === true || localStorage.getItem("locked") === "true");
  let emailsSent = (localStorage.getItem("emails_sent") === true || localStorage.getItem("emails_sent") === "true");

  const fullAccessText = "You have been gifted with full access 🎁";

  let numCompletionsRoundedDown = null;
  if (completions) {
    numCompletionsRoundedDown = Math.floor(completions/1000)*1000;
  }

  let numPeopleText = "thousands of people";
  if (numCompletionsRoundedDown) {
    numPeopleText = "the <b>" + numCompletionsRoundedDown.toLocaleString() + "</b> people";
  }

  let topText = "Join " + numPeopleText + " we've helped get into their dream career! For career changes big and small, get the insights and facts you need to find a way forward.";
  if (educationInstitution) {
    topText = "Join " + numPeopleText + " we've helped discover their dream career! Get the insights and facts you need to plan your future after " + educationInstitution.name + ".";
  }
  const getStartedButton = 
    <ButtonWrap>
      <Amplitude userProperties={getNullifiedAmplitudeUserProperties(adId, source, medium)}>
        {({ logEvent }) =>
          <>
            {educationInstitution && !educationInstitution.can_access ? (
              <ButtonContainer>
                <Tooltip text={"Contact " + educationInstitution.name + " to access"}>
                  <GrayButton disabled>Get started</GrayButton>
                </Tooltip>
              </ButtonContainer>
            ) : (
              <>
                {!userID && (
                  <ButtonContainer>
                    <PrimaryButton id="start_button" onClick={() => {handleQuizStart(adId, source, medium); logEvent('Clicked Get Started');}}>Get started</PrimaryButton>
                  </ButtonContainer>
                )}
                {userID && !haveResults && (
                  <>
                    <ButtonContainer>
                      <PrimaryButton onClick={() => {handleResume(userID, null); logEvent('Clicked Resume on Homepage');}}>Resume</PrimaryButton>
                    </ButtonContainer>
                    <ButtonContainer>
                      <SecondaryButton id="start_button" onClick={() => {setOpenStartAgain(true); logEvent('Clicked Start Again');}}>Start again</SecondaryButton>
                    </ButtonContainer>
                  </>
                )}
                {userID && haveResults && (
                  <>
                    <ButtonContainer>
                      <PrimaryButton onClick={() => {handleResume(userID, "results"); logEvent('Clicked View Results on Homepage');}}>View your results</PrimaryButton>
                    </ButtonContainer>
                    <ButtonContainer>
                      <SecondaryButton id="start_button" onClick={() => {setOpenStartAgain(true); logEvent('Clicked Start Again');}}>Start again</SecondaryButton>
                    </ButtonContainer>
                  </>
                )}
                <Modal
                  open={openStartAgain}
                  handleClose={() => setOpenStartAgain(false)}
                  title="Are you sure?"
                  buttonPrimaryLabel="Yes"
                  buttonSecondaryLabel="No"
                  handlePrimaryButtonClick={() => {
                    handleStartAgain(adId, source, medium);
                    logEvent('Start Again');
                  }}
                >
                  {userID && !haveResults && (
                    <P>Are you sure you want to start again? You'll lose all of your progress so far.</P>
                  )}
                  {userID && haveResults && !emailsSent && (
                    <P>Are you sure you want to start again? You'll lose access to your results.</P>
                  )}
                  {userID && haveResults && emailsSent && (
                    <P>Are you sure you want to start again? You'll only be able to access your results from the link we emailed you.</P>
                  )}
                </Modal>
              </>
            )}
          </>
        }
      </Amplitude>
      {/* {(!haveResults || areResultsLocked) && price_headline && !userHasAccess && !freeAccessText && !educationInstitution && (
        <TopTextGray align="center">{price_headline}</TopTextGray>
      )}
      {(!haveResults || areResultsLocked) && userHasAccess && !organisationName && (
        <TopTextGray align="center">{fullAccessText}</TopTextGray>
      )}
      {freeAccessText && !organisationName && (
        <TopTextGray align="center">{freeAccessText}</TopTextGray>
      )} */}
      {((userHasAccess && organisationName) || educationInstitution) && (
        <TopTextGray align="center" bold>Full access provided by {educationInstitution ? educationInstitution.name : organisationName}</TopTextGray>
      )}
      {!userID && (!haveResults || areResultsLocked) && ((userHasAccess && organisationName) || educationInstitution) && (
        (educationInstitution && !educationInstitution.can_access ? (
          <TopTextGray align="center">You have used your licences. Please contact your LCG Regional Account Director to buy more licences and continue using CareersPro at {educationInstitution ? educationInstitution.name : organisationName}.</TopTextGray>
        ) : (educationInstitution && !educationInstitution.can_access ? (
          <TopTextGray align="center">To use CareersPro at {educationInstitution ? educationInstitution.name : organisationName}, please contact careers-pro@learningcurvegroup.co.uk.</TopTextGray>
        ) : (
          <TopTextGray align="center">By tapping “Get started” you agree to our <DisclaimerLink to="/terms" target="_blank" rel="noopener noreferrer">Terms</DisclaimerLink> and <DisclaimerLink to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</DisclaimerLink> and you consent to your data being shared with {educationInstitution ? educationInstitution.name : organisationName} for the purpose of supporting your career exploration</TopTextGray>
        )))
      )}
      {userID && ((userHasAccess && organisationName) || educationInstitution) && (
        <TopTextGray align="center">By tapping “Start again” you agree to our <DisclaimerLink to="/terms" target="_blank" rel="noopener noreferrer">Terms</DisclaimerLink> and <DisclaimerLink to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</DisclaimerLink> and you consent to your data being shared with {educationInstitution ? educationInstitution.name : organisationName} for the purpose of supporting your career exploration</TopTextGray>
      )}
      {/* {(!haveResults || areResultsLocked) && price_disclaimer && !userHasAccess && !freeAccessText && !educationInstitution && (
        <TopTextGray align="center">{price_disclaimer}</TopTextGray>
      )} */}
    </ButtonWrap>

  return (
    <Section>
      <Grid>
        <Row>
          <Amplitude>
            <LogOnMount eventType="Visited HomeScreen">
              <Col xs={12} md={10} mdOffset={1}>
                <Title align="center">The AI-powered<br />career planning platform</Title>
              </Col>
            </LogOnMount>
          </Amplitude>
          <ColVerticallyCentered xs={6} md={6}>
            <TopText align="center">{ReactHtmlParser(topText)}</TopText>
            <GetStartedDesktop>
              {getStartedButton}
            </GetStartedDesktop>
          </ColVerticallyCentered>
          <Col xs={6} md={6}>
            <ScreenshotOverview src={screenshotOverview} alt="Screenshot - Quiz" />
          </Col>
          <GetStartedMobile>
            <Col xs={12} md={6} mdOffset={3}>
              {getStartedButton}
            </Col>
          </GetStartedMobile>
        </Row>
      </Grid>
      {(organisationLogo || (educationInstitution && educationInstitution.logo)) && (
        <OrganisationLogo src={(educationInstitution && educationInstitution.logo) ? educationInstitution.logo : organisationLogo} alt={(educationInstitution ? educationInstitution.name : organisationName) + " logo"}></OrganisationLogo>
      )}
      {/* <ImageLeft>
        <MaskedImage imgUrl={leftImageUrl} alt="" />
      </ImageLeft>
      <ImageRight>
        <MaskedImage imgUrl={rightImageUrl} left={false} alt="" />
      </ImageRight> */}
    </Section>
  );
};

export default SectionTop;

SectionTop.propTypes = {
  handleQuizStart: PropTypes.func.isRequired,
};

const Section = styled.div`
  margin-bottom: 32px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 0;
  }
`;

const GetStartedDesktop = styled.div`
  width: 350px;
  margin: 0 0 0 auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    display: none;
  }
`;

const GetStartedMobile = styled.div`
  display: none;

  @media (max-width: ${layout.breakpoints.MD}) {
    display: block;
    margin: 10px auto 0 auto;
    width: 100%
  }
`;

const ButtonWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  text-align: center;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin-top: 20px;
  }
`;

// const RowCenter = styled(Row)`
//   justify-content: center;
// `;

// const ImageLeft = styled.div`
//   bottom: 136px;
//   left: 50%;
//   margin-left: -932px;
//   position: absolute;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     bottom: 0;
//     display: inline-box;
//     margin-left: 0;
//     position: relative;
//     transform: translateX(-50%);
//     display: none;
//   }
// `;

// const ImageRight = styled.div`
//   bottom: 136px;
//   left: 50%;
//   margin-left: 220px;
//   position: absolute;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     display: none;
//   }
// `;

const Title = styled(H1)`
  margin: 32px 0 52px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    margin: 4px 0 20px 0;
  }
`;

// const Subtitle = styled(H4)`
//   font-weight: 400;
//   text-align: center;
//   margin-bottom: 20px;
//   padding-left: 10px;
//   padding-right: 10px;

//   @media (max-width: ${layout.breakpoints.MD}) {
//     font-size: 20px;
//     line-height: 32px;
//     max-width: 420px;
//     margin: auto;
//     position: relative;
//   }
// `;

const ColVerticallyCentered = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TopText = styled(P)`
  color: ${colors.TEXT_DARK};
  max-width: 350px;
  margin-left: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    max-width: 250px;
    position: relative;
    padding-top: 16px;
  }
`;

const TopTextGray = styled(PVSmall)`
  color: ${colors.TEXT_DARK};
  margin-bottom: 4px;
  ${props => props.bold && "font-weight: bold;"}

  @media (max-width: ${layout.breakpoints.MD}) {
    max-width: 380px;
    margin: auto;
    position: relative;
    padding: 0 20px 0 20px;
  }
`;

const ScreenshotOverview = styled.img`
  max-width: 350px;
  display: block;
  width: 100%;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 250px;
  }
`;

const ButtonContainer = styled.div`
  margin: 10px auto 20px auto;
  display: block
`

const DisclaimerLink = styled(Link)`
  color: ${colors.TEXT_DARK};
  cursor: pointer;
  text-decoration: underline;

  &:not([href]):not([tabindex]) {
    color: ${colors.TEXT_DARK};
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`

const OrganisationLogo = styled.img`
  max-width: 400px;
  max-height: 250px;
  margin: 32px auto 12px auto;
  display: block;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 350px;
    max-height: 220px;
    margin: 20px auto 0px auto;
  }

  @media(max-width: ${layout.breakpoints.SM}) {
    max-width: 300px;
    max-height: 200px;
    margin: 20px auto 0px auto;
  }

  @media(max-width: ${layout.breakpoints.VSM}) {
    max-width: 250px;
    max-height: 150px;
    margin: 20px auto 0px auto;
  }
`;
