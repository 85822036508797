import React, { useState } from 'react';
import colors from '../config/colors';
import styled from 'styled-components';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Header from './Header';
import { H1, H2, H3, H4, H5, P, PSmall, Ul } from '../ui/typography/Typography';
import { PrimaryButton, SecondaryButton, GrayButton, LCGPurpleButton } from '../ui/buttons/Buttons';
import { FooterLink } from '../ui/buttons/Link';
import Quote from './Quote';
import ProgressBar from './ProgressBar';
import Multiselect from './multiselect/Multiselect';
import SelectBar from './SelectBar';
import Select from '../ui/form/Select';
import Input from '../ui/form/Input';
import Checkbox from '../ui/form/Checkbox';
import AutocompleteField from '../ui/form/AutocompleteField';
import QuestionnaireCard from './QuestionnaireCard';
import ResultsCard from './archive/ResultsCard';
import ResultsFeedback from './ResultsFeedback';
import BgThreeDots from '../ui/bg/BgThreeDots';
import BgTwoDotsLCGPurpleLine from '../ui/bg/BgTwoDotsLCGPurpleLine';
import BgBlueLineGreenLine from '../ui/bg/BgColoredLines';
import BgTwoDotsTwoShapes from '../ui/bg/BgTwoDotsTwoShapes';
import { ConfettiBg, ColorBg } from '../ui/bg/BgStyles';
import Loader from './Loader';
import Modal from './Modal';
import TeamMember from './TeamMember';
import MaskedImage from './MaskedImage';
import CheckboxCard from './CheckboxCard';
import Tooltip from './Tooltip';
import ResultsModal from './resultsmodal/ResultsModal';

const Styleguide = () => {
  const [open, setOpen] = useState(false);
  const [openLarge, setOpenLarge] = useState(false);
  const [openResultsModal, setOpenResultsModal] = useState(false);

  return (
    <>
      <Header />
      <Header logo_alignment="center" />
      <Grid>
        <RowStyled>
          <Col xs={12}>
            <H1>This is Heading 1</H1>
            <H1 align="center" color={colors.TEXT_GRAY} desktop_styles_only>This is Heading 1 centered and in a different color and no mobile styling</H1>
            <H2>This is Heading 2</H2>
            <H3>This is Heading 3</H3>
            <H4>This is Heading 4</H4>
            <H5>This is Heading 5</H5>
            <P>This is a Paragraph</P>
            <P bold color={colors.LCG_DARK_PURPLE}>This is a Paragraph - bold and in a different color</P>
            <PSmall>This is a small Paragraph</PSmall>
          </Col>
        </RowStyled>
        <RowStyled>
          <Col md={3}>
            <PrimaryButton>Get started</PrimaryButton>
          </Col>
          <Col md={3}>
            <SecondaryButton>Get started</SecondaryButton>
          </Col>
          <Col md={3}>
            <GrayButton>Get started</GrayButton>
          </Col>
          <Col md={3}>
            <LCGPurpleButton>Get started</LCGPurpleButton>
          </Col>
        </RowStyled>
        <RowStyled>
          <Col md={3}>
            <PrimaryButton disabled>Primary button</PrimaryButton>
          </Col>
          <Col md={3}>
            <SecondaryButton disabled>Secondary button</SecondaryButton>
          </Col>
          <Col md={3}>
            <Tooltip text="this is a tooltip">
              <SecondaryButton disabled>Button with tooltip</SecondaryButton>
            </Tooltip>
          </Col>
          <Col md={3}>
            <Tooltip text="this is a tooltip">
              <P>text with tooltip</P>
            </Tooltip>
          </Col>
        </RowStyled>
        <RowCentered>
          <Col>
            <Quote
              text="This is amazing. Really inspiring ideas!"
              stars={5}
            />
          </Col>
        </RowCentered>
        <RowCentered>
          <Col xs={12} sm={10}>
            <ProgressBar max={100} current={28} />
          </Col>
        </RowCentered>
        <RowCentered>
          <Col xs={12} sm={6}>
            <Multiselect
              values={[
                {friendly_name: 'Physical', id: 'physical', selected: false},
                {friendly_name: 'Leading', id: 'leading', selected: false},
                {friendly_name: 'Making things', id: 'making_things', selected: false},
                {friendly_name: 'Researching', id: 'researching', selected: false},
              ]}
              selectedValues={[]}
              handleChange={(item) => {console.log(item)}}
            />
          </Col>
        </RowCentered>
        <RowCentered>
          <Col xs={12}>
            <SelectBar
              values={[
                {label: 'Highschool', id: 'highschool'},
                {label: 'University', id: 'university'},
                {label: 'Postgraduate', id: 'postgraduate'},
              ]}
              handleChange={(item) => {console.log(item)}}
            />
          </Col>
        </RowCentered>
        <RowStyled>
          <Col xs={12} md={3}>
            <Select
              options={[
                {label: 'Highschool', id: 'highschool'},
                {label: 'University', id: 'university'},
                {label: 'Postgraduate', id: 'postgraduate'},
              ]}
              handleChange={(item) => {console.log(item)}}
            />
          </Col>
          <Col xs={12} md={3}>
            <Select
              options={[
                {label: 'Highschool', id: 'highschool'},
                {label: 'University', id: 'university'},
                {label: 'Postgraduate', id: 'postgraduate'},
              ]}
              selectedOption={{label: 'Postgraduate', id: 'postgraduate'}}
              handleChange={(item) => {console.log(item)}}
              error="Error message here"
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={3}>
            <Input
              handleChange={(item) => {console.log(item)}}
              placeholder="placeholder text"
              label="University"
              hint="hint text"
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={3}>
            <Input
              inputValue="default value set"
              handleChange={(item) => {console.log(item)}}
              error="Error message here"
            />
          </Col>
          <Col xs={12} md={3}>
            <Input
              handleChange={(item) => {console.log(item)}}
              type="number"
              placeholder="number type"
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={5}>
            <AutocompleteField
              handleChange={(item) => {console.log(item)}}
              placeholder="start typing..."
              label="Autocomplete field"
              hint="This will do a search for matches from this list - ['Designer', 'Developer', 'Manager', 'Investor', 'Teacher', 'Writer', 'Accountant', 'Architect']"
              suggestions={{"strings":[{"name":"Accountant","id":6,"key":0},{"name":"Architect","id":7,"key":1},{"name":"Designer","id":0,"key":2},{"name":"Developer","id":1,"key":3},{"name":"Investor","id":3,"key":4},{"name":"Manager","id":2,"key":5},{"name":"Teacher","id":4,"key":6},{"name":"Writer","id":5,"key":7}],"ids":{"0":[],"1":[],"2":[],"3":[],"4":[],"5":[],"6":[],"7":[],"8":[],"9":[],"a":[6,7],"b":[],"c":[],"d":[0,1],"e":[],"f":[],"g":[],"h":[],"i":[3],"j":[],"k":[],"l":[],"m":[2],"n":[],"o":[],"p":[],"q":[],"r":[],"s":[],"t":[4],"u":[],"v":[],"w":[5],"x":[],"y":[],"z":[]}}}
              onBlur={() => (console.log("onBlur"))}
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={4}>
            <Checkbox
              name="tc"
              label="Check this box if you’re happy for us to stay in touch, so we can better help you discover and get into your dream career"
              handleChange={item => console.log(item)}
            />
          </Col>
          <Col xs={12} md={4}>
            <Checkbox
              error="This field is required"
              name="tc2"
              label="Check this box if you’re happy for us to stay in touch, so we can better help you discover and get into your dream career"
              handleChange={item => console.log(item)}
            />
          </Col>
          <Col xs={12} md={4}>
            <Checkbox
              defaultChecked={true}
              name="tc"
              label="Check this box if you’re happy for us to stay in touch, so we can better help you discover and get into your dream career"
              handleChange={item => console.log(item)}
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={6}>
            <CheckboxCard
              title="Financial Risk Analyst"
              description="Financial risk analysts identify and analyse the areas of potential risk threatening the assets, earning capacity or success of organisations."
              tags={['office-work', 'physical', 'imaginitve']}
              handleClick={item => console.log(item)}
              value="financial_risk_analyst"
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={6}>
            <QuestionnaireCard
              title="Financial Risk Analyst"
              description="Financial risk analysts identify and analyse the areas of potential risk threatening the assets, earning capacity or success of organisations."
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={7}>
            <ResultsCard
              imageUrl="https://via.placeholder.com/654x236"
              title="Financial Risk Analyst"
              subtitle="£10,000 - £999,9999"
              description="Financial risk analysts identify and analyse the areas of potential risk threatening the assets, earning capacity or success of organisations."
              ctaLabel="See opportunities"
              handleCtaClick={() => setOpenResultsModal(true)}
              tags={['office-work', 'physical', 'imaginitve']}
              labelOpen="Learn more"
              labelClosed="Collapse"
            >
              <P nomargin>• Developing micro-organisms and plants to clean polluted land or water</P>
              <P nomargin>• Developing micro-organisms and plants to clean polluted land or water</P>
              <P nomargin>• Developing micro-organisms and plants to clean polluted land or water</P>
              <Ul>
                <li>This is a Paragraph</li>
                <li>This is a Paragraph</li>
                <li>This is a Paragraph</li>
              </Ul>
            </ResultsCard>
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={7}>
            <ResultsCard
              title="Financial Risk Analyst"
              subtitle="£10,000 - £999,9999"
              description="Financial risk analysts identify and analyse the areas of potential risk threatening the assets, earning capacity or success of organisations."
              ctaLabel="See opportunities"
              handleCtaClick={() => setOpenResultsModal(true)}
              tags={['office-work', 'physical', 'imaginitve']}
              labelOpen="Learn more"
              labelClosed="Collapse"
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12} md={7}>
            <ResultsFeedback
              title="How useful are these results to you?"
              textAreaTitle="How could these results be improved?"
              placeholder="Any feedback you may provide is appreciated..."
              buttonLabel="Submit feedback"
              handleSubmit={result => console.log(result)}
            />
          </Col>
        </RowStyled>
        <RowStyled>
          <Col xs={12}>
            <Loader>
              <P>Thanks - that’s all the initial questions! </P>
              <P>We’re now preparing your career quiz questions...</P>
            </Loader>
          </Col>
        </RowStyled>
        <RowCentered>
          <Col>
            <div onClick={() => setOpen(true)}>
              <PrimaryButton>Open modal</PrimaryButton>
            </div>
          </Col>
          <Col>
            <div onClick={() => setOpenLarge(true)}>
              <PrimaryButton>Open large modal</PrimaryButton>
            </div>
          </Col>
        </RowCentered>
        <RowStyled>
          <Col xs={12} md={6}>
            <TeamMember
              imageUrl="https://via.placeholder.com/352"
              title="Phil Hewinson"
              description="Phil founded Would You Rather Be in the summer of 2019.  He spent 12 years of his career working in product, technical and leadership roles at Monzo, Facebook, Google and Microsoft.  He is passionate about using software to have a social impact, and shares his journey on his blog."
              color={colors.LCG_DARK_PURPLE}
              left
            />
          </Col>

          <Col xs={12} md={6}>
            <TeamMember
              imageUrl="https://via.placeholder.com/352"
              title="Phil Hewinson"
              description="Phil founded Would You Rather Be in the summer of 2019.  He spent 12 years of his career working in product, technical and leadership roles at Monzo, Facebook, Google and Microsoft.  He is passionate about using software to have a social impact, and shares his journey on his blog."
              color={colors.LCG_DARK_PURPLE}
            />
          </Col>
        </RowStyled>
         <RowCentered>
          <Col xs={12}>
            <MaskedImage imgUrl="https://via.placeholder.com/1000" alt="" />
          </Col>
        </RowCentered>
        <RowCentered>
         <Col xs={12}>
           <MaskedImage imgUrl="https://via.placeholder.com/1000" left={false} alt="" />
         </Col>
       </RowCentered>
       <RowCentered>
          <Col xs={12}>
            <FooterLink>Privacy Policy</FooterLink>
            <FooterLink>Cookie Policy</FooterLink>
          </Col>
      </RowCentered>
      </Grid>

      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        title="This is the title of the modal window"
        buttonPrimaryLabel="Accept"
        buttonSecondaryLabel="Cancel"
        handlePrimaryButtonClick={() => console.log('Primary button click')}
        handleSecondaryButtonClick={() => console.log('Secondary button click')}
      >
        <P>Are you sure you want to restart? You'll lose all of your progress so far.</P>
      </Modal>
      <Modal
        open={openLarge}
        handleClose={() => setOpenLarge(false)}
        buttonPrimaryLabel="Close"
        handlePrimaryButtonClick={() => console.log('Primary button click')}
        large
      >
        <Iframe className="privacy_policy_iframe" src="https://www.careerspro.co.uk/privacy.html" />
      </Modal>
      <ResultsModal
        open={openResultsModal}
        setOpen={setOpenResultsModal}
        title="Want to become a Project Manager?"
        handleShowMore={() => console.log('show more')}
        showMoreButton
        results={[
          {
            title: "Principle of Business Administration",
            duration: "6 weeks",
            description: "The level 2 Certificate in Principles of business administration gives you the knowledge to work in a range of different environments in a business admin role.",
            url: "http://google.com"
          },
          {
            title: "Business Improvement Techniques",
            duration: "6 weeks",
            description: "The level 2 Certificate in Principles of business administration gives you the knowledge to work in a range of different environments in a business admin role.",
            url: "http://google.com"
          }
        ]}
      >
        <P align="center">Complete these free part-time courses to build some of the skills you’ll need as a Project Manager.</P>
        <P align="center">Each course is fully-funded and will give you a nationally recognised qualification, which is equivalent to a GCSE A*-C.</P>
      </ResultsModal>
      <Section><BgThreeDots /></Section>
      <Section><BgThreeDots LCG_DARK_PURPLECircleUp /></Section>
      <Section><BgTwoDotsLCGPurpleLine /></Section>
      <Section><BgBlueLineGreenLine /></Section>
      <Section><BgTwoDotsTwoShapes /></Section>
      <SectionWithConfetti />
      <SectionWithColorBg />
    </>
  );
};

export default Styleguide;

const RowStyled = styled(Row)`
  margin-bottom: 40px;
`;

const RowCentered = styled(Row)`
  margin-bottom: 40px;
  justify-content: center;
`;

const Section = styled.div`
  position: relative;
  border-top: 1px solid ${colors.GRAY};
  border-bottom: 1px solid ${colors.GRAY};
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const SectionWithConfetti = styled(Section)`
  ${ConfettiBg};
`;

const SectionWithColorBg = styled(Section)`
  ${ColorBg};
`;

const Iframe = styled.iframe`
  height: 60vh;
  border: 0;
`;
