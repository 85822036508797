import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../config/colors';
import layout from '../config/layout';

import { H3, PSmall } from '../ui/typography/Typography';
import { Tag } from '../ui/tag/Tag';
import { PrimaryButton } from '../ui/buttons/Buttons';

import Confetti from '../assets/confetti-mobile.svg';
import IconTrash from '../assets/icon-trash.svg';

const ResultsCardSimple = ({
  title,
  subtitle,
  tags,
  ctaLabel,
  ctaImage,
  ctaUrl,
  children,
  handleCtaClick,
  topLabel,
  extraNotes,
  textUnderButton,
  skillsMatch,
  showConfetti,
  blur,
  trash,
  handleTrashClick
}) => {
  return (
    <Container showConfetti={showConfetti} blur={blur}>
      {topLabel && (<TopLabel>{topLabel}</TopLabel>)}
      {title && (
        <>
          <Title align="center">{title}</Title>
          {trash && (<TrashImage src={IconTrash} alt="" onClick={handleTrashClick} />)}
        </>
      )}
      {subtitle && <Subtitle align="center" nomargin={extraNotes || skillsMatch}>{subtitle}</Subtitle>}
      {extraNotes && <Subtitle align="center" nomargin={skillsMatch}>{extraNotes}</Subtitle>}
      {skillsMatch && <SkillsMatch>{skillsMatch}</SkillsMatch>}
      {tags && (
        <TagContainer>
          {tags.map(item => (
            <Tag key={item}>#{item}</Tag>
          ))}
        </TagContainer>
      )}
      {children && children}
      {ctaLabel && (
        <TextCenter>
          {ctaUrl ? (
            <a href={ctaUrl}>
              <PrimaryButton disabled={blur}>{ctaLabel}{ctaImage && (<Image src={ctaImage} alt="" />)}</PrimaryButton>
            </a>
          ) : (
            <PrimaryButton onClick={handleCtaClick} disabled={blur}>{ctaLabel}{ctaImage && (<Image src={ctaImage} alt="" />)}</PrimaryButton>
          )}
        </TextCenter>
      )}
      {textUnderButton && (
        <TextUnderButton align="center">{textUnderButton}</TextUnderButton>
      )}
    </Container>
  );
};

export default ResultsCardSimple;

ResultsCardSimple.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tags: PropTypes.array,
  ctaLabel: PropTypes.string,
  ctaImage: PropTypes.any,
  ctaUrl: PropTypes.string,
  children: PropTypes.node,
  handleCtaClick: PropTypes.func,
  topLabel: PropTypes.any,
  extraNotes: PropTypes.any,
  textUnderButton: PropTypes.string,
  skillsMatch: PropTypes.string,
  showConfetti: PropTypes.bool,
};


const Container = styled.div`
  background-image: ${props => props.showConfetti  && `url(${Confetti})`};
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  margin-bottom: 32px;
  background-color: ${colors.WHITE};
  padding: 32px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 24px 16px;
  }

  ${props => props.blur  && `filter: blur(7px) brightness(1);`};
`;

const Title = styled(H3)`
  margin-bottom: 4px;
  padding: 0 50px 0 50px;
  width: 100%;
`;

const TrashImage = styled.img`
  position: absolute;
  padding: 16px;
  top: 8px;
  right: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    top: 4px;
    right: 4px;
  }

  @media (max-width: ${layout.breakpoints.SM}) {
    top: 10px;
    right: 0px;
  }
`;

const TextCenter = styled.div`
  text-align: center;
`;

const TagContainer = styled(TextCenter)`
  margin-bottom: 0px;
`;

const TopLabel = styled.div`
  font-size: 12px;
  line-height: 18px;
  background-color: ${colors.LCG_DARK_GREY};
  color: ${colors.WHITE};
  position: absolute;
  padding: 1px 4px;
  border-radius: 4px;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  width: fit-content;
  text-transform: uppercase;
  text-align: center;
`;

const Subtitle = styled(PSmall)`
  margin-bottom: ${props => props.nomargin ? 0 : '16px' };
`;

const SkillsMatch = styled.p`
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  color: ${colors.TEXT_DARK};
  text-align: center;
  margin-bottom: 4px;
`;

const TextUnderButton = styled(PSmall)`
  margin: 20px 12px 0px 12px;
`

const Image = styled.img`
  max-width: 18px;
  margin-left: 8px;
  top: 1px;
  position: relative;
`
