import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactFacebookPixel from 'react-facebook-pixel';
// import ReactTiktokPixel from 'react-tiktok-pixel';
import TagManager from 'react-gtm-module'
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from '@amplitude/react-amplitude';
import Cookies from "js-cookie";
import OrganisationScreen from './screens/OrganisationScreen/OrganisationScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import TwoFactorScreen from './screens/LoginScreen/TwoFactorScreen';
import ContactSalesScreen from './screens/ContactSalesScreen/ContactSalesScreen';
import SignupScreen from './screens/SignupScreen/SignupScreen';
import CreateAccountScreen from './screens/CreateAccountScreen/CreateAccountScreen';
import DashboardScreen from './screens/DashboardScreen/DashboardScreen';
import TeamScreen from './screens/TeamScreen/TeamScreen';
import AddTeamMemberScreen from './screens/AddTeamMemberScreen/AddTeamMemberScreen';
import CourseLinksScreen from './screens/CourseLinksScreen/CourseLinksScreen';
import PurchaseLicenceScreen from './screens/PurchaseLicencesScreen/PurchaseLicencesScreen';
import AddUsersScreen from './screens/AddUsersScreen/AddUsersScreen';
import InvoicesScreen from './screens/InvoicesScreen/InvoicesScreen';
import PasswordResetScreen from './screens/PasswordResetScreen/PasswordResetScreen';
import PrivacyScreen from './screens/PrivacyScreen/PrivacyScreen';
import FinalResults from './screens/FinalResults/FinalResults';
import CareerScreen from './screens/CareerScreen/CareerScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import TermsScreen from './screens/TermsScreen/TermsScreen';
import Styleguide from './components/Styleguide';
import DegreeScreen from './screens/DegreeScreen/DegreeScreen';
import NoDegreeScreen from './screens/DegreeScreen/NoDegreeScreen';
import Pair from './screens/Pair/Pair';
import TopQualities from './screens/TopQualities/TopQualities';
import TopCareers from './screens/TopCareers/TopCareers';
import LocationScreen from './screens/LocationScreen/LocationScreen';
import UserSignupScreen from './screens/UserSignupScreen/UserSignupScreen';
import ContactDetailsScreen from './screens/ContactDetailsScreen/ContactDetailsScreen';
import SituationScreen from './screens/SituationScreen/SituationScreen';
import EducationScreen from './screens/EducationScreen/EducationScreen';
import ExperienceScreen from './screens/ExperienceScreen/ExperienceScreen';
import SecondarySchoolScreen from './screens/SecondarySchoolScreen/SecondarySchoolScreen';
import CollegeScreen from './screens/CollegeScreen/CollegeScreen';
import UniversityScreen from './screens/UniversityScreen/UniversityScreen';
import OtherCourseScreen from './screens/OtherCourseScreen/OtherCourseScreen';
import TakeQuizScreen from './screens/TakeQuizScreen/TakeQuizScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import BrowseCareers from './screens/FinalResults/BrowseCareers';
import APIScreen from './screens/APIScreen';
import GenerateAccessLink from './screens/GenerateAccessLink';
import CreateSignupLinkEducationInstitution from './screens/CreateSignupLink/CreateSignupLinkEducationInstitution';
import { useStateValue } from "./services/state";
import usePageViews from './pageViews';
import { facebookPixelInit } from './facebookPixelInit';

const AMPLITUDE_KEY = process.env.NODE_ENV === "production" ? process.env.REACT_APP_AMPLITUDE_KEY : process.env.REACT_APP_AMPLITUDE_KEY_DEV;

const App = () => {
  const [pixelActive, setPixelActive] = useState(false);
  const [{ window_top, cookies, user_id }, dispatch] = useStateValue();

  // Google
  const tagManagerArgs = {
    gtmId: 'GTM-NCPCG6P'
  };

    // Init page view hook
    usePageViews();

  if ( (cookies === 'on' || Cookies.get('cookies') === 'on' ||
        localStorage.getItem("cookies") === true || localStorage.getItem("cookies") === "true" || true) && !pixelActive ) {
    setPixelActive(true);

    // Google Tag Manager
    TagManager.initialize(tagManagerArgs);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'pageView'
    });

    // Tiktok
    // ReactTiktokPixel.track('PageView');
  }

  useEffect(() => {
    // Reload page every 6 hours
    const id = setTimeout(() => {
      console.log("Reloading page after 6 hours");
      window.location.reload(true);
    }, 1000 * 60 * 60 * 6); // 6 hours in milliseconds
    return () => clearTimeout(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window_top || window_top === 0) {
      window.scrollTo({top: window_top});
      dispatch({type: 'update', values: {window_top: null}});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  useEffect(() => {
    if ( user_id && (cookies === 'on' || Cookies.get('cookies') === 'on' ||
         localStorage.getItem("cookies") === true || localStorage.getItem("cookies") === "true" || true) ) {
      // Facebook
      facebookPixelInit(user_id);
      ReactFacebookPixel.pageView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const content = (
    <Switch>
      <Route exact path="/career/:careerUrl/pathways/:pathwayId" component={CareerScreen} />
      <Route exact path="/career/:careerUrl" component={CareerScreen} />
      <Route exact path="/organisations" component={OrganisationScreen} />
      <Route exact path="/organisations/login" component={LoginScreen} />
      <Route exact path="/organisations/login/:source" component={LoginScreen} />
      <Route exact path="/organisations/two-factor" component={TwoFactorScreen} />
      <Route exact path="/organisations/contact" component={ContactSalesScreen} />
      <Route exact path="/organisations/signup" component={SignupScreen} />
      <Route exact path="/organisations/signup/:size" component={SignupScreen} />
      <Route exact path="/organisations/create-account/:id" component={CreateAccountScreen} />
      <Route exact path="/organisations/dashboard" component={DashboardScreen} />
      <Route exact path="/organisations/team" component={TeamScreen} />
      <Route exact path="/organisations/add_team_member" component={AddTeamMemberScreen} />
      <Route exact path="/organisations/courses" component={CourseLinksScreen} />
      <Route exact path="/organisations/purchase" component={PurchaseLicenceScreen} />
      <Route exact path="/organisations/add_users" component={AddUsersScreen} />
      <Route exact path="/organisations/invoices" component={InvoicesScreen} />
      <Route exact path="/organisations/password-reset" component={PasswordResetScreen} />
      <Route exact path="/organisations/password-reset/:id" component={PasswordResetScreen} />
      <Route exact path="/organisations/create-signup-link" component={CreateSignupLinkEducationInstitution} />
      <Route exact path="/privacy/:version" component={PrivacyScreen} />
      <Route exact path="/privacy" component={PrivacyScreen} />
      <Route exact path="/terms/:version" component={TermsScreen} />
      <Route exact path="/terms" component={TermsScreen} />
      <Route exact path="/styleguide" component={Styleguide} />
      <Route exact path="/404" component={NotFoundScreen} />
      <Route exact path="/:userId/experience" component={ExperienceScreen} />
      <Route exact path="/:userId/education" component={EducationScreen} />
      <Route exact path="/:userId/signup" component={UserSignupScreen} />
      <Route exact path="/:userId/contact" component={ContactDetailsScreen} />
      <Route exact path="/:userId/situation" component={SituationScreen} />
      <Route exact path="/:userId/location" component={LocationScreen} />
      <Route exact path="/:userId/secondary-school" component={SecondarySchoolScreen} />
      <Route exact path="/:userId/college" component={CollegeScreen} />
      <Route exact path="/:userId/university" component={UniversityScreen} />
      <Route exact path="/:userId/course" component={OtherCourseScreen} />
      <Route exact path="/:userId/career-selection/:sourceId" component={TopCareers} />
      <Route exact path="/:userId/quality-selection" component={TopQualities} />
      <Route exact path="/:userId/quiz/:questionId" component={Pair} />
      <Route exact path="/:userId/take-quiz" component={TakeQuizScreen} />
      <Route exact path="/:userId/results" component={FinalResults} />
      <Route exact path="/:userId/have-degree" component={DegreeScreen} />
      <Route exact path="/:userId/aspire-degree" component={NoDegreeScreen} />
      <Route exact path="/:userId/career/:careerUrl/pathways/:pathwayId" component={CareerScreen} />
      <Route exact path="/:userId/career/:careerUrl" component={CareerScreen} />
      <Route exact path="/:userId/payment" component={PaymentScreen} />
      <Route exact path="/:userId/upgrade" component={PaymentScreen} />
      <Route exact path="/:userId/thankyou" component={PaymentScreen} />
      <Route exact path="/:userId/browse" component={BrowseCareers} />
      <Route exact path="/api/v1/:apiEndPoint/:userID" component={APIScreen} />
      <Route exact path="/api/v1/:apiEndPoint" component={APIScreen} />
      <Route exact path="/organisations/api/v1/:apiEndPoint" component={APIScreen} />
      <Route exact path="/generate-access-link" component={GenerateAccessLink} />
      <Route exact path="/:userId" component={HomeScreen} />
      <Route exact path="/" component={HomeScreen} />
      <Redirect from='*' to='/404' />
    </Switch>
  );

  if ( cookies === 'on' || Cookies.get('cookies') === 'on' ||
       localStorage.getItem("cookies") === true || localStorage.getItem("cookies") === "true" || true ) {
    return (
      <AmplitudeProvider
        amplitudeInstance={amplitude.getInstance()}
        apiKey={ cookies === 'on' || Cookies.get('cookies') === 'on' ||
                 localStorage.getItem("cookies") === true || localStorage.getItem("cookies") === "true" || true ? AMPLITUDE_KEY :  '' }
      >
        {content}
      </AmplitudeProvider>
    );
  } else {
    return content;
  }
}


export default App;
