import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import colors from '../config/colors';
import layout from '../config/layout';

import { H4, PSmall } from '../ui/typography/Typography';
import { PrimaryButton } from '../ui/buttons/Buttons';

import IconExternalLink from '../assets/icon-external-link-white.svg';
import IconTick from '../assets/icon-tick.svg';

const CheckboxCardWithButton = ({
  children,
  user_id,
  title,
  handleCardClick,
  value,
  buttonLabel,
  buttonUrl,
  topLabel,
  isSelected,
  checkedLabel,
  handleButtonClick,
}) => {
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected])

  const handleCardSelect = () => {
    if (user_id) {
      handleCardClick && handleCardClick(value, !selected);
      setSelected(!selected);
    }
  };

  const onButtonClick = () => {
    handleButtonClick(value);
    const url = buttonUrl.match(/^http[s]?:\/\//) ? buttonUrl : 'http://' + buttonUrl;
    window.open(url, '_blank');
  };

  return (
    <Container selected={selected} user_id={user_id}>
    {topLabel && <TopLabel>{topLabel}</TopLabel>}
      <ClickableBg onClick={handleCardSelect}></ClickableBg>
      {checkedLabel && selected && <CheckedLabel>{checkedLabel}</CheckedLabel>}
      <Title selected={selected}>{title}</Title>
      <Content selected={selected}>
        {children}
        {buttonLabel && (
          <ButtonWrap>
            <PrimaryButton onClick={onButtonClick}>{buttonLabel}<Image src={IconExternalLink} alt="" /></PrimaryButton>
          </ButtonWrap>
        )}
      </Content>
    </Container>
  );
};

export default CheckboxCardWithButton;

CheckboxCardWithButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
};


CheckboxCardWithButton.defaultProps = {
  isSelected: false,
}

const ContainerNonCheckableStyle = css`
  background-color: ${colors.WHITE};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  display: block;
  padding: 32px;
  position: relative;
  width: 100%;
  max-width: 544px;
  margin: 0 auto 20px;
`;

const ContainerCheckableStyle = css`
  background-color: ${props => props.selected ? colors.GRAY_LIGHT : colors.WHITE};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_LIGHT_GREY}, 0px 8px 16px ${colors.LCG_LIGHT_GREY};
  cursor: pointer;
  display: block;
  padding: 32px;
  position: relative;
  width: 100%;
  max-width: 544px;
  margin: 0 auto 20px;

  &::after {
    background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE :  colors.GRAY_LIGHT};
    background-image: url(${IconTick});
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 100%;
    content: "";
    height: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
  }
`;

const Container = styled.a`
  ${props => props.user_id ? ContainerCheckableStyle : ContainerNonCheckableStyle}
`;

const ClickableBg = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ButtonWrap = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  margin-top: 20px;
`;

const Title = styled(H4)`
  text-decoration: ${props => props.selected ? 'line-through' : 'unset'};

  ${props => props.selected && `
    font-size: 20px;
    line-height: 28px;
    color: ${colors.TEXT_BODY};
  `};

  @media(max-width: ${layout.breakpoints.MD}) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Content = styled.div`
  max-height: ${props => props.selected ? 0 : '1000px'};
  overflow: hidden;
  transition: max-height 0.32s;
`;

const Image = styled.img`
  margin-left: 4px;
  top: 4px;
  padding-bottom: 2px;
`;

const TopLabel = styled.div`
  background-color: ${colors.LCG_LIGHT_PURPLE};
  border-radius: 4px;
  color: ${colors.WHITE};
  font-size: 12px;
  font-weight: 700;
  left: 50%;
  line-height: 18px;
  padding: 1px 4px;
  position: absolute;
  text-transform: uppercase;
  top: -10px;
  transform: translateX(-50%);
`;

const CheckedLabel = styled(PSmall)`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
`;
