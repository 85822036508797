import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body,
  * {
    box-sizing: border-box;
    font-family: 'Overpass', sans-serif;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  body {
    &.fixed {
      overflow: hidden;
    }
  }
`;

export default GlobalStyle;
