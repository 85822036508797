/* Dashboard > Manage team (top right) */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import axios from 'axios';

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createOrgSubmitData } from '../../utils/helper';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P } from '../../ui/typography/Typography';
import { PrimaryButton, GrayButton, SecondaryButton } from '../../ui/buttons/Buttons';
import LoadingScreen from '../LoadingScreen';
import Input from '../../ui/form/Input';
import { copyArray } from '../../utils/copyArray';

const CourseLinksScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);

  const [formValues, setFormValues] = useState([]);

  useGetScreenData('organisations/courses', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/courses', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/courses") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    // Instantiate formValues...
    if (context && context.course_links) {
      setFormValues(context.course_links);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (key, value) => {
    const formValuesCopy = copyArray(formValues);
    let found = false;
    for (let i=0; i<formValuesCopy.length && !found; i++) {
      if (formValuesCopy[i].key === key) {
        formValuesCopy[i].url = value;
        found = true;
      }
    }
    setFormValues(formValuesCopy);
  };

  const haveChangesBeenMade = () => {
    // Check if formValues has any different values to that set in context
    let changesMade = false;
    if (context && context.course_links && context.course_links.length > 0) {
      for (let i=0; i<formValues.length && !changesMade; i++) {
        if (formValues[i].url !== context.course_links[formValues[i].key].url) {
          changesMade = true;
        }
      }
    }
    return changesMade;
  }

  const saveUpdates = async () => {
    if (!haveChangesBeenMade()) return null;
    setIsLoading(true);
    const updates = [];
    for (let i=0; i<formValues.length; i++) {
      if (formValues[i].url !== context.course_links[formValues[i].key].url) {
        updates.push(formValues[i]);
      }
    }
    const orgSubmitData = createOrgSubmitData({
      updates: updates,
    });
    axios.post('/api/v1/update_course_links', orgSubmitData).then(res => {
      setIsLoading(false);
      dispatch({type: "updateResponse", data:res.data});
    });
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  const changesHaveBeenMade = haveChangesBeenMade();

  return(
    <>
      <MetaTags>
        <title>CareersPro - Course links</title>
        <meta id="meta-description" name="description" content="CareersPro - Course links" />
      </MetaTags>
      <Container>
        <TopBar pageTitle={context.organisation_name} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" />
        <CircleOne />
        <CircleTwo />
        <TextContainer>
          <Text>Below are listed all of the college courses your students can be linked to. You can edit these links to point directly to your course pages.  Or if you don’t provided a course, just remove the link altogether and we will no longer link to your college for that course.</Text>
        </TextContainer>
        <ButtonContainer>
          {changesHaveBeenMade ? (
            <PrimaryButton onClick={() => saveUpdates()}>Save updates</PrimaryButton>
          ) : (
            <GrayButton>Save updates</GrayButton>
          )}
        </ButtonContainer>
        {formValues && formValues.length > 0 && (
          <UsersTable>
            <thead>
              <TableRow header>
                <TableHeaderCell pl><TableText header>Qualification name</TableText></TableHeaderCell>
                <TableHeaderCell pr><TableText header>Course link</TableText></TableHeaderCell>
              </TableRow>
            </thead>
            <tbody>
              {formValues.map((item) => (
                <TableRow key={item.key} id={item.id}>
                  <TableCell pl><TableText>{item.name}</TableText></TableCell>
                  <TableCell pr wide>
                    <Input
                      placeholder="Course link (leave blank if you don't run this course)"
                      handleChange={(it) => handleInputChange(item.key, it)}
                      inputValue={item.url}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </UsersTable>
        )}
        <ButtonContainer>
          {changesHaveBeenMade ? (
            <PrimaryButton onClick={() => saveUpdates()}>Save updates</PrimaryButton>
          ) : (
            <GrayButton>Save updates</GrayButton>
          )}
        </ButtonContainer>
        <BackButtonContainer>
          <Link to={`/organisations/dashboard`}>
            <SecondaryButton>Back to dashboard</SecondaryButton>
          </Link>
        </BackButtonContainer>
      </Container>
    </>
  );
};

export default CourseLinksScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 250px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 40px;
`;

const TextContainer = styled.div`
  padding: 20px 12px 20px 12px;
`

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  padding-left: 20px;
  padding-right: 20px;
  max-width: 600px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const UsersTable = styled(Table)`
  margin: auto;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  ${props => `padding: 12px ${props.pr ? "20px": "8px"} 12px ${props.pl ? "20px": "8px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 8px ${props.pr ? "12px": "4px"} 8px ${props.pl ? "12px": "4px"};`}
  }
`

const TableRow = styled.tr`
  ${props => props.header && `background-color: ${colors.BG_LIGHT_GRAY};`}
  ${props => !props.header && props.id % 2 === 0 && `background-color: ${colors.WHITE};`}
`

const TableText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin: auto;
  ${props => props.header && "font-weight: bold;"}
  ${props => props.status_id === 0 && `color: ${colors.LCG_DARK_PURPLE};`}
  ${props => props.status_id === 1 && `color: ${colors.LCG_LIGHT_PURPLE};`}
  ${props => props.status_id === 2 && `color: ${colors.LCG_DARK_PURPLE};`}
  ${props => props.error && `color: ${colors.LCG_DARK_PURPLE};`}
`

const TableCell = styled.td`
  ${props => `padding: 20px ${props.pr ? "32px": "20px"} 20px ${props.pl ? "32px": "20px"};`}
  text-align: center;
  ${props => props.wide && "width: 60%;"}

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  }
`

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 32px 0 32px;
  padding-top: 20px;
`;
