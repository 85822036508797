/* Homepage > About us section */

import React, { useState } from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { P, H3 } from '../../ui/typography/Typography';
import Title from './Title';
import { BgDarkBlueLine } from '../../ui/bg/BgColoredLines';

const AboutUsSection = () => {

  const [ hoverState, setHoverState ]= useState({
    phil: true,
    emma: true,
    laura: true,
  });

  const images = {
    phil: process.env.PUBLIC_URL + '/images/phil2.jpg',
    phil_hover: process.env.PUBLIC_URL + '/images/phil3.jpg',
    emma: process.env.PUBLIC_URL + '/images/emma2.jpg',
    emma_hover: process.env.PUBLIC_URL + '/images/emma3.jpg',
    laura: process.env.PUBLIC_URL + '/images/laura2.jpg',
    laura_hover: process.env.PUBLIC_URL + '/images/laura3.jpg',
  }

  const toggleHoverState = (name, value) => {
    setHoverState({
      ...hoverState,
      [name]: value,
    });
  };

  return (
    <Container>
      <BgDarkBlueLine />
      <Grid id="aboutus">
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <Title label="About us" is_subtitle>
              The average person thinks about <Span>quitting their job 16 times a year</Span>. We think that should change, so decided to do something about it.
            </Title>
          </Col>
          <ColBottomPadding xs={12} md={8} mdOffset={2}>
            <P color={colors.TEXT_DARK} text-align="center"><b>CareersPro</b> is a London-based tech startup. Our team is passionate about providing world class careers advice using the power of software.</P>
          </ColBottomPadding>
          <TeamMemberColumn xs={12} md={8} mdOffset={2}>
            <TeamMemberImage
              src={hoverState['phil'] ? images['phil_hover'] : images['phil']}
              alt=""
              afterLoad={() => {toggleHoverState('phil', false)}}
              onMouseOver={() => {toggleHoverState('phil', true)}}
              onMouseOut={() => {toggleHoverState('phil', false)}}
            />
            <TeamMemberBio>
              <TeamMemberName>Phil Hewinson</TeamMemberName>
              <P color={colors.TEXT_DARK}>Phil founded Would You Rather Be in the summer of 2019. He has 12 years experience working in product, technical and leadership roles at Monzo, Facebook, Google and Microsoft. He is passionate about using software to have a social impact and shares his journey on <a href='https://philhewinson.com/' target='_blank' rel='noopener noreferrer'>his blog</a>.</P>
              <P color={colors.TEXT_DARK}>He loves chocolate and morning runs.</P>
            </TeamMemberBio>
          </TeamMemberColumn>
          <TeamMemberColumn xs={12} md={8} mdOffset={2}>
            <TeamMemberImage
              src={hoverState['emma'] ? images['emma_hover'] : images['emma']}
              alt=""
              afterLoad={() => {toggleHoverState('emma', false)}}
              onMouseOver={() => {toggleHoverState('emma', true)}}
              onMouseOut={() => {toggleHoverState('emma', false)}}
            />
            <TeamMemberBio>
              <TeamMemberName>Emma Rosen</TeamMemberName>
              <P color={colors.TEXT_DARK}>Emma is a career change specialist; she’s the author of The Radical Sabbatical, 2x TED speaker and founder of two previous startups with a focus in marketing. She is passionate about the democratisation of career happiness and its positive impact throughout society. Find out more about Emma on <a href='https://www.25before25.co.uk/' target='_blank' rel='noopener noreferrer'>her website.</a></P>
              <P color={colors.TEXT_DARK}>She is often found hiking up mountains.</P>
            </TeamMemberBio>
          </TeamMemberColumn>
          <TeamMemberColumn xs={12} md={8} mdOffset={2}>
            <TeamMemberImage
              src={hoverState['laura'] ? images['laura_hover'] : images['laura']}
              alt=""
              afterLoad={() => {toggleHoverState('laura', false)}}
              onMouseOver={() => {toggleHoverState('laura', true)}}
              onMouseOut={() => {toggleHoverState('laura', false)}}
            />
            <TeamMemberBio>
              <TeamMemberName>Laura Tucker</TeamMemberName>
              <P color={colors.TEXT_DARK}>Laura is a Data Analyst and a career changer. With 8 years experience as a fashion buyer at premium retail brands she now heads up research and data. She is passionate about empowering people to explore new career options - knowledge is power!</P>
              <P color={colors.TEXT_DARK}>Laura’s a big fan of wild swimming.</P>
            </TeamMemberBio>
          </TeamMemberColumn>
        </Row>
      </Grid>
    </Container>
  );
};

export default AboutUsSection;

const Container = styled.div`
  padding-top: 236px;
  position: relative;
  overflow: hidden;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-top: 136px;
  }
`;

const Span = styled.span`
  color: ${colors.LCG_LIGHT_PURPLE};
`;

const ColBottomPadding = styled(Col)`
  padding-bottom: 12px
`;

const TeamMemberColumn = styled(Col)`
  display: flex;
  padding-top: 20px;

  @media (max-width: ${layout.breakpoints.SM}) {
    display: block;
    padding-top: 12px;
  }
`;

const TeamMemberImage = styled(LazyLoadImage)`
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 12px auto 0px auto;
  display: block;

  @media (max-width: ${layout.breakpoints.MD}) {
    width: 180px;
    height: 180px;
    margin-top: 8px;
  }

  @media (max-width: ${layout.breakpoints.SM}) {
    width: 150px;
    height: 150px;
  }
`;

const TeamMemberBio = styled.div`
  display: block;
  padding-left: 32px;
  
  @media (max-width: ${layout.breakpoints.SM}) {
    max-width: 400px;
    margin: auto;
    padding-left: 0px;
  }
`;

const TeamMemberName = styled(H3)`
  margin: 0px auto 16px auto;

  @media (max-width: ${layout.breakpoints.SM}) {
    max-width: 300px;
    text-align: center;
    margin-top: 20px;
  }
`;
