/* Final results > Career screen > View pathway */

import React from 'react';
import styled from 'styled-components';
import axios from  'axios';
import ReactHtmlParser from 'react-html-parser';
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

import colors from '../../config/colors';
import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { createSubmitData, getParameterByName } from '../../utils/helper';

import { P, H3, PSmall } from '../../ui/typography/Typography';
import CheckboxCardWithButton from '../../components/CheckboxCardWithButton';
import CareerFeedback from './CareerFeedback';

import IconTickCircle from '../../assets/icon-tick-circle-purple.svg';
import ConfettiMobile from '../../assets/confetti-mobile.svg';
import Confetti from '../../assets/confetti.svg';

const PathwayModalContent = ({ pathway, career, first_name }) => {
  const [{ user_id }, dispatch] = useStateValue();

  if (!pathway) return null;

  const handleButtonClick = (value, logEvent) => {
    logEvent('Clicked Pathway Button', {
      career_id: career.career_id,
      pathway_id: pathway.id,
      career_name: career.career_name,
      pathway_name: pathway.title,
      task_type: value.type,
      task_id: value.id,
      task_name: value.title,
    });
    if (user_id) {
      const submitData = createSubmitData({
        user_id: user_id,
        career_id: career.career_id,
        pathway_id: pathway.id,
        task_card_type: value.type,
        task_card_id: value.id,
      });
      axios.post('/api/v1/pathwayTaskCardButtonClicked', submitData);
    }
  };

  const handleCardSelect = (pathwayTask, completed, logEvent) => {
    if (user_id) {
      logEvent('Completed Pathway Task', {
        career_id: career.career_id,
        pathway_id: pathway.id,
        career_name: career.career_name,
        pathway_name: pathway.title,
        task_type: pathwayTask.type,
        task_id: pathwayTask.id,
        task_name: pathwayTask.title,
        completed: completed,
      });

      const submitData = createSubmitData({
        user_id: user_id,
        career_id: career.career_id,
        pathway_id: pathway.id,
        task_card_type: pathwayTask.type,
        task_card_id: pathwayTask.id,
        completed: completed,
      });
      axios.post('/api/v1/pathwayTaskCardCompleteChange', submitData).then(res => {
        dispatch({type: "updateResponse", data:res.data});
      });
    }
  };
  const showConfetti = user_id && pathway.intro_sentence.includes("Congratulations");

  const feedback = getParameterByName('feedback');

  let requirementsTitle = "";
  if (first_name) {
    requirementsTitle += first_name + ", you have ";
  } else {
    requirementsTitle += "You have ";
  }
  if (pathway.required_tasks.filter(it => !it.completed).length > 0) {
    requirementsTitle += pathway.required_tasks.filter(it => !it.completed).length + " requirement" + (pathway.required_tasks.filter(it => !it.completed).length !== 1 ? "s" : "") + " to meet";
  } else {
    requirementsTitle += "no requirements to meet";
  }

  return (
    <Amplitude eventProperties={{career_id: career.career_id, pathway_id: pathway.id, career_name: career.career_name, pathway_name: pathway.title}}>
      <LogOnMount eventType="View Pathway">
        <Container showConfetti={showConfetti}>
          <Label align="center">{career.career_name} pathway</Label>
          <Title align="center">{pathway.title}</Title>
          <P align="center">{ReactHtmlParser(pathway.intro_sentence)}</P>
        {pathway.time_cost_sentence && <P align="center">{ReactHtmlParser(pathway.time_cost_sentence)}</P>}
        {pathway.key_details_paragraph && <P align="center">{ReactHtmlParser(pathway.key_details_paragraph)}</P>}
        {!user_id && feedback && <CareerFeedback user_id={user_id} career_id={career.career_id} pathway_id={pathway.id} />}
        <Title align="center" user_id={user_id} hasIcon marginTop="32px" pb={pathway.required_tasks.filter(it => !it.completed).length === 0}>{requirementsTitle}</Title>
        {pathway.required_tasks.filter(it => !it.completed).length > 0 && user_id && (<SubTitle align="center">Tick off your requirements and keep track of your progress.</SubTitle>)}
        <Amplitude>
          {({ logEvent }) => (
            <div>
              {pathway.required_tasks.map(task => (
                <CheckboxCardWithButton
                  user_id={user_id}
                  title={task.title}
                  key={task.key}
                  buttonLabel={task.button && task.button.text}
                  buttonUrl={task.button && task.button.url}
                  isSelected={task.completed}
                  checkedLabel="Completed"
                  value={task}
                  handleButtonClick={value => handleButtonClick(value, logEvent)}
                  handleCardClick={(pathwayTask, completed) => handleCardSelect(pathwayTask, completed, logEvent)}
                >
                  <>
                    {task.subtitle && <PSmall>{task.subtitle}</PSmall>}
                    <div>
                      {task.fields.map(field => (
                        <div key={`field_${field.key}`}>
                          <Row>
                            <FieldTitle>{field.title}</FieldTitle>
                            <FieldBody>{field.body}</FieldBody>
                          </Row>
                          {field.extra && <FieldExtra>{field.extra}</FieldExtra>}
                        </div>
                      ))}
                    </div>
                  </>
                </CheckboxCardWithButton>
              ))}
            </div>
          )}
        </Amplitude>
        {pathway.entry_job && (
          <>
            <Title align="center" user_id={user_id} marginTop="52px">Get a job</Title>
            {!pathway.entry_job.prereq_apprenticeship && (
              <SubTitle align="center">Once you’ve met the above requirements, you’ll be ready to apply for a job.</SubTitle>
            )}
            {pathway.entry_job.prereq_apprenticeship && (
              <SubTitle align="center">90% of people are offered permanent roles in their organisation on completion of an apprenticeship. You’re also ready to apply for a job elsewhere if you’d like to.</SubTitle>
            )}
            <Amplitude>
              {({ logEvent }) => (
                <CheckboxCardWithButton
                  user_id={user_id}
                  title={pathway.entry_job.title}
                  key={pathway.entry_job.key}
                  buttonLabel={pathway.entry_job.button && pathway.entry_job.button.text}
                  buttonUrl={pathway.entry_job.button && pathway.entry_job.button.url}
                  isSelected={pathway.entry_job.completed}
                  checkedLabel="Completed"
                  value={pathway.entry_job}
                  handleButtonClick={value => handleButtonClick(value, logEvent)}
                  handleCardClick={(pathwayTask, completed) => handleCardSelect(pathwayTask, completed, logEvent)}
                >
                  <>
                    {pathway.entry_job.subtitle && <PSmall>{pathway.entry_job.subtitle}</PSmall>}
                    <div>
                      {pathway.entry_job.fields.map(field => (
                        <div key={`field_${field.key}`}>
                          <Row>
                            <FieldTitle>{field.title}</FieldTitle>
                            <FieldBody>{field.body}</FieldBody>
                          </Row>
                          {field.extra && <FieldExtra>{field.extra}</FieldExtra>}
                        </div>
                      ))}
                    </div>
                  </>
                </CheckboxCardWithButton>
              )}
            </Amplitude>
          </>
        )}
        {pathway.optional_tasks.length > 0 && (
          <>
            <Title align="center" user_id={user_id} marginTop="52px">Optional requirements</Title>
            <SubTitle align="center" bottomPadding>These requirements are optional, but meeting them may make you a better candidate.</SubTitle>
            <Amplitude>
              {({ logEvent }) => (
                <div>
                  {pathway.optional_tasks.map(task => (
                    <CheckboxCardWithButton
                      user_id={user_id}
                      title={task.title}
                      key={task.key}
                      buttonLabel={task.button && task.button.text}
                      buttonUrl={task.button && task.button.url}
                      topLabel={task.is_recommended ? 'Recommended' : 'Optional'}
                      isSelected={task.completed}
                      checkedLabel="Completed"
                      value={task}
                      handleButtonClick={value => handleButtonClick(value, logEvent)}
                      handleCardClick={(pathwayTask, completed) => handleCardSelect(pathwayTask, completed, logEvent)}
                    >
                      <>
                        {task.subtitle && <PSmall>{task.subtitle}</PSmall>}
                        <div>
                          {task.fields.map(field => (
                            <div key={`field_${field.key}`}>
                              <Row>
                                <FieldTitle>{field.title}</FieldTitle>
                                <FieldBody>{field.body}</FieldBody>
                              </Row>
                              {field.extra && <FieldExtra>{field.extra}</FieldExtra>}
                            </div>
                          ))}
                        </div>
                      </>
                    </CheckboxCardWithButton>
                  ))}
                </div>
              )}
            </Amplitude>

          </>
          )}
      </Container>
    </LogOnMount>
  </Amplitude>
  );
};

export default PathwayModalContent;

const Container = styled.div`

  &::before {
    display: ${props => props.showConfetti ? 'block' : 'none'};
    content: '';
    background-image: url(${Confetti});
    background-repeat: repeat-x;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    @media (max-width: ${layout.breakpoints.MD}) {
      background-image: url(${ConfettiMobile});
    }
  }

  p {
    position: relative;
  }
`;

const Label = styled(P)`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const Title = styled(H3)`
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
  position: relative;
  margin-bottom: 2px;
  ${props => (!props.user_id || props.pb) && `
    padding-bottom: 15px;
  `}

  ${props => props.hasIcon && `
    background-image: url(${IconTickCircle});
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 50px;
  `}
`;

const SubTitle = styled(P)`
  ${props => props.bottomPadding && `
    padding-bottom: 12px;
  `}
`;

const Row = styled.div`
  border-top: 1px solid ${colors.LCG_LIGHT_GREY};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;

  &:first-child {
    border-top: 0;
  }
`;

const FieldTitle = styled(PSmall)`
  max-width: 40%;
  margin-right: 12px;
  margin-bottom: 0;
`;

const FieldBody = styled(PSmall)`
  max-width: calc(60% - 12px);
  margin-bottom: 0;
  color: ${colors.TEXT_DARK};
  text-align: right;
`;

const FieldExtra = styled(PSmall)`
  margin-bottom: 8px;
  display: block;
  font-style: italic;
`;
