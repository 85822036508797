/* Generic career screen > Try quiz section at bottom
   Example link: https://careerspro.co.uk/career/nurse */

import React from 'react';
import styled from 'styled-components';
import { Amplitude } from '@amplitude/react-amplitude';
import PropTypes from 'prop-types';

import { H3, P } from '../../ui/typography/Typography';
import IconIdea from '../../assets/icon-idea.svg';
import { LCGPurpleButton } from '../../ui/buttons/Buttons';

const TakeQuizSection = ({ handleQuizStart, adId, source, medium, careerID }) => {
  return (
    <Container>
      <Title align="center">Try our AI-powered career planning platform</Title>
      <P align="center">Our AI-powered platform will show you which careers you'll thrive in, with personalised pathways into each of them based on your situation and background.</P>
      <Amplitude userProperties={{
        user_id: 'null',
        have_degree: 'null',
        aspire_degree: 'null',
        qualities: 'null',
        num_qualities_selected: 'null',
        careers: 'null',
        num_careers_selected: 'null',
        location: 'null',
        postcode: 'null',
        live_in_the_uk: 'null',
        open_to_relocating: 'null',
        employment_status: 'null',
        in_fulltime_education: 'null',
        place_of_study: 'null',
        age: 'null',
        salary: 'null',
        homeless: 'null',
        highest_education_level: 'null',
        alevels: 'null',
        alevel_school: 'null',
        alevel_school_from_home: 'null',
        alevel_school_outside_uk: 'null',
        degree: 'null',
        degree_university: 'null',
        degree_university_outside_uk: 'null',
        masters: 'null',
        masters_university: 'null',
        masters_university_outside_uk: 'null',
        phd: 'null',
        phd_university: 'null',
        phd_university_outside_uk: 'null',
        rating: 'null',
        ad_id: adId ? adId : 'null',
        source: source ? source : 'null',
        medium: medium ? medium : 'null',
      }}>
        {({ logEvent }) =>
          <ButtonWrap>
            <LCGPurpleButton align="center" onClick={() => {handleQuizStart(adId, source, medium); logEvent('Clicked Get Started', {career_id: careerID});}}>Get started</LCGPurpleButton>
          </ButtonWrap>
        }
      </Amplitude>
    </Container>
  );
};

export default TakeQuizSection;

TakeQuizSection.propTypes = {
  handleQuizStart: PropTypes.func.isRequired,
};

const Container = styled.div`
  margin: 32px auto 84px auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 544px;
`;

const Title = styled(H3)`
  padding-top: 50px;
  background-image: url(${IconIdea});
  background-repeat: no-repeat;
  background-position: top center;
`;

const ButtonWrap = styled.div`
  text-align: center;
  display: block;
  margin: auto;
`;
