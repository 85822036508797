/* Homepage > Organisations > Contact sales */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

import TopBar from '../../components/TopBar';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createSubmitData } from '../../utils/helper';
import { validateEmail } from '../../utils/validation';

import Input from '../../ui/form/Input';
import Select from '../../ui/form/Select';
import TextArea from '../../ui/form/TextArea';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import { P, H3 } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';

const ContactSalesScreen = () => {

  const history = useHistory();

  const [ thanks, setThanks ] = useState(false);

  const [formValues, setFormValues] = useState({
      organisation: "",
      organisation_type: "",
      name: "",
      job_title: "",
      email: "",
      phone: "",
      message: "",
    });

  const initialEmailErrorState = {
    organisation: null,
    name: null,
    job_title: null,
    email: null,
    phone: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "organisation" || name === "name" || name === "job_title" || name === "phone") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    } else if ( name === "email") {
      const errorMsg = !validateEmail(value) ? "This is not a valid email" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let errors = {...initialEmailErrorState};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if (key !== "message") {
        if (value === "") {
          formValid = false;
          errors = {
            ...errors,
            [key]: "This field is required"
          }
        } else if (key === "email") {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        }
      }
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  const submitForm = () => {
    if (validateForm(true)) {
      const submitData = createSubmitData({
        organisation_details: formValues,
      });
      axios.post('/api/v1/handleGetInTouchInput/', submitData);

      setThanks(true);
      setFormValues({
        organisation: "",
        organisation_type: "",
        name: "",
        job_title: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  }

  return(
    <>
      <MetaTags>
        <title>CareersPro - Contact sales</title>
        <meta id="meta-description" name="description" content="CareersPro - Contact sales" />
      </MetaTags>
      <Container>
        <TopBar pageTitle="Organisations - Contact sales" />
        <CircleOne />
        <CircleTwo />
        {thanks && (
          <TopSectionSuccess>
            <TopTextSuccess align="center">Thanks for getting in touch!</TopTextSuccess>
            <TopTextSuccess align="center">Our sales team will be in touch shortly.</TopTextSuccess>
            <ButtonWrap>
              <Link to={`/organisations`}>
                <PrimaryButton>Back to organisation page</PrimaryButton>
              </Link>
            </ButtonWrap>
          </TopSectionSuccess>
        )}
        {!thanks && (
          <>
            <Grid>
              <Row>
                <Col xs={12} md={8} mdOffset={2}>
                  <TopText>Complete this form and a member of our sales team will be in touch!</TopText>
                  <Form>
                    <SubTitle>Organisation details</SubTitle>
                    <InputWrap>
                      <Input type="text" name="organisation" label="Organisation name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'organisation')} error={formErrors['organisation']} />
                    </InputWrap>
                    <InputWrap>
                      <Select
                        label="Organisation type"
                        options={[
                          {label: 'Career coach', id: 'career_coach'},
                          {label: 'Training provider', id: 'training_provider'},
                          {label: 'Outplacement service', id: 'outplacement_service'},
                          {label: 'School', id: 'school'},
                          {label: 'College', id: 'college'},
                          {label: 'University', id: 'university'},
                          {label: 'Other', id: 'other'},
                        ]}
                        onBlur={validateField}
                        handleChange={(it) => handleInputChange(it && it.id ? it.id : "", 'organisation_type')}
                        error={formErrors['organisation_type']}
                      />
                    </InputWrap>
                    <SubTitle>Your details</SubTitle>
                    <InputWrap>
                      <Input type="text" name="name" label="Name" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'name')} error={formErrors['name']} />
                    </InputWrap>
                    <InputWrap>
                      <Input type="text" name="job_title" label="Job title" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'job_title')} error={formErrors['job_title']} />
                    </InputWrap>
                    <SubTitle>Contact details</SubTitle>
                    <InputWrap>
                      <Input type="email" name="email" label="Email"  onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} error={formErrors['email']} />
                    </InputWrap>
                    <InputWrap>
                      <Input type="tel" name="phone" label="Phone" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'phone')} error={formErrors['phone']} />
                    </InputWrap>
                    <SubTitle>Message (optional)</SubTitle>
                    <TextArea type="text" name="message" handleChange={(it) => handleInputChange(it, 'message')} />
                    <ButtonContainer>
                      {!validateForm(false) ? (
                        <Tooltip text="Please enter your details above">
                          <GrayButton disabled>Submit</GrayButton>
                        </Tooltip>
                      ) : (
                        <PrimaryButton onClick={() => submitForm()}>Submit</PrimaryButton>
                      )}
                    </ButtonContainer>
                  </Form>
                </Col>
              </Row>
            </Grid>
            <BackButtonContainer>
              <SecondaryButton onClick={() => {history.goBack()}}>Back</SecondaryButton>
            </BackButtonContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default ContactSalesScreen;


const Container = styled.div`
  position: relative;
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 110px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  top: 479px;

  @media(max-width: ${layout.breakpoints.MD}) {
    top: 249px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  max-width: 450px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const ButtonContainer = styled.div`
  padding: 40px 0 32px 0;
  text-align: center;
`;

const TopText = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  padding: 20px 0 20px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 12px 0;
  }
`;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const TopSectionSuccess = styled.div`
  margin-top: 20px;
`;

const TopTextSuccess = styled(P)`
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 576px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 356px;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;
