import { useEffect } from 'react';
import axios from 'axios';
import { useStateValue } from '../services/state';
import { useHistory } from 'react-router-dom';
import publicIP from 'public-ip';

import { getParameterByName } from '../utils/helper';

// Get completions number for homepage
export const useGetCompletions = () => {
  const [{completions}, dispatch] = useStateValue();

  const getCompletions = async () => {
    const source = getParameterByName('utm_source');
    const resp = await axios.get('/api/v1/total_completions' + (source ? ('?source=' + source) : ''));
    return resp.data;
  };

  useEffect(() => {
    const completionsData = async () => {
      const data = await getCompletions();
      dispatch({ type: 'update' , values: {
        completions: data.total_completions,
        price_headline: data.price_headline,
        price_disclaimer: data.price_disclaimer,
        price: data.price,
        premium_price: data.premium_price,
        original_price: data.original_price,
      }})
    };
    !completions && completionsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// Get IP address of the client
export const useGetIP = () => {
  const [{ip}, dispatch] = useStateValue();

  const getIP = async () => {
    try {
      const resp = await publicIP.v4();
      return resp;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const ipData = async () => {
      const ip = await getIP();
      dispatch({ type: 'update' , values: {
        ip: ip,
      }})
    };
    !ip && ipData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const fetchData = async (screen_name, user_id, screen_context, extra_attributes) => {
  let extraAttributes = "";
  if (extra_attributes != null) {
    extraAttributes =  "&" + new URLSearchParams(extra_attributes).toString();
  }
  const apiUrl = `/api/v1/fetch_screen?screen_name=${screen_name}${user_id ? ('&user_id=' + user_id) : ''}${screen_context ? ('&screen_context=' + screen_context) : ''}${extraAttributes ? extraAttributes : ''}`;
  try {
    const resp = await axios.get(apiUrl);
    return resp;
  } catch(error) {
    return error.response;
  }
};

export const useGetScreenData = (screenName, setIsLoading, userId, screenContext, extraAttributes) => {
  const [{ screen_name }, dispatch] = useStateValue();
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    const getData = async (userId, screenContext, extraAttributes) => {
      !isCancelled && dispatch({type: 'startLoading'});
      const resp = await fetchData(screenName, userId, screenContext, extraAttributes);
      if (resp && resp.status === 200 && !isCancelled) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== screenName) {
          let dataUrl = `${(!resp.data.user_id) ? "" : "/" + resp.data.user_id}/${resp.data.screen.name}`;
          if (resp.data.screen.name === 'quiz') dataUrl += `/${resp.data.screen.context.question_number}`;
          if (resp.data.screen.name === 'career-selection') dataUrl += `/${resp.data.screen.context.source_url}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
      if (resp && resp.status === 404 && !isCancelled) {
        dispatch({type: 'endLoading'});
        history.push('/404');
      }
    };

    if (screen_name !== screenName ) {
      getData(userId, screenContext, extraAttributes);
    } else {
      setIsLoading(false);
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
