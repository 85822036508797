const colors = {
  PRIMARY: '#1905FA',
  PRIMARY_DARK: '#1404C8',
  PRIMARY_FADE: 'rgba(25, 5, 250, 0.1)',
  YELLOW: '#FAB905',
  YELLOW_DARK: '#C89404',
  YELLOW_FADE: 'rgba(250, 185, 5, 0.1)',
  YELLOW_LIGHT: '#FEF1CD',
  PURPLE: '#7105FA',
  PURPLE_FADE: 'rgba(122, 21, 250, 0.1)',
  PURPLE_LIGHT: '#CCA3FF',
  GRAY: '#808080',
  GRAY_LIGHT: '#BFBFBF',
  GRAY_DARK: '#666666',
  GRAY_FADE: 'rgba(128, 128, 128, 0.1)',
  BLACK_FADE: 'rgba(0, 0, 0, 0.1)',
  RED_DARK: '#C8043F',
  RED_FADE: 'rgba(250, 5, 79, 0.1)',
  RED: '#FA054F',
  TEAL: '#05FAA8',
  TEAL_DARK: '#04c887',
  TEAL_FADE: 'rgba(5, 250, 168, 0.1)',
  BLUE: '#05D7F8',
  CYAN: '#05D7F8',
  CYAN_LIGHT: '#C8F7FE',
  ORANGE: '#FA8005',
  TEXT_DARK: '#2b3535',
  TEXT_BODY: '#2b3535',
  TEXT_GRAY: '#808080',
  TEXT_LIGHT: '#FFFFFF',
  BTN_BORDER_LIGHT: '#EDEBFF',
  BG_LIGHT: '#FFFFFF',
  BG_GRAY: '#F2F2F2',
  BG_LIGHT_GRAY: '#E9E9E9',
  BG_VLIGHT_GRAY: '#F7F7F7',

  // Learning Curve Colours

  BLACK: '#000000',
  WHITE: '#FFFFFF',
  TEXT_LCG_DARK_GREY: '#000000',
  LCG_LIGHT_PURPLE: '#85529c',
  LCG_LIGHT_PURPLE_FADE: 'rgba(133, 82, 156, 0.1)',
  LCG_DARK_PURPLE: '#724686',
  LCG_DARK_PURPLE_FADE: 'rgba(114, 70, 134, 0.1)',
  LCG_GREEN: '#28a745',
  LCG_GREEN_FADE: 'rgb(40, 167, 69, 0.1)',
  LCG_GREY: '#353D3E',
  LCG_DARK_GREY: '#2b3535',
  LCG_LIGHT_GREY: '#747878',
  LCG_LIGHTER_GREY: '#BFBFBF',
  LCG_LIGHTEST_GREY: '#DDDCDC',
  LCG_PINK: '#ec008b',
  LCG_PINK_LOGO: '#E01A65',
  LCG_DARK_BLUE: '#06687f',
  LCG_DARK_BLUE_FADE: 'rgba(6,104,127,0.1)',
  LCG_LIGHT_BLUE: '#a4d0dc',
  LCG_LIGHT_BLUE_FADE: 'rgba(164,208,220,0.1)'
};
export default colors;
