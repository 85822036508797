/* Education screen > None tab
   Ref. EducationScreen.js
   May need to select "No" for "Do you have a degree?" to see the None tab */

import React, { useState } from 'react';
import styled from 'styled-components';
import { copyArray } from '../../utils/copyArray';

import Input from '../../ui/form/Input';


const None = ({ handleChange }) => {
  const [values, setValues] = useState([
    {
      id: 1,
      value: "",
      placeholder: "e.g. Certificate in Health and Social Care",
      label: "Qualifications",
      hint: "Let us know any qualifications you may have."
    },
    {id: 2, value: "", placeholder: "e.g. Award in IT User Skills"},
    {id: 3, value: "", placeholder: ""},
  ]);

  const updateField = (val, id) => {
    const v = copyArray(values);
    let addValue = false;
    // eslint-disable-next-line no-unused-vars
    for (const value of v) {
      if (value.id === id) {
        value.value = val;
      }

      if (id === values.length) addValue = true;
    }
    if (addValue) v.push({id: values.length + 1, value: "" });
    setValues(v);

    let otherQualifications = [];
    // eslint-disable-next-line no-unused-vars
    for (const item of v) {
      if (item.value !== "") otherQualifications.push(`${item.value}`);
    }
    handleChange(otherQualifications);
  };

  return (
    <>
      {values.map(item => (
        <InputWrap key={item.id}>
          <Input
            label={item.label}
            hint={item.hint}
            placeholder={item.placeholder}
            id={item.id}
            handleChange={(it) => updateField(it, item.id)}
          />
        </InputWrap>
      ))}
    </>
  );
};

export default None;

const InputWrap = styled.div`
  margin-bottom: 12px;
`;
