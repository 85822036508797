import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BgThreeDots from '../../ui/bg/BgThreeDots';
import Header from '../../components/Header';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { H3, P } from '../../ui/typography/Typography';
import layout from '../../config/layout';
import ReactHtmlParser from 'react-html-parser';


const ThreeDotsBgLayout = ({ title, subtitle, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <BgThreeDots />
      <Header logo_alignment="center" />
      <Grid>
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            {title && (<Title align="center" hasSubtitle={subtitle}>{title}</Title>)}
            {subtitle && (<P align="center">{ReactHtmlParser(subtitle)}</P>)}
          </Col>
        </Row>
        {children}
      </Grid>
    </Container>
  );
};

export default ThreeDotsBgLayout;

ThreeDotsBgLayout.propTyoes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

const Container = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`;

const Title = styled(H3)`
  margin-bottom: ${props => props.hasSubtitle ? '8px' : '32px'};
  margin-top: 46px;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 12px;
  }
`;
