/* Main dashboard screen when people login
   Dashboard login flow: Homepage > Organisations > Login > Enter email/password > Dashboard */

import React, { useEffect, useState, useLayoutEffect, createRef } from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { useHistory, Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import axios from 'axios';

import { useStateValue } from '../../services/state';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { createOrgSubmitData, getPriceText, capitalizeFirstLetter } from '../../utils/helper';
import { copyArray } from '../../utils/copyArray';

import { MoreLinkA } from '../../ui/buttons/Link';

import TopBar from '../../components/TopBar';
import { CircleRed, SemiCircleLCGPurple } from '../../ui/bg/Shapes';
import { P, H3, H2, H5, Error, HintText } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
import LoadingScreen from '../LoadingScreen';
import downloadIcon from '../../assets/download_blue.png';
import SelectBar from '../../components/SelectBar';

const DashboardScreen = () => {

  const history = useHistory();

  const [{ context, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ screenSize, setScreenSize ] = useState(null);

  const [ linkCopied, setLinkCopied ] = useState(null);
  const [ emailSendMessages, setEmailSendMessages ] = useState([]);
  const [ sendingEmail, setSendingEmail ] = useState(null);
  const [ downloadingReport, setDownloadingReport ] = useState(null);
  const [ exportingUserDetails, setExportingUserDetails ] = useState(null);
  const [ downloadingUserDetails, setDownloadingUserDetails ] = useState(null);
  const [ usersArchivedLocally, setUsersArchivedLocally ] = useState([]);
  const [ usersRestoredLocally, setUsersRestoredLocally ] = useState([]);
  const [ logoUpdateError, setLogoUpdateError ] = useState(null);
  const [ localLogo, setLocalLogo ] = useState(null);

  const [ filterValues, setFilterValues ] = useState([]);

  const [ filterType, setFilterType ] = useState(null);

  const [ showMoreCareersOfInterest, setShowMoreCareersOfInterest ] = useState(false);

  const inputFile = createRef();

  const addChangeLogo = () => {
    inputFile.current.click();
  }

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let input = event.target;
    if (!input) {
      setLogoUpdateError("Error: Your browser does not properly implement the event object.");
    } else if (!input.files) {
      setLogoUpdateError("Error: Your browser does not support the `files` property of the file input.");
    } else if (!input.files[0]) {
      setLogoUpdateError("Error: No file selected.");
    } else {
      let file = input.files[0];
      let fileType = null;
      if (file && file.type) {
        fileType = file.type.toLowerCase();
      }
      if (!fileType || (!fileType.includes("png") && !fileType.includes("gif") && !fileType.includes("jpg") && !fileType.includes("jpeg") && !fileType.includes("svg"))) {
        setLogoUpdateError("Error: You must select an image in one of these formats: png, gif, jpg, jpeg or svg");
      } else {
        let fileNameSuffix = "";
        if (fileType.includes("png")) {
          fileNameSuffix = ".png";
        } else if (fileType.includes("gif")) {
          fileNameSuffix = ".gif";
        } else if (fileType.includes("jpg")) {
          fileNameSuffix = ".jpg";
        } else if (fileType.includes("jpeg")) {
          fileNameSuffix = ".jpeg";
        } else if (fileType.includes("svg")) {
          fileNameSuffix = ".svg";
        }
        let fr = new FileReader();
        fr.readAsText(file, "UTF-8");
        fr.onload = function(evt) {
          let fileName = context.organisation_id ? context.organisation_id : context.education_institution_id;
          if (fileName) {
            // file.lastModifiedDate = new Date();
            // file.name = fileName;
            let folderName = context.education_institution_id ? "careersPro-institution-logos" : "careersPro-organisation-logos";
            azureFileUpload(file, folderName, fileName + fileNameSuffix);
            // awsFileUpload(file, folderName, fileName);
          } else {
            setLogoUpdateError("Error: Image failed to upload (no organisation or education institution id - please contact careers-pro@learningcurvegroup.co.uk with this error)");
            console.log("Error: No organisation or education institution id");
          }
        }
      }
    }
    // Reset the form in case the same file is uploaded again
    event.target.value = '';
  }

  const azureFileUpload = (file, folderName, fileName, fileType) => {
    axios.put(`/api/v1/azure_file_upload?folder-name=${folderName}&file-name=${fileName}&file-type=${fileType}`, file).then((res) => {
      if (res.status === 200) {
        console.log("success - logo updated at URL: " + res.data.url);
        logoUploaded(res.data.url);
      } else {
        console.log("failed");
        setLogoUpdateError("Error: Image failed to upload");
      }
    }).catch((error) => {
      console.log("error");
      setLogoUpdateError("Error: Image failed to upload");
      if (error.response && error.response.status === 401) {
        console.log("401 error");
      }
    });
  }

  // const awsFileUpload = (file, folderName, fileName) => {
  //   axios.get("/api/v1/sign-s3-put?file-name=" + fileName + "&file-type=" + file.type +
  //             "&file-size=" + file.size + "&folder-name=" + folderName, {
  //   }).then((res) => {
  //     if (res.data.signedRequest) {
  //       console.log("signedRequest = " + res.data.signedRequest);
  //       let options = {
  //         headers: {
  //           'Content-Type': file.type
  //         }
  //       };
  //       axios.put(res.data.signedRequest, file, options)
  //       .then(result => {
  //         console.log("Successful S3 upload at " + res.data.url);
  //         logoUploaded(res.data.url);
  //       })
  //       .catch(error => {
  //         console.error("S3 Put Error " + JSON.stringify(error));
  //         setLogoUpdateError("Error: Image failed to upload");
  //       });
  //     } else {
  //       console.log("Error: Image failed to upload");
  //     }
  //   });
  // }

  const logoUploaded = (url) => {
    setLogoUpdateError(null);
    // Save logo URL against organisation, then save it locally to update it visually
    console.log(url);
    setLocalLogo(url + "?random_number=" + Math.floor(Math.random()*1000000));
    const orgSubmitData = createOrgSubmitData({
      organisation_id: context.organisation_id,
      education_institution_id: context.education_institution_id,
      logo_url: url,
    });
    axios.post('/api/v1/save_logo_url', orgSubmitData).then((res) => {});
  }

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetScreenData('organisations/dashboard', setIsLoading, null, null, {
    organisation_user_id: localStorage.getItem("organisation_user_id"),
    session_id: localStorage.getItem("session_id"),
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const resp = await fetchData('organisations/dashboard', null, null, {
        organisation_user_id: localStorage.getItem("organisation_user_id"),
        session_id: localStorage.getItem("session_id"),
      });
      if (resp.status === 200) {
        dispatch({type: 'updateResponse', data: resp.data});
        if (resp.data.screen.name !== "organisations/dashboard") {
          let dataUrl = `/${resp.data.screen.name}`;
          history.push(dataUrl);
        } else {
          setIsLoading(false);
        }
      }
    };
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    let filterValuesToSet = [];
    if (context && context.is_school) {
      filterValuesToSet = [
        {label: "All Years", id: "all"},
        {label: "Year 5", id: "year_5"},
        {label: "Year 6", id: "year_6"},
        {label: "Year 7", id: "year_7"},
        {label: "Year 8", id: "year_8"},
        {label: "Year 9", id: "year_9"},
        {label: "Year 10", id: "year_10"},
        {label: "Year 11", id: "year_11"},
        {label: "Year 12", id: "year_12"},
        {label: "Year 13", id: "year_13"},
      ];
    } else {
      filterValuesToSet = [
        {label: "Active", id: "active"},
        {label: "Archived", id: "archived"},
      ];
    }
    setFilterValues(filterValuesToSet);
    setFilterType(filterValuesToSet.length > 0 ? filterValuesToSet[0] : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (item) => {
    setFilterType(item);
  }

  const setArchived = (user, isArchived) => {

    // Local changes
    const usersArchivedLocallyCopy = copyArray(usersArchivedLocally);
    const usersRestoredLocallyCopy = copyArray(usersRestoredLocally);
    if (isArchived) {
      usersArchivedLocallyCopy[user.key] = true;
      usersRestoredLocallyCopy[user.key] = null;
    } else {
      usersArchivedLocallyCopy[user.key] = null;
      usersRestoredLocallyCopy[user.key] = true;
    }
    setUsersArchivedLocally(usersArchivedLocallyCopy);
    setUsersRestoredLocally(usersRestoredLocallyCopy);

    // Database changes
    const orgSubmitData = createOrgSubmitData({
      user_id: user.id,
      archived: isArchived,
    });
    axios.post('/api/v1/update_user_archived_status', orgSubmitData).then((res) => {});

  }

  const sendEmail = (userId) => {
    setSendingEmail(userId);
    const orgSubmitData = createOrgSubmitData({
      user_id: userId,
    });
    axios.post('/api/v1/send_email_invitation', orgSubmitData).then((res) => {
      if (res.status === 200) {
        emailSendMessages[userId] = res.data;
        setEmailSendMessages(emailSendMessages);
        setSendingEmail(null);
      } else {
        emailSendMessages[userId] = {
          success: false,
          message: "Failed to send email",
        };
        setEmailSendMessages(emailSendMessages);
        setSendingEmail(null);
      }
    }).catch((error) => {
      emailSendMessages[userId] = {
        success: false,
        message: "Failed to send email",
      };
      setEmailSendMessages(emailSendMessages);
      setSendingEmail(null);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("organisation_user_id");
        localStorage.removeItem("session_id");
        history.push(`/organisations/login`);
      }
    });
  }

  const exportUserDetails = async () => {
    if (context.is_college) {
      if (context.education_institution_id >= 0) {
        setExportingUserDetails(true);
        const resp = await axios({
          url: `/api/v1/exportFECollegeUserDetails?organisation_user_id=${localStorage.getItem("organisation_user_id")}&session_id=${localStorage.getItem("session_id")}&education_institution_id=${context.education_institution_id}`,
          method: 'GET',
          responseType: 'blob',
        });
        if (resp && resp.status === 200) {
          let filename = context.organisation_name + ".csv";
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          setExportingUserDetails(false);
        } else {
          setExportingUserDetails(false);
        }
      }
    } else if (context.is_school) {
        if (context.education_institution_id >= 0) {
        setExportingUserDetails(true);
        const resp = await axios({
          url: `/api/v1/exportFECollegeUserDetails?organisation_user_id=${localStorage.getItem("organisation_user_id")}&session_id=${localStorage.getItem("session_id")}&education_institution_id=${context.education_institution_id}`,
          method: 'GET',
          responseType: 'blob',
        });
        if (resp && resp.status === 200) {
          let filename = context.organisation_name + ".csv";
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          setExportingUserDetails(false);
        } else {
          setExportingUserDetails(false);
        }
      }
    } else {
      if (context.organisation_id !== null) {
        setExportingUserDetails(true);
        const resp = await axios({
          url: `/api/v1/exportStandardUserDetails?organisation_user_id=${localStorage.getItem("organisation_user_id")}&session_id=${localStorage.getItem("session_id")}&organisation_id=${context.organisation_id}`,
          method: 'GET',
          responseType: 'blob',
        });
        if (resp && resp.status === 200) {
          let filename = context.organisation_name + ".csv";
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          setExportingUserDetails(false);
        } else {
          setExportingUserDetails(false);
        }
      }
    }
  }

  const downloadUserDetails = async (careerId, careerName) => {
    if (careerId >= 0 && context.education_institution_id >= 0 && filterType) {
      setDownloadingUserDetails(careerId);
      const resp = await axios({
        url: `/api/v1/getUserDetails?organisation_user_id=${localStorage.getItem("organisation_user_id")}&session_id=${localStorage.getItem("session_id")}&career_id=${careerId}&education_institution_id=${context.education_institution_id}&school_year=${filterType.id}`,
        method: 'GET',
        responseType: 'blob',
      });
      if (resp && resp.status === 200) {
        let filename = context.organisation_name + " - " + careerName + " - " + filterType.label + ".csv";
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingUserDetails(null);
      } else {
        setDownloadingUserDetails(null);
      }
    }
  }

  const downloadReport = async (userId) => {
    setDownloadingReport(userId);
    const resp = await axios({
      url: `/api/v1/getCareerReport?user_id=${userId}`,
      method: 'GET',
      responseType: 'blob',
    });
    if (resp && resp.status === 200) {
      let filename = "Career Report.pdf";
      let contentDispositionHeaders = resp.headers["content-disposition"].split(";");
      for (let i=0; i<contentDispositionHeaders.length; i++) {
        if (contentDispositionHeaders[i].includes("filename")) {
          filename = contentDispositionHeaders[i].split("=")[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setDownloadingReport(null);
    } else {
      setDownloadingReport(null);
    }
  }

  if (loading || isLoading || !context) return <LoadingScreen />;

  let introTitleMessage = "Welcome to your new dashboard!";
  let introMessages = [];
  let lastIntroMessages = [];
  if (context.prepay && context.num_licences === 0) {
    introMessages.push("Once users are added, you can automatically send them an email to invite them to use CareersPro, or copy their link and send your own email to them.");
  } else if (context.education_institution_id !== null) {
    if (context.num_licences > 0 || context.num_licences === -1) {
      introMessages.push("To get started, share the link below with your " + context.user_type + "s.");
    }    
    if (context.is_college && (context.num_licences > 0 || context.num_licences === -1)) {
      lastIntroMessages.push("Or you can add them below along with all their details, and send them email invitations.");
    }
  } else {
    introMessages.push("You can add users by clicking the button below.");
    introMessages.push("Once users are added, you can automatically send them an email to invite them to use CareersPro, or copy their link and send your own email to them.");
  }

  let usersToShow = [];
  if (context && context.users) {
    for (let i=0; i<context.users.length; i++) {
      if (!filterType || filterType.id === "all") {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "active" && (usersRestoredLocally[context.users[i].key] || (!usersArchivedLocally[context.users[i].key] && !context.users[i].archived) ) ) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "archived" && (usersArchivedLocally[context.users[i].key] || (!usersRestoredLocally[context.users[i].key] && context.users[i].archived) ) ) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_5" && context.users[i].school_year === 5) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_6" && context.users[i].school_year === 6) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_7" && context.users[i].school_year === 7) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_8" && context.users[i].school_year === 8) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_9" && context.users[i].school_year === 9) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_10" && context.users[i].school_year === 10) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_11" && context.users[i].school_year === 11) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_12" && context.users[i].school_year === 12) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "year_13" && context.users[i].school_year === 13) {
        usersToShow.push(context.users[i]);
      } else if (filterType.id === "alumni" && context.users[i].school_year > 13) {
        usersToShow.push(context.users[i]);
      }
    }
  }

  let topCareers = [];

  if (context && context.top_careers && context.top_careers.length > 0) {

    for (let i=0; i<context.top_careers.length; i++) {
      var newCareer = {};
      newCareer["career_id"] = context.top_careers[i].career_id;
      newCareer["career_name"] = context.top_careers[i].career_name;
      if (filterType.id === "year_9") {
        newCareer["user_count"] = context.top_careers[i].year_9_count;
      } else if (filterType.id === "year_10") {
        newCareer["user_count"] = context.top_careers[i].year_10_count;
      } else if (filterType.id === "year_11") {
        newCareer["user_count"] = context.top_careers[i].year_11_count;
      } else if (filterType.id === "year_12") {
        newCareer["user_count"] = context.top_careers[i].year_12_count;
      } else if (filterType.id === "year_13") {
        newCareer["user_count"] = context.top_careers[i].year_13_count;
      } else if (filterType.id === "alumni") {
        newCareer["user_count"] = context.top_careers[i].alumni_count;
      } else {
        newCareer["user_count"] = context.top_careers[i].all_count;
      }
      if (newCareer["user_count"] > 0) {
        topCareers.push(newCareer);
      }
    }

    topCareers.sort(function(a, b) {
      return b.user_count - a.user_count;
    });

  }

  let topCareersToShow = [];
  let numCareersToShow = showMoreCareersOfInterest ? 20 : 5;
  for (let i=0; i<topCareers.length && i<numCareersToShow; i++) {
    topCareersToShow.push(topCareers[i]);
  }

  const menuItems = [
    { menu_name: "Manage team", key: 0, href: "/organisations/team", colleges_only: false },
    { menu_name: "Update course links", key: 1, href: "/organisations/courses", colleges_only: true },
  ];

  return(
    <>
      <MetaTags>
        <title>CareersPro - Dashboard</title>
        <meta id="meta-description" name="description" content="CareersPro - Dashboard" />
      </MetaTags>
      <Container>
      <TopBar pageTitle={context.organisation_name} pageWelcome={`Welcome, ${context.organisation_user_first_name}`} showLogout={true} sticky logoLink="/organisations/dashboard" logoutEndpoint={context.endpoint} showMenuIcon={context.admin} menuItems={menuItems} isCollege={context.is_college} />
        <CircleOne />
        <CircleTwo elevated={context.education_institution_id && context.users.length !== 0} />
        {context.admin && (
          <>
            <ManagementButtons>
              <Center>
                {!context.internal && (
                  <>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e) => onChangeFile(e)} />
                    <AddChangeLogoButton small={true} onClick={() => addChangeLogo()}>{(context.organisation_logo || localLogo) ? `Change logo` : `Add logo`}</AddChangeLogoButton>
                  </>
                )}
              </Center>
            </ManagementButtons>
            {logoUpdateError && (<ErrorStyled>{logoUpdateError}</ErrorStyled>)}
          </>
        )}
        <Center>
          {localLogo && (
            <OrganisationLogo src={localLogo} alt={context.organisation_name + " logo"}></OrganisationLogo>
          )}
          {(!localLogo && context.organisation_logo) && (
            <OrganisationLogo src={context.organisation_logo} alt={context.organisation_name + " logo"}></OrganisationLogo>
          )}
        </Center>
        {context.users && context.users.length === 0 && (context.admin || context.education_institution_id) && !context.internal && (
          <WelcomeTitle>{introTitleMessage}</WelcomeTitle>
        )}
        {context.num_licences !== -1 && !context.internal && (
          <>
            <Text pt>You have {context.num_licences.toLocaleString()} licence{context.num_licences !== 1 ? `s` : ``} remaining.</Text>
            <Text pt>If you would like to purchase more licences then contact your LCG Regional Account Director.</Text>
          </>
        )}
        {context.num_licences === -1 && !context.internal && (
          <>
            <Text pt>You have unlimited licences.</Text>
          </>
        )}
        {context.users && context.users.length === 0 && (context.admin || context.education_institution_id) && !context.internal && introMessages.map((message, key) => (
          <Text pt mw="850px" key={key}>{message}</Text>
        ))}
        {context.internal && (
          <ButtonContainer>
            <Link to={`/organisations/create-signup-link`}>
              <PrimaryButton>Create new Educational institution dashboard</PrimaryButton>
            </Link>
          </ButtonContainer>
        )}
        {context.internal && (
          <ButtonContainer>
            <Link to={`/organisations/signup`}>
              <PrimaryButton>Create new standard dashboard</PrimaryButton>
            </Link>
          </ButtonContainer>
        )}
        {!context.prepay && !context.internal && context.admin && !context.education_institution_id && (
          <InvoiceContainer>
            <Text pb>You will be invoiced {getPriceText(context.per_user_net_price_pence_for_invoicing)} + VAT per user added</Text>
            <Link to={`/organisations/invoices`}>
              <SecondaryButton small={true}>View invoices</SecondaryButton>
            </Link>
          </InvoiceContainer>
        )}
        {(context.num_licences > 0 || context.num_licences === -1) && context.link && context.link_readable && (
          <LinkContainer no_margin={lastIntroMessages.length > 0}>
            <LinkTable>
              <tbody>
                <tr>
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <LinkCell left></LinkCell>
                  )}
                  <LinkCell><Text bold no_padding>{context.link_readable}</Text></LinkCell>
                  <LinkCell right>
                    <SecondaryButton
                      small
                      onClick={() => {
                        navigator.clipboard.writeText(context.link);
                      }}>
                      Copy
                    </SecondaryButton>
                  </LinkCell>
                </tr>
              </tbody>
            </LinkTable>  
          </LinkContainer>          
        )}
        {/* {context.link && context.link_readable && !context.internal && (
          <LinkContainer no_margin={context.users && context.users.length === 0 && (context.admin || context.education_institution_id) && lastIntroMessages.length > 0}>
            {context.users && context.users.length > 0 && !context.is_college && (
              <>
                <SubTitle>Link</SubTitle>
                <HintTextStyled>Share this link with your {context.user_type}s to give them access to CareersPro</HintTextStyled>
              </>
            )}
            {context.users && context.users.length > 0 && context.is_college && (
              <>
                <SubTitle>Link</SubTitle>
                <Text>Share this link with your {context.user_type}s to give them access. Or you can add them below along with all their details, and send them email invitations.</Text>
              </>
            )}
            {context.num_licences > 0 || context.num_licences === -1 && (
              <LinkTable>
                <tbody>
                  <tr>
                    {screenSize > layout.numericalBreakpoints.SM && (
                      <LinkCell left></LinkCell>
                    )}
                    <LinkCell><Text bold no_padding>{context.link_readable}</Text></LinkCell>
                    <LinkCell right>
                      <SecondaryButton
                        small
                        onClick={() => {
                          navigator.clipboard.writeText(context.link);
                        }}>
                        Copy
                      </SecondaryButton>
                    </LinkCell>
                  </tr>
                </tbody>
              </LinkTable>
            )}            
          </LinkContainer>
        )} */}
        {context.users && context.users.length === 0 && (context.admin || context.education_institution_id) && !context.internal && lastIntroMessages.map((message, key) => (
          <LastIntroMessagesContainer>
            <Text pt mw="850px" key={key}>{message}</Text>
          </LastIntroMessagesContainer>
        ))}
        {(!context.education_institution_id || context.is_college) && !context.internal && (
          <>
           <SubTitle>Users</SubTitle>
            <ButtonContainer>
              {(context.num_licences > 0 || context.num_licences === -1) && !context.internal ? (
                <>
                  <Link to={`/organisations/add_users`}>
                    <PrimaryButton>Add users</PrimaryButton>
                  </Link>
                  {context.is_college && (
                    <HintTextStyled pt>Even if students use the link above, you can import their details by adding them, and their entries will be updated for reporting and exporting</HintTextStyled>
                  )}
                </>
              ) : (
                <Tooltip text="Purchase more user licences first">
                  <GrayButton>Add users</GrayButton>
                </Tooltip>
              )}
            </ButtonContainer>
          </>
        )}
        {topCareersToShow.length > 0 && !context.internal && (
          <ContentContainer>
            <SubTitle>Top {Math.min(topCareers.length, 20)} careers of interest</SubTitle>
            <UsersTable>
              <thead>
                <TableRow header>
                  {screenSize > layout.numericalBreakpoints.VSM && (
                    <TableHeaderCell pl><TableText header>Rank</TableText></TableHeaderCell>
                  )}
                  <TableHeaderCell pl={screenSize <= layout.numericalBreakpoints.VSM}><TableText header>Career</TableText></TableHeaderCell>
                  <TableHeaderCell pr={screenSize <= layout.numericalBreakpoints.SM}><TableText header># {context.user_type}s</TableText></TableHeaderCell>
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableHeaderCell pr={screenSize > layout.numericalBreakpoints.SM}><TableText header>{capitalizeFirstLetter(context.user_type)} details</TableText></TableHeaderCell>
                  )}
                </TableRow>
              </thead>
              <tbody>
                {topCareersToShow.map((item, key) => (
                  <TableRow key={key} id={key}>
                    {screenSize > layout.numericalBreakpoints.VSM && (
                      <TableCell pl><TableText>{key+1}.</TableText></TableCell>
                    )}
                    <TableCell pl={screenSize <= layout.numericalBreakpoints.VSM}><TableText>{item.career_name}</TableText></TableCell>
                    <TableCell pr={screenSize <= layout.numericalBreakpoints.SM}><TableText>{item.user_count}</TableText></TableCell>
                    {screenSize > layout.numericalBreakpoints.SM && (
                      <TableCell pr={screenSize > layout.numericalBreakpoints.SM}>
                        {downloadingUserDetails === item.career_id ? (
                          <TableText>Downloading...</TableText>
                        ) : (
                        <SecondaryButton
                          small
                          onClick={() => {
                            downloadUserDetails(item.career_id, item.career_name);
                          }}>
                          {capitalizeFirstLetter(context.user_type)} details <DownloadImage src={downloadIcon} alt="" />
                        </SecondaryButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </tbody>
            </UsersTable>
            {topCareers.length > 5 && (
              <LinkWrap>
                <MoreLinkA onClick={() => setShowMoreCareersOfInterest(!showMoreCareersOfInterest)} arrowUp={showMoreCareersOfInterest ? false : true}>{showMoreCareersOfInterest ? 'Show less' : 'Show more'}</MoreLinkA>
              </LinkWrap>
            )}
          </ContentContainer>
        )}
        {context.users.length > 0 && !context.internal && (
          <ContentContainer>
            <SubTitle>{capitalizeFirstLetter(context.user_type)} results</SubTitle>
            <ButtonContainer>
              {exportingUserDetails ? (
                <TableText>Exporting...</TableText>
              ) : (
                <PrimaryButton onClick={() => {exportUserDetails();}}>Export {capitalizeFirstLetter(context.user_type)} details</PrimaryButton>
              )}
            </ButtonContainer>
            {screenSize > layout.numericalBreakpoints.SM && filterValues.length > 0 && (
              <GridStyledForFilter>
                <FilterContainer>
                  <FilterLabel>Filter by</FilterLabel>
                  <SelectBar
                    values={filterValues}
                    handleChange={(item) => handleFilterChange(item)}
                    selectedValue={filterValues[0]}
                    ignoreScreenSize
                  />
                </FilterContainer>
              </GridStyledForFilter>
            )}
            <UsersTable>
              <thead>
                <TableRow header>
                  <TableHeaderCell pl><TableText header>Name</TableText></TableHeaderCell>
                  {screenSize > layout.numericalBreakpoints.SM && (
                    <TableHeaderCell><TableText header>Email</TableText></TableHeaderCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.SM && (//context.is_college && ( - Temporarily removed
                    <TableHeaderCell><TableText header>Student ID</TableText></TableHeaderCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.SM && context.is_school && (
                    <TableHeaderCell><TableText header>Year</TableText></TableHeaderCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.VSM && (
                    <TableHeaderCell><TableText header>Link</TableText></TableHeaderCell>
                  )}
                  {screenSize > layout.numericalBreakpoints.SM && (!context.education_institution_id || context.is_college) && (
                    <TableHeaderCell><TableText header>Invite</TableText></TableHeaderCell>
                  )}
                  <TableHeaderCell pr={screenSize <= layout.numericalBreakpoints.SM}><TableText header>{context.education_institution_id && !context.is_college ? `Career report` : `Status`}</TableText></TableHeaderCell>
                  {screenSize > layout.numericalBreakpoints.SM && !context.is_school && (
                    <TableHeaderCell pr><TableText header>Archive</TableText></TableHeaderCell>
                  )}
                </TableRow>
              </thead>
              <tbody>
                {usersToShow.map((item, key) => (
                  <TableRow key={key} id={key}>
                    <TableCell pl><TableText>{item.name}</TableText></TableCell>
                    {screenSize > layout.numericalBreakpoints.SM && (
                      <TableCell><TableText>{item.email}</TableText></TableCell>
                    )}
                    {screenSize > layout.numericalBreakpoints.SM && ( //context.is_college && ( - Temporarily removed
                      <TableCell><TableText>{item.student_id ? item.student_id : ""}</TableText></TableCell>
                    )}
                    {screenSize > layout.numericalBreakpoints.SM && context.is_school && (
                      <TableCell><TableText>{item.school_year <= 13 ? item.school_year : "Alumni"}</TableText></TableCell>
                    )}
                    {screenSize > layout.numericalBreakpoints.VSM && (
                      <TableCell>
                        {linkCopied === key ? (
                          <TableText>Copied</TableText>
                        ) :
                        (
                          <SecondaryButton
                            small
                            onClick={() => {
                              navigator.clipboard.writeText(item.link);
                              setLinkCopied(key)
                            }}>
                            Copy
                          </SecondaryButton>
                        )}
                      </TableCell>
                    )}
                    {screenSize > layout.numericalBreakpoints.SM && (!context.education_institution_id || context.is_college) && (
                      item.status.name !== "Completed" ? (
                        <TableCell>
                          {sendingEmail === item.id ? (
                            <TableText>Sending email...</TableText>
                          ) :
                          (emailSendMessages[item.id] ? (
                            <TableText error={!emailSendMessages[item.id].success}>{emailSendMessages[item.id].message}</TableText>
                          ) :
                          (!item.invite_sent ? (
                            <SecondaryButton
                              small
                              onClick={() => {
                                sendEmail(item.id)
                              }}>
                              Send email
                            </SecondaryButton>
                          ) :
                          (
                            <SecondaryButton
                              small
                              onClick={() => {
                                sendEmail(item.id)
                              }}>
                              Resend email
                            </SecondaryButton>
                          )))}
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )
                    )}
                    <TableCell pr={screenSize <= layout.numericalBreakpoints.SM}>
                      {item.status.name === "Completed" ? (
                        downloadingReport === item.id ? (
                          <TableText>Downloading...</TableText>
                        ) : (
                        <SecondaryButton
                          small
                          onClick={() => {
                            downloadReport(item.id);
                          }}>
                          Report <DownloadImage src={downloadIcon} alt="" />
                        </SecondaryButton>
                        )
                      ) : (
                        <TableText status_id={item.status.id}>{item.status.name}</TableText>
                      )}
                    </TableCell>
                    {screenSize > layout.numericalBreakpoints.SM && !context.is_school && filterType && (
                      <TableCell pr>
                        <SecondaryButton
                          small
                          onClick={() => {
                            setArchived(item, (filterType.id === "active" ? true : false));
                          }}>
                          {filterType.id === "active" ? `Archive` : `Restore`}
                        </SecondaryButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </tbody>
            </UsersTable>
          </ContentContainer>
        )}
        {usersToShow.length === 0 && filterType && filterType.id === "active" && context.organisation_id && !context.internal && (
          <Text italic mw="850px">Add users by clicking on the button above.</Text>
        )}
        {usersToShow.length === 0 && filterType && filterType.id === "archived" && context.organisation_id && !context.internal && (
          <Text italic mw="850px">Users that you remove will appear here.</Text>
        )}
        {usersToShow.length === 0 && filterType && filterType.id === "active" && context.education_institution_id && !context.internal && !context.is_college && (
          <>
            <Text italic mw="850px">No {context.user_type}s have completed the career assessment yet.</Text>
            <Text italic mw="850px">Share the link above to give them access.</Text>
          </>
        )}
        {usersToShow.length === 0 && filterType && filterType.id === "archived" && context.education_institution_id && !context.internal && (
          <Text italic mw="850px">{capitalizeFirstLetter(context.user_type)}s that you remove will appear here.</Text>
        )}
        {usersToShow.length === 0 && context.is_school && !context.internal && (
          <>
            <Text italic mw="850px">No {filterType && filterType.id !== "all" ? filterType.label.toLowerCase() : ""} {context.user_type}s have completed the career assessment yet.</Text>
            <Text italic mw="850px">Share the link above to give them access.</Text>
          </>
        )}
      </Container>
    </>
  );
};

export default DashboardScreen;


const Container = styled.div`
  position: relative;
  padding-bottom: 32px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 20px;
  }
`;

const ContentContainer = styled.div`
  padding-bottom: 40px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 32px;
  }
`;

const CircleOne = styled(CircleRed)`
  left: -60px;
  top: 210px;

  @media(max-width: ${layout.breakpoints.MD}) {
    left: -100px;
  }
`;

const CircleTwo = styled(SemiCircleLCGPurple)`
  right: 0px;
  ${props => props.elevated ? `top: 200px` : `top: 250px`};
`;

const InvoiceContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`

const Text = styled(P)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  ${props => !props.no_padding && `padding-left: 40px;`}
  ${props => !props.no_padding && `padding-right: 40px;`}
  ${props => `max-width: ${props.mw ? props.mw: "600px"};`}
  margin: auto;
  ${props => props.italic && 'font-style: italic;'};
  ${props => props.bold && 'font-style: bold;'};
  ${props => props.wrap && 'overflow-wrap: anywhere;'};

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;

const SubTitle = styled(H3)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const WelcomeTitle = styled(H2)`
  text-align: center;
  padding: 20px 0 12px 0;
  margin-bottom: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 20px 0 8px 0;
    margin-bottom: 4px;
  }
`;

const HintTextStyled = styled(HintText)`
  padding: 0 32px 0 32px;
  ${props => props.pt && `padding-top: 12px;`}
  max-width: 600px;
  margin: auto;
`

const LastIntroMessagesContainer = styled.div`
  margin-bottom: 32px;
`

const LinkContainer = styled.div`
  text-align: center;
  margin: 20px 0 ${props => props.no_margin ? `0` : `32px`};
`

const LinkTable = styled(Table)`
  margin: auto;
  border-collapse: collapse;
`;

const LinkCell = styled.td`
  ${props => props.left && `width: 30%;`}
  ${props => props.right && `width: 30%; text-align: left;`}
  padding: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 8px;
  }
`

const UsersTable = styled(Table)`
  margin: auto;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  ${props => `padding: 20px ${props.pr ? "32px": "20px"} 20px ${props.pl ? "32px": "20px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  text-align: center;
  }
`

const TableRow = styled.tr`
  ${props => props.header && `background-color: ${colors.BG_LIGHT_GRAY};`}
  ${props => !props.header && props.id % 2 === 0 && `background-color: ${colors.WHITE};`}
`

const TableText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin: auto;
  ${props => props.header && "font-weight: bold;"}
  ${props => props.status_id === 0 && `color: ${colors.LCG_LIGHT_GREY};`}
  ${props => props.status_id === 1 && `color: ${colors.LCG_LIGHT_PURPLE};`}
  ${props => props.status_id === 2 && `color: ${colors.LCG_DARK_PURPLE};`}
  ${props => props.error && `color: ${colors.LCG_DARK_PURPLE};`}
`

const TableCell = styled.td`
  ${props => `padding: 20px ${props.pr ? "32px": "20px"} 20px ${props.pl ? "32px": "20px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  text-align: center;
  }
`

const DownloadImage = styled.img`
  position: relative;
  max-width: 20px;
  margin-left: 6px;
  top: 3px;
  
  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 17px;
    margin-left: 4px;
    top: 3px;
  }
`;

const ManagementButtons = styled.div`
  margin: 0 20px 0 20px;
  display: flex;
  padding-top: 12px;
  padding-bottom: 8px;
`

const Center = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
`;

// const Right = styled.div`
//   margin: auto 0 auto auto;
//   text-align: right;
//   display: flex;
// `;

const AddChangeLogoButton = styled(SecondaryButton)`
  margin: 0 8px auto auto;
`

const GridStyledForFilter = styled.div`
  position: relative;
  max-width: 70rem;
  margin: auto;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px 12px 40px 0px;
`;

const FilterLabel = styled(H5)`
  margin: 0px 12px 0px 12px;
  padding-top: 8px;

  @media (max-width: ${layout.breakpoints.MD}) {
    text-align: center;
    margin: 0px 12px 6px 12px;
  }
`;

const OrganisationLogo = styled.img`
  max-width: 160px;
  max-height: 100px;
  margin: auto;
  padding-top: 12px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 140px;
    max-height: 88px;
  }
`;

const ErrorStyled = styled(Error)`
  margin: auto;
  text-align: center;
  display: flex;
`

const LinkWrap = styled.div`
  padding-top: 20px;
  text-align: center;
`;
