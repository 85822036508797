/* Generic career screen with "feedback=true" as query parameter >
   "Give feedback" screen that opens when you tap "Give feedback" button
   Example link: https://careerspro.co.uk/career/nurse?feedback=true */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import colors from '../../config/colors';

import { createSubmitData } from '../../utils/helper';
import { validateEmail } from '../../utils/validation';

import { PrimaryButton, LCGPurpleButton, GrayButton } from '../../ui/buttons/Buttons';
import Modal from '../../components/Modal';
import Input from '../../ui/form/Input';
import TextArea from '../../ui/form/TextArea';
import Tooltip from '../../components/Tooltip';
import { H3, P, Label } from '../../ui/typography/Typography';
import IconStarWhite from '../../assets/icons/icon-star-white.svg';
import IconStarGray from '../../assets/icons/icon-star-gray.svg';
import IconStar from '../../assets/icons/icon-star.svg';

const CareerFeedback = ({ user_id, career_id, pathway_id, num_pathways }) => {
  const [ open, setOpen ] = useState(false);
  const [ thanks, setThanks ] = useState(false);
  const [infoAccuracyStars, setInfoAccuracyStars] = useState(0);
  const [formValues, setFormValues] = useState({
      name: "",
      organisation: "",
      job_title: "",
      email: "",
      info_accuracy_rating: 0,
      incorrect_missing: "",
      improve: "",
    });

  const initialEmailErrorState = {
    name: null,
    organisation: null,
    job_title: null,
    email: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    handleInputChange(infoAccuracyStars, 'info_accuracy_rating');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoAccuracyStars]);

  const submitForm = () => {
    if (validateForm(true)) {
      const submitData = createSubmitData({
        career_id: career_id,
        pathway_id: pathway_id,
        career_feedback: formValues,
      });
      axios.post('/api/v1/handleCareerFeedbackInput/', submitData);

      setThanks(true);
      setFormValues({
        name: "",
        organisation: "",
        job_title: "",
        email: "",
        info_accuracy_rating: 0,
        incorrect_missing: "",
        improve: "",
      });
      setInfoAccuracyStars(0);
    }
  }

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "name" || name === "organisation" || name === "job_title") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    } else if ( name === "email") {
      const errorMsg = !validateEmail(value) ? "This is not a valid email" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let feedbackProvided = false;
    let errors = {...initialEmailErrorState};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if (key === "name" || key === "organisation" || key === "job_title" || key === "email") {
        if (value === "") {
          formValid = false;
          errors = {
            ...errors,
            [key]: "This field is required"
          }
        } else if (key === "email") {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        }
      } else {
        if (key === "info_accuracy_rating") {
          if (value > 0) {
            feedbackProvided = true;
          }
        } else if (key === "incorrect_missing" || key === "improve") {
          if (value !== "") {
            feedbackProvided = true;
          }
        }
      }
    }

    if (!feedbackProvided) {
      formValid = false;
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  return (
    <>
      <ButtonWrap nomargin={num_pathways === 0}>
        <LCGPurpleButton align="center" onClick={() => setOpen(true)}>Give feedback</LCGPurpleButton>
      </ButtonWrap>
      <Modal
        open={open && !thanks}
        handleClose={() => setOpen(false)}
        large
        hasCloseButton
      >
        <H3 align="center">Give feedback</H3>
        <P align="center">Help us improve our service by letting us know the accuracy of the information on this page and how it can be improved.</P>
        <Form>
          <InputWrap>
            <Label htmlFor={infoAccuracyStars}>How accurate is the information on this page?</Label>
            <Stars>
            {
              [...Array(5)].map((item, i) => (
                  <Star selected={infoAccuracyStars >= i + 1} key={i} onClick={() => setInfoAccuracyStars(i + 1)} />
              ))
            }
          </Stars>
          </InputWrap>
          <InputWrap>
            <TextArea type="text" handleChange={(it) => handleInputChange(it, 'incorrect_missing')} name="incorrect_missing" label="Is there anything incorrect or missing about the careers information and/or pathways?" error={formErrors['incorrect_missing']} defaultValue={formValues['incorrect_missing']} />
          </InputWrap>
          <InputWrap>
            <TextArea type="text" handleChange={(it) => handleInputChange(it, 'improve')} name="improve" label="How would you further improve the information on this page?" error={formErrors['improve']} defaultValue={formValues['improve']} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'name')} name="name" label="Name" error={formErrors['name']} inputValue={formValues['name']} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'organisation')} name="organisation" label="Organisation" error={formErrors['organisation']} inputValue={formValues['organisation']} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'job_title')} name="job_title" label="Job title" error={formErrors['job_title']} inputValue={formValues['job_title']} />
          </InputWrap>
          <InputWrap>
            <Input type="email" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} name="email" label="Email" error={formErrors['email']} inputValue={formValues['email']} />
          </InputWrap>
          <ButtonContainer>
            {!validateForm(false) ? (
              <Tooltip text="Please enter your details and feedback above">
                <GrayButton disabled>Submit</GrayButton>
              </Tooltip>
            ) : (
              <PrimaryButton onClick={() => submitForm()}>Submit</PrimaryButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      <Modal
        open={open && thanks}
        handleClose={() => {setOpen(false); setThanks(false)}}
        large
        hasCloseButton
      >
        <H3 align="center">Thank you!</H3>
        <P align="center">Thank you for helping us improve our service.</P>
        <Form>
          <ButtonContainer>
            <PrimaryButton onClick={() => {setOpen(false); setThanks(false)}}>Close</PrimaryButton>
          </ButtonContainer>
        </Form>
      </Modal>
    </>
  );
};

export default CareerFeedback;

const ButtonWrap = styled.div`
  text-align: center;
  margin: 32px 0 ${props => props.nomargin ? 0 : '32px'};
  padding-bottom: ${props => props.pb ? '60px' : 0};
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 32px;
  max-width: 576px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const Stars = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 248px;
  height: 48px;
  display: flex;
  margin: 8px 0 32px 0;
`;

const Star = styled.a`
  background-color: ${props => props.selected ? (props.submitted ? colors.TEXT_BODY : colors.LCG_DARK_PURPLE) : colors.GRAY_FADE};
  background-image: url(${props => props.selected ? IconStarWhite : (props.submitted ? IconStarGray : IconStar) });
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  flex: 1;
  height: 100%;
  margin-left: 2px;

  &:first-child {
    margin-left: 0;
  }
`;
