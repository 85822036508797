/* Final results > Email results (at top) */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from "@amplitude/react-amplitude";

import { createSubmitData } from '../../utils/helper';
import { validateEmail, doesEmailDomainMatch } from '../../utils/validation';
import { useStateValue } from "../../services/state";
import { copyArray } from '../../utils/copyArray';

import { PrimaryButton, SecondaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Modal from '../../components/Modal';
import Input from '../../ui/form/Input';
import Tooltip from '../../components/Tooltip';
import { H4, P, PSmall } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';

const UpdateDetails = () => {
  const [{ user_id, loading, context }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    student_id: "",
    email: "",
  });

  const initialEmailErrorState = {
    first_name: null,
    last_name: null,
    student_id: null,
    email: null,
  };

  const [ formErrors, setFormErrors ] = useState(initialEmailErrorState);

  useEffect(() => {
    if (context) {
      const formValuesCopy = copyArray(formValues);
      if (context.first_name) {
        formValuesCopy["first_name"] = context.first_name;
      }
      if (context.last_name) {
        formValuesCopy["last_name"] = context.last_name;
      }
      if (context.student_id) {
        formValuesCopy["student_id"] = context.student_id;
      }
      if (context.email) {
        formValuesCopy["email"] = context.email;
      }
      setFormValues(formValuesCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleInputChange = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submitForm = (logEvent) => {
    if (validateForm(true)) {
      setIsLoading(true);
      logEvent('Update Details');
      const submitData = createSubmitData({
        user_id: user_id,
        details: formValues,
      });
      axios.post('/api/v1/handleUpdateDetails/', submitData).then(res => {
        setIsLoading(false);
        setOpen(false);
        dispatch({type: "updateResponse", data:res.data});
      });
    }
  }

  const validateField = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "first_name"|| name === "last_name") {
      const errorMsg = value === "" ? "This field is required" : null;
      setFormErrors({
        ...formErrors,
        [name]: errorMsg
      })
    } else if ( name === 'email') {
      if (formValues["student_id"] === "") {
        const errorMsg = !validateEmail(value) ? "This is not a valid email" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      } else {
        const errorMsg = (!validateEmail(value) || !doesEmailDomainMatch(value, context.email_domains)) ? "You must provide your " + context.education_institution_name + " email" : null;
        setFormErrors({
          ...formErrors,
          [name]: errorMsg
        });
      }
    }

  };

  const validateForm = (setErrors) => {
    let formValid = true;
    let errors = {...initialEmailErrorState};

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(formValues)) {
      if (key === "first_name" || key === "last_name") {
        if (value === "") {
          formValid = false;
          errors = {
            ...errors,
            [key]: "This field is required"
          }
        }
      } else if ( key === 'email') {
        if (formValues["student_id"] === "") {
          if (!validateEmail(value)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "This is not a valid email"
            }
          }
        } else {
          if (!validateEmail(value) || !doesEmailDomainMatch(value, context.email_domains)) {
            formValid = false;
            errors = {
              ...errors,
              [key]: "You must provide your " + context.education_institution_name + " email"
            }
          }
        }
      }
    }

    if (setErrors) setFormErrors(errors);
    return formValid;
  };

  if (loading || isLoading) return <LoadingScreen />;

  return (
    <>
      <ButtonWrap>
        <SecondaryButton onClick={() => setOpen(true)}>Update your details</SecondaryButton>
      </ButtonWrap>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        large
        hasCloseButton
      >
        <H4 align="center">Update your details</H4>
        <P align="center">Update your contact details and student reference number</P>
        <Form>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'first_name')} name="first_name" label="First name" error={formErrors['first_name']} inputValue={context.first_name ? context.first_name  : ""} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'last_name')} name="last_name" label="Last name" error={formErrors['last_name']} inputValue={context.last_name ? context.last_name  : ""} />
          </InputWrap>
          <InputWrap>
            <Input type="text" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'student_id')} name="student_id" label="What is your student ID?" error={formErrors['student_id']} inputValue={context.student_id ? context.student_id  : ""} />
          </InputWrap>
          <InputWrap>
            <Input type="email" onBlur={validateField} handleChange={(it) => handleInputChange(it, 'email')} name="email" label={formValues["student_id"] === "" ? "Email address" : context.education_institution_name + " email"} error={formErrors['email']} inputValue={context.email ? context.email  : ""} />
          </InputWrap>
          <ButtonContainer>
            {!validateForm(false) ? (
              <Tooltip text="Please enter your details above">
                <GrayButton disabled>Update details</GrayButton>
              </Tooltip>
            ) : (
              <Amplitude>
                {({logEvent}) => (
                  <>
                    <PrimaryButton onClick={() => submitForm(logEvent)}>Update details</PrimaryButton>
                    <PrivacyText align="center">By tapping <b>Update details</b> you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></PrivacyText>
                  </>
                )}
              </Amplitude>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateDetails;

const ButtonWrap = styled.div`
  text-align: center;
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.div`
  margin: auto;
  margin-top: 12px;
  max-width: 352px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const PrivacyText = styled(PSmall)`
  margin-top: 10px;
`;
