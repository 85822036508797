/* Dashboard (pay-as-you-go) > Add users ("Add manually" tab) */

import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { copyArray } from '../../utils/copyArray';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useStateValue } from '../../services/state';

import layout from '../../config/layout';
import { validateEmail, doesEmailDomainMatch } from '../../utils/validation';
import { createOrgSubmitData, getPriceText } from '../../utils/helper';

import Input from '../../ui/form/Input';

import { PVSmall, H4, Error } from '../../ui/typography/Typography';
import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import Tooltip from '../../components/Tooltip';
import Checkbox from '../../ui/form/Checkbox';
import LoadingScreen from '../LoadingScreen';

const AddUsersManuallyScreen = () => {

  const history = useHistory();

  const [{ context, loading }] = useStateValue();

  const [ screenSize, setScreenSize ] = useState(null);

  const [users, setUsers] = useState([]);
  const [invite, setInvite] = useState(false);

  const [ generalError, setGeneralError ] = useState(null);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ loadingText, setLoadingText ] = useState();

  const instantiateUsers = () => {
    let usersInstantiated = [];
    var numInitialUsers = 3;
    if (context.prepay && !context.internal && !context.is_college && context.num_licences < 3) {
      numInitialUsers = context.num_licences;
    }
    for (let i=0; i<numInitialUsers; i++) {
      usersInstantiated.push({ id: (i+1), first_name: "", first_name_error: null, last_name: "", last_name_error: null, email: "", email_error: null, student_id: "", student_id_error: null, is_valid: null });
    }
    setUsers(usersInstantiated);
  }

  useEffect(() => {
    instantiateUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserValid = (user) => {
    if (user["first_name"] === "" && user["last_name"] === "" && user["email"] === "" && (!context.is_college || user["student_id"] === "")) {
      return null;
    } else if (
        user["first_name"] !== "" &&
        user["last_name"] !== "" &&
        (validateEmail(user["email"]) && (!context.is_college || doesEmailDomainMatch(user["email"], context.email_domains))) &&
        (!context.is_college || user["student_id"] !== "")) {
      return true;
    } else {
      return false;
    }
  }

  const getNumValidUsers = () => {
    var numValidUsers = 0;
    // eslint-disable-next-line no-unused-vars
    for (const user of users) {
      if (user["is_valid"] === true) {
        numValidUsers++;
      }
    }
    return numValidUsers;
  }

  const updateField = (value, type, id) => {
    setGeneralError(null);
    const usersCopy = copyArray(users);
    let addUser = false;
    // eslint-disable-next-line no-unused-vars
    for (const user of usersCopy) {
      if (user.id === id) {
        user[type] = value;
        user["is_valid"] = isUserValid(user);
      }
      if (id === users.length && (!context.prepay || context.internal || context.is_college || context.num_licences > users.length)) addUser = true;
    }
    if (addUser) usersCopy.push({id: users.length + 1, first_name: "", last_name: "", email: ""});
    setUsers(usersCopy);
  };

  const onBlur = (type, id) => {
    const usersCopy = copyArray(users);

    // eslint-disable-next-line no-unused-vars
    for (const user of usersCopy) {
      if (user.id === id) {
        if (user["first_name"].length === 0 && (user["last_name"].length > 0 || user["email"].length > 0)) {
          user["first_name_error"] = "This field is required";
        } else {
          user["first_name_error"] = null;
        }

        if (user["last_name"].length === 0 && user["email"].length > 0) {
          user["last_name_error"] = "This field is required";
        } else {
          user["last_name_error"] = null;
        }

        if (type === "email") {
          if (user["email"].length === 0 && user["first_name"].length > 0 && user["last_name"].length > 0) {
            user["email_error"] = "This field is required";
          } else if (user["email"].length > 0 && context.is_college && (!validateEmail(user["email"]) || !doesEmailDomainMatch(user["email"], context.email_domains))) {
            user["email_error"] = "This is not a valid " + context.education_institution_name + " email";
          } else if (user["email"].length > 0 && !validateEmail(user["email"])) {
            user["email_error"] = "This is not a valid email";
          } else {
            user["email_error"] = null;
          }
        }

        if (type === "student_id") {
          if (context.is_college && user["student_id"].length === 0 && (user["first_name"].length > 0 && user["last_name"].length > 0 && user["email"].length > 0)) {
            user["student_id_error"] = "This field is required";
          } else {
            user["student_id_error"] = null;
          }
        }
      }
    }

    setUsers(usersCopy);
  }

  const validateForm = () => {
    let invalidUserExists = false;
    // eslint-disable-next-line no-unused-vars
    for (const user of users) {
      if (user["is_valid"] === false) {
        invalidUserExists = true;
      }
    }
    let isValid = null;
    if (invalidUserExists) {
      isValid = false;
    } else if (!invalidUserExists && getNumValidUsers() > 0) {
      isValid = true;
    }
    return isValid;
  };

  const submitForm = () => {
    if (!validateForm()) return;

    setIsLoading(true);

    let usersToSubmit = [];
    // eslint-disable-next-line no-unused-vars
    for (const user of users) {
      if (user["is_valid"] === true) {
        usersToSubmit.push({
          first_name: user["first_name"],
          last_name: user["last_name"],
          email: user["email"],
          student_id: user["student_id"] //context.is_college ? user["student_id"] : null, - Temp for now
        })
      }
    }

    const orgSubmitData = createOrgSubmitData({
      organisation_id: context.organisation_id,
      education_institution_id: context.education_institution_id,
      organisation_user_id: context.organisation_user_id,
      users: usersToSubmit,
      send_emails: invite,
      source: "manual",
    });

    const numValidUsers = getNumValidUsers();

    axios.post('/api/v1/create_users', orgSubmitData).then((res) => {
      if (res.status === 201) {
        setLoadingText("Adding " + numValidUsers.toLocaleString() + " user" + (numValidUsers > 1 ? "s" : "") + "...");
        setTimeout(() => {
          setIsLoading(false);
          history.push(`/organisations/dashboard`);
        }, 2000);
      } else {
        setIsLoading(false);
        if (res.data.error) {
          setGeneralError(res.data.error);
          instantiateUsers();
        }
      }
    }).catch((error) => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          setGeneralError(error.response.data.error);
          instantiateUsers();
        } else if (error.response.statusText) {
          setGeneralError(error.response.statusText);
          instantiateUsers();
        }
        if (error.response.status === 401) {
          localStorage.removeItem("organisation_user_id");
          localStorage.removeItem("session_id");
          history.push(`/organisations/login`);
        }
      }
    });
  };

  if (loading || isLoading || !context) return <LoadingScreen text={loadingText} />;

  const isValid = validateForm();
  const numValidUsers = getNumValidUsers();

  return (
    <Container>
      {users.map(item => (
        <InputWrap key={item.id}>
          <Number small={screenSize <= layout.numericalBreakpoints.SM}>{screenSize <= layout.numericalBreakpoints.SM && "User "}{item.id}{screenSize <= layout.numericalBreakpoints.SM ? ":" : "."}</Number>
          <InputContainer>
            <Input
              placeholder="First name"
              handleChange={(it) => updateField(it, "first_name", item.id)}
              onBlur={(it) => onBlur("first_name", item.id)}
              error={item.first_name_error}
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Last name"
              handleChange={(it) => updateField(it, "last_name", item.id)}
              onBlur={(it) => onBlur("last_name", item.id)}
              error={item.last_name_error}
            />
          </InputContainer>
          <InputContainer twice_size>
            <Input
              twice_size
              placeholder="Email"
              handleChange={(it) => updateField(it, "email", item.id)}
              onBlur={(it) => onBlur("email", item.id)}
              error={item.email_error}
            />
          </InputContainer>
          <InputContainer>
            <Input
              placeholder="Student ID"
              handleChange={(it) => updateField(it, "student_id", item.id)}
              onBlur={(it) => onBlur("student_id", item.id)}
              error={item.student_id_error}
            />
          </InputContainer>
        </InputWrap>
      ))}
      <CheckboxWrap>
        <Checkbox
          label="Invite these users via email"
          name="invite"
          handleChange={e => setInvite(e)}
        />
      </CheckboxWrap>
      <ButtonWrap>
        {isValid === true ? (
          <PrimaryButton onClick={() => submitForm()}>Add {numValidUsers.toLocaleString()} users</PrimaryButton>
        ) : (
          isValid === false ? (
            <Tooltip text="Please complete missing or invalid user details above">
              <GrayButton>{numValidUsers > 0 ? `Add ${numValidUsers.toLocaleString()} users` : `Add users`}</GrayButton>
            </Tooltip>
          ) : (
            <Tooltip text="Please enter user details above">
              <GrayButton>Add users</GrayButton>
            </Tooltip>
          )
        )}
      </ButtonWrap>
      {generalError && <ErrorStyled>{generalError}</ErrorStyled>}
      {isValid === true && !context.internal && context.num_licences !== -1 && (
        <SubmitButtonUnderText text-align="center">This will use up {numValidUsers.toLocaleString()} of your licences, leaving you with {(context.num_licences - numValidUsers).toLocaleString()} remaining</SubmitButtonUnderText>
      )}
    </Container>
  );
};

export default AddUsersManuallyScreen;

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px 20px 8px 20px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 12px 4px 12px;
  }
`

const InputWrap = styled.div`
  margin-bottom: 12px;
  display: flex;

  @media (max-width: ${layout.breakpoints.SM}) {
    display: block;
  }
`;

const CheckboxWrap = styled.div`
  text-align: center;
  margin-top: 32px;
  margin-bottom: 12px;

  @media (max-width: ${layout.breakpoints.SM}) {
    margin-top: 20px;
    margin-bottom: 8px;
  }
`

const InputContainer = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  ${props => props.twice_size ? "flex 2" : "flex 1"};

  @media (max-width: ${layout.breakpoints.SM}) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
  }
`

const Number = styled(H4)`
  margin: auto;
  ${props => props.small ? "width: 100%" : "width: 30px"};
  ${props => props.small && "padding: 20px 0 8px 0"};
`

const ButtonWrap = styled.div`
  margin-bottom: 8px;
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const SubmitButtonUnderText = styled(PVSmall)`
  text-align: center;
  padding-top: 8px;
`

const ErrorStyled = styled(Error)`
  text-align: center;
`
