import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import layout from '../config/layout';
import { H2, P } from '../ui/typography/Typography';
import ReactHtmlParser from 'react-html-parser';

const TeamMember = ({
  color,
  title,
  description,
  imageUrl,
  left,
}) => {
  return (
    <Container>
      <ImageContainer color={color} left={left}>
        <Image src={imageUrl} alt="" />
      </ImageContainer>
      {title && (
        <Content>
          <Name>{title}</Name>
          {description && (<P>{ReactHtmlParser(description)}</P>)}
        </Content>
      )}
    </Container>
  );
};

export default TeamMember;

TeamMember.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  left: PropTypes.bool,
};

const Container = styled.div`
  max-width: 100%;
  margin: 32px 0;
`;

const ImageContainer = styled.div`
  margin-bottom: 20px;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 181px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => props.color};
    top: -14px;
    left: ${props => props.left ? '-14px' : 'auto'};
    right: ${props => props.left ? 'auto' : '-14px'};
    border-radius: 20px;
    z-index: 0;

    @media(max-width: ${layout.breakpoints.MD}) {
      top: -11px;
      left: ${props => props.left ? '-11px' : 'auto'};
      right: ${props => props.left ? 'auto' : '-11px'};
    }
  }
`;

const Image = styled.img`
  border-radius: 20px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 600px;
`;

const Content = styled.div`
  max-width: 100px;
`;

const Name = styled(H2)`
  margin-bottom: 16px;
`;
