import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ReactSwipe from 'react-swipe';
import { Row, Col } from 'react-styled-flexboxgrid';

import { P, H3 } from '../ui/typography/Typography';
import colors from '../config/colors';
import layout from '../config/layout';
import { useStateValue } from "../services/state";

import IconBenefitCheckmark from '../assets/icon-benefit-checkmark.svg';
import IconArrowLeft from '../assets/icon-arrow-left.svg';
import IconArrowRight from '../assets/icon-arrow-right.svg';

import ScreenshotAppResults from '../assets/screenshot_app_results_purple.png';
import ScreenshotAppPathways from '../assets/screenshot_app_pathways_purple.png';
import ScreenshotAppSkills from '../assets/screenshot_app_skills_purple.png';
import ScreenshotAppBrowse from '../assets/screenshot_app_browse_purple.png';
import ScreenshotAppVideos from '../assets/screenshot_app_videos_purple.png';
import ScreenshotOrgDashboard from '../assets/screenshot_org_dashboard_purple.png';
import ScreenshotOrgReport from '../assets/screenshot_org_report_purple.png';

import ScreenshotAppResultsDesktop from '../assets/screenshot_app_results_desktop_purple.png';
import ScreenshotAppPathwaysDesktop from '../assets/screenshot_app_pathways_desktop_purple.png';
import ScreenshotAppSkillsDesktop from '../assets/screenshot_app_skills_desktop_purple.png';
import ScreenshotAppBrowseDesktop from '../assets/screenshot_app_browse_desktop_purple.png';
import ScreenshotAppVideosDesktop from '../assets/screenshot_app_videos_purple.png';
import ScreenshotOrgDashboardDesktop from '../assets/screenshot_org_dashboard_desktop_purple.png';
import ScreenshotOrgReportDesktop from '../assets/screenshot_org_report_desktop_purple.png';

const FeaturesCard = ({price, premium_price, upgrade, organisation, include_skills}) => {
  const [{ context }] = useStateValue();
  const [ isLargeScreen, setIsLargeScreen ] = useState(false);

  useLayoutEffect(() => {
    function updateWindowSize() {
      if (window.innerWidth > layout.numericalBreakpoints.MD) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBenefitsList = () => {
    let benefitsList = [];
    benefitsList.push({benefit: "See all your top careers 🔥", level: "standard"});
    if (!context || !context.has_free_careers) {
      benefitsList.push({benefit: "Personalised entry routes with time, cost and starting salaries 🥳", level: "standard"});
    } else {
      benefitsList.push({benefit: "Personalised to-do lists to get into each career, including courses to take, tasks to do and entry jobs to apply to 🥳", level: "standard"});
    }
    if (!context || (context && context.include_skills_match === true) || include_skills === true) {
      benefitsList.push({benefit: "Identify your transferable skills for each career 💪", level: "premium"});
    }
    // benefitsList.push("Salaries, day-to-day tasks and live jobs ads 💸");
    // benefitsList.push("See which careers have lots of jobs today and are future-proof 📈");
    // benefitsList.push("Sort your careers by time, cost, salary and availability of jobs 🤓");
    // benefitsList.push("Results emailed to you, so you can keep coming back 💌");
    benefitsList.push({benefit: "Watch day in the life videos for many careers 🎥", level: "premium"});
    benefitsList.push({benefit: "Browse our entire careers database 🔎", level: "premium"});
    benefitsList.push({benefit: "Download a personalised career report 📝", level: "premium"});
    // benefitsList.push("Access to all information for 1 year and exciting new features as they are published ⚡");
    // benefitsList.push("Customer support - we’re here for you whenever you need us 🤝");
    // benefitsList.push("14-day money back guarantee if you’re not satisfied, simply drop us an email 😊");
    return benefitsList;
  };

  const benefitsList = createBenefitsList();

  let benefitKey = 0;

  let reactSwipeEl;

  return (
    <BenefitsCard>
      <Row>
        <ColLeft xs={1} md={1} onClick={() => reactSwipeEl.prev()}>
          <IconArrow src={IconArrowLeft} alt="" />
        </ColLeft>
        <Col xs={10} md={10}>
          <ReactSwipe
            className="carousel"
            swipeOptions={{ continuous: true }}
            ref={el => (reactSwipeEl = el)}
          >
            {!organisation && !upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">Unlock your top careers for {price}</BenefitsCardTitle>
                <BenefitsListSection>
                  {benefitsList.map(item => item.level === "standard" && (
                    <Benefit key={benefitKey++}>
                      <BenefitIcon src={IconBenefitCheckmark} alt="" /><BenefitText>{ReactHtmlParser(item.benefit)}</BenefitText>
                    </Benefit>
                  ))}
                </BenefitsListSection>
                <BenefitsCardTitle align="center" pt>Unlock everything for {premium_price}</BenefitsCardTitle>
                <BenefitsListSection>
                  {benefitsList.map(item => item.level === "premium" && (
                    <Benefit key={benefitKey++}>
                      <BenefitIcon src={IconBenefitCheckmark} alt="" /><BenefitText>{ReactHtmlParser(item.benefit)}</BenefitText>
                    </Benefit>
                  ))}
                </BenefitsListSection>
              </SwipeScreen>
            )}
            {!upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">{organisation ? "Help your users..." : "Standard feature"}</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppResultsDesktop : ScreenshotAppResults} alt="Screenshot - results" />
                <BenefitsCardTitle align="center" pt>{organisation ? "discover their dream careers" : "Discover your dream careers"}</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {!upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">{organisation ? "Help your users..." : "Standard feature"}</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppPathwaysDesktop : ScreenshotAppPathways} alt="Screenshot - pathways" />
                <BenefitsCardTitle align="center" pt>{organisation ? "learn how to get into each career" : "Learn how to get into each career"}</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {!upgrade && (!context || (context && context.include_skills_match === true) || include_skills === true) && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">{organisation ? "Help your users..." : "Premium feature"}</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppSkillsDesktop : ScreenshotAppSkills} alt="Screenshot - skills" />
                <BenefitsCardTitle align="center" pt>{organisation ? "identify their transferable skills for each career" : "Identify your transferable skills for each career"}</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {!upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">{organisation ? "Enable your users to..." : "Premium feature"}</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppVideosDesktop : ScreenshotAppVideos} alt="Screenshot - videos" />
                <BenefitsCardTitle align="center" pt>{organisation ? "explore day-in-the-life videos" : "Explore day-in-the-life videos"}</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {!upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">{organisation ? "Enable your users to..." : "Premium feature"}</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppBrowseDesktop : ScreenshotAppBrowse} alt="Screenshot - browse" />
                <BenefitsCardTitle align="center" pt>{organisation ? "browse our database of 500+ careers" : "Browse our database of 500+ careers"}</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {!upgrade && !organisation && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">Premium feature</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotOrgReportDesktop : ScreenshotOrgReport} alt="Screenshot - report" />
                <BenefitsCardTitle align="center" pt>Download a personalised career report</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {organisation && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">Manage your users...</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotOrgDashboardDesktop : ScreenshotOrgDashboard} alt="Screenshot - dashboard" />
                <BenefitsCardTitle align="center" pt>with your own dashboard</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {organisation && (
              <SwipeScreen>
                <BenefitsCardTitle align="center">Provide your users...</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotOrgReportDesktop : ScreenshotOrgReport} alt="Screenshot - report" />
                <BenefitsCardTitle align="center" pt>with a personalised career report</BenefitsCardTitle>
              </SwipeScreen>
            )}
            {upgrade && (!context || (context && context.include_skills_match === true) || include_skills === true) && (
              <SwipeScreen>
                <BenefitsCardTitle align="center" pt>Identify your transferable skills for each career</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppSkillsDesktop : ScreenshotAppSkills} alt="Screenshot - skills" />
              </SwipeScreen>
            )}
            {upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center" pt>Explore day-in-the-life videos</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppVideosDesktop : ScreenshotAppVideos} alt="Screenshot - videos" />
              </SwipeScreen>
            )}
            {upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center" pt>Browse our database of 500+ careers</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotAppBrowseDesktop : ScreenshotAppBrowse} alt="Screenshot - browse" />
              </SwipeScreen>
            )}
            {upgrade && (
              <SwipeScreen>
                <BenefitsCardTitle align="center" pt>Download a personalised career report</BenefitsCardTitle>
                <ScreenshotApp src={isLargeScreen ? ScreenshotOrgReportDesktop : ScreenshotOrgReport} alt="Screenshot - report" />
              </SwipeScreen>
            )}
          </ReactSwipe>
        </Col>
        <ColRight xs={1} md={1} onClick={() => reactSwipeEl.next()}>
          <IconArrow src={IconArrowRight} alt="" />
        </ColRight>
      </Row>
    </BenefitsCard>
  );
};

export default FeaturesCard;

const BenefitsCard = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${colors.LCG_DARK_GREY}, 0px 8px 16px ${colors.LCG_DARK_GREY};
  margin: 0 auto 40px auto;
  padding: 32px 32px 32px 32px;
  position: relative;
  max-width: 850px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 24px 20px;
    max-width: 350px;
  }
`;

const BenefitsCardTitle = styled(H3)`
  margin: 0 8px 20px 8px;
  padding-top: ${props => props.pt ? '32px' : '20px'};

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => props.pt && `padding-top: 20px;`};
  }
`;

const BenefitsListSection = styled.div`
  padding: 0 8px 0 8px;
  margin: -12px auto 0 auto;
  position: relative;
  max-width: 350px;
`;

const Benefit = styled.div`
  margin-bottom: 12px;
  margin-top: 32px;
  display: flex;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-bottom: 12px;
    margin-top: 25px;
  }
`

const BenefitText = styled(P)`
  display: flex;
  text-align: left;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-left: 12px;
  }
`

const BenefitIcon = styled.img`
  max-width: 36px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 24px;
  }
`;

const ColLeft = styled(Col)`
  padding: 0;
  margin: auto;
  text-align: left;
`

const ColRight = styled(Col)`
  padding: 0;
  margin: auto;
  text-align: right;
`

const IconArrow = styled.img`
  max-width: 36px;
  padding-top: 200px;
  padding-bottom: 200px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 24px;
  }
`;

const SwipeScreen = styled.div`
  text-align: center;
`

const ScreenshotApp = styled.img`
  max-width: 650px;
  margin-top: 40px;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 200px;
    margin-top: 0px;
  }
`
