import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { PSmall, Label } from '../typography/Typography';
import IconArrowDown from '../../assets/icons/icon-arrow-down.svg';

const Select = ({ error, selectedOption, options, handleChange, label, required, is_disabled }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (typeof(selectedOption) === 'object') {
      setSelected(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (e) => {
    if (!is_disabled) {
      setSelected(e.target.value ? JSON.parse(e.target.value) : null);
      handleChange(e.target.value ? JSON.parse(e.target.value) : null);
    }
  };

  const defaultItem = {
    label: 'Select an option...',
    id: null
  };

  return (
    <>
      {label && (
        <Label htmlFor={selected}>{label}{required && (<Required> *</Required>)}</Label>
      )}
      <Container>
        <SelectInput error={error && error} value={JSON.stringify(selected)} onChange={handleSelect} disabled={is_disabled}>
          <option key={-1} value={JSON.stringify(defaultItem)}>{defaultItem.label}</option>
          {options.map(item => (
            <option key={item.id} value={JSON.stringify(item)}>{item.label}</option>
          ))}
        </SelectInput>
        {error && (
          <Error>
            <PSmall color={colors.LCG_DARK_PURPLE}>{error}</PSmall>
          </Error>
        )}
      </Container>
    </>
  );
};

export default Select;

Select.propTypes = {
  error: PropTypes.string,
  selectedOption: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  handleChange: PropTypes.func,
};

const Container = styled.div`
  width: 100%;
  position: relative;

  &::after {
    content: "";
    width: 14px;
    height: 8px;
    background-image: url(${IconArrowDown});
    background-repeat: no-repeat;
    position: absolute;
    right: 12px;
    top: 21px;
  }
`;

const SelectInput = styled.select`
  appearance: none;
  border: 0;
  width: 100%;
  padding: 8px 12px;
  font-size: 20px;
  line-height: 30px;
  background-color: ${props => props.error ? colors.LCG_DARK_PURPLE_FADE : 'transparent'};
  border: 2px solid ${props => props.error ? colors.LCG_DARK_PURPLE : colors.LCG_LIGHT_GREY};
  border-radius: 8px;

  &:focus {
    outline: none;
    border-color: ${colors.LCG_LIGHT_PURPLE};
  }

  &:disabled {
    cursor: default;
    color: ${colors.LCG_DARK_GREY};
    background-color: ${colors.LCG_LIGHTER_GREY};

    &:hover {
      background-color: ${colors.LCG_LIGHTER_GREY};
    }
  }
`;

const Error = styled.div`
  margin-top: 4px;
`;

const Required = styled.span`
  color: ${colors.LCG_DARK_PURPLE};
`;
