/* Legacy screen at the start of the flow - no longer used */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';
import { useRouteMatch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import { useStateValue } from "../../services/state";
import { createSubmitData } from '../../utils/helper';
import { useGetScreenData } from '../../hooks/getDataHooks';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import SelectButton from '../../components/singleselect/SelectButton';
import { Label, HintText } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen'
import RestartFlow from '../RestartFlow';


const DegreeScreen = () => {
  const [{ user_id, loading, context }, dispatch] = useStateValue();
  const [loadingText, setLoadingText] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const match = useRouteMatch("/:userId/have-degree");
  const history = useHistory();

  useGetScreenData('have-degree', setIsLoading, match.params.userId);

  const handleClick = (value, logEvent) => {

    if (value) setLoadingText(context.loading_message);

    dispatch({ type: 'update', values: {
      showPreQuizInfoScreen: value,
      have_degree: value,
      loading: true
    }})

    logEvent('Completed Have Degree', { have_degree: value });

    const formData = createSubmitData({
      have_degree: value,
      user_id: user_id,
    });

    axios.post('/api/v1/handleHaveDegreeInput', formData).then((res) => {
      setIsLoading(true);
      dispatch({ type: 'updateResponse', data: res.data });
      if (res.data.screen.name === 'aspire-degree') {
        setIsLoading(false);
        history.push(`/${res.data.user_id}/${res.data.screen.name}`);
      } else {
        setTimeout(() => {
          setIsLoading(false);
          history.push(`/${res.data.user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
        }, 2500);
      }
    })
  };

  if (loading || isLoading) return <LoadingScreen text={loadingText} />

  return (
    <>
      <MetaTags>
        <title>Have a degree?</title>
        <meta id="meta-description" name="description" content="Do you have a degree?" />
      </MetaTags>
      <ThreeDotsBgLayout
        title="Let's kick things off"
        subtitle="This will help us suggest more relevant jobs"
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionContainer>
              <Label>Do you have a degree?</Label>
              <HintText>For example a BA, BSc, MA etc.</HintText>
              <Amplitude userProperties={{user_id: user_id}}>
                {({ logEvent }) => (
                  <>
                  <ButtonWrap onClick={() => {handleClick(true, logEvent)}} data-testid="have_degree">
                    <SelectButton label="Yes" />
                  </ButtonWrap>
                  <ButtonWrap onClick={() => handleClick(false, logEvent)} data-testid="no_degree">
                    <SelectButton label="No" />
                  </ButtonWrap>
                  </>
                )}
              </Amplitude>
            </QuestionContainer>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default DegreeScreen;

const QuestionContainer = styled.div`
  margin: auto;
  max-width: 352px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 8px;
`;
