/* Dashboard (pay-as-you-go) > Add users ("Add with API" tab) */

import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Table } from "react-bootstrap";
import axios from 'axios';

import { useStateValue } from '../../services/state';

import colors from '../../config/colors';
import layout from '../../config/layout';

import { getPriceText } from '../../utils/helper';

import { P, PSmall, H3, Ul } from '../../ui/typography/Typography';
import { PrimaryButton, SecondaryButton } from '../../ui/buttons/Buttons';

const AddUsersWithAPIScreen = () => {

  const [{context}] = useStateValue();

  const [ screenSize, setScreenSize ] = useState(null);

  const [ accessToken, setAccessToken ] = useState(null);

  const [ createUserCurlExampleCopied, setCreateUserCurlExampleCopied ] = useState(false);
  const [ createUsersCurlExampleCopied, setCreateUsersCurlExampleCopied ] = useState(false);

  useLayoutEffect(() => {
    function updateWindowSize() {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener('resize', updateWindowSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateAccessToken = async () => {
    const resp = await axios.get(
      '/api/v1/generate_access_token' +
      '?organisation_user_id=' + localStorage.getItem("organisation_user_id") +
      '&session_id=' + localStorage.getItem("session_id"));
    if (resp.data && resp.data.access_token) {
      setAccessToken(resp.data.access_token);
    }
  };

  let topParagraphSuffix = "";
  if (context && !context.internal && !context.is_college) {
    topParagraphSuffix += " Note that each user you create will ";
    if (context.prepay) {
      topParagraphSuffix += "consume a user licence.";
    } else {
      topParagraphSuffix += "cost you " + getPriceText(context.per_user_net_price_pence_for_invoicing) + " + VAT.";
    }
  }

  return (
    <Container>
      <Section>
        <SmallText>If you would like to integrate into your existing management system, you can use our API.  You'll find examples of the available HTTP POST requests below along with the responses you can expect.{topParagraphSuffix}</SmallText>
      </Section>
      <Section>
        <SubTitle>Step 1: Generate an access token</SubTitle>
          {context && !context.access_token_generated ? (
            <SmallText>To start with, generate an access token to be used in the HTTP requests below.  You'll need to store it in a secure place as it will be hidden as soon as you navigate away from this page and will never be shown again.  You can generate a new access token anytime to replace the current one.</SmallText> ) : (
            <SmallText>You have already generated an access token, which you should have stored in a secure location (we can't show this to you again due to security reasons).  Click the button below to generate a new one - but as soon as you do that, the old one will expire with immediate effect and you'll need to use the new one going forward.</SmallText> )
          }
          {!accessToken && (
            <ButtonContainer>
              <PrimaryButton onClick={generateAccessToken}>Generate {context.access_token_generated ? "new" : ""} access token</PrimaryButton>
            </ButtonContainer>
          )}
          {accessToken && (
            <ButtonContainer>
              <SmallText pb>Here is your access token:</SmallText>
              <SmallText bold pb should_wrap>{accessToken}</SmallText>
              <SmallText italic>Note: This will disappear forever once you leave this screen, so please store it in a safe place</SmallText>
            </ButtonContainer>
          )}
      </Section>
      <Section>
        <SubTitle>Step 2a: Create one user at a time</SubTitle>
        <ExampleTitleContainer>
          <ExampleTitle>Example</ExampleTitle>
          {createUserCurlExampleCopied ? (
            <CopiedText>Copied</CopiedText>
          ) : (
            <SecondaryButton small onClick={() => {
              navigator.clipboard.writeText(context.create_user_curl_example.replace(/<[^>]*>?/gm, ''));
              setCreateUserCurlExampleCopied(true);
              setCreateUsersCurlExampleCopied(false);
            }}>Copy</SecondaryButton>
          )}
        </ExampleTitleContainer>
        {context && (<Code>{ReactHtmlParser(context.create_user_curl_example)}</Code>)}
        <SubSectionTitle>Notes</SubSectionTitle>
        <Ul>
          <li><PSmall>These fields are required: first_name, last_name, email, student_id, learner_status</PSmall></li>
          <li><PSmall>These fields are optional: department, course_code, course_title, age_group, ethnicity, sex</PSmall></li>
          <li><PSmall>Set “send_email” to true if you want email invitations to be automatically sent to each user on creation</PSmall></li>
          <li><PSmall>Set “is_live” to false if you want to test the API without actually creating users and consuming user licences (all API responses will be the same, but users won't be created). The example above has "is_live" set to false, so be sure to change it to true when you are ready to go live and create users.</PSmall></li>
        </Ul>
        <SubSectionTitle extra_padding_bottom>Status codes {(screenSize <= layout.numericalBreakpoints.SM) && "in response"}{(screenSize > layout.numericalBreakpoints.SM) && "with example responses"}</SubSectionTitle>
        <ResponsesTable>
          <thead>
            <TableRow header>
              <TableHeaderCell><TableText header>Code</TableText></TableHeaderCell>
              <TableHeaderCell><TableText header>Description</TableText></TableHeaderCell>
              {screenSize > layout.numericalBreakpoints.SM && (<TableHeaderCell><TableText header>Example body response</TableText></TableHeaderCell>)}
            </TableRow>
          </thead>
          <tbody>
            {context && context.create_user_status_codes_with_example_responses.map((item, key) => (
              <TableRow key={key}>
                <TableCell pl pr><TableText>{item.code}</TableText></TableCell>
                <TableCell><TableText>{item.description}</TableText></TableCell>
                {screenSize > layout.numericalBreakpoints.SM && (<TableCell pr><TableText code>{ReactHtmlParser(item.example_body_response)}</TableText></TableCell>)}
              </TableRow>
            ))}
          </tbody>
        </ResponsesTable>
      </Section>
      <Section>
        <SubTitle>Step 2b: Create multiple users at once</SubTitle>
        <ExampleTitleContainer>
          <ExampleTitle>Example</ExampleTitle>
          {createUsersCurlExampleCopied ? (
            <CopiedText>Copied</CopiedText>
          ) : (
            <SecondaryButton small onClick={() => {
              navigator.clipboard.writeText(context.create_users_curl_example.replace(/<[^>]*>?/gm, ''));
              setCreateUserCurlExampleCopied(false);
              setCreateUsersCurlExampleCopied(true);
            }}>Copy</SecondaryButton>
          )}
        </ExampleTitleContainer>
        {context && (<Code>{ReactHtmlParser(context.create_users_curl_example)}</Code>)}
        <SubSectionTitle>Notes</SubSectionTitle>
        <Ul>
          <li><PSmall>These fields are required: first_name, last_name, email, student_id, learner_status</PSmall></li>
          <li><PSmall>These fields are optional: department, course_code, course_title, age_group, ethnicity, sex</PSmall></li>
          <li><PSmall>Set “send_email” to true if you want email invitations to be automatically sent to each user on creation</PSmall></li>
          <li><PSmall>Set “is_live” to false if you want to test the API without actually creating users and consuming user licences (all API responses will be the same, but users won't be created). The example above has "is_live" set to false, so be sure to change it to true when you are ready to go live and create users.</PSmall></li>
        </Ul>
        <SubSectionTitle extra_padding_bottom>Status codes {(screenSize <= layout.numericalBreakpoints.SM) && "in response"}{(screenSize > layout.numericalBreakpoints.SM) && "with example responses"}</SubSectionTitle>
        <ResponsesTable>
          <thead>
            <TableRow header>
              <TableHeaderCell><TableText header>Code</TableText></TableHeaderCell>
              <TableHeaderCell><TableText header>Description</TableText></TableHeaderCell>
              {screenSize > layout.numericalBreakpoints.SM && (<TableHeaderCell><TableText header>Example body response</TableText></TableHeaderCell>)}
            </TableRow>
          </thead>
          <tbody>
            {context && context.create_users_status_codes_with_example_responses.map((item, key) => (
              <TableRow key={key}>
                <TableCell pl pr><TableText>{item.code}</TableText></TableCell>
                <TableCell><TableText>{item.description}</TableText></TableCell>
                {screenSize > layout.numericalBreakpoints.SM && (<TableCell pr><TableText code>{ReactHtmlParser(item.example_body_response)}</TableText></TableCell>)}
              </TableRow>
            ))}
          </tbody>
        </ResponsesTable>
      </Section>
    </Container>
  );
};

export default AddUsersWithAPIScreen;

const Container = styled.div`
  max-width: 850px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`

const Section = styled.div`
  padding: 20px 0 12px 0;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px 0 8px 0;
  }
`

const SubTitle = styled(H3)`
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-top: 12px;
  }
`;

const ExampleTitleContainer = styled.div`
  display: flex;
  padding-bottom: 12px;
  height: 50px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-bottom: 8px;
  }
`

const SubSectionTitle = styled(P)`
  color: ${colors.TEXT_DARK};
  font-size: 24px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  ${props => props.extra_padding_bottom && "padding-bottom: 20px;"}

  @media (max-width: ${layout.breakpoints.MD}) {
    font-size: 18px;
    padding-top: 8px;
    ${props => props.extra_padding_bottom && "padding-bottom: 12px;"}
  }
`

const ExampleTitle = styled(SubSectionTitle)`
  padding-right: 32px;
  padding-top: 0px;
  padding-bottom: 0px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding-right: 20px;
  }
`

const CopiedText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin-top: auto;
  margin-bottom: auto;
`

const SmallText = styled(PSmall)`
  color: ${colors.TEXT_DARK};
  text-align: center;
  ${props => props.pb && "padding-bottom: 20px;"}
  ${props => props.pt && "padding-top: 32px;"}
  ${props => props.bold && 'font-style: bold;'};
  ${props => props.italic && 'font-style: italic;'};
  ${props => props.should_wrap && 'overflow-wrap: anywhere;'};
  padding-left: 25px;
  padding-right: 25px;
  margin: auto;

  @media (max-width: ${layout.breakpoints.MD}) {
    ${props => props.pb && "padding-bottom: 12px;"}
    ${props => props.pt && "padding-top: 20px;"}
  }
`;

const Code = styled(PSmall)`
  font-family: 'Courier', sans-serif;
  background-color: ${colors.LCG_DARK_GREY};
  color: ${colors.TEXT_LIGHT};
  word-wrap: break-word;
  padding: 20px;

  @media (max-width: ${layout.breakpoints.MD}) {
    padding: 12px;
  }
`

const ResponsesTable = styled(Table)`
  margin: auto;
  border-collapse: collapse;
`;

const TableHeaderCell = styled.th`
  padding: 12px 8px 12px 8px;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 8px 4px 8px 4px;
  }
`

const TableRow = styled.tr`
  ${props => props.header && `background-color: ${colors.BG_LIGHT_GRAY};`}
  ${props => !props.header && props.id % 2 === 0 && `background-color: ${colors.WHITE};`}
`

const TableText = styled(P)`
  color: ${colors.TEXT_DARK};
  margin: auto;
  ${props => props.header && "font-weight: bold;"}
  ${props => props.code && "font-family: 'Courier', sans-serif; font-size: 14px; line-height: 24px; word-wrap: break-word;"}
`

const TableCell = styled.td`
  ${props => `padding: 12px ${props.pr ? "20px": "12px"} 12px ${props.pl ? "20px": "12px"};`}
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => `padding: 8px ${props.pr ? "12px": "8px"} 8px ${props.pl ? "12px": "8px"};`}
  text-align: center;
  }
`

const ButtonContainer = styled.div`
  text-align: center;
  margin: 32px 0 20px;
`;
