import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/colors';
import { P } from '../../ui/typography/Typography';
import IconTick from '../../assets/icon-tick.svg';

const MultiselectItem = ({ label, item_selected, disabled, testid }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(item_selected);
  }, [item_selected]);

  return (
    <Item onClick={() => {if (!disabled) setSelected(!selected)}} selected={selected} data-testid={testid} disabled={disabled}>
      <P nomargin bold color={selected ? colors.WHITE : (disabled ? colors.GRAY_LIGHT : colors.GRAY_DARK)}>{label}</P>
    </Item>
  );
};

export default MultiselectItem;

MultiselectItem.propTypes = {
  label: PropTypes.string,
  item_selected: PropTypes.bool
};

const Item = styled.button`
  background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.WHITE};
  cursor: pointer;
  padding: 8px;
  position: relative;
  text-align: center;
  border: 0;
  width: 100%;

  &:hover {
    background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_PURPLE_FADE};
  }

  &:focus {
    outline: none;
    background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_PURPLE_FADE};;
  }

  &:disabled {
    cursor: default;
    background-color: ${colors.GRAY};
    color: ${colors.WHITE};

    &:hover {
      background-color: ${colors.GRAY};
      color: ${colors.WHITE};
    }
  }

  ${props => props.selected && `
    &::after {
      background-image: url(${IconTick});
      background-repeat: no-repeat;
      content: "";
      height: 10px;
      margin-top: -5px;
      position: absolute;
      right: 20px;
      top: 50%;
      width: 12px;
    }
  `}
`;
