/* Utility function for sorting final results */

export const sortResults = (results, type ) => {

  if (type === 'time') {

    // Sort results by time, ascending (null values appear last)

    results.sort(function(x, y) {
      if ('pathways_summary' in x && !('pathways_summary' in y)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('pathways_summary' in x) && 'pathways_summary' in y) {
        return 1; // Return y before x
      } else if (!('pathways_summary' in x) && !('pathways_summary' in y)) {
        return 0; // Leave unchanged
      } else if ('lowest_time_days' in x.pathways_summary && !('lowest_time_days' in y.pathways_summary)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('lowest_time_days' in x.pathways_summary) && 'lowest_time_days' in y.pathways_summary) {
        return 1; // Return y before x
      } else if (!('lowest_time_days' in x.pathways_summary) && !('lowest_time_days' in y.pathways_summary)) {
        return 0; // Leave unchanged
      } else if (x.pathways_summary.lowest_time_days < y.pathways_summary.lowest_time_days) {
        return -1;  // Leave unchanged, returning x before y
      } else if (x.pathways_summary.lowest_time_days > y.pathways_summary.lowest_time_days) {
        return 1; // Return y before x
      } else if ( (x.pathways_summary.lowest_time.includes("+") === true || x.pathways_summary.lowest_time.includes("Varied") === true) &&
                  (y.pathways_summary.lowest_time.includes("+") === false && y.pathways_summary.lowest_time.includes("Varied") === false) ) {
        return 1; // Return y before x
      } else if ( (y.pathways_summary.lowest_time.includes("+") === true || y.pathways_summary.lowest_time.includes("Varied") === true) &&
                  (x.pathways_summary.lowest_time.includes("+") === false && x.pathways_summary.lowest_time.includes("Varied") === false) ) {
        return -1;  // Leave unchanged, returning x before y
      } else {
        return 0; // Leave unchanged
      }
    });

  } else if (type === 'cost') {

    // Sort results by cost, ascending (null values appear last)

    results.sort(function(x, y) {
      if ('pathways_summary' in x && !('pathways_summary' in y)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('pathways_summary' in x) && 'pathways_summary' in y) {
        return 1; // Return y before x
      } else if (!('pathways_summary' in x) && !('pathways_summary' in y)) {
        return 0; // Leave unchanged
      } else if ('lowest_cost_in_gbp' in x.pathways_summary && !('lowest_cost_in_gbp' in y.pathways_summary)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('lowest_cost_in_gbp' in x.pathways_summary) && 'lowest_cost_in_gbp' in y.pathways_summary) {
        return 1; // Return y before x
      } else if (!('lowest_cost_in_gbp' in x.pathways_summary) && !('lowest_cost_in_gbp' in y.pathways_summary)) {
        return 0; // Leave unchanged
      } else if (x.pathways_summary.lowest_cost_in_gbp < y.pathways_summary.lowest_cost_in_gbp) {
        return -1;  // Leave unchanged, returning x before y
      } else if (x.pathways_summary.lowest_cost_in_gbp > y.pathways_summary.lowest_cost_in_gbp) {
        return 1; // Return y before x
      } else if ( (x.pathways_summary.lowest_cost.includes("+") === true || x.pathways_summary.lowest_cost.includes("Varied") === true) &&
                  (y.pathways_summary.lowest_cost.includes("+") === false && y.pathways_summary.lowest_cost.includes("Varied") === false) ) {
        return 1; // Return y before x
      } else if ( (y.pathways_summary.lowest_cost.includes("+") === true || y.pathways_summary.lowest_cost.includes("Varied") === true) &&
                  (x.pathways_summary.lowest_cost.includes("+") === false && x.pathways_summary.lowest_cost.includes("Varied") === false) ) {
        return -1;  // Leave unchanged, returning x before y
      } else {
        return 0; // Leave unchanged
      }
    });

  } else if (type === 'salary') {

    // Sort results by salary (starting), descending

    results.sort(function(x, y) {
      if ('pathways_summary' in x && !('pathways_summary' in y)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('pathways_summary' in x) && 'pathways_summary' in y) {
        return 1; // Return y before x
      } else if (!('pathways_summary' in x) && !('pathways_summary' in y)) {
        return 0; // Leave unchanged
      } else if ('highest_starting_salary_in_gbp' in x.pathways_summary && !('highest_starting_salary_in_gbp' in y.pathways_summary)) {
        return -1;  // Leave unchanged, returning x before y
      } else if (!('highest_starting_salary_in_gbp' in x.pathways_summary) && 'highest_starting_salary_in_gbp' in y.pathways_summary) {
        return 1; // Return y before x
      } else if (!('highest_starting_salary_in_gbp' in x.pathways_summary) && !('highest_starting_salary_in_gbp' in y.pathways_summary)) {
        return 0; // Leave unchanged
      } else if (x.pathways_summary.highest_starting_salary_in_gbp < y.pathways_summary.highest_starting_salary_in_gbp) {
        return 1; // Return y before x
      } else if (x.pathways_summary.highest_starting_salary_in_gbp > y.pathways_summary.highest_starting_salary_in_gbp) {
        return -1;  // Leave unchanged, returning x before y
      } else {
        return 0; // Leave unchanged
      }
    });

  } else if (type === 'skills') {

    // Sort results by skills_match score, descending

    results.sort(function(x, y) {
      if (x.skills_match > y.skills_match) {
        return -1;  // Leave unchanged, returning x before y
      }
      if (x.skills_match < y.skills_match) {
        return 1; // Return y before x
      }
      return 0; // Leave unchanged
    });

  } else if (type === 'jobs') {

    // Sort results by availibity of jobs (today and in the future) using the jobs_score, descending

    results.sort(function(x, y) {
      if (x.jobs_score > y.jobs_score) {
        return -1;  // Leave unchanged, returning x before y
      }
      if (x.jobs_score < y.jobs_score) {
        return 1; // Return y before x
      }
      return 0; // Leave unchanged
    });

  }

  return results;

};
