/* Homepage > Get started > Let's kick things off > {other input screen(s)} > Work experience screen */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Amplitude } from '@amplitude/react-amplitude';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import layout from '../../config/layout';
import { useStateValue } from "../../services/state";
import { copyArray } from '../../utils/copyArray';
import { cleanStringForComparison } from '../../utils/helper';
import { useGetScreenData } from '../../hooks/getDataHooks';
import { createSubmitData } from '../../utils/helper';
import { getNameByID, getNamesByIDs } from '../../utils/getNamesByIDs';

import ThreeDotsBgLayout from '../../ui/layout/ThreeDotsBgLayout';
import { Col, Row } from 'react-styled-flexboxgrid';
import AutocompleteField from '../../ui/form/AutocompleteField';
import { PrimaryButton, SecondaryButton } from '../../ui/buttons/Buttons';
import { Label, Error, HintText } from '../../ui/typography/Typography';
import LoadingScreen from '../LoadingScreen';
import RestartFlow from '../RestartFlow';

const ExperienceScreen = () => {
  const [{ user_id, context, loading }, dispatch] = useStateValue();

  const [ generalError, setGeneralError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingText, setLoadingText ] = useState();
  const [ careers, setCareers ] = useState([
    { name: "", id: 0, error: null },
    { name: "", id: 1, error: null },
    { name: "", id: 2, error: null },
    { name: "", id: 3, error: null }
  ]);
  const [ data, setData ] = useState();
  const history = useHistory();
  const match = useRouteMatch("/:userId/experience");

  useGetScreenData('experience',setIsLoading, match.params.userId);

  const onFieldBlur = (item, id) => {
    const val = context.job_autocomplete_options.strings.find(it => cleanStringForComparison(it.name.toLowerCase()) === ((item !== null) ? cleanStringForComparison(item.toLowerCase()) : item));
    if (!val && item !== '') {
      let copy = copyArray(careers);
      copy[id].error = "This is not a valid job";
      setCareers(copy);
    }
  };

  const handleInputFieldChange = (value, id) => {
    let copy = copyArray(careers);
    copy[id].error = null;
    copy[id].value = value.name || value;

    if (id === (copy.length - 1)) {
      copy.push({ name: "", id: copy.length, error: null })
    }

    setCareers(copy);
    prepData(copy);
  };

  const prepData = (jobs) => {
    let pastEscoCareers = [];
    let currentEscoCareer = null;
    // eslint-disable-next-line no-unused-vars
    for (const job of jobs) {
      if (context.job_autocomplete_options) {
        const val = context.job_autocomplete_options.strings.find(it =>
          cleanStringForComparison(it.name.toLowerCase()) === ((job.value != null) ? cleanStringForComparison(job.value.toLowerCase()) : job.value));

        if (val) {
          if (job.id === 0 && context.employment_status === 'employed') {
            currentEscoCareer = {
              id: val.id,
              name: val.name,
            };
          } else {
            pastEscoCareers.push({
              id: val.id,
              name: val.name,
            });
          }
        }
      }
    }
    let newData = {
      current_esco_career: currentEscoCareer,
      past_esco_careers: pastEscoCareers
    };
    setData(newData);
  };

  const validateForm = () => {
    let isValid = false;
    if (!data) return isValid;

    if (data.current_esco_career) isValid = true;
    if (data.past_esco_careers.length > 0) isValid = true;

    return isValid;
  };

  const submitForm = (logEvent) => {
    setLoadingText(context.loading_message);
    setIsLoading(true);
    let formData = createSubmitData({
      user_id: user_id,
      experience_details: {},
    });

    if (data) {
      formData = {
        ...formData,
        experience_details: {
          current_esco_career: data.current_esco_career,
          past_esco_careers: data.past_esco_careers,
        }
      }
    }

    axios.post('/api/v1/handleExperienceInput', formData).then((res) => {
      dispatch({type: 'updateResponse', data: res.data});
      if (res.data.errors) {
        setIsLoading(false);
        // eslint-disable-next-line no-unused-vars
        for (const error of res.data.errors) {
          if (error.hasOwnProperty('non-field-errors')) {
            setGeneralError(error['non-field-errors']);
          }
        }
      } else {
        if (data && logEvent) {
          logEvent('Completed Experience Input', {
            current_career_entered: data.current_esco_career ? true : false,
            num_past_careers: (data.past_esco_careers ? data.past_esco_careers.length : null),
            current_career: data.current_esco_career ? getNameByID(data.current_esco_career.id, context.job_autocomplete_options) : null,
            past_careers: data.past_esco_careers ? getNamesByIDs(data.past_esco_careers, context.job_autocomplete_options, "id") : null,
          });
        }
        if (res.data.screen.context.question_number) {
          dispatch({type: 'update', values: { window_top: 0, showPreQuizInfoScreen: true }});
          setTimeout(() => {
            setIsLoading(false);
            history.push(`/${user_id}/${res.data.screen.name}/${res.data.screen.context.question_number}`);
          }, 2500);
        } else {
          setIsLoading(false);
          history.push(`/${user_id}/${res.data.screen.name}`);
        }
      }
    })

  };

  if (loading || isLoading) return <LoadingScreen text={loadingText} />;

  return (
    <>
      <MetaTags>
        <title>Experience</title>
        <meta id="meta-description" name="description" content="Input your experience" />
      </MetaTags>
      <ThreeDotsBgLayout
        title={context && context.age <= 24 ? "Your work experience" : "Your experience"}
        subtitle="This will help us match your skills to careers of interest."
      >
        <Row>
          <Col xs={12} md={8} mdOffset={2}>
            <QuestionWrap>
              {context.employment_status === 'employed' && (
                  <InputWrap>
                    <Label>Current job</Label>
                    <HintText>Select an option from those available. If you cannot find a suitable career simply leave it blank.</HintText>
                    <AutocompleteField
                      placeholder="e.g. accountant, builder..."
                      suggestions={context.job_autocomplete_options}
                      handleChange={it => handleInputFieldChange(it, careers[0].id)}
                      error={careers[0].error}
                      onBlur={e => onFieldBlur(e.target.value, careers[0].id)}
                      testid="current_job"
                    />
                  </InputWrap>
                )
              }
              <Label>{context && context.employment_status === 'student' ? "Your experience of the world of work" : context.age <= 24 ? "Work experience and internships" : "Past jobs"}</Label>
              <HintText>{
                context && context.employment_status === 'student' ? "Select job titles for the careers that you have had some experience in, including virtual or in-person work experience, completing an online course, work shadowing or internships. You should also include any jobs you may have had." : context.age <= 24 ? "Select careers that you have had at least 1 week's experience in. You should also include any jobs you may have had."
                : "Include all jobs that you have had at least 6 months experience in"}
              </HintText>
              {context && context.age <= 24 && (
                <HintText>Don't worry if you don't have any work experience yet though, you can leave this blank.</HintText>
              )}
              {careers.map(career => {
                if (career.id > 0) {
                  return (
                    <SingleInputWrap key={career.id}>
                      <AutocompleteField
                        placeholder="e.g. accountant, builder..."
                        suggestions={context.job_autocomplete_options}
                        handleChange={it => handleInputFieldChange(it, career.id)}
                        error={career.error}
                        onBlur={e => onFieldBlur(e.target.value, career.id)}
                      />
                  </SingleInputWrap>
                  )
                } else {
                  return null
                }
              })}
              <ButtonWrap>
                {validateForm() ? (
                  <Amplitude userProperties={{
                    current_career_entered: data.current_esco_career ? true : false,
                    num_past_careers: (data.past_esco_careers ? data.past_esco_careers.length : null),
                    current_career: data.current_esco_career ? getNameByID(data.current_esco_career.id, context.job_autocomplete_options) : null,
                    past_careers: data.past_esco_careers ? getNamesByIDs(data.past_esco_careers, context.job_autocomplete_options, "id") : null,
                  }}>
                    {({ logEvent }) => (
                      <PrimaryButton onClick={() => submitForm(logEvent)} data-testid="next">Next</PrimaryButton>
                    )}
                  </Amplitude>
                ) : (
                  <SecondaryButton onClick={() => submitForm(null)} data-testid="skip">Skip</SecondaryButton>
                )}
                {generalError && <Error>{generalError.map(item => item)}</Error>}
              </ButtonWrap>
            </QuestionWrap>
          </Col>
        </Row>
        <RestartFlow largeMarginTop endpoint={context.endpoint} />
      </ThreeDotsBgLayout>
    </>
  );
};

export default ExperienceScreen;

const InputWrap = styled.div`
  margin-bottom: 20px;
`;

const SingleInputWrap = styled.div`
  margin-bottom: 12px;
`;

const ButtonWrap = styled.div`
  margin-top: 52px;
  text-align: center;

  @media(max-width: ${layout.breakpoints.MD}) {
    margin-top: 32px;
  }
`;

const QuestionWrap = styled.div`
  max-width: 382px;
  margin: auto;
`;
