/* Education screen > GCSEs tab
   Ref. EducationScreen.js
   May need to select "No" for "Do you have a degree?" to see the GCSEs tab */

import React, { useState } from 'react';
import styled from 'styled-components';

import { copyArray } from '../../utils/copyArray';

import { Label } from '../../ui/typography/Typography';
import Multiselect from '../../components/multiselect/Multiselect';
import IncrementInput from '../../components/IncrementInput';


const Gcses = ({ handleChange }) => {
  let acValues = [];
  [...Array(21)].map((item, i) => {
     return acValues.push({id: i, name: i})
  });

  const gcseValues = [
    { name: 'Maths', id: 'maths', selected: false },
    { name: 'English', id: 'english', selected: false },
    { name: 'Science', id: 'science', selected: false },
    { name: 'A foreign language', id: 'foreign_language', selected: false },
  ];

  const [ac, setAc] = useState(acValues[0]);
  const [gcse, setGcse] = useState(gcseValues);

  const  handleGsceUpdate = (id) => {
    let copy = copyArray(gcse);
    // eslint-disable-next-line no-unused-vars
    for (const value of copy) {
      if (value.id === id) {
        value.selected = !value.selected;
      }
    }
    setGcse(copy);
    prepData(copy, ac);
  };

  const handleAcUpdate = (item) => {
    setAc(item);
    prepData(gcse, item);
  };

  const prepData = (data_gcse, data_ac) => {
    const math = data_gcse.find(it => (it.id === 'maths'));
    const english = data_gcse.find(it => (it.id === 'english'));
    const science = data_gcse.find(it => (it.id === 'science'));
    const foreign_language = data_gcse.find(it => (it.id === 'foreign_language'));

    handleChange({
      num_gcses: data_ac.id,
      have_gcse_maths: math.selected,
      have_gcse_english: english.selected,
      have_gcse_science: science.selected,
      have_gcse_foreign_language: foreign_language.selected,
    });
  };


  return (
    <>
      <InputWrap>
        <Label>How many GCSEs do you have at grade A* to C?</Label>
        <IncrementInput values={acValues} startValue={acValues[0]} handleChange={item => handleAcUpdate(item)} />
      </InputWrap>
      <InputWrap>
        <Label>Which of the following subjects do you have GCSEs in at grade A* to C?</Label>
        <Multiselect values={gcseValues} handleChange={item => handleGsceUpdate(item)} />
      </InputWrap>
    </>
  );
};

export default Gcses;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;
