/* Payment form and card collection details within PaymentScreen.js */

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { injectStripe } from 'react-stripe-elements';
import ReactFacebookPixel from 'react-facebook-pixel';
// import ReactTiktokPixel from 'react-tiktok-pixel';
import { Amplitude } from "@amplitude/react-amplitude";
import { Col, Row } from 'react-styled-flexboxgrid';

import { useStateValue } from '../../services/state';
import { validateEmail } from '../../utils/validation';
import { createSubmitData } from '../../utils/helper';
import colors from '../../config/colors';
import layout from '../../config/layout';

import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import CardSection from './CardSection';
import Input from '../../ui/form/Input';
import Select from '../../ui/form/Select';
import Checkbox from '../../ui/form/Checkbox';
import { PSmall, P, Label, H4 } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import { facebookPixelInit } from '../../facebookPixelInit';
import IconTick from '../../assets/icon-tick.svg';

const CheckoutForm = ({ elements, stripe, setPackagePurchased, upgrade, contactDetails }) => {
  const [{ user_id, context }] = useStateValue();
  const [ checkoutState, setCheckoutState ] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emailConfirm: "",
    source: null,
    subscribed: false,
    package: (upgrade ? "upgrade" : null),
  });

  const [ processing, setProcessing ] = useState(false);
  const [ error, setError ] = useState();
  const [ errors, setErrors ] = useState({
    first_name: "",
    last_name: "",
    email: "",
    emailConfirm: "",
    card_details: "",
  });

  const nullPrice = {
    pence: null,
    text: null,
  }
  const [ price, setPrice ] = useState(upgrade ? {pence: context.upgrade_price_pence, text: context.upgrade_price_text} : nullPrice);

  const validateField = e => {

    if (e.elementType === "card") {

      const errorMsg = checkoutState.are_card_details_valid !== true ? "You must provide valid card details" : null;
      setErrors({
        ...errors,
        "card_details": errorMsg
      });

    } else {

      const value = e.target.value;
      const name = e.target.name;
  
      if (name === "first_name") {
        const errorMsg = value === "" ? "You must provide your first name" : null;
        setErrors({
          ...errors,
          [name]: errorMsg
        });
      } else if (name === "last_name") {
        const errorMsg = value === "" ? "You must provide your last name" : null;
        setErrors({
          ...errors,
          [name]: errorMsg
        });
      } else if (name === 'email' || name === 'emailConfirm') {
        const email = name === 'email' ? (value ? value.toLowerCase().trim() : "") : (checkoutState.email ? checkoutState.email.toLowerCase().trim() : "");
        const emailConfirm = name === 'emailConfirm' ? (value ? value.toLowerCase().trim() : "") : (checkoutState.emailConfirm ? checkoutState.emailConfirm.toLowerCase().trim() : "");
        let newErrors = {};
        if (name === 'email') {
          newErrors["email"] = value === "" ? "You must provide your email" : (!validateEmail(value) ? "You must provide a valid email" : null);
        }
        newErrors["emailConfirm"] = (emailConfirm !== "" && emailConfirm !== email ? "Email doesn't match" : null);
        setErrors({
          ...errors,
          ...newErrors,
        });
      }

    }

  };

  const validateForm = (addErrors) => {
    let isValid = true;
    let newErrors = {
      first_name: '',
      last_name: '',
      email: '',
      emailConfirm: '',
    };

    if (context.ask_for_contact_details && checkoutState.first_name === "" && !upgrade) {
      newErrors = {...newErrors, first_name: "This field is required"};
      isValid = false;
    }

    if (context.ask_for_contact_details && checkoutState.last_name === "" && !upgrade) {
      newErrors = {...newErrors, last_name: "This field is required"};
      isValid = false;
    }

    if ( context.ask_for_contact_details &&
         (checkoutState.email.length === "" || !validateEmail(checkoutState.email)) &&
         !upgrade ) {
      newErrors = {...newErrors, email: "This is not a valid email"};
      isValid = false;
    }

    const email = (checkoutState.email ? checkoutState.email.toLowerCase().trim() : "");
    const emailConfirm = (checkoutState.emailConfirm ? checkoutState.emailConfirm.toLowerCase().trim() : "");
    if (context.ask_for_contact_details && emailConfirm !== email && !upgrade) {
      newErrors = {...newErrors, emailConfirm: "Email doesn't match"};
      isValid = false;
    }

    if (context && !context.utm_source && (!checkoutState.source || !checkoutState.source.id) && !upgrade) {
      newErrors = {...newErrors, source: "Please tell us where you heard about us"};
      isValid = false;
    }

    if (checkoutState.are_card_details_valid !== true) {
      isValid = false;
    }

    addErrors && setErrors(newErrors);
    return isValid;
  };

  const getStripeResponse = async () => {
    const cardElement = elements.getElement('card');
    try {
      const stripeResponse = await stripe.confirmCardPayment(
        (checkoutState['package'] === "premium" ? context.stripe_premium_payment_intent.client_secret : (upgrade ? context.stripe_upgrade_payment_intent.client_secret : context.stripe_payment_intent.client_secret)),
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: ((!context.ask_for_contact_details || upgrade) && contactDetails ? `${contactDetails.first_name} ${contactDetails.last_name}` : `${checkoutState.first_name} ${checkoutState.last_name}`),
              email: ((!context.ask_for_contact_details || upgrade) && contactDetails ? contactDetails.email : checkoutState.email),
            }
          }
        }
      );
      return stripeResponse;
    } catch(error) {
      return error.response;
    }
  };

  const handleSubmit = async (e, logEvent) => {
    e.preventDefault();
    if (!validateForm() || processing) return null;
    setProcessing(true);

    const stripeResponse = await getStripeResponse();
    if (stripeResponse.error) {
      setProcessing(false);
      setError('Payment failed: ' + stripeResponse.error.message);
    } else if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
      const submitData = createSubmitData({
        user_id: user_id,
        package: checkoutState.package,
        stripe_payment_intent: (checkoutState['package'] === "premium" ? context.stripe_premium_payment_intent : (upgrade ? context.stripe_upgrade_payment_intent : context.stripe_payment_intent)),
        contact_details: (!context.ask_for_contact_details || upgrade) ? null : {
          first_name: checkoutState.first_name,
          last_name: checkoutState.last_name,
          email: checkoutState.email,
          subscribed: checkoutState.subscribed,
        },
        source: (upgrade || context.utm_source) ? null : (checkoutState.source ? checkoutState.source.id : null),
      });
      axios.post('/api/v1/confirm_payment_client', submitData).then((res) => {
        setProcessing(false);
        if (res.data && res.data.payment_succeeded === true) {
          if (price.pence > 0) {
            // Facebook
            facebookPixelInit(user_id);
            ReactFacebookPixel.track(
              'Purchase',
              {
                value: (price.pence/100),
                currency: context.price_currency,
                external_id: user_id,
              },
              { eventID: res.data.fb_event_id_payment },
            );

            // Amplitude
            logEvent('Payment', {
              num_careers: context.num_careers,
              price_pence: price.pence,
              price_currency: context.price_currency,
              price_text: price.text,
              source: checkoutState.source ? checkoutState.source.label : (context.utm_source ? context.utm_source : null),
            });

            // Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'payment',
              'value': (price.pence/100),
              'currency': context.price_currency,
            });

            // Tiktok
            // ReactTiktokPixel.track('Purchase', {
            //   'value': (price.pence/100),
            //   'currency': context.price_currency,
            // });
          }
          setPackagePurchased(checkoutState.package);
        } else if (res.data && res.data.payment_failed_message) {
          setError("Payment failed on server: " + res.data.payment_failed_message);
          console.log("Payment failed on server: " + res.data.payment_failed_message);
        } else {
          // Else it has not succeeded or failed (probably in progress somehow), so don't make any updates
        }
      });
    }
  };

  const handleInputChange = (value, name) => {
    if (name === "package") {
      if (checkoutState['package'] === value) {
        setCheckoutState({
          ...checkoutState,
          [name]: null,
        });
        setPrice(nullPrice);
      } else {
        setCheckoutState({
          ...checkoutState,
          [name]: value,
        });
        if (value === "standard") {
          setPrice({
            pence: context.unlock_price_pence,
            text: context.unlock_price_text,
          });
        } else if (value === "premium") {
          setPrice({
            pence: context.unlock_premium_price_pence,
            text: context.unlock_premium_price_text,
          });
        }
      }
    } else {
      setCheckoutState({
        ...checkoutState,
        [name]: value,
      });
    }
  };

  const standardDescription = "See your top careers and personalised entry routes 🔥";
  const premiumDescriptionLine1 = "Everything in standard, plus..";
  const premiumDescriptionLine2 = "transferable skills, videos and browse all 500+ careers 💪";

  return (
    <Container>
      <Amplitude>
        {({ logEvent }) => (
          <Form onSubmit={(e) => handleSubmit(e, logEvent)}>
            {!upgrade && (
              <>
                <InputWrap>
                  <Label>Choose your package<Required> *</Required></Label>
                  <CardContainer pt>
                    <CheckboxContainer onClick={(e) => handleInputChange('standard', 'package')} selected={checkoutState['package'] === 'standard'}>
                      <RowStyled>
                        <Col xs={6} md={6}>
                          <H4 align="left">Standard</H4>
                        </Col>
                        <Col xs={6} md={6}>
                          <H4 align="right">{context.unlock_price_text}</H4>
                        </Col>
                      </RowStyled>
                      <P align="center" margin="16px 0 0 0">{standardDescription}</P>
                      <P align="center" margin="16px 0 0 0" italic>One-off charge</P>
                    </CheckboxContainer>
                  </CardContainer>
                  <CardContainer>
                    <CheckboxContainer onClick={(e) => handleInputChange('premium', 'package')} selected={checkoutState['package'] === 'premium'}>
                      <RowStyled>
                        <Col xs={6} md={6}>
                          <H4 align="left">Premium</H4>
                        </Col>
                        <Col xs={6} md={6}>
                          <H4 align="right">{context.unlock_premium_price_text}</H4>
                        </Col>
                      </RowStyled>
                      <P align="center" margin="16px 0 0 0">{premiumDescriptionLine1}<br />{premiumDescriptionLine2}</P>
                      <P align="center" margin="16px 0 0 0" italic>One-off charge</P>
                    </CheckboxContainer>
                  </CardContainer>
                </InputWrap>
                {context.ask_for_contact_details && (
                  <>
                    <InputWrap>
                      <Input label="First name" type="text" name="first_name" onBlur={validateField} handleChange={e => handleInputChange(e, 'first_name')} error={errors.first_name} required />
                    </InputWrap>
                    <InputWrap>
                      <Input label="Last name" type="text" name="last_name" onBlur={validateField} handleChange={e => handleInputChange(e, 'last_name')} error={errors.last_name} required />
                    </InputWrap>
                    <InputWrap>
                      <Input label="Email" type="text" name="email" onBlur={validateField} handleChange={e => handleInputChange(e, 'email')} error={errors.email} required />
                    </InputWrap>
                    <InputWrap>
                      <Input label="Confirm email" type="text" name="emailConfirm" onBlur={validateField} handleChange={e => handleInputChange(e, 'emailConfirm')} error={errors.emailConfirm} required />
                    </InputWrap>
                  </>
                )}
                {context && !context.utm_source && (
                  <InputWrap>
                    <Select
                      label="Where did you hear about us?"
                      options={[
                        {label: 'Word of mouth', id: 'word_of_mouth'},
                        {label: 'Instagram', id: 'instagram'},
                        {label: 'Facebook', id: 'facebook'},
                        {label: 'Google', id: 'google'},
                        {label: 'Press', id: 'press'},
                        {label: 'Career coach', id: 'career_coach'},
                        {label: 'Podcast', id: 'podcast'},
                        {label: 'Other', id: 'other'},
                      ]}
                      handleChange={e => handleInputChange(e, 'source')}
                      error={errors.source}
                      required
                    />
                  </InputWrap>
                )}
                {context.ask_for_contact_details && (
                  <InputWrap>
                    <Checkbox
                      label="Check this box if you're happy for us to stay in touch, so we can better help you discover and land your dream job"
                      name="subscribed"
                      handleChange={e => handleInputChange(e, 'subscribed')}
                    />
                  </InputWrap>
                )}
              </>
            )}
            <CardSection
              onBlur={validateField}
              handleChange={(e) => {
                if (e !== null) {
                  handleInputChange(e.complete, 'are_card_details_valid');
                }
              }}
              error={errors["card_details"]}
              required
            />
            <ButtonWrap>
              {processing ? (
                <PSmall align="center">Processing payment...</PSmall>
              ) : validateForm(false) ? (
                <PrimaryButton>Pay {price.text}</PrimaryButton>
              ) : !checkoutState.package ? (
                <Tooltip text="Please choose a package above">
                  <GrayButton disabled>Choose package</GrayButton>
                </Tooltip>
              ) : (
                <Tooltip text="Please enter your details above">
                  <GrayButton disabled>Pay {price.text}</GrayButton>
                </Tooltip>
              )}
            </ButtonWrap>
            {error && <Error align="center">{error}</Error>}
            {!upgrade && checkoutState.package && (
              <PayButtonSubText align="center">By paying, you'll have access to our <b>{checkoutState.package}</b> package for <b>1 year</b> and you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a></PayButtonSubText>

            )}
            {upgrade && (
              <PayButtonSubText align="center">Switch to premium for {context.upgrade_price_text}</PayButtonSubText>
            )}
          </Form>
        )}
      </Amplitude>
    </Container>
  );
};

export default injectStripe(CheckoutForm);

const Container = styled.div`
  padding-bottom: 20px;
  color: ${props => props.selected ? colors.WHITE :  colors.LCG_DARK_GREY};
`;

const Form = styled.form`
  max-width: 450px;
  margin: auto;
  position: relative;

  @media(max-width: ${layout.breakpoints.MD}) {
    max-width: 352px;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const CardContainer = styled.div`
  margin-bottom: 16px;
  ${props => props.pt && `padding-top: 8px;`};
`;

const CheckboxContainer = styled.button`
  background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE_FADE :  colors.BG_LIGHT};
  color: ${props => props.selected ? colors.WHITE :  colors.LCG_DARK_GREY};
  border-radius: 20px;
  box-shadow: 0px 0px 0px 2px ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_GREY}, 0px 8px 16px ${props => props.selected ? 'transparent' : colors.LCG_LIGHT_GREY};
  cursor: pointer;
  display: block;
  padding: 32px 72px;
  position: relative;
  width: 100%;
  border: 0;

  @media(max-width: ${layout.breakpoints.MD}) {
    padding: 32px 52px;
  }

  &::after {
    background-color: ${props => props.selected ? colors.LCG_LIGHT_PURPLE :  colors.LCG_LIGHT_GREY};
    color: ${props => props.selected ? colors.WHITE :  colors.LCG_DARK_GREY};
    background-image: url(${IconTick});
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 30%;
    content: "";
    height: 32px;
    width: 32px;
    position: absolute;
    left: 16px;
    top: 16px;

    @media(max-width: ${layout.breakpoints.MD}) {
      height: 24px;
      width: 24px;
    }
  }

  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => props.selected ? colors.LCG_LIGHT_PURPLE : colors.LCG_LIGHT_GREY}, 0px 8px 16px ${props => props.selected ? 'transparent' : colors.LCG_LIGHT_GREY};
  }

  &:disabled {
    cursor: default;
    background-color: ${colors.GRAY_LIGHT};

    &:hover {
      background-color: ${colors.GRAY_LIGHT};
    }
  }
`;

const RowStyled = styled(Row)`
  padding-left: 20px;
  padding-right: 20px;
`

const Error = styled(P)`
  color: #FA054F;
`;

const PayButtonSubText = styled(PSmall)`
  margin-top: 20px;
`;

const Required = styled.span`
  color: ${colors.LCG_DARK_PURPLE};
`;
