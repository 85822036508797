/* Payment part of SignupScreen.js */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { injectStripe } from 'react-stripe-elements';
import axios from 'axios';

import { useStateValue } from '../../services/state';
import layout from '../../config/layout';
import { getPriceText, createSubmitData } from '../../utils/helper';

import { PrimaryButton, GrayButton } from '../../ui/buttons/Buttons';
import CardSection from '../PaymentScreen/CardSection';
import { PSmall, P, Label } from '../../ui/typography/Typography';
import Tooltip from '../../components/Tooltip';
import Loader from  '../../components/Loader';
import IncrementInput from '../../components/IncrementInput';

const StripeForm = ({ elements, stripe, processing, formValues, formErrors, price, setPrice, error, paymentIntent, setPaymentIntent, validateField, validateForm, handleInputChange, submitForm, checkingEmailUniqueness, checkingEndpointUniqueness, defaultNumUserLicences, b2bPackage, setB2bPackage, hasSidePadding }) => {

  const [{ context }] = useStateValue();

  useEffect(() => {
    updatePriceInformation(formValues["num_licences"]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const handleInputChangeLocal = (value, name) => {
    if (name === "num_licences") {
      if (context) {
        if (context.inputChangesToProcess == null) {
          context.inputChangesToProcess = [];
        }
        context.inputChangesToProcess.push(value);
        if (!context.updatingPriceInformation) {
          updatePriceInformation();
        }
      }
    }
    handleInputChange(value, name);
  };

  const updatePriceInformation = (value) => {

    context.updatingPriceInformation = true;

    let newNumLicences = null;
    if (value) {
      newNumLicences = value;
    } else if (context && context.inputChangesToProcess && context.inputChangesToProcess.length > 0) {
      newNumLicences = context.inputChangesToProcess[0];
      context.inputChangesToProcess = context.inputChangesToProcess.slice(1);
    }

    if (newNumLicences != null && newNumLicences > 0 && context && context.b2b_packages) {

      let found = false;
      for (let i=context.b2b_packages.length-1; i>=0 && !found; i--) {
        if (context.b2b_packages[i].price_pence !== null && newNumLicences >= context.b2b_packages[i].min_num_users) {

          setPaymentIntent(null);

          setB2bPackage(context.b2b_packages[i]);

          const pricePence = newNumLicences * context.b2b_packages[i].price_pence;
          const vatPence = newNumLicences * context.b2b_packages[i].vat_pence;
          const totallPricePence = pricePence + vatPence;

          const priceText = getPriceText(pricePence);
          const vatText = getPriceText(vatPence);
          const totalPriceText = getPriceText(totallPricePence);

          setPrice({
            price_pence: pricePence,
            price_text: priceText,
            vat_pence: vatPence,
            vat_text: vatText,
            total_price_pence: totallPricePence,
            total_price_text: totalPriceText
          });

          // Get a new payment intent with the new price
          const submitData = createSubmitData({
            price_pence: totallPricePence,
            num_user_licences: newNumLicences,
          });
          axios.post('/api/v1/get_stripe_payment_intent', submitData).then(res => {
            setPaymentIntent(res.data.stripe_payment_intent);
            if (context && context.inputChangesToProcess && context.inputChangesToProcess.length > 0) {
              // Process the next one in the queue now that the last has been updated
              updatePriceInformation();
            } else {
              context.updatingPriceInformation = false;
            }
          });

          found = true;
        }
      }

    } else {
      context.updatingPriceInformation = false;
    }

  };

  let perUserPriceText = null;
  if (formValues.num_licences !== null) {
    perUserPriceText = b2bPackage.price_text + " + VAT per user";
    if (b2bPackage.discount) {
      perUserPriceText += " (" + b2bPackage.discount + " discount applied";
      if (b2bPackage.min_num_users > 1) {
        perUserPriceText += " for " + b2bPackage.min_num_users + " users)";
      } else {
        perUserPriceText += ")";
      }
    }
  }

  return (
    <Container hasSidePadding={hasSidePadding}>
      <NumLicencesLabel>Number of user licences</NumLicencesLabel>
      <IncrementInput
        lowestValue={1}
        highestValue={10000}
        startValue={defaultNumUserLicences}
        canEdit
        width="180px"
        handleChange={(it) => handleInputChangeLocal(it, 'num_licences')}
        extra_text_to_show={formValues.num_licences !== null && price.price_text ? `${price.price_text} + VAT` : null}
      />
      {perUserPriceText && (
        <NumLicencesSubText>{perUserPriceText}</NumLicencesSubText>
      )}
      <CardSection
        onBlur={validateField}
        handleChange={(e) => {
          if (e !== null) {
            handleInputChange(e.complete, 'are_card_details_valid');
          }
        }}
        error={formErrors["card_details"]}
      />
      <PayButtonWrap>
        {processing ? (
          <PSmall align="center">Processing payment...</PSmall>
        ) : validateForm(false) ? (
          paymentIntent && checkingEmailUniqueness !== true && checkingEndpointUniqueness !== true ? (
            <PrimaryButton onClick={() => submitForm(elements, stripe)}>Pay {formValues.num_licences !== null && price.total_price_text}</PrimaryButton>
          ) : (
            <Loader />
          )
        ) : (
          <Tooltip text="Please enter your details above">
            <GrayButton disabled>Pay {formValues.num_licences !== null && price.total_price_text}</GrayButton>
          </Tooltip>
        )}
      </PayButtonWrap>
      {error && <Error align="center">{error}</Error>}
      {formValues.num_licences !== null && (
        <PayButtonSubText align="center">Pay {price.price_text} + {price.vat_text} VAT for {formValues.num_licences.toLocaleString()} user licence{formValues.num_licences !== 1 ? `s` : ``}</PayButtonSubText>
      )}
      <PayButtonSubText align="center">By paying you agree to our <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></PayButtonSubText>
    </Container>
  );
};

export default injectStripe(StripeForm);

const Container = styled.div`
  margin: auto;
  max-width: 450px;

  @media(max-width: ${layout.breakpoints.MD}) {
    ${props => props.hasSidePadding && "padding-left: 20px;"}
    ${props => props.hasSidePadding && "padding-right: 20px;"}
  }
`;

const NumLicencesLabel = styled(Label)`
  padding-top: 12px;
`

const NumLicencesSubText = styled(PSmall)`
  margin-bottom: 32px;
  margin-top: 12px;
`

const PayButtonWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
  padding-top: 8px;
`;

const Error = styled(P)`
  color: #FA054F;
`;

const PayButtonSubText = styled(PSmall)`
  margin-top: 20px;
`;
