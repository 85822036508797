import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../config/colors';
import { H4, H5 } from '../ui/typography/Typography';
import TextArea from '../ui/form/TextArea';
import { PrimaryButton } from '../ui/buttons/Buttons';
import axios from 'axios';
import { useStateValue } from "../services/state";
import fingerprint from 'fingerprintjs';
import Checkbox from '../ui/form/Checkbox';
import IconStarWhite from '../assets/icons/icon-star-white.svg';
import IconStarGray from '../assets/icons/icon-star-gray.svg';
import IconStar from '../assets/icons/icon-star.svg';
import { Amplitude } from "@amplitude/react-amplitude";

const ResultsFeedback = ({ title, textAreaTitle, placeholder, buttonLabel, handleSubmit }) => {
  const [stars, setStars] = useState(0);
  const [hoveredStars, setHoveredStars] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [permissionToShare, setPermissionToShare] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [{ user_id, rating }, dispatch] = useStateValue();

  const onSubmit = () => {
    setSubmitted(true);
    handleSubmit({
      stars: stars,
      feedback: feedback,
      permission_to_share: permissionToShare,
    });
  };

  useEffect(() => {
    setStars(rating);
  }, [rating]);

  const submitStars = (starCount, logEvent) => {
    logEvent('Submitted Rating', {rating: starCount});
    setStars(starCount);
    const userFingerprint = (new fingerprint()).get();
    const userAgent = navigator.userAgent;
    axios.post('/api/v1/handleStarRatingInput', {
      user_id: user_id,
	    fingerprint: userFingerprint,
	    user_agent: userAgent,
	    rating: starCount,
    }).then(res => {
      dispatch({type: 'update', values: {rating: starCount}});
    })
  };

  return (
    <Container>
      <Title align="center">{title}</Title>
      <Amplitude userProperties={{rating: stars}}>
        {({ logEvent }) => (
          <Stars>
            {
              [...Array(5)].map((item, i) => (
                  <Star
                    submitted={submitted}
                    selected={stars >= i + 1}
                    key={i}
                    onClick={() => submitStars(i + 1, logEvent)}
                    onMouseEnter={() => setHoveredStars(i + 1)}
                    onMouseLeave={() => setHoveredStars(0)}
                    isHovered={hoveredStars >= i + 1}
                  />
              ))
            }
          </Stars>
        )}
      </Amplitude>
      {stars > 0 && (
        <>
          <H5 align="center">{textAreaTitle}</H5>
          <TextAreaContainer >
            <TextArea
              disabled={submitted}
              handleChange={text => setFeedback(text)}
              placeholder={placeholder}
            />
          </TextAreaContainer>
          {stars === 5 && (
            <InputWrap>
              <Checkbox
                defaultChecked={permissionToShare}
                handleChange={it => setPermissionToShare(it)}
                label="Permission for us to share your feedback in our marketing materials"
                name="permission_to_share"
                disabled={submitted}
              />
            </InputWrap>
          )}
          <Center>
            {stars > 0 && (
              <PrimaryButton onClick={onSubmit}>{buttonLabel}</PrimaryButton>
            )}
          </Center>
        </>
      )}
    </Container>
  );
};

export default ResultsFeedback;

ResultsFeedback.propTypes = {
  title: PropTypes.string.isRequired,
  textAreaTitle: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
};

const Container = styled.div``;

const Stars = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 248px;
  height: 48px;
  display: flex;
  margin: 8px auto 32px;
`;

const Star = styled.a`
  background-color: ${props => props.selected || props.isHovered ? (props.submitted ? colors.TEXT_BODY : colors.LCG_DARK_PURPLE) : colors.GRAY_FADE};
  background-image: url(${props => props.selected || props.isHovered ? IconStarWhite : (props.submitted ? IconStarGray : IconStar) });
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  flex: 1;
  height: 100%;
  margin-left: 2px;

  &:first-child {
    margin-left: 0;
  }
`;

const TextAreaContainer = styled.div`
  max-width: 544px;
  margin: 16px auto 16px;
`;

const Center = styled.div`
  text-align: center;
`;

const Title = styled(H4)`
  margin-top: 0;
`;

const InputWrap = styled.div`
  max-width: 544px;
  margin: 16px auto 32px;
`;
