/* Screen to show data from API endpoints */

import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';

const APIScreen = () => {

  const [ data, setData ] = useState(null);

  let retrievingData = false;

  (async () => {
    if (!retrievingData && !data) {
      retrievingData = true;
      const retrievedData = await axios.get(window.location.pathname + window.location.search);
      if (retrievedData.data) {
        if (retrievedData.headers && retrievedData.headers["content-disposition"] && retrievedData.headers["content-disposition"].includes("attachment")) {
          let filename = "data.csv";
          let contentDispositionHeaders = retrievedData.headers["content-disposition"].split(";");
          for (let i=0; i<contentDispositionHeaders.length; i++) {
            if (contentDispositionHeaders[i].includes("filename")) {
              filename = contentDispositionHeaders[i].split("=")[1];
            }
          }
          const url = window.URL.createObjectURL(new Blob([retrievedData.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } else {
          setData(retrievedData.data);
        }
        retrievingData = false;
      }
    }
  })();

  return (
    <>
      <MetaTags>
        <title>API screen</title>
        <meta id="meta-description" name="description" content="API screen" />
        <meta name="robots" content="noindex" />
      </MetaTags>
      <Container>
        {!data && "Loading..."}
        {data && (
          <>
            {ReactHtmlParser(data)}
          </>
        )}
      </Container>
    </>
  );

}

export default APIScreen;

const Container = styled.div`
  margin: 12px 8px 32px 8px;
`;
