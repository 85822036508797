/* Quiz question (pair) screen */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import MetaTags from 'react-meta-tags';

import { useStateValue } from "../../services/state";
import { useRouteMatch } from 'react-router-dom';
import { useGetScreenData, fetchData } from '../../hooks/getDataHooks';
import { useHistory } from 'react-router-dom';
import { createSubmitData } from '../../utils/helper';

import { SecondaryButton } from '../../ui/buttons/Buttons';
import InfoScreen from '../InfoScreen';
import CareerChoice from './CareerChoice';
import PreQuizInfoScreen from './PreQuizInfoScreen';
import RestartFlow from '../RestartFlow';
import LoadingScreen from '../LoadingScreen';

const Pair = () => {
  const [{ showPreQuizInfoScreen, context, user_id, loading }, dispatch] = useStateValue();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ loadingText, setLoadingText ] = useState();
  let match = useRouteMatch("/:userId/quiz/:questionId");
  const history = useHistory();

  useGetScreenData('quiz', setIsLoading, match.params.userId, match.params.questionId);

  useEffect(() => {
   return history.listen((location, type) => {
     const getData = async () => {
      dispatch({type: 'startLoading'});
      let arr = location.pathname.split('/').filter(a => a !== '');
      const resp = await fetchData('quiz', match.params.userId, arr[arr.length - 1]);
      if (resp.status === 200) {
       dispatch({type: 'updateResponse', data: resp.data});
      }
    };
    type === 'POP' && getData()
   })
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history])

  const onMessageContinue = () => {
    dispatch({type: 'update', values: {context: {...context, message_body: null}}})
  };

  if (isLoading || isLoading) return <LoadingScreen text={loadingText} />

  if (showPreQuizInfoScreen) return <PreQuizInfoScreen />;

  if (context.message_body) {
    const infoTitle = context.message_body[0];
    const infoBody = [...context.message_body];
    infoBody.splice(0,1);

    return (
      <InfoScreen
        title={infoTitle}
        text={infoBody}
        buttonLabel="Keep going"
        handleButtonClick={onMessageContinue}
       />
    );
  }

  const handlePairSelection = (value) => {
    setLoadingText(context.loading_message);
    dispatch({type: 'startLoading'});
    const formData = createSubmitData({
      user_id: user_id,
      pair_id: context.pair.id,
      a_id: context.pair.a.id,
      b_id: context.pair.b.id,
      a_won: value,
    });

    axios.post('/api/v1/selectionMade', formData).then(resp => {
      const screen = resp.data.screen;
      if (screen.name === 'quality-selection') {
        setIsLoading(true);
        setTimeout(() => {
          dispatch({type: 'updateResponse', data: resp.data});
          history.push(`/${resp.data.user_id}/${screen.name}/${screen.context.question_number ? screen.context.question_number : ''}`)
        }, 2500);
      } else {
        history.push(`/${resp.data.user_id}/${screen.name}/${screen.context.question_number ? screen.context.question_number : ''}`)
        dispatch({type: 'updateResponse', data: resp.data});
      }

    });
  };

  return (
    <>
      <MetaTags>
        <title>Question {context.question_number}</title>
        <meta id="meta-description" name="description" content="Choose Between..." />
      </MetaTags>
      <CareerChoice
        pair={context.pair}
        onClick={handlePairSelection}
        pairs_judged={context.question_number - 1}
        num_pairs_to_judge={context.num_pairs_to_judge}
        loading={loading}
        loading_text={context.loading_message}
      />
      {context.question_number > 1 &&
        <BackButtonContainer>
          <SecondaryButton onClick={() => {history.goBack()}}>Back</SecondaryButton>
        </BackButtonContainer>
      }
      <RestartFlow largeMarginTop endpoint={context.endpoint} />
    </>
  );
};

export default Pair;

const BackButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0 32px;
`;
