import ReactFacebookPixel from 'react-facebook-pixel';

var isInit = false;

export const facebookPixelInit = (userID) => {

  if (!isInit) {

    isInit = true;

    const options = {
      autoConfig: true,   // set pixel’s autoConfig
      debug: false,       // enable logs
    };

    const advancedMatching = { external_id: userID };

    ReactFacebookPixel.init('271820550624443', advancedMatching, options);

    return true;

  } else {
    return false;
  }

};
